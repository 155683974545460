import { useFeatureFlag, Feature } from '@src/components/FeatureFlag'
import {
  useIsDexcomSteloSensorMode,
  useHasSyncedSteloRealtime,
  useHasViewedSteloMigrationNotice,
} from '@src/utils/hooks'

export const useShowMigratedSteloSetupPopup = () => {
  const isSteloSignupEnabled = useFeatureFlag(Feature.SteloSignUp)
  const isDexcomSteloSensorMode = useIsDexcomSteloSensorMode()
  const hasSyncedSteloRealtime = useHasSyncedSteloRealtime()
  const hasViewedSteloMigrationNotice = useHasViewedSteloMigrationNotice()

  if (!isSteloSignupEnabled) {
    return false
  }

  if (!isDexcomSteloSensorMode) {
    return false
  }

  // member has never created a stelo connection
  if (hasSyncedSteloRealtime) {
    return false
  }

  // member has already viewed the stelo migration notice, show the popup
  return hasViewedSteloMigrationNotice
}
