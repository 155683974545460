import React from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text } from '@components/base'

export const DexcomConnectionSuccessfulScreen = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()

  const message = "You're all set. You'll begin to see glucose data once it is available."

  const onNextPress = () => {
    navigation.navigate('Drawer')
  }

  return (
    <SafeAreaView edges={['top', 'bottom']} style={[styles.container]}>
      <View style={styles.contentContainer}>
        <Icon weight="fill" style={styles.icon} name="seal-check" size={48} />
        <Text type="title-2" style={styles.titleText}>
          You're All Set! 🎉
        </Text>

        <Text type="large" style={styles.bodyText}>
          {message}
        </Text>
      </View>

      <View style={styles.button}>
        <Button type="primary" size="block" onPress={onNextPress} accessibilityLabel="Continue">
          Done
        </Button>
      </View>
    </SafeAreaView>
  )
}

const themedStyles = StyleService.create({
  icon: {
    marginTop: 140,
    color: 'theme.success.base',
  },
  container: {
    flex: 1,
    paddingVertical: 16,
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingBottom: 16,
    backgroundColor: 'theme.background',
  },
  titleText: {
    marginTop: 32,
  },
  bodyText: {
    addingHorizontal: 32,
    marginTop: 36,
    textAlign: 'center',
  },
  button: {
    marginTop: 36,
    marginHorizontal: 24,
  },
})
