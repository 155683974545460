import moment from 'moment'
import { chunk, mean, round } from 'lodash'
import { DATE_FORMAT } from '@src/config/momentFormat'
import { Point } from '../../types'

export const previousEndDate = (startDate: string) =>
  moment(startDate).subtract(1, 'day').format(DATE_FORMAT)
export const previousStartDate = (startDate: string, rangeDays: number) =>
  moment(startDate).subtract(rangeDays, 'days').format(DATE_FORMAT)

export const groupByDayOfWeek = (current: Point[], previous: Point[], precision = 0) => {
  if (current.length !== previous.length) {
    return []
  }

  return [
    ...groupByDay(previous, 'previous', precision),
    ...groupByDay(current, 'current', precision),
  ]
}

const groupByDay = (data: Point[], type: 'current' | 'previous', precision = 0) => {
  const filteredData = data.filter(({ y }) => y !== null)
  const valuesGroupedByDay = new Map<number, number[]>()
  for (const { x, y } of filteredData) {
    const day = moment(x, 'YYYY/MM/DD').day()
    const valuesForDay = valuesGroupedByDay.get(day) || ([] as number[])
    valuesForDay.push(y as number)
    valuesGroupedByDay.set(day, valuesForDay)
  }
  return Array.from(valuesGroupedByDay.entries()).map(([key, array]) => {
    const y = round(mean(array), precision)
    return {
      x: key,
      y,
      displayValue: filteredData.find((value) => value.y === y)?.displayValue,
      type,
    }
  })
}

interface ChartDataGroupedByNHoursInterval {
  /**
   * x - index of interval. For example if 4 hours interval passed then x: 0 will correspond to 0-4 hours interval
   */
  x: number
  y: number | null
}

interface GroupHourDataByNHoursParams {
  data: Point[]
  hourInterval: 1 | 2 | 3 | 4 | 6 | 8 | 12
  roundPrecision: 0 | 1
  aggregationFunction: (array: Point['y'][]) => number
}

export const groupHourDataByNHours: (
  params: GroupHourDataByNHoursParams,
) => ChartDataGroupedByNHoursInterval[] = ({
  data,
  hourInterval,
  roundPrecision, // TODO: It shouldn't be here. It's more about data presentation than data grouping
  aggregationFunction,
}) =>
  chunk(data, hourInterval).map((chunkedData, index) => {
    const chunkYs = chunkedData.map(({ y }) => y).filter((y) => y !== null)
    return {
      x: index,
      y: round(aggregationFunction(chunkYs), roundPrecision) || null,
    }
  })
