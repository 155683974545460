import React from 'react'
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon } from '@src/components/base/Icon'
import { Text } from '@src/components/base/Text'
import { useWeightLossExperience } from './context'

export const PersonalPlan = () => {
  const { showBackButton, stepConfig, goToPreviousStep } = useWeightLossExperience()
  const styles = useStyleSheet(themedStyles)
  const insets = useSafeAreaInsets()

  const StepScreen = stepConfig.screen

  return (
    <View style={styles.topLevelContainer}>
      <ScrollView
        bounces={false}
        contentContainerStyle={[styles.container, { paddingBottom: Math.max(insets.bottom, 16) }]}
      >
        <View style={styles.titleContainer}>
          {showBackButton ? (
            <TouchableOpacity
              onPress={goToPreviousStep}
              accessibilityLabel="Back"
              style={styles.backButton}
            >
              <Icon name="caret-left" size={20} />
            </TouchableOpacity>
          ) : (
            <View style={styles.blankSpace} />
          )}
          {stepConfig.title && (
            <Text type="title-1" style={styles.title}>
              {stepConfig.title}
            </Text>
          )}
          <View style={styles.blankSpace} />
        </View>

        <View style={{ justifyContent: 'space-between', flexGrow: 1 }}>
          <StepScreen />
        </View>
      </ScrollView>
    </View>
  )
}

const themedStyles = StyleService.create({
  topLevelContainer: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  container: {
    flexGrow: 1,
    paddingTop: 60,
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  titleContainer: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 32,
    justifyContent: 'space-between',
  },
  backButton: {
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -8,
  },
  blankSpace: {
    width: 40,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    alignSelf: 'center',
    lineHeight: 32,
    width: 260,
  },
})
