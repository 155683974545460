import { gql } from '@apollo/client'

const ALL_STATS_DATA = gql`
  query allStats($filter: DateFilter) {
    allStats(filter: $filter) {
      statistics {
        type
        title
        description
        value
        valuePrev
        displayValue
        change
        units
        range {
          min
          max
          warning
          danger
          reverse
        }
        meta {
          key
          section
        }
      }
    }
  }
`

export default ALL_STATS_DATA
