import React, { View } from 'react-native'
import { StyleService, useStyleSheet } from '@style/service.ts'
import { Icon, Text } from '@components/base'

interface CgmBenefitLabelProps {
  label: string
}

export const CgmBenefitLabel = ({ label }: CgmBenefitLabelProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <View style={styles.row}>
      <Icon name="check" style={styles.icon} size={24} weight="bold" />

      <Text type="regular" style={styles.text}>
        {label}
      </Text>
    </View>
  )
}

const themedStyle = StyleService.create({
  row: {
    flexDirection: 'row',
    gap: 12,
    marginBottom: 12,
  },
  icon: {
    color: 'theme.success.base',
  },
  text: {
    flex: 1,
    fontWeight: 400,
  },
})
