import { useSelector } from 'react-redux'
import { remindersConfigSelector } from '@src/selectors/app'
import { remindersSelector } from '@src/selectors/settings'
import { Feature, useFeatureFlag } from '@src/components'
import { MobileAppFeature, NotificationEngineReminderKind } from '@src/types'
import { useDexcomConnection, useLibre3NativeEnabled } from '@src/utils/hooks'
import { User } from '@src/utils'
import { fieldValueSelector } from '../UserSettings/ToggleSetting'
import { TempScanReminderKind } from './utils'

const SCAN_REMINDER = {
  title: 'Scan Reminder',
  key: TempScanReminderKind,
  allowedFrequencies: [],
  icon: 'bell-ringing',
}

export const useAvailableReminders = () => {
  const remindersConfig = useSelector(remindersConfigSelector)
  const userReminders = useSelector(remindersSelector)

  const isCGMFeatureAvailable = User.hasFeature(MobileAppFeature.ScanCgm)
  const scanReminderEnabled = useSelector(fieldValueSelector('reminders'))
  const glucoseCheckReminderEnabled = useIncludeGlucoseCheckReminder() && isCGMFeatureAvailable

  const newReminders = remindersConfig.filter(
    ({ key }) =>
      !userReminders.some(({ kind }) => key === kind) &&
      (glucoseCheckReminderEnabled || key !== NotificationEngineReminderKind.GlucoseCheck),
  )

  if (!isCGMFeatureAvailable) {
    return newReminders
  }

  return scanReminderEnabled || glucoseCheckReminderEnabled
    ? newReminders
    : [SCAN_REMINDER, ...newReminders]
}

export const useIncludeGlucoseCheckReminder = () => {
  const glucoseCheckReminderEnabled = useFeatureFlag(Feature.GlucoseCheckReminder)

  const dexcomConnection = useDexcomConnection()

  const dexcomEnabled = !!dexcomConnection
  const { currentSensorIsLibre3 } = useLibre3NativeEnabled()

  return glucoseCheckReminderEnabled && (dexcomEnabled || currentSensorIsLibre3)
}
