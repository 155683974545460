import React, { useState } from 'react'
import { View, ViewStyle } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { NavigationContainer } from '@src/screens/Common/containers'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text } from '@components/base'
import { openUrl, useDispatchAsync, Zendesk } from '@src/utils'
import { Analytics, CustomEventTypes, Device } from '@src/config'
import { useTutorialNavigation } from '@src/screens/Tutorials/hooks'
import { UiInlineAlert, UiStateNames } from '@src/types'
import { InlineAlert, LoadingIndicator } from '@src/components'
import { userSelector } from '@src/selectors/app'
import { AppStackParamList } from '@src/navigation/types'
import { useIsDarkMode } from '@src/config/theme'
import { useCheckGlucoseConnection } from '../Connectivity/hooks/useCheckGlucoseConnection'
import { useByosOnboardingTutorial, useShouldShowByosOnboarding } from './hooks'

const HEALTH_APP_LABEL = Device.ios ? 'Apple Health' : 'GoogleFit'

const APPLE_HEALTH_SUPPORT_URL =
  'https://support.nutrisense.io/hc/en-us/articles/4826896538903-How-to-Sync-your-HealthKit'
const GOOGLE_FIT_SUPPORT_URL =
  'https://support.nutrisense.io/hc/en-us/articles/10984276187543-App-Integrations-Google-Fit'

export const CheckConnectionScreen = () => {
  const styles = useStyleSheet(themedStyles)
  const dispatchAsync = useDispatchAsync()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [connectionFailed, setConnectionFailed] = useState(false)
  const [alertVisible, setAlertVisible] = useState(false)
  const navigation = useNavigation()
  const route = useRoute<RouteProp<AppStackParamList, 'CheckConnection'>>()
  const fromTutorialId = route.params?.fromTutorialId
  const isOnboarding = useShouldShowByosOnboarding()
  const user = useSelector(userSelector)
  const allowBackNavigation = isOnboarding ? false : true
  const isDarkMode = useIsDarkMode()

  const { group: tutorialGroup, sensorKind: tutorialSensorKind } = useByosOnboardingTutorial()
  const navigateToTutorial = useTutorialNavigation(tutorialGroup, tutorialSensorKind)
  const checkGlucoseConnection = useCheckGlucoseConnection()

  const alertTextColor = isDarkMode ? 'theme.tooltip.textPrimary' : 'theme.text.primary'

  const onRestartTutorialPress = async () => {
    if (!isOnboarding && fromTutorialId) {
      navigation.navigate('CMSTutorial', { tutorialId: fromTutorialId })
    }
    await dispatchAsync({
      type: 'app/upsertUiState',
      payload: {
        name: UiStateNames.OwnSensorTutorialCompleted,
        value: false,
      },
    })
    navigateToTutorial()
  }

  const onLearnMorePress = () => {
    const url = Device.ios ? APPLE_HEALTH_SUPPORT_URL : GOOGLE_FIT_SUPPORT_URL
    return openUrl(url)
  }

  const onContinuePress = async () => {
    setConnectionFailed(false)

    if (!isOnboarding) {
      navigation.navigate('Drawer')
      return
    }

    await dispatchAsync({
      type: 'app/upsertUiState',
      payload: {
        name: UiStateNames.OwnSensorOnboardingCompleted,
        value: true,
      },
    })
  }

  const checkConnectionWithTimeout = () => {
    return new Promise<boolean>((resolve, reject) => {
      const timer = setTimeout(() => {
        reject(new Error('Connection timeout'))
      }, 10000)

      checkGlucoseConnection()
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          clearTimeout(timer)
        })
    })
  }

  const onCheckConnectionPress = () => {
    setLoading(true)
    setConnectionFailed(false)

    checkConnectionWithTimeout()
      .then((result) => {
        Analytics.track(CustomEventTypes.CheckGlucoseConnection, { success: result })
        setConnectionFailed(!result)
        setAlertVisible(!result)

        if (result) {
          dispatch({
            type: 'app/upsertUiState',
            payload: {
              name: UiStateNames.OwnSensorGlucoseDetected,
              value: true,
            },
          })

          dispatch({
            type: 'app/updateHealthData',
          })

          navigation.navigate('ConnectionSuccessful')
        }
      })
      .catch(() => {
        setConnectionFailed(true)
        setAlertVisible(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onContactSupportPress = () => {
    openUrl(Zendesk.baseUrl(user?.email))
  }

  return (
    <NavigationContainer
      allowBackNavigation={allowBackNavigation}
      title="Let's check your connection"
    >
      <SafeAreaView edges={['bottom']} style={[styles.container]}>
        <View style={styles.contentContainer}>
          <Text type="regular" bold style={{ marginBottom: 8 }}>
            Steps completed so far
          </Text>
          <TouchableOpacity onPress={onLearnMorePress} accessibilityLabel="Learn more">
            <View style={styles.completedStep}>
              <Text style={styles.stepDescription} type="regular">
                Accept permissions to sync {HEALTH_APP_LABEL}
              </Text>
              <Text style={styles.stepLink} type="regular">
                Learn more
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={onRestartTutorialPress} accessibilityLabel="Learn more">
            <View style={styles.completedStep}>
              <Text style={styles.stepDescription} type="regular">
                Within Dexcom app, enable Connections with {HEALTH_APP_LABEL}
              </Text>
              <Text style={styles.stepLink} type="regular">
                Restart tutorial
              </Text>
            </View>
          </TouchableOpacity>

          <View style={styles.inlineNotice}>
            <Icon name="info" weight="regular" />
            <Text type="regular" style={styles.inlineNoticeText}>
              These steps are performed outside of our app, so we're assuming you have completed
              them already. If not, click on the links above.
            </Text>
          </View>
        </View>

        <View style={styles.buttonContainer}>
          {!loading && !connectionFailed && (
            <Button
              type="primary"
              size="block"
              onPress={onCheckConnectionPress}
              accessibilityLabel="Check Connection"
            >
              Check Connection
            </Button>
          )}
          {loading && !connectionFailed && (
            <>
              <View style={styles.connectingPopup}>
                <Text type="regular" style={styles.connectingPopupText}>
                  Checking connection...
                </Text>
              </View>
              <Button
                type="primary"
                size="block"
                accessibilityLabel="Checking Connection"
                disabled={false}
                accessoryRight={
                  loading && <LoadingIndicator viewStyles={styles.loadingView} color="white" />
                }
                isAccessoryInline
              />
            </>
          )}

          {connectionFailed && (
            <>
              {alertVisible && (
                <InlineAlert
                  style={[styles.warningPopup]}
                  textStyle={{ color: alertTextColor } as ViewStyle}
                  category={UiInlineAlert.Warning}
                  onClose={() => setAlertVisible(false)}
                  message="No data detected. Note, it may take 3 hours for Nutrisense to receive the data.
              If you have just applied your sensor, please continue."
                />
              )}
              <Button
                type="primary"
                size="block"
                onPress={onContinuePress}
                style={styles.continueButton}
                accessibilityLabel="Continue"
              >
                Continue
              </Button>
              <Button
                type="secondary"
                size="block"
                onPress={onCheckConnectionPress}
                accessibilityLabel="Retry"
              >
                Retry
              </Button>
            </>
          )}
        </View>
        {!isOnboarding && connectionFailed && (
          <Text type="regular" style={styles.footer}>
            Still have issues? Contact our{' '}
            <TouchableOpacity accessibilityLabel="Contact Support" onPress={onContactSupportPress}>
              <Text type="regular" bold lineSpacing="none" style={styles.link}>
                Support Team
              </Text>
            </TouchableOpacity>{' '}
            to help you further diagnose!
          </Text>
        )}
      </SafeAreaView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    marginTop: 32,
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: 16,
    backgroundColor: 'theme.background',
  },
  buttonContainer: {
    marginHorizontal: 24,
    marginBottom: 16,
  },
  continueButton: {
    marginBottom: 8,
  },
  connectingPopup: {
    backgroundColor: 'theme.surface.base',
    marginBottom: 12,
    marginHorizontal: 16,
    paddingHorizontal: 12,
    paddingVertical: 16,
    borderRadius: 8,
  },
  connectingPopupText: {
    textAlign: 'center',
  },
  warningPopup: {
    marginVertical: 8,
    padding: 8,
    backgroundColor: 'theme.warning.base',
  },
  warningTextStyle: {
    color: 'theme.tooltip.textPrimary',
  },
  completedStep: {
    flexDirection: 'row',
    minWidth: '100%',
    backgroundColor: 'theme.surface.base3',
    marginTop: 16,
    padding: 16,
    borderRadius: 16,
    justifyContent: 'space-between',
  },
  stepDescription: {
    flexWrap: 'wrap',
    maxWidth: 184,
  },
  stepLink: {
    fontWeight: 'bold',
    alignSelf: 'center',
    color: 'theme.text.link',
  },
  inlineNotice: {
    flexDirection: 'row',
    marginTop: 16,
    gap: 8,
  },
  inlineNoticeText: {
    alignSelf: 'center',
    maxWidth: 312,
  },
  loadingView: {
    height: 20,
    marginLeft: 4,
  },
  footer: {
    textAlign: 'center',
    marginTop: 16,
    marginHorizontal: 16,
  },
  link: {
    color: 'theme.text.link',
    marginTop: 'auto',
  },
})
