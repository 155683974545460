import React from 'react'
import { View } from 'react-native'
import { useIsConnected } from 'react-native-offline'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'

const NoInternetConnectionBar = () => {
  const styles = useStyleSheet(themedStyle)

  const isInternetReachable = useIsConnected()

  if (isInternetReachable) {
    return null
  }

  return (
    <View style={styles.container}>
      <Text type="small" style={styles.text}>
        No internet connection
      </Text>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'theme.error.base',
  },
  text: {
    color: 'theme.solid.black',
  },
})

export default NoInternetConnectionBar
