import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/core'
import { StackNavigationProp } from '@react-navigation/stack'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, IconButton, Text } from '@components/base'
import { AppStackParamList } from '@src/navigation/types'
import { useGoBack } from '@src/utils/navigation'
import { Analytics, CustomEventTypes } from '@src/config'
import { updateJournalEngagementPromptConfig } from '@screens/JournalEntries/utils'

export const JournalEngagementPromptModal = () => {
  const styles = useStyleSheet(themedStyles)
  const insets = useSafeAreaInsets()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const goBack = useGoBack()

  const trackInteraction = (action: string) => {
    Analytics.track(CustomEventTypes.JournalEngagementPromptInteraction, { action })
  }

  const handleActionButtonPress = () => {
    trackInteraction('Try Journal')
    navigation.replace('AddJournalEntry', { body: '' })
  }

  const onCloseIconPress = () => {
    trackInteraction('Close')
    goBack()
  }

  useEffect(() => {
    updateJournalEngagementPromptConfig({
      promptLastDisplayDate: moment().toISOString(),
    })
  }, [])

  return (
    <View style={[styles.tooltipContainer, { marginBottom: insets.bottom }]}>
      <Text type="regular" bold style={styles.tooltipTitle}>
        New Journal Feature: Add Wellness Scores to Notes.
      </Text>
      <IconButton
        accessibilityLabel="Close"
        iconName="x"
        size="s"
        style={styles.closeButton}
        iconProps={{ style: styles.closeIcon }}
        type="transparent"
        onPress={onCloseIconPress}
      />
      <Text type="regular" style={styles.tooltipText}>
        Glucose scores impact more than your physical health. Discover the deeper connection between
        your emotional wellbeing and glucose levels with our new Journal feature. Gain a holistic
        view of how your emotions are influenced by your glucose readings, and uncover valuable
        insights for improved overall wellness.
      </Text>
      <Button
        type="primary"
        size="small"
        accessibilityLabel="Try journal"
        onPress={handleActionButtonPress}
        style={styles.tooltipButton}
      >
        <Text type="small" bold style={styles.actionText}>
          Try journal
        </Text>
      </Button>
    </View>
  )
}

const themedStyles = StyleService.create({
  tooltipContainer: {
    position: 'absolute',
    bottom: 74,
    right: 12,
    width: 305,
    padding: 16,
    borderRadius: 16,
    backgroundColor: 'theme.surface.base0',
  },
  closeButton: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  closeIcon: {
    color: 'theme.solid.white',
  },
  tooltipTitle: {
    color: 'theme.solid.white',
    marginBottom: 8,
    marginRight: 32,
  },
  tooltipText: {
    color: 'theme.solid.white',
    marginBottom: 16,
  },
  tooltipButton: {
    alignSelf: 'flex-start',
    paddingHorizontal: 16,
    paddingVertical: 8,
    backgroundColor: 'theme.solid.white',
  },
  actionText: {
    textTransform: 'uppercase',
    color: 'theme.solid.black',
  },
})
