import React, { useCallback, useState } from 'react'
import { Image, ScrollView } from 'react-native'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import {
  CommonRefreshControl,
  Feature,
  NavigationBarAccessory,
  useFeatureFlag,
} from '@src/components'
import { ArcIndicatorsPager, CalendarBar, InsightsList } from '@src/screens/Insights/components'
import { useFetchStats } from '@src/screens/Insights/utils/hooks'
import { NotificationBanner } from '@src/components/notifications/NotificationBanner'
import { MobileAppFeature, NotificationEngineScreen, UiStateNames } from '@src/types'
import { User } from '@src/utils'
import { goalMetricsSelector, primaryGoalSelector, userSelector } from '@src/selectors/app'
import { AccountAvatarNavigationBar } from '@src/components/navigationBar/AccountAvatarNavigationBar'
import { Icon } from '@src/components/base'
import { blurredArcIndicatorsImage } from '@src/assets/images'
import { UpsellCGMBanner } from '@src/screens/Home/components/Banners/UpsellCGMBanner'
import { useIsDarkMode } from '@src/config/theme'
import { GoalView } from '../components/GoalView'

export const InsightsScreen = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const isDarkMode = useIsDarkMode()
  const user = useSelector(userSelector)

  const [refreshing, setRefreshing] = useState(false)

  const { refetch, fetchStats } = useFetchStats()

  const rightAccessories: NavigationBarAccessory[] = [
    {
      renderIconComponent: () => <Icon name="gear" style={styles.icon} />,
      onPress: () => navigation.navigate('InsightsSettings'),
      accessibilityLabel: 'Insights Settings',
    },
  ]

  const userGoalsEnabled = useFeatureFlag(Feature.UserGoals)

  const primaryGoal = useSelector(primaryGoalSelector)

  const goalMetrics = useSelector(goalMetricsSelector)

  const primaryGoalMetrics = goalMetrics.filter((metric) => metric.primary)

  const insightsTitle = userGoalsEnabled ? 'Trends' : 'Insights'

  const isCGMFeatureAvailable = User.hasFeature(MobileAppFeature.ScanCgm)

  const hasViewedTrends = user?.uiStates.some(
    ({ name, value }) => name === UiStateNames.ViewedTrends && value === true,
  )

  useFocusEffect(
    useCallback(() => {
      dispatch({ type: 'users/fetch' })
      if (!hasViewedTrends) {
        dispatch({
          type: 'app/upsertUiState',
          payload: {
            name: UiStateNames.ViewedTrends,
            value: true,
          },
        })
      }
    }, [dispatch, hasViewedTrends]),
  )

  const onRefresh = async () => {
    setRefreshing(true)
    try {
      await refetch()
    } finally {
      setRefreshing(false)
    }
  }

  return (
    <AccountAvatarNavigationBar title={insightsTitle} rightAccessories={rightAccessories}>
      <ScrollView
        style={styles.content}
        contentContainerStyle={[styles.content]}
        showsVerticalScrollIndicator={false}
        refreshControl={<CommonRefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
      >
        <NotificationBanner screen={NotificationEngineScreen.Insights} />
        <CalendarBar fetchStats={fetchStats} />
        {isCGMFeatureAvailable ? (
          <ArcIndicatorsPager />
        ) : (
          <UpsellCGMBanner
            image={<Image source={blurredArcIndicatorsImage(isDarkMode).imageSource} />}
          />
        )}
        {userGoalsEnabled && primaryGoal && isCGMFeatureAvailable && (
          <GoalView goal={primaryGoal} metrics={primaryGoalMetrics} />
        )}
        <InsightsList primaryMetrics={userGoalsEnabled ? primaryGoalMetrics : []} />
      </ScrollView>
    </AccountAvatarNavigationBar>
  )
}

const themedStyles = StyleService.create({
  content: {
    backgroundColor: 'theme.background',
  },
  icon: {
    marginRight: 16,
  },
})
