import React, { useEffect, useMemo, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { groupBy, uniqueId } from 'lodash'
import Orientation from 'react-native-orientation-locker'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Debug, Device } from '@config'
import { DATE_FORMAT, MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT } from '@src/config/momentFormat'
import { useHasLargeScreen, useShouldLockToPortrait } from '@src/config/device'
import { Intercom, useDispatchAsync } from '@utils'
import { Feature, NavigationBarAccessory, useFeatureFlag } from '@components'
import { NotificationBanner } from '@src/components/notifications/NotificationBanner'
import { NotificationEngineScreen } from '@src/types'
import { IconButton } from '@components/base'
import { AccountAvatarNavigationBar } from '@src/components/navigationBar/AccountAvatarNavigationBar'
import { useAllMemberNotes } from '@src/screens/NutritionistHub'
import { useFetchStats } from '@src/screens/Insights/utils/hooks'
import { eventsForDateSelector } from '@src/selectors/events'
import { GroupedEvent } from '@src/screens/Events/models/events.types'
import { useChartInteractions } from '../utils/useChartInteractions'
import { OnboardingChecklistBanner } from '../components/Banners/OnboardingChecklistBanner'
import { VideoCallsUpsellBanner } from '../components/Banners/VideoCallsUpsellBanner'
import { useGetByosVideoCallUpsellNextStep, useOnboardingModals } from '../utils/hooks'
import { DailyActionsSection } from '../../DailyActions/DailyActionsSection'
import { HomeScreenChart } from '../components/HeroChart/HomeScreenChart'
import { ScanMigrationWarningBanner } from '../components/Banners/ScanMigrationWarningBanner'
import { SteloSyncDelayBanner } from '../components/Banners/SteloSyncDelayBanner'
import { NutritionCoachingChatlikeBanner } from '../components/Banners/NutritionCoachingChatlikeBanner'
import { FreeConsultationChatlikeBanner } from '../components/Banners/FreeConsultationChatlikeBanner'
import { ByosVideoCallsUpsellStep } from '../constants'
import { ChatbotIcon } from './ChatbotIcon'
import { MessageIcon } from './MessageIcon'

export const PLUS_MENU_BOTTOM_MARGIN = 14

export const HomeContainer = () => {
  const navigation = useNavigation()
  const dispatchAsync = useDispatchAsync()
  const styles = useStyleSheet(themedStyle)
  const isDataLoading = useRef(false)
  const insets = useSafeAreaInsets()

  useOnboardingModals()
  useAllMemberNotes()

  const nextByosVideoCallsUpsellStep = useGetByosVideoCallUpsellNextStep()

  const weightLossRecurrentCallsEnabled = useFeatureFlag(Feature.WeightLossRecurrentCalls)

  const today = moment().format(DATE_FORMAT)

  const { startDate, endDate } = { startDate: today, endDate: today }

  const events = useSelector(eventsForDateSelector)

  const { fetchStats } = useFetchStats()

  const groupedEvents: GroupedEvent[] = useMemo(() => {
    const dateGroups = groupBy(events, (i) => moment(i.occurredAt).format(DATE_FORMAT))
    return Object.entries(dateGroups).map(([date, data], index) => ({
      id: uniqueId(date),
      index,
      title: moment(date).format(MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT),
      occurredAt: moment(date),
      data,
    }))
  }, [events])

  const { primaryChartRef, secondaryChartRef, onChartLoadEnd } = useChartInteractions(groupedEvents)

  const shouldLockToPortrait = useShouldLockToPortrait()

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      if (shouldLockToPortrait) {
        Orientation.lockToPortrait()
      }
    })
    return unsubscribe
  }, [navigation, shouldLockToPortrait])

  const refreshData = useCallback(
    async (useCache: boolean) => {
      if (isDataLoading.current) {
        return
      }

      const promises = [
        dispatchAsync({
          type: 'dailyNutrition/fetch',
          payload: {
            filter: {
              startDate,
              endDate,
            },
          },
          useCache,
        }),
        dispatchAsync({
          type: 'dailyCharts/fetch',
          payload: {
            filter: {
              startDate,
              endDate,
            },
          },
          useCache,
        }),
        dispatchAsync({
          type: 'dailyActions/list',
        }),
      ]

      if (weightLossRecurrentCallsEnabled) {
        promises.push(
          dispatchAsync({
            type: 'nutritionistHub/fetchAppointmentRecurrence',
          }),
        )

        promises.push(
          dispatchAsync({
            type: 'nutritionistHub/fetchCoachAssignments',
          }),
        )

        promises.push(
          dispatchAsync({
            type: 'nutritionistHub/fetchInsurancePolicy',
          }),
        )
      }

      isDataLoading.current = true

      try {
        await Promise.all(promises)
      } finally {
        isDataLoading.current = false
      }
    },
    [dispatchAsync, endDate, startDate, weightLossRecurrentCallsEnabled],
  )

  const loadDataOnFocus = useCallback(() => {
    refreshData(false)
    fetchStats({ startDate, endDate })
  }, [fetchStats, refreshData, startDate, endDate])

  useFocusEffect(loadDataOnFocus)

  const hasLargeScreen = useHasLargeScreen()
  const rightAccessories: NavigationBarAccessory[] = []

  if (useFeatureFlag(Feature.Chatbot) && !Device.web) {
    rightAccessories.push({
      renderIconComponent: () => <ChatbotIcon />,
      onPress: () => navigation.navigate('Chat'),
      accessibilityLabel: 'Chat',
    })
  }

  const showMessages = Debug.shouldEnableIntercom()
  if (showMessages) {
    rightAccessories.push({
      renderIconComponent: () => <MessageIcon />,
      onPress: () => Intercom.showIntercomMessenger({ source: 'Home' }),
      accessibilityLabel: 'Message',
    })
  }

  return (
    <AccountAvatarNavigationBar
      rightAccessories={rightAccessories}
      style={styles.container}
      title="Today"
      hideLeftIcon={hasLargeScreen}
    >
      <ScanMigrationWarningBanner />
      <NotificationBanner screen={NotificationEngineScreen.Home} calendarDate={startDate} />
      {nextByosVideoCallsUpsellStep === ByosVideoCallsUpsellStep.ChatlikeNotification && (
        <NutritionCoachingChatlikeBanner />
      )}
      {nextByosVideoCallsUpsellStep ===
        ByosVideoCallsUpsellStep.FreeConsultationChatlikeNotification && (
        <FreeConsultationChatlikeBanner />
      )}
      <OnboardingChecklistBanner />
      <VideoCallsUpsellBanner />
      <SteloSyncDelayBanner />
      <DailyActionsSection
        HeroChartComponent={
          <HomeScreenChart
            primaryChartRef={primaryChartRef}
            secondaryChartRef={secondaryChartRef}
            onChartLoadEnd={onChartLoadEnd}
          />
        }
      />
      <IconButton
        accessibilityLabel="Open Menu"
        iconName="plus"
        size="l"
        style={[
          styles.toggle,
          { bottom: hasLargeScreen && insets.bottom ? insets.bottom : PLUS_MENU_BOTTOM_MARGIN },
        ]}
        type="primary"
        onPress={() => navigation.navigate('ShowActions')}
      />
    </AccountAvatarNavigationBar>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  toggle: {
    position: 'absolute',
    right: 20,
  },
})
