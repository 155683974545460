import React, { useEffect } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Checkbox, Text } from '@components/base'
import { MarkdownView } from '@src/components/Markdown'
import { useActiveSubscriptions } from '@src/hooks/useActiveSubscriptions'
import { userSelector } from '@src/selectors/app'
import { ConsentInputConfig } from '../../types'
import { InputPropsBase } from './types'
import { Option, OptionType } from './Shared/Option'

interface ConsentInputProps extends InputPropsBase {
  response: ConsentInputConfig
}

export const ConsentInput = ({ field, response }: ConsentInputProps) => {
  const styles = useStyleSheet(themedStyles)
  const user = useSelector(userSelector)
  const activeSubscriptions = useActiveSubscriptions()
  const activeSubscriptionTitle =
    activeSubscriptions.length > 0 ? activeSubscriptions[0].primaryProduct.title : ''

  const { consent_label: consentLabel = '', opt_out_label: optOutLabel, consent_body: body = '' } =
    response.kindMetadata ?? {}

  const showOptOutLabel = !!optOutLabel

  const onChange = field.onChange

  const fieldValue = field.value

  useEffect(() => {
    // set value object if we can opt out consent
    if (showOptOutLabel && typeof fieldValue !== 'undefined' && typeof fieldValue !== 'object') {
      onChange({ value: fieldValue })
      return
    }

    // set boolean value if only want to set consent
    if (!showOptOutLabel && (typeof fieldValue === 'undefined' || fieldValue === '')) {
      onChange(false)
      return
    }
  }, [onChange, fieldValue, showOptOutLabel])

  const unwrappedFieldValue: boolean | undefined = fieldValue?.value ?? fieldValue

  const formattedConsentLabel = consentLabel.replace(':name', user?.fullName ?? '')
  const formattedOptOutLabel = optOutLabel?.replace(':name', user?.fullName ?? '')
  const formattedConsentBody = body.replace(':corePlanName', activeSubscriptionTitle)

  return (
    <View style={styles.consentWrapper}>
      <MarkdownView content={formattedConsentBody} />

      {showOptOutLabel ? (
        <>
          <Option
            label={formattedConsentLabel}
            selected={unwrappedFieldValue === true}
            onPress={() => {
              field.onChange({ value: true })
            }}
            type={OptionType.Radio}
          />
          <Option
            label={formattedOptOutLabel as string}
            selected={unwrappedFieldValue === false}
            onPress={() => {
              field.onChange({ value: false })
            }}
            type={OptionType.Radio}
          />
        </>
      ) : (
        <TouchableOpacity
          activeOpacity={0.7}
          style={styles.container}
          accessibilityLabel={!unwrappedFieldValue ? "I don't consent" : 'I consent'}
          onPress={() => field.onChange(!unwrappedFieldValue)}
        >
          <Text type="regular" bold style={styles.label}>
            {formattedConsentLabel}
          </Text>
          <Checkbox checked={!!unwrappedFieldValue} onChange={field.onChange} />
        </TouchableOpacity>
      )}
    </View>
  )
}

const themedStyles = StyleService.create({
  consentWrapper: {
    flex: 1,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 16,
    marginTop: 16,
    borderRadius: 16,
    backgroundColor: 'theme.surface.base2',
  },
  label: {
    marginRight: 20,
    flexShrink: 1,
  },
})
