import React from 'react'
import { useSelector } from 'react-redux'
import { GestureDismissibleAlertModal } from '@components'
import { useGoBack } from '@utils/navigation'
import { openUrl, Zendesk } from '@src/utils'
import { userSelector } from '@src/selectors/app'

export const ManageUpcomingSubscription = () => {
  const goBack = useGoBack()
  const user = useSelector(userSelector)

  const handleContactSupport = () => {
    openUrl(Zendesk.changeProgramUrl(user?.email))
    goBack()
  }

  return (
    <GestureDismissibleAlertModal
      title="Manage Subscription"
      description="Please contact support to help with any queries regarding this subscription."
      actionTitle="Contact Support"
      dismissButtonText="Cancel"
      onActionPress={handleContactSupport}
    />
  )
}
