import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { View, TextStyle, ImageBackground } from 'react-native'
import { LongPressGestureHandler } from 'react-native-gesture-handler'
import Config from 'react-native-config'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Button } from '@components/base'
import { LinearGradient, NutrisenseLogo } from '@components'
import { Debug } from '@config'
import { runnerFullImage } from '@src/assets/images'

export const SignInOrSignUp = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()

  const onSignUpPress = () => {
    navigation.navigate('Walkthrough')
  }

  const onSignInPress = () => {
    navigation.navigate('SignIn')
  }

  const onAdminPress = () => {
    if (Debug.useAdminMenu) {
      navigation.navigate('ShowAdmin')
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <ImageBackground
          source={runnerFullImage.imageSource}
          style={styles.imageBackground}
          resizeMode="cover"
        />
        <LongPressGestureHandler
          onHandlerStateChange={onAdminPress}
          enabled={Config.ENV !== 'production'}
          minDurationMs={50}
        >
          <View style={styles.logoContainer}>
            <NutrisenseLogo
              fill={(styles.logoText as TextStyle).color}
              width={288}
              height={44}
              viewBox="0 0 240 37"
            />
          </View>
        </LongPressGestureHandler>
      </View>
      <View style={styles.contentContainer}>
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 0, y: 1 }}
          startColor="white"
          startOpacity={0}
          endColor="white"
          endOpacity={1}
          style={styles.gradient}
        />
        <View style={styles.buttonsContainer}>
          <Button type="primary" size="block" accessibilityLabel="Sign Up" onPress={onSignUpPress}>
            Sign Up
          </Button>
          <Button
            type="transparent"
            size="block"
            accessibilityLabel="Already have an account"
            onPress={onSignInPress}
            textStyle={styles.signInButton}
          >
            Already have an account
          </Button>
        </View>
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
  },
  imageContainer: {
    width: '100%',
    position: 'relative',
    height: '87%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoContainer: {
    position: 'absolute',
    top: '15%',
  },
  contentContainer: {
    flex: 1,
    zIndex: 2,
    marginTop: -140,
  },
  gradient: {
    position: 'relative',
    zIndex: 1,
    height: 70,
  },
  logoText: {
    color: 'theme.solid.white',
  },
  buttonsContainer: {
    backgroundColor: 'theme.solid.white',
    paddingHorizontal: 24,
    gap: 8,
    flex: 1,
  },
  signInButton: {
    color: 'theme.solid.black',
  },
  imageBackground: {
    width: '100%',
    flex: 1,
  },
})
