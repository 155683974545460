import React from 'react'
import { Image, ImageStyle, View } from 'react-native'
import { useSelector } from 'react-redux'
import { NutritionistBubble } from '@src/components/NutritionistBubble'
import { topPriorityCoachAssignmentSelector } from '@src/screens/NutritionistHub/models/nutritionistHub.selectors'
import { StyleService, useStyleSheet } from '@src/style/service'
import { downwardCurveGraphImage } from '@src/assets/images'
import { PercentageLoadingButton } from '@src/components/PercentageLoadingButton'
import { useWeightLossExperience } from './context'

export const CreatingPlan = () => {
  const { isLoading, fakeLoadingTotalTime, goToNextStep } = useWeightLossExperience()
  const styles = useStyleSheet(themedStyles)
  const coachAssignment = useSelector(topPriorityCoachAssignmentSelector)

  return (
    <>
      <View style={{ alignItems: 'center' }}>
        <NutritionistBubble
          label="Calculating your ideal weight loss plan..."
          photoSrc={coachAssignment?.coach?.coachProfile?.photo}
        />

        <Image
          resizeMethod="resize"
          source={downwardCurveGraphImage.imageSource}
          style={styles.image as ImageStyle}
        />
      </View>

      <View style={styles.buttonContainer}>
        <PercentageLoadingButton
          loading={isLoading}
          loadingTotalTime={fakeLoadingTotalTime}
          buttonText="Schedule Initial Calls"
          onClick={goToNextStep}
        />
      </View>
    </>
  )
}

const themedStyles = StyleService.create({
  title: {
    fontSize: 24,
    textAlign: 'center',
    maxWidth: 240,
    lineHeight: 32,
    marginBottom: 32,
  },
  image: {
    maxWidth: 308,
    height: undefined,
    maxHeight: 210,
    alignSelf: 'center',
    aspectRatio: 3 / 1,
    marginTop: 140,
  },
  buttonContainer: {
    height: 56,
    width: '100%',
  },
})
