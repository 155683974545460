import { AuthConfiguration } from 'react-native-app-auth'
import { oauthLogin } from '@src/utils/oauth'

const REDIRECT_URI = 'nutrisense.auth://app/oauth/dexcom'

const BASE_API_URL = 'https://api.dexcom.com'
const SANDBOX_BASE_API_URL = 'https://sandbox-api.dexcom.com'

const getConfig = ({ useSandbox, clientId }: { useSandbox: boolean; clientId: string }) => {
  const baseUrl = useSandbox ? SANDBOX_BASE_API_URL : BASE_API_URL

  const authConfig: AuthConfiguration = {
    clientId,
    redirectUrl: REDIRECT_URI,
    scopes: ['offline_access'],
    usePKCE: false,
    serviceConfiguration: {
      authorizationEndpoint: `${baseUrl}/v2/oauth2/login`,
      tokenEndpoint: `${baseUrl}/v2/oauth2/token`,
    },
    skipCodeExchange: true,
  }

  return authConfig
}

const login = async ({ useSandbox, clientId }: { useSandbox: boolean; clientId: string }) => {
  const result = await oauthLogin(getConfig({ useSandbox, clientId }))

  return { authorizationCode: result.user?.authorizationCode, status: result.status }
}

export default { login }
