import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { ChatlikeNotificationBanner } from '@src/components/notifications/ChatlikeNotificationBanner'
import { SurveysConfigKind, User } from '@src/types'
import { userSelector } from '@src/selectors/app'
import { recordByosVideoCallsUpsellInteraction } from '../../utils/utils'
import { ByosVideoCallsUpsellStep } from '../../constants'

export const FreeConsultationChatlikeBanner = () => {
  const user = useSelector(userSelector)
  const navigation = useNavigation()
  const [dismissed, setDismissed] = useState(false)

  if (dismissed) {
    return null
  }

  const onAction = () => {
    recordByosVideoCallsUpsellInteraction(
      ByosVideoCallsUpsellStep.FreeConsultationChatlikeNotification,
      user as User,
    )
    navigation.navigate('Questionnaire', {
      questionnaire: SurveysConfigKind.Insurance,
      skipIntroScreen: true,
    })
    setDismissed(true)
  }

  const onDismiss = () => {
    recordByosVideoCallsUpsellInteraction(
      ByosVideoCallsUpsellStep.FreeConsultationChatlikeNotification,
      user as User,
    )
    setDismissed(true)
  }

  return (
    <ChatlikeNotificationBanner
      action={onAction}
      dismiss={onDismiss}
      content="Claim Your FREE Expert Nutritionist Consultation Today."
      actionButtonLabel="Schedule Free Call"
    />
  )
}
