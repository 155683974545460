import { gql } from '@apollo/client'
import { CORE_SENSOR_FIELDS } from './fragments/sensor'
import { CORE_SETTINGS_FIELDS } from './fragments/settings'
import { CORE_USER_FIELDS } from './fragments/user'
import { CORE_SUBSCRIPTION_FIELDS } from './fragments/subscription'
import { CORE_ADDRESS_FIELDS } from './fragments/address'

export const FETCH_USER = gql`
  ${CORE_USER_FIELDS}
  ${CORE_ADDRESS_FIELDS}
  ${CORE_SETTINGS_FIELDS}
  ${CORE_SENSOR_FIELDS}
  ${CORE_SUBSCRIPTION_FIELDS}
  query currentUser {
    currentUser {
      ...CoreUserFields
      referralUrl
      eligibleForInsuranceCoverage
      eligibleForDexcomResearch
      address {
        ...CoreAddressFields
      }
      paymentMethod {
        stripeId
        cardType
        last4
        expirationDate
      }
      subscriptions {
        ...CoreSubscriptionFields
      }
      ongoingDietitianSubscription {
        id
      }
      addonLimits {
        description
        limit
        purchasedAmount
        productId
      }
      refillConsentNeeded
      libreAccount {
        libreId
      }
      dexcomConnection {
        status
        lastSyncedAt
      }
      dexcomSteloConnection {
        status
        lastSyncedAt
      }
      primaryGoal {
        title
        kind
        description
      }
      coachChatConversation {
        intercomId
      }
      goalMetrics {
        primary
        kind
        lowThresholdValue
        highThresholdValue
        section
        statKey
      }
      fulfillmentConfiguration {
        sensorKind
      }
      uxSensorMode {
        kind
      }
      settings {
        ...CoreSettingsFields
      }
      sensor {
        ...CoreSensorFields
      }
      userProperties {
        videoCallRescheduleFreePassUsed {
          value
        }
        weightLossExperience {
          value
        }
      }
    }
  }
`
