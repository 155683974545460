import { round, mapValues, uniqBy } from 'lodash'
import { BaseIngredient, IngredientServingOption } from '@screens/Ingredients/types'
import { ABBREVIATED_GRAM, NORMAL_GRAM } from '@screens/Ingredients/utils'
import {
  NUTRITIONIX_CALORIES,
  NUTRITIONIX_NUTRIENT_ALIASES,
  NUTRITIONIX_NUTRIENT_NAME_BY_ID,
  NUTRITIONIX_NUTRITION_PREFIX,
} from '@services/Nutritionix/constants'
import { NutritionixIngredient } from '@services/Nutritionix/types'
import { isBrandedIngredient, isIngredientWithNutrients } from '@services/Nutritionix/utils'
import { IngredientSource } from '@src/types'
import { safeStartCase } from '@utils/global'

const NUTRITIONIX_PLACEHOLDER_IMAGE_NAME = 'nix-apple-grey.png'

const getIngredientDescription = (item: NutritionixIngredient): string => {
  const foodName = safeStartCase(item.food_name)

  if (isBrandedIngredient(item)) {
    return `${item.brand_name} ${foodName}`
  }

  return foodName
}

const getIngredientNutrition = (item: NutritionixIngredient): BaseIngredient['nutrition'] => {
  return (item.full_nutrients || []).reduce((acc, nutrient) => {
    // Filter out nutrients without value, except calories which are required
    if (!nutrient.value && nutrient.attr_id !== NUTRITIONIX_CALORIES.attr_id) {
      return acc
    }

    const nutrientNameWithPrefix = NUTRITIONIX_NUTRIENT_NAME_BY_ID[nutrient.attr_id]

    if (nutrientNameWithPrefix) {
      const nutrientName = nutrientNameWithPrefix.slice(NUTRITIONIX_NUTRITION_PREFIX.length)
      acc[NUTRITIONIX_NUTRIENT_ALIASES[nutrientName] || nutrientName] = nutrient.value
    }

    return acc
  }, {} as Record<string, number>)
}

const getIngredientServingOptions = (item: NutritionixIngredient): IngredientServingOption[] => {
  // without fetching detailed nutrition data there can be 2 serving units:
  // default nutritionix serving unit (serving_unit) and grams (derived from serving_weight_grams)
  const {
    serving_unit: servingUnit,
    serving_qty: servingQuantity,
    serving_weight_grams: servingWeightGrams,
  } = item

  const baseNutrition = getIngredientNutrition(item)
  const nutritionPerDefaultUnit = mapValues(baseNutrition, (value) => value / servingQuantity)
  let nutritionPerGram = nutritionPerDefaultUnit

  const servingOptions = [
    {
      name: servingUnit === ABBREVIATED_GRAM ? NORMAL_GRAM : servingUnit,
      quantity: servingQuantity,
      nutritionPerServing: nutritionPerDefaultUnit,
    },
  ]

  if (servingWeightGrams && servingUnit !== ABBREVIATED_GRAM) {
    nutritionPerGram = mapValues(baseNutrition, (value) => value / servingWeightGrams)

    servingOptions.push({
      name: NORMAL_GRAM,
      quantity: servingWeightGrams,
      nutritionPerServing: nutritionPerGram,
    })
  }

  if (isIngredientWithNutrients(item)) {
    const additionalServingOptions = uniqBy(item.alt_measures || [], 'measure')
      .filter(({ measure }) => measure !== servingUnit && measure !== ABBREVIATED_GRAM)
      .map(({ measure, serving_weight: servingWeight, qty: quantity }) => ({
        name: measure,
        quantity,
        nutritionPerServing: mapValues(
          nutritionPerGram,
          (value) => (value * servingWeight) / quantity,
        ),
      }))

    servingOptions.push(...additionalServingOptions)
  }

  return servingOptions
}

export const getThirdPartyIngredientId = (item: NutritionixIngredient): string => {
  if (isBrandedIngredient(item)) {
    return `branded/${item.nix_item_id}`
  } else if (isIngredientWithNutrients(item)) {
    // unique for similar ingredients, e.g. "apple", "apples" and "apple, raw"
    return `common/${item.tags.tag_id}`
  }

  return `common/${item.tag_id}`
}

export const normalizeNutritionixItem = (item: NutritionixIngredient): BaseIngredient => {
  const id = getThirdPartyIngredientId(item)
  const calories =
    item[NUTRITIONIX_CALORIES.name] ||
    item.full_nutrients?.find((nutrient) => nutrient.attr_id === NUTRITIONIX_CALORIES.attr_id)
      ?.value

  return {
    id,
    calories: round(calories || 0),
    description: getIngredientDescription(item),
    imageURL: item.photo.thumb.endsWith(NUTRITIONIX_PLACEHOLDER_IMAGE_NAME)
      ? null
      : item.photo.thumb,
    isUpToDate: true,
    nutrition: getIngredientNutrition(item),
    servingAmount: round(item.serving_qty, 2),
    servingOptions: getIngredientServingOptions(item),
    servingUnits: item.serving_unit === ABBREVIATED_GRAM ? NORMAL_GRAM : item.serving_unit,
    thirdPartyIngredientId: id,
    thirdPartyIngredientSource: IngredientSource.Nutritionix,
  }
}
