import React, { useEffect } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Feature, NavigationBarAccessory, useFeatureFlag } from '@components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Debug } from '@config'
import { Intercom, Storage } from '@utils'
import { ChatbotIcon } from '@src/screens/Home/containers/ChatbotIcon'
import { MessageIcon } from '@src/screens/Home/containers/MessageIcon'
import { AccountAvatarNavigationBar } from '@src/components/navigationBar/AccountAvatarNavigationBar'
import { userSelector } from '@src/selectors/app'
import { useAllMemberNotes } from '@screens/NutritionistHub'
import { NutritionistHubScreen } from '../components/NutritionistHubScreen'
import { EhrNutritionistHubScreen } from '../components/EhrNutritionistHubScreen'

export const NutritionistHubContainer = () => {
  const rightAccessories: NavigationBarAccessory[] = []
  const navigation = useNavigation()
  const styles = useStyleSheet(themedStyle)
  const user = useSelector(userSelector)

  useAllMemberNotes()

  const showCoachRatingModal =
    useFeatureFlag(Feature.CoachRating) &&
    user?.lastOccurredCoachingAppointment &&
    !user.lastOccurredCoachingAppointment.coachRating &&
    moment(user.lastOccurredCoachingAppointment.lastState.createdAt).diff(new Date()) < 14 &&
    !Storage.get(`${Storage.COACH_RATING_DISMISSED_KEY}_${user.lastOccurredCoachingAppointment.id}`)

  if (useFeatureFlag(Feature.Chatbot)) {
    rightAccessories.push({
      renderIconComponent: () => <ChatbotIcon />,
      onPress: () => navigation.navigate('Chat'),
      accessibilityLabel: 'Chat',
    })
  }

  const showMessages = Debug.shouldEnableIntercom()
  if (showMessages) {
    rightAccessories.push({
      renderIconComponent: () => <MessageIcon />,
      onPress: () => Intercom.showIntercomMessenger({ source: 'NutritionistHub' }),
      accessibilityLabel: 'Message',
    })
  }

  useEffect(() => {
    if (showCoachRatingModal) {
      navigation.navigate('RateCoachModal', {
        appointmentId: user.lastOccurredCoachingAppointment?.id,
      })
    }
  }, [navigation, showCoachRatingModal, user?.lastOccurredCoachingAppointment?.id])

  return (
    <AccountAvatarNavigationBar
      title="Nutritionist"
      rightAccessories={rightAccessories}
      style={styles.container}
    >
      {user?.migrationFromInsuranceEnabled ? (
        <EhrNutritionistHubScreen />
      ) : (
        <NutritionistHubScreen />
      )}
    </AccountAvatarNavigationBar>
  )
}

const themedStyle = StyleService.create({
  container: {
    backgroundColor: 'theme.background',
  },
})
