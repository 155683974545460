import { gql } from '@apollo/client'

export const FETCH_EHR_SUGGESTED_AVAILABILITY = gql`
  query ehrSuggestedAvailability {
    ehrSuggestedAvailability {
      meetingAt
      providerId
      providerName
      title
      appointmentKind
      providerProfile {
        id
        fullName
        bio
        photo
        credentials
        specialties {
          key
          name
        }
      }
    }
  }
`
