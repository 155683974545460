import { gql } from '@apollo/client'
import { SIMPLE_SURVEY_FIELDS, SURVEY_FIELDS } from './survey'
import { SURVEY_OUTCOME } from './surveyOutcome'
import { SURVEY_LINK_CONFIG_FIELDS } from './surveyLinkConfig'

export const SURVEY_LINK = gql`
  ${SURVEY_FIELDS}
  ${SURVEY_OUTCOME}
  ${SURVEY_LINK_CONFIG_FIELDS}
  fragment SurveyLink on SurveyLink {
    id
    state
    inProgress
    startedAt
    finished
    finishedAt
    outcome {
      ...SurveyOutcome
    }
    survey {
      ...SurveyFields
    }
    responses {
      id
      questionKey
      answer
    }
    earlyExitDeepLink

    config {
      ...SurveyLinkConfig
    }
  }
`

export const SIMPLE_SURVEY_LINK = gql`
  ${SIMPLE_SURVEY_FIELDS}
  ${SURVEY_OUTCOME}
  fragment SimpleSurveyLink on SurveyLink {
    id
    state
    inProgress
    startedAt
    finished
    finishedAt
    outcome {
      ...SurveyOutcome
    }
    survey {
      ...SimpleSurveyFields
    }
    responses {
      id
      questionKey
      answer
    }
    earlyExitDeepLink
  }
`
