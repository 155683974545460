import React, { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { Tooltip } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@src/components/base'
import { DailyAction } from '@src/types'
import { Haptic } from '@src/utils'

export interface AutocompleteBadgeProps {
  dailyAction: DailyAction & { completed: boolean }
  onPress: () => void
}

const AutocompleteBadge = ({ dailyAction, onPress }: AutocompleteBadgeProps) => {
  const styles = useStyleSheet(themedStyle)

  const [visible, setVisible] = useState(false)

  const handlePress = () => {
    if (!dailyAction.completed) {
      return onPress()
    }

    if (!visible) {
      Haptic.heavyTap()
    }
    setVisible(!visible)
  }

  return (
    <TouchableOpacity accessibilityLabel="autocomplete" onPress={handlePress}>
      <View style={styles.iconWrapper}>
        <View
          style={[
            styles.iconCircleBackground,
            dailyAction.completed && styles.iconCircleBackgroundActive,
          ]}
        />
        <Tooltip
          anchor={() => (
            <View>
              {dailyAction.completed ? (
                <Icon name="link" style={[styles.icon, styles.iconActive]} size={16} />
              ) : (
                <Icon name="plus" style={[styles.icon]} weight="bold" size={16} />
              )}
            </View>
          )}
          placement="top"
          onBackdropPress={handlePress}
          visible={visible}
          style={styles.tooltipContainer}
        >
          {() => (
            <Text type="small" style={styles.text}>
              Auto completed by the meal you logged.
            </Text>
          )}
        </Tooltip>
      </View>
    </TouchableOpacity>
  )
}

export default AutocompleteBadge

const themedStyle = StyleService.create({
  iconWrapper: { flex: 1, marginRight: 12, justifyContent: 'center', alignItems: 'center' },
  iconCircleBackground: {
    borderRadius: 100,
    backgroundColor: 'theme.primary.base',
    width: 28,
    height: 28,
    position: 'absolute',
  },
  icon: {
    color: 'theme.solid.white',
  },
  iconActive: {
    color: 'theme.marketing.brown',
  },
  iconCircleBackgroundActive: {
    backgroundColor: 'theme.marketing.yellow',
  },
  tooltipContainer: {
    backgroundColor: 'theme.tooltip.background',
  },
  text: {
    color: 'theme.tooltip.textPrimary',
  },
})
