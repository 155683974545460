import { gql } from '@apollo/client'

export const CORE_MEAL_FIELDS = gql`
  fragment CoreMealFields on Meal {
    id
    description
    occurredAt
    generatedOccurredAt
    updatedAt
    createdAt
    favorite
    kind
    source
    title
    avatar
    ingredients {
      id
      description
      servingAmount
      servingUnits
      calories
      nutrition {
        key
        value
      }
      thirdPartyIngredientSource
      thirdPartyIngredientId
    }
    nutrition {
      key
      value
    }
    statistics {
      aucScore
      glucoseMax
      glucoseMin
      glucoseDelta
      glucose2hrDelta
      glucoseScoreDelta
      glucoseScorePeak
      glucoseScoreAuc
      glucoseScore2hrDelta
      glucoseScore
    }
    glucose {
      range
      healthyRange
      values {
        timestamp
        value
        displayValue
      }
    }
  }
`
