import React, { useState } from 'react'
import { View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { ReactNativeFile } from '@src/utils/image'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { ProfilePhoto, ScrollableAvoidKeyboard } from '@components'
import { NavigationContainer } from '@screens/Common/containers'
import { SignUpForm } from '@screens/SignUp'
import { Asset } from '@utils/image'
import { useSubmitOwnSensorSurvey } from '@src/screens/OwnSensorOnboarding/hooks'

export const CreateAccount = () => {
  const styles = useStyleSheet(themedStyle)

  const submitOwnSensorSurvey = useSubmitOwnSensorSurvey()

  const [photo, setPhoto] = useState<Partial<Asset>>()
  const [photoSource, setPhotoSource] = useState<{ uri: string }>({ uri: '' })

  const onChangeProfilePhoto = (file: ReactNativeFile) => {
    setPhoto(file)
    setPhotoSource({ uri: file.uri })
  }

  const insets = useSafeAreaInsets()

  return (
    <NavigationContainer title="Create your account" style={styles.container}>
      <ScrollableAvoidKeyboard
        style={styles.container}
        contentContainerStyle={[styles.content, { paddingBottom: insets.bottom + 16 }]}
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
        extraScrollHeight={200}
      >
        <View style={styles.headerContainer}>
          <ProfilePhoto source={photoSource} onPhotoChange={onChangeProfilePhoto} />
          <Text type="regular" bold style={styles.photoLabel}>
            Add a photo
          </Text>
        </View>
        <SignUpForm photo={photo} onSuccess={submitOwnSensorSurvey} />
      </ScrollableAvoidKeyboard>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    paddingHorizontal: 8,
  },
  content: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: 600,
    paddingHorizontal: 16,
  },
  headerContainer: {
    paddingVertical: 24,
    alignItems: 'center',
  },
  photoLabel: {
    marginTop: 16,
  },
})
