import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { ScrollView, View } from 'react-native'
import { Divider } from '@ui-kitten/components'
import { useNavigation, useRoute } from '@react-navigation/native'
import { NavigationContainer } from '@src/screens/Common/containers'
import {
  dexcomResearchClientIdSelector,
  dexcomSteloClientIdSelector,
  researchFaqsSelector,
} from '@src/selectors/app'
import { Button, Toggle } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Intercom, openUrl } from '@src/utils'
import { Apollo } from '@src/config'
import { Dexcom } from '@src/services'
import { Feature, InlineAlert, Section, useFeatureFlag } from '@src/components'
import {
  DexcomConnectionKind,
  DexcomConnectionStatus,
  SensorModel,
  UiInlineAlert,
} from '@src/types'
import {
  useDexcomConnection,
  useDexcomConnectionKind,
  useDexcomConnectionSensorName,
  useIsDexcomResearch,
} from '@src/utils/hooks'
import { AppRouteProp } from '@src/navigation/types'
import { sensorSelector } from '@src/selectors/sensor'
import LastSyncedSection from '../components/Integrations/LastSyncedSection'

const CALIBRATE_SENSOR_TEXT = 'Calibrate Sensor'
const REQUEST_SUPPORT_TEXT = 'Request Sensor Support'
const ACTIVATE_SENSOR_TEXT = 'How to Activate a Sensor'

export const DexcomSensorSettingsContainer = () => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const dispatch = useDispatch()
  const route = useRoute<AppRouteProp<'DexcomSensorSettings'>>()
  const { autoToggleOn = false } = route.params || {}
  const navigation = useNavigation()

  const enableSteloRealtimeCalibration = useFeatureFlag(Feature.SteloRealtimeCalibration)

  const dexcomConnectionKind = useDexcomConnectionKind()

  const dexcomConnection = useDexcomConnection()

  const researchFaqs = useSelector(researchFaqsSelector)
  const sensor = useSelector(sensorSelector)

  const isDexcomResearch = useIsDexcomResearch()

  const isDexcomSteloConnection = dexcomConnectionKind === DexcomConnectionKind.Stelo
  const dexcomClientId = useSelector(
    isDexcomSteloConnection ? dexcomSteloClientIdSelector : dexcomResearchClientIdSelector,
  )

  const sensorName = useDexcomConnectionSensorName()

  const DEXCOM_CONNECTION_SUCCESS_TEXT = `${sensorName} integration activated! Sensor data will appear in Nutrisense app shortly.`
  const DEXCOM_CONNECTION_ERROR_TEXT = `${sensorName} integration failed. Issues with setup? Request sensor support below.`

  const [toggleActive, setToggleActive] = useState(
    dexcomConnection?.status === DexcomConnectionStatus.Active,
  )
  const [error, setError] = useState<string | null>(null)

  const onEnableConnection = useCallback(() => {
    Dexcom.login({
      useSandbox: Apollo.endpointEnv !== 'production',
      clientId: dexcomClientId,
    }).then(({ authorizationCode }) => {
      if (authorizationCode) {
        setError(null)
        dispatch({
          type: 'app/upsertDexcomConnection',
          payload: { authorizationCode, dexcomConnectionKind },
          success: () => {
            setToggleActive(true)
            navigation.navigate('DexcomConnectionSuccessful')
          },
          failure: () => {
            setToggleActive(false)
            setError(DEXCOM_CONNECTION_ERROR_TEXT)
          },
        })
      } else {
        setToggleActive(false)
        setError(DEXCOM_CONNECTION_ERROR_TEXT)
      }
    })
  }, [DEXCOM_CONNECTION_ERROR_TEXT, dexcomClientId, dexcomConnectionKind, dispatch, navigation])

  const onDisableConnection = () => {
    setToggleActive(false)
    dispatch({
      type: 'app/deleteDexcomConnection',
      payload: { dexcomConnectionKind },
      failure: () => {
        setToggleActive(true)
      },
    })
  }

  const onTogglePress = () => {
    if (toggleActive) {
      onDisableConnection()
    } else {
      setToggleActive(true)
      onEnableConnection()
    }
  }

  const shouldAutomaticallyEnableConnection = autoToggleOn && !toggleActive

  useEffect(() => {
    if (shouldAutomaticallyEnableConnection) {
      navigation.setParams({ autoToggleOn: false })

      onEnableConnection()
    }
  }, [navigation, onEnableConnection, shouldAutomaticallyEnableConnection])

  const onActivateSensorPress = () => {
    openUrl(researchFaqs.gettingStartedUrl)
  }

  const onRequestSupportPress = () => {
    Intercom.showIntercomMessenger({ source: 'SensorSettings' })
  }

  const onCalibrateSensorPress = () => {
    navigation.navigate('CalibrateSensor')
  }

  const shouldShowCalibrationButton =
    sensor &&
    !isDexcomResearch &&
    (sensor.modelEnum !== SensorModel.DexcomStelo || enableSteloRealtimeCalibration)

  return (
    <NavigationContainer title="Sensor Settings" showLoadingIndicator>
      <ScrollView
        contentContainerStyle={[styles.content, { paddingBottom: insets.bottom + 16 }]}
        bounces={false}
        style={styles.container}
      >
        <View style={styles.content}>
          <Section title="Dexcom">
            <Toggle checked={toggleActive} onChange={onTogglePress} />
          </Section>
          <Divider />
          {dexcomConnection?.lastSyncedAt && (
            <>
              <LastSyncedSection lastSynced={dexcomConnection.lastSyncedAt} />
              <Divider />
            </>
          )}
          {dexcomConnection && !dexcomConnection.lastSyncedAt && (
            <InlineAlert
              category={UiInlineAlert.Info}
              message={DEXCOM_CONNECTION_SUCCESS_TEXT}
              style={styles.inlineAlert}
            />
          )}
          {error && (
            <InlineAlert
              category={UiInlineAlert.Danger}
              message={DEXCOM_CONNECTION_ERROR_TEXT}
              style={styles.inlineAlert}
            />
          )}
          {dexcomConnection?.status === DexcomConnectionStatus.Invalidated && (
            <InlineAlert
              style={styles.inlineAlert}
              category={UiInlineAlert.Danger}
              message={`Your ${sensorName} data integration authentication has been reset. Please authenticate again to integrate your data.`}
            />
          )}
        </View>
        <View style={styles.buttonsWrapper}>
          {shouldShowCalibrationButton && (
            <Button
              accessibilityLabel={CALIBRATE_SENSOR_TEXT}
              type="primary"
              size="block"
              onPress={onCalibrateSensorPress}
            >
              {CALIBRATE_SENSOR_TEXT}
            </Button>
          )}
          <Button
            accessibilityLabel={REQUEST_SUPPORT_TEXT}
            type="outline"
            size="block"
            onPress={onRequestSupportPress}
          >
            {REQUEST_SUPPORT_TEXT}
          </Button>
          {/* TODO: Add back when Stelo has an FAQ to link to */}
          {!isDexcomSteloConnection && (
            <Button
              type="transparent"
              size="block"
              onPress={onActivateSensorPress}
              accessibilityLabel={ACTIVATE_SENSOR_TEXT}
            >
              {ACTIVATE_SENSOR_TEXT}
            </Button>
          )}
        </View>
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    paddingTop: 16,
    backgroundColor: 'theme.background',
  },
  content: {
    flex: 1,
  },
  inlineAlert: {
    marginTop: 24,
    marginHorizontal: 16,
  },
  buttonsWrapper: {
    marginTop: 24,
    gap: 8,
    marginHorizontal: 16,
  },
})
