import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { RouteProp, useRoute } from '@react-navigation/core'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { App } from '@src/config'
import { Button } from '@components/base'
import { productsSelector } from '@src/selectors/app'
import { SubscriptionPlans } from '@src/screens/Marketplace/components/SubscriptionPlans'
import { NavigationContainer } from '@src/screens/Common/containers'
import { OnboardingStackParamsList, SubscriptionsStackParamsList } from '@src/navigation/types'
import { useAllowSkipPurchaseFlow, useShouldShowPurchaseFlow } from '@src/utils/hooks'
import { isGroupedProducts } from '@src/screens/Marketplace/types/types'
import { Product } from '@src/types'
import { useGroupedProducts } from '@src/screens/Marketplace/utils/hooks'
import { calculateSavings } from '@src/screens/Marketplace/utils/utils'
import { useNavigateToCheckout } from '../hooks/useNavigateToCheckout'

export const SelectSubscription = () => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const route = useRoute<
    | RouteProp<OnboardingStackParamsList, 'SelectSubscription'>
    | RouteProp<SubscriptionsStackParamsList, 'SelectSubscription'>
  >()
  const { productGroupId, requireEligibilityCheck = false } = route.params ?? {}

  const dispatch = useDispatch()

  const [selectedProduct, setSelectedProduct] = useState<Product>()
  const allProducts = useSelector(productsSelector)

  const products = allProducts.filter((product) => product.productGroup?.id === productGroupId)
  const groupedProducts = useGroupedProducts(products)
  const item = groupedProducts.find((group) => isGroupedProducts(group))

  const navigateToCheckout = useNavigateToCheckout()

  const onSkipPress = () => {
    dispatch({
      type: 'app/skipOnboardingFlow',
    })
  }

  const allowSkipPurchaseFlow = useAllowSkipPurchaseFlow()

  const showPurchaseFlow = useShouldShowPurchaseFlow()

  if (!item) {
    return null
  }

  const onNextPress = () => {
    if (!selectedProduct) {
      return
    }

    const product = selectedProduct
    const savings = calculateSavings({ item, product })

    navigateToCheckout({ product, savings, requireEligibilityCheck })
  }

  const title = item.products.every((product) => product.includesCgm)
    ? 'Glucose Programs'
    : 'Programs'

  return (
    <NavigationContainer style={styles.container} title={title}>
      <ScrollView
        style={styles.container}
        contentContainerStyle={{ flexGrow: 1, paddingBottom: insets.bottom + 16 }}
      >
        <SubscriptionPlans
          item={item}
          selectedProduct={selectedProduct}
          onProductSelected={(product) => setSelectedProduct(product)}
        />
        <View style={styles.actionsContainer}>
          {selectedProduct && (
            <Button type="primary" size="block" accessibilityLabel="Next" onPress={onNextPress}>
              Next
            </Button>
          )}
          {allowSkipPurchaseFlow && showPurchaseFlow && (
            <Button
              accessibilityLabel="Not now"
              size="block"
              type="transparent"
              onPress={onSkipPress}
            >
              {'Not now' + (App.build.production ? '' : ' [Debug]')}
            </Button>
          )}
        </View>
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  actionsContainer: {
    marginTop: 24,
    marginHorizontal: 16,
  },
})
