import React from 'react'
import { Image, ImageStyle } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { BottomSheet } from '@src/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@src/components/base'
import { nutritionGuidanceImage } from '@src/assets/images'
import { SurveysConfigKind, User } from '@src/types'
import { userSelector } from '@src/selectors/app'
import { AppStackParamList } from '@src/navigation/types'
import { ByosVideoCallsUpsellStep } from '../constants'
import { recordByosVideoCallsUpsellInteraction } from '../utils/utils'

export const NutritionGuidanceUpsellModal = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const user = useSelector(userSelector)

  const onSeeIfYouQualifyPress = () => {
    recordByosVideoCallsUpsellInteraction(ByosVideoCallsUpsellStep.InitialModal, user as User)
    navigation.replace('Questionnaire', {
      questionnaire: SurveysConfigKind.Insurance,
      skipIntroScreen: true,
    })
  }

  const onDismiss = () => {
    recordByosVideoCallsUpsellInteraction(ByosVideoCallsUpsellStep.InitialModal, user as User)
  }

  return (
    <BottomSheet
      primaryButton={{ text: 'See If You Qualify', onPress: onSeeIfYouQualifyPress }}
      showDismissButton
      onDismiss={onDismiss}
      dismissButtonText="No thanks"
      buttonDirection="column-reverse"
    >
      <Image source={nutritionGuidanceImage.imageSource} style={styles.image as ImageStyle} />
      <Text style={styles.title} type="title-2">
        Get professional nutrition guidance paid for by your insurance
      </Text>
      <Text style={styles.content} type="regular">
        Get access to a glucose-certified nutritionist with experience in analyzing CGM data. Most
        Nutrisense members pay $0 out-of-pocket. Easily check your coverage and get ready to boost
        your health journey.
      </Text>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  title: {
    textAlign: 'center',
    marginVertical: 16,
  },
  content: {
    textAlign: 'center',
    marginBottom: 16,
  },
  image: {
    aspectRatio: 3 / 2,
    width: '100%',
    height: undefined,
    borderRadius: 20,
  },
})
