import React, { useContext } from 'react'
import { RouteProp, useRoute } from '@react-navigation/native'
import { IntroScreen, SurveyLinkConfigIntroItem } from '@src/types'
import { QuestionnaireScreenName, QuestionnaireStackParamList } from '@src/navigation/types'
import { QuestionnaireContext } from '../../questionnaire.navigator'
import { SectionWithBackgroundImage } from './SectionWithBackgroundImage'
import { SectionWithoutBackgroundImage } from './SectionWithoutBackgroundImage'

export const QuestionnaireSectionStartScreen = () => {
  const {
    sectionStartScreenConfig: { findItemByIndex },
  } = useContext(QuestionnaireContext)
  const route = useRoute<
    RouteProp<QuestionnaireStackParamList, QuestionnaireScreenName.QuestionnaireSectionStart>
  >()
  const { currentItemIndex, shouldIncrementItemIndex } = route.params

  const introScreen = findItemByIndex(currentItemIndex) as SurveyLinkConfigIntroItem

  const { backgroundImageUrl } = introScreen as IntroScreen

  return backgroundImageUrl ? (
    <SectionWithBackgroundImage
      currentIntroItemIndex={currentItemIndex}
      shouldIncrementItemIndex={shouldIncrementItemIndex}
      introScreen={introScreen}
    />
  ) : (
    <SectionWithoutBackgroundImage
      shouldIncrementItemIndex={shouldIncrementItemIndex}
      currentIntroItemIndex={currentItemIndex}
      introScreen={introScreen}
    />
  )
}
