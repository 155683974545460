import { Selector, createSelector } from 'reselect'
import moment from 'moment'
import { EventsItemType } from '@src/screens/Events/models/events.types'
import { dailyMeasurementTypeDefLookupSelector } from '@src/selectors/app'
import { interpolateEvents } from '@src/models/dailyCharts/chart.transforms'
import { RootStoreState } from '../app.types'

export const dailyChartsStoreStateSelector = (state: RootStoreState) => state.dailyCharts

export const eventsChartsSelector = <T extends EventsItemType>(
  eventsSelector: Selector<RootStoreState, T[]>,
) =>
  createSelector(
    [eventsSelector, dailyChartsStoreStateSelector, dailyMeasurementTypeDefLookupSelector],
    (events, charts, dailyMeasurementTypeDefLookup) => {
      const {
        charts: { primary, secondary },
      } = charts

      const primaryValuesWithMoment = primary.values.map((value) => ({
        ...value,
        x: moment(value.x),
      }))

      const secondaryValuesWithMoment = secondary.values.map((value) => ({
        ...value,
        x: moment(value.x),
      }))

      const eventsWithoutDailyMeasurements = events.filter(
        (event) => !('type' in event) || !dailyMeasurementTypeDefLookup[event.type],
      )

      const interpolatedPrimaryEvents = interpolateEvents(
        primaryValuesWithMoment,
        eventsWithoutDailyMeasurements,
        primary.range,
      )

      const interpolatedSecondaryEvents = interpolateEvents(
        secondaryValuesWithMoment,
        eventsWithoutDailyMeasurements,
        secondary.range,
      )

      return {
        ...charts,
        primary: {
          ...primary,
          values: primaryValuesWithMoment.concat(interpolatedPrimaryEvents),
        },
        secondary: {
          ...secondary,
          values: secondaryValuesWithMoment.concat(interpolatedSecondaryEvents),
        },
      }
    },
  )
