import React from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Button, Icon, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppStackParamList } from '@src/navigation/types'

export const SteloPaymentSuccessful = () => {
  const styles = useStyleSheet(themedStyle)

  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const onPress = () => {
    navigation.replace('Drawer')
  }

  return (
    <SafeAreaView edges={['top', 'bottom']} style={styles.container}>
      <View style={styles.content}>
        <Icon weight="fill" style={styles.icon} name="seal-check" size={56} />
        <Text type="title-2" style={[styles.text, styles.title]}>
          Payment successful!
        </Text>

        <View>
          <Text type="title-3" style={styles.text}>
            Your Stelo Biosensor is on the way 🚀
          </Text>
          <Text type="regular" style={[styles.text, styles.secondaryText]}>
            This usually takes 5-7 business days
          </Text>
        </View>
      </View>

      <Button type="primary" size="block" accessibilityLabel="Explore The App" onPress={onPress}>
        Explore The App
      </Button>
    </SafeAreaView>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    color: 'theme.success.base',
  },
  text: {
    textAlign: 'center',
  },
  title: {
    marginTop: 24,
    marginBottom: 78,
  },
  secondaryText: {
    marginTop: 12,
    marginBottom: 80,
    color: 'theme.text.secondary',
  },
})
