import React, { useMemo, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { RouteProp, useRoute } from '@react-navigation/native'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button } from '@components/base'
import { CommonAuthorizedStackParamList } from '@src/navigation/types'
import { TransparentNavigationBar } from '@src/components/navigationBar/TransparentNavigationBar'
import { ChoiceList } from '@components/Choice'
import { NavigationContainer } from '@src/screens/Common/containers'
import { Product } from '@src/types'

interface ProductItem {
  id: string
  body: string
  product: Product
}

export const DietitianMenuGroupProductSelector = () => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const navigation = useNavigation<StackNavigationProp<CommonAuthorizedStackParamList>>()
  const route = useRoute<
    RouteProp<CommonAuthorizedStackParamList, 'DietitianMenuGroupProductSelector'>
  >()
  const { groupedProducts, selection } = route.params
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(selection?.product)

  const items: ProductItem[] = useMemo(
    () =>
      groupedProducts.products.map((product) => ({ product, id: product.id, body: product.title })),
    [groupedProducts],
  )

  const onSelectPress = () => {
    if (!selectedProduct) {
      return
    }
    const selection = { product: selectedProduct }

    navigation.navigate('DietitianMenuCheckout', { groupedProducts, selection })
  }

  const onChooseProduct = ({ product }: ProductItem) => {
    setSelectedProduct(product)
  }

  const handleFeedbackPress = () => {
    navigation.navigate('DietitianMenuFeedback')
  }

  return (
    <NavigationContainer
      style={styles.container}
      title="Choose Question"
      NavigationBarComponent={TransparentNavigationBar}
    >
      <ScrollView
        style={styles.container}
        contentContainerStyle={[styles.contentContainer, { paddingBottom: insets.bottom + 16 }]}
      >
        <View>
          <ChoiceList
            items={items}
            selectedItemId={selectedProduct?.id}
            onPress={onChooseProduct}
          />
        </View>
        <View>
          <Button
            type="primary"
            size="block"
            disabled={!selectedProduct}
            accessibilityLabel="Choose"
            onPress={onSelectPress}
          >
            Choose
          </Button>
          <Button
            type="transparent"
            size="block"
            style={styles.feedbackButton}
            accessibilityLabel="My question is not listed here"
            onPress={handleFeedbackPress}
          >
            My question is not listed here
          </Button>
        </View>
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    justifyContent: 'space-between',
    flexGrow: 1,
    padding: 24,
  },
  feedbackButton: {
    marginTop: 8,
  },
})
