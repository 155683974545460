import { gql } from '@apollo/client'

const DELETE_MEAL = gql`
  mutation deleteMeal($id: ID!) {
    deleteMeal(id: $id) {
      id
    }
  }
`

export default DELETE_MEAL
