import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  SensorStatus,
  SensorTypes,
  beginActivation,
  beginScanning,
} from 'react-native-freestyle-libre'
import { CommonActions, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Sensor } from '@utils'
import { globalNFCListener } from '@services'
import { ScanModalComponent } from '@src/screens/Scans/components/ScanModal'
import { useGoBack } from '@src/utils/navigation'
import { useSnack } from '@src/utils/navigatorContext'
import { AppRouteProp, AppStackParamList, OnboardingStackParamsList } from '@src/navigation/types'
import { Feature, useFeatureFlag } from '@src/components'
import { libreLinkUpPatientIdSelector, userSelector } from '@src/selectors/app'
import { useDisableInAppScan } from '@src/hooks/useDisableInAppScan'
import { useLibre3NativeEnabled } from '@src/utils/hooks'
import { ScanningContext } from '@src/context/scanningContext'
import Storage from '@src/utils/storage'
import { useBluetoothStatus } from '@src/screens/Home/utils/useBluetoothStatus'
import { useShowScanMigrationOnScan } from '../hooks/useShowScanMigrationOnScan'
import { useIsScanDisabledForSensor } from '../hooks/useIsScanDisabledForSensor'

export const ScanModalContainer = () => {
  const navigation = useNavigation<
    StackNavigationProp<AppStackParamList & OnboardingStackParamsList>
  >()
  const route = useRoute<AppRouteProp<'AddScan'>>()
  const goBack = useGoBack()
  const showSnack = useSnack()

  const [isScanning, setIsScanning] = useState(false)
  const { isNfcSupported, isNfcEnabled } = useContext(ScanningContext)

  const action = route.params?.action || 'scan'

  const enableLibre2Support = useFeatureFlag(Feature.Libre2Support)
  const { isBluetoothEnabled } = useBluetoothStatus()
  const isScanDisabled = useDisableInAppScan()
  const scanDisabledForSensor = useIsScanDisabledForSensor()
  const { uxSensorModePendingLibre3, currentSensorIsLibre3 } = useLibre3NativeEnabled()

  const showMigrationGuide = useShowScanMigrationOnScan()
  const user = useSelector(userSelector)

  const allowLibre3ScanningWithoutLibreLinkUpPatientId = useFeatureFlag(
    Feature.Libre3ScanningWithoutLibreLinkUpPatientId,
  )

  const libreLinkUpPatientId = useSelector(libreLinkUpPatientIdSelector)

  const parentScreen = route.params?.parentScreen
  const parentScreenParams =
    (route.params && 'parentScreenParams' in route.params && route.params.parentScreenParams) ||
    undefined

  const close = useCallback(() => {
    if (parentScreen) {
      navigation.replace(parentScreen as any, parentScreenParams)
      return
    }

    goBack()
  }, [goBack, navigation, parentScreen, parentScreenParams])

  const startScanning = useCallback(() => {
    setIsScanning(true)

    globalNFCListener.scanningOptions.enableLibre2Support = enableLibre2Support
    globalNFCListener.scanningOptions.enableLibre3Support = true
    globalNFCListener.scanningOptions.libreLinkUpPatientId = libreLinkUpPatientId || undefined
    // eslint-disable-next-line max-len
    globalNFCListener.scanningOptions.allowLibre3ScanningWithoutLibreLinkUpPatientId = allowLibre3ScanningWithoutLibreLinkUpPatientId

    if (action === 'activate') {
      globalNFCListener.activate()
    } else {
      globalNFCListener.scan()
    }
  }, [
    action,
    allowLibre3ScanningWithoutLibreLinkUpPatientId,
    enableLibre2Support,
    libreLinkUpPatientId,
  ])

  const onCompleted = useCallback(
    (data?: Awaited<ReturnType<typeof beginScanning>>) => {
      setIsScanning(false)

      globalNFCListener.stop()

      const { success, sensor } = data || { success: false, sensor: null }

      if (!sensor || !success) {
        return close()
      }

      const nextScreen = parentScreen || 'Drawer'

      // full block for Libre 1 and 2 users
      if (scanDisabledForSensor({ model: sensor.model })) {
        return navigation.replace('ScanMigrationGuide', {
          parentScreen: nextScreen,
          parentScreenParams: parentScreenParams as any,
        })
      }

      // warning for Libre 1 and 2 users
      if (showMigrationGuide({ model: sensor.model })) {
        Storage.set(
          `${Storage.SCAN_DISABLED_NOTICE_SHOWN_AT_KEY}_${user?.id}`,
          moment().toISOString(),
        )
        return navigation.replace('ScanMigrationGuide', {
          parentScreen: nextScreen,
          parentScreenParams: parentScreenParams as any,
        })
      }

      close()
    },
    [
      close,
      navigation,
      parentScreen,
      parentScreenParams,
      scanDisabledForSensor,
      showMigrationGuide,
      user?.id,
    ],
  )

  const onCancel = () => {
    setIsScanning(false)
  }

  const onError = () => {
    setIsScanning(false)
  }

  const onRetryButtonPress = () => {
    startScanning()
  }

  const onCancelButtonPress = () => {
    onCompleted()
  }

  useEffect(() => {
    const checkConnectivity = () => {
      if (isBluetoothEnabled === false) {
        navigation.replace('ConnectivityErrorModal', { error: 'bluetooth' })
      } else {
        close()
      }
    }

    const onActivate = (data: Awaited<ReturnType<typeof beginActivation>>) => {
      const { success, sensor } = data

      if (!success || !sensor) {
        navigation.replace('ErrorScanningModal', {
          parentScreen,
          parentScreenParams: parentScreenParams as any,
        })
        return
      }

      const nextScreen = parentScreen || 'Drawer'

      if (scanDisabledForSensor({ model: sensor.model })) {
        return navigation.replace('ScanMigrationGuide', {
          parentScreen: nextScreen,
          parentScreenParams: parentScreenParams as any,
        })
      }

      if (sensor.status === SensorStatus.Starting) {
        if (sensor.model === SensorTypes.Libre3) {
          navigation.navigate('SensorSettings')
          return
        }

        navigation.dispatch((state) => {
          // Remove activation tutorial from routes stack, so Libre 1 users don't stuck on it after sensor activation
          const routes = state.routes.filter((route) => route.name !== 'TutorialsGroup')

          return CommonActions.reset({
            ...state,
            routes,
            index: routes.length - 1,
          })
        })

        const startingSensorKey = `${Storage.SENSOR_ACTIVATION_MODAL_VISITED_KEY}_${sensor.serialNumber}`
        const scannedStartingSensor = Storage.get(startingSensorKey, false)

        if (!scannedStartingSensor) {
          Storage.set(startingSensorKey, true)
          navigation.replace('CGMActivated')
          return
        }
      }

      if (sensor.model === SensorTypes.Libre3) {
        checkConnectivity()
      } else {
        close()
      }
    }

    const subscribeForNFCEvents = () => {
      globalNFCListener.addListener('onScan', onCompleted)
      globalNFCListener.addListener('onActivate', onActivate)
      globalNFCListener.addListener('onCancel', onCancel)
      globalNFCListener.addListener('onError', onError)

      startScanning()

      // we need this method to be invoked only once
      navigation.removeListener('transitionEnd', subscribeForNFCEvents)
    }

    navigation.addListener('transitionEnd', subscribeForNFCEvents)

    return () => {
      globalNFCListener.removeListener('onScan', onCompleted)
      globalNFCListener.removeListener('onActivate', onActivate)
      globalNFCListener.removeListener('onCancel', onCancel)
      globalNFCListener.removeListener('onError', onError)
    }
  }, [
    close,
    isBluetoothEnabled,
    navigation,
    onCompleted,
    parentScreen,
    parentScreenParams,
    scanDisabledForSensor,
    showMigrationGuide,
    startScanning,
    user?.id,
  ])

  useEffect(() => {
    if (!uxSensorModePendingLibre3 && !currentSensorIsLibre3 && isScanDisabled) {
      showSnack('Your sensor does not require scanning', null, 'warning')
      goBack()
    }
  }, [currentSensorIsLibre3, goBack, isScanDisabled, showSnack, uxSensorModePendingLibre3])

  if (!uxSensorModePendingLibre3 && !currentSensorIsLibre3 && isScanDisabled) {
    return null
  }

  return (
    <ScanModalComponent
      isReadyToScan={isNfcSupported && isNfcEnabled}
      isScanning={isScanning}
      statusDescription={Sensor.statusDescription({
        isNfcEnabled,
        isNfcSupported,
        status: SensorStatus.Ready,
        action,
      })}
      onRetryButtonPress={onRetryButtonPress}
      onCancelButtonPress={onCancelButtonPress}
    />
  )
}
