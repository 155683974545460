import React from 'react'
import { View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Product } from '@src/types'
import { Button, Text } from '@src/components/base'
import { getPriceRangeDescriptor } from '@src/screens/Marketplace/utils/utils'
import { StyleService, useStyleSheet } from '@src/style/service'

interface BottomBarProps {
  selectedProduct: Product
  baseProduct: Product | undefined
  onNextPress: () => void
}

export const BottomBar = (props: BottomBarProps) => {
  const insets = useSafeAreaInsets()

  const styles = useStyleSheet(themedStyle)

  const { selectedProduct, baseProduct, onNextPress } = props

  const priceDescriptor = getPriceRangeDescriptor(selectedProduct)

  const baseProductPriceDescriptor = baseProduct ? getPriceRangeDescriptor(baseProduct) : undefined

  return (
    <View style={[styles.container, { paddingBottom: insets.bottom + 16 }]}>
      <View>
        <Text type="large" bold style={styles.title}>
          Total
        </Text>
        {!!priceDescriptor.prefix && <Text type="title-2">{priceDescriptor.prefix}</Text>}
        <Text type="title-2">
          {priceDescriptor.amount}
          <Text type="small" bold style={styles.secondaryText}>
            {priceDescriptor.suffix}
          </Text>
        </Text>
        {baseProductPriceDescriptor && baseProduct && selectedProduct.price < baseProduct.price && (
          <Text type="regular">
            <Text type="large" bold style={[styles.lineThroughText, styles.tertiaryText]}>
              {baseProductPriceDescriptor.amount}
            </Text>
            <Text type="small" bold style={styles.tertiaryText}>
              {baseProductPriceDescriptor.suffix}
            </Text>
          </Text>
        )}
      </View>
      <Button
        type="primary"
        accessibilityLabel="Next"
        style={styles.button}
        size="large"
        onPress={onNextPress}
      >
        Next
      </Button>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    marginBottom: 2,
  },
  button: {
    minWidth: 142,
  },
  secondaryText: {
    color: 'theme.text.secondary',
  },
  tertiaryText: {
    color: 'theme.text.tertiary',
  },
  lineThroughText: {
    textDecorationLine: 'line-through',
  },
})
