import { useSelector } from 'react-redux'
import { Feature, useFeatureFlag } from '@src/components'
import { userSelector } from '@src/selectors/app'
import { Storage } from '@src/utils'
import {
  useIsDexcomSteloSensorMode,
  useHasSyncedSteloRealtime,
  useHasSteloRealtimeApiUpgradeBanner,
} from '@src/utils/hooks'
import { useStorageValue } from '@src/utils/storage'

export const useShouldShowRealtimeSteloUpgradeModal = () => {
  const isSteloSignupEnabled = useFeatureFlag(Feature.SteloSignUp)
  const isDexcomSteloSensorMode = useIsDexcomSteloSensorMode()
  const user = useSelector(userSelector)
  const userId = user?.id

  const [hasViewedRealtimeSteloUpgradeModal] = useStorageValue(
    `${Storage.STELO_REALTIME_API_UPGRADE_MODAL_VISITED_KEY}_${userId}`,
  )
  const hasSyncedSteloRealtime = useHasSyncedSteloRealtime()
  const shouldShowModal = useHasSteloRealtimeApiUpgradeBanner()

  if (!isSteloSignupEnabled) {
    return false
  }

  if (!isDexcomSteloSensorMode) {
    return false
  }

  // member has already seen the banner
  if (hasViewedRealtimeSteloUpgradeModal) {
    return false
  }

  // member has created a stelo connection
  if (hasSyncedSteloRealtime) {
    return false
  }

  // member has recently been switched to Stelo and has not seen the banner yet
  return shouldShowModal
}
