export enum OwnSensorOption {
  DexcomStelo = 'Dexcom Stelo',
  DexcomG7 = 'Dexcom G7',
  DexcomG6 = 'Dexcom G6',
  FreeStyleLibre3 = 'FreeStyle Libre 3',
  FreeStyleLibre2 = 'FreeStyle Libre 2',
  FreeStyleLibre14Day = 'FreeStyle Libre 14-Day System',
  Other = 'Other',
}

export enum OwnSensorSurveyAnswer {
  DexcomStelo = 'dexcom_stelo',
  DexcomG6 = 'dexcom_g6',
  DexcomG7 = 'dexcom_g7',
  Libre1 = 'libre1',
  Libre2 = 'libre2',
  Libre3 = 'libre3',
  Other = 'other',
}

export const OwnSensorSurveyAnswers: Record<OwnSensorOption, OwnSensorSurveyAnswer> = {
  [OwnSensorOption.DexcomStelo]: OwnSensorSurveyAnswer.DexcomStelo,
  [OwnSensorOption.DexcomG6]: OwnSensorSurveyAnswer.DexcomG6,
  [OwnSensorOption.DexcomG7]: OwnSensorSurveyAnswer.DexcomG7,
  [OwnSensorOption.FreeStyleLibre14Day]: OwnSensorSurveyAnswer.Libre1,
  [OwnSensorOption.FreeStyleLibre2]: OwnSensorSurveyAnswer.Libre2,
  [OwnSensorOption.FreeStyleLibre3]: OwnSensorSurveyAnswer.Libre3,
  [OwnSensorOption.Other]: OwnSensorSurveyAnswer.Other,
}

export enum OwnSensorInfoText {
  OtherSensor = "We are working on supporting this sensor and will let you know when it's available.",
  Libre = "Make sure you can download FreeStyle Libre's app in your country.",
  Libre3 = `To use this sensor with our app, you must activate the sensor in this app. Each Libre 3 sensor can only be synced to one app at a time.`,
  DexcomG6 = `There is a 3 hour delay between Dexcom and Nutrisense. Also, make sure you can download Dexcom's app in your country.`,
  iosDexcomG7 = `There is a 3 hour delay between Dexcom and Nutrisense. Also, make sure you can download Dexcom's app in your country.`,
  AndroidDexcomG7 = 'Unfortunately, Dexcom G7 support on Android devices are unavailable at this time',
  iosDexcomStelo = `There is a 3 hour delay between Dexcom and Nutrisense. Also, make sure you can download Dexcom's app in your country.`,
  AndroidDexcomStelo = 'Unfortunately, Stelo by Dexcom support on Android devices are unavailable at this time.',
}
