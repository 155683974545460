import { createSelector } from 'reselect'
import { take } from 'lodash'
import { RootStoreState } from '@src/models/app.types'

export const nutritionistHubStateSelector = (state: RootStoreState) => state.nutritionistHub

export const latestUserAppointmentSelector = createSelector(
  nutritionistHubStateSelector,
  (nutritionistHubStore) => {
    return nutritionistHubStore.latestUserAppointment
  },
)

export const latestUserEhrAppointmentSelector = createSelector(
  nutritionistHubStateSelector,
  (nutritionistHubStore) => {
    return nutritionistHubStore.latestUserEhrAppointment
  },
)

export const insurancePolicySelector = createSelector(
  nutritionistHubStateSelector,
  (nutritionistHubStore) => nutritionistHubStore.insurancePolicy,
)

export const topPriorityCoachAssignmentSelector = createSelector(
  nutritionistHubStateSelector,
  (nutritionistHubStore) => nutritionistHubStore.topPriorityCoachAssignment,
)

export const allMemberNotesSelector = createSelector(
  nutritionistHubStateSelector,
  (nutritionistHubStore) => nutritionistHubStore.allMemberNotes,
)

export const memberNotesUpdatedAtTimestampSelector = createSelector(
  nutritionistHubStateSelector,
  (nutritionistHubStore) => nutritionistHubStore.memberNotesUpdatedAt,
)

export const pinnedMemberNotesSelector = createSelector(allMemberNotesSelector, (allMemberNotes) =>
  allMemberNotes.filter((note) => !!note.pinnedAt),
)

export const unpinnedMemberNoteSelector = createSelector(allMemberNotesSelector, (allMemberNotes) =>
  allMemberNotes.filter((note) => !note.pinnedAt),
)

export const noteSelector = (noteId: string) =>
  createSelector(allMemberNotesSelector, (notes) => {
    return notes.find((note) => note.id === noteId)
  })

export const latestUnpinnedMemberNoteSelector = createSelector(
  unpinnedMemberNoteSelector,
  (unpinnedNotes) => unpinnedNotes[0],
)

export const topMemberNotesSelector = createSelector(
  pinnedMemberNotesSelector,
  unpinnedMemberNoteSelector,
  (pinnedNotes, lastNotes) => {
    // we always want to show only the last unpinned note
    const takeCount = pinnedNotes.length + 1
    return take([...pinnedNotes, ...lastNotes], takeCount)
  },
)

export const upcomingAppointmentsSelector = createSelector(
  nutritionistHubStateSelector,
  (nutritionistHubStore) => nutritionistHubStore.upcomingAppointments,
)

export const upcomingEhrAppointmentsSelector = createSelector(
  nutritionistHubStateSelector,
  (nutritionistHubStore) => nutritionistHubStore.upcomingEhrAppointments,
)

export const pastAppointmentsSelector = createSelector(
  nutritionistHubStateSelector,
  (nutritionistHubStore) => nutritionistHubStore.pastAppointments,
)

export const pastEhrAppointmentsSelector = createSelector(
  nutritionistHubStateSelector,
  (nutritionistHubStore) => nutritionistHubStore.pastEhrAppointments,
)

export const pendingAppointmentsSelector = createSelector(
  nutritionistHubStateSelector,
  (nutritionistHubStore) => nutritionistHubStore.pendingAppointments,
)

export const pendingEhrAppointmentsSelector = createSelector(
  nutritionistHubStateSelector,
  (nutritionistHubStore) => nutritionistHubStore.pendingEhrAppointments,
)

export const suggestedAvailabilitySelector = createSelector(
  nutritionistHubStateSelector,
  (nutritionistHubStore) => nutritionistHubStore.suggestedAvailability,
)

export const ehrSuggestedAvailabilitySelector = createSelector(
  nutritionistHubStateSelector,
  (nutritionistHubStore) => nutritionistHubStore.ehrSuggestedAvailability,
)

export const appointmentRecurrenceSelector = createSelector(
  nutritionistHubStateSelector,
  (nutritionistHubStore) => nutritionistHubStore.appointmentRecurrence,
)

export const insuranceCardSubmissionSelector = createSelector(
  nutritionistHubStateSelector,
  (nutritionistHubStore) => nutritionistHubStore.insuranceCardSubmission,
)

export const earlierAvailableSlotsByAppointmentIdSelector = createSelector(
  nutritionistHubStateSelector,
  (nutritionistHubStore) => nutritionistHubStore.earlierAvailableSlotsByAppointmentId || {},
)

export const ehrEarlierAvailableSlotsByAppointmentIdSelector = createSelector(
  nutritionistHubStateSelector,
  (nutritionistHubStore) => nutritionistHubStore.ehrEarlierAvailableSlotsByAppointmentId || {},
)

export const latestUserAppointmentEarlierAvailableSlotsSelector = createSelector(
  [latestUserAppointmentSelector, earlierAvailableSlotsByAppointmentIdSelector],
  (latestUserAppointment, earlierAvailableSlotsByAppointmentId) => {
    if (!latestUserAppointment) {
      return []
    }

    return earlierAvailableSlotsByAppointmentId[latestUserAppointment.id] || []
  },
)

export const latestUserEhrAppointmentEarlierAvailableSlotsSelector = createSelector(
  [latestUserEhrAppointmentSelector, ehrEarlierAvailableSlotsByAppointmentIdSelector],
  (latestUserAppointment, earlierAvailableSlotsByAppointmentId) => {
    if (!latestUserAppointment) {
      return []
    }

    return earlierAvailableSlotsByAppointmentId[latestUserAppointment.id] || []
  },
)

export const ehrAvailableDatesWithSlotsSelector = createSelector(
  nutritionistHubStateSelector,
  (nutritionistHubStore) => nutritionistHubStore.ehrAvailableDatesWithSlots,
)
