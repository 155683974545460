import { gql } from '@apollo/client'
import { CORE_USER_FIELDS } from './fragments/user'

export const CREATE_USER = gql`
  ${CORE_USER_FIELDS}
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $phoneNumber: String
    $photo: Upload
    $policyConsentKinds: [LegalPolicyConsentKind!]
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      photo: $photo
      phoneNumber: $phoneNumber
      email: { email: $email, password: $password }
      policyConsentKinds: $policyConsentKinds
    ) {
      token
      role
      success
      status
      user {
        ...CoreUserFields
      }
      features
    }
  }
`
