import { gql } from '@apollo/client'
import { SIMPLE_SURVEY_LINK } from './fragments/surveyLink'
import { SURVEY_LINK_CONFIG_FIELDS } from './fragments/surveyLinkConfig'

export const FETCH_LATEST_SURVEY_BY_KIND = gql`
  ${SIMPLE_SURVEY_LINK}
  ${SURVEY_LINK_CONFIG_FIELDS}
  query latestSurveyLinkByKind($kind: SurveysConfigKind!) {
    latestSurveyLinkByKind(kind: $kind) {
      ...SimpleSurveyLink
      completionDeepLink

      config {
        ...SurveyLinkConfig
      }
    }
  }
`
