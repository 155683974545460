import { trim } from 'lodash'
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js'
export const PATTERN_NAME = /[a-zA-Z ,.'-]+/
export const PATTERN_DOB = /\d{1,2}\/\d{1,2}\/\d{4}/
export const PATTERN_EMAIL = new RegExp(
  /^([0-9a-zA-Z]+[-._+])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])(?!con$)[a-zA-Z]{2,6}$/,
)
export const PATTERN_PASSWORD_LENGTH = /.{10,}/
export const PATTERN_UPPER_CASE = /[A-ZČĐŠŽ]/
export const PATTERN_DIGIT = /\d/
export const PATTERN_SPECIAL_CHARACTER = /[^\w\s]|_/
export const PATTERN_SMS_CODE = /\d{4}/
export const PATTERN_CARD_NUMBER = /\d{4} \d{4} \d{4} \d{4}/
export const PATTERN_CARD_EXPIRE_DATE = /\d{2}\/\d{2}/
export const PATTERN_CARD_CVV = /\d{3}/
export const PATTERN_FULLNAME = /^$|^[a-zA-ZčČćĆđĐšŠžŽ-]+ [a-zA-ZčČćĆđĐšŠžŽ-]+$/
export const PATTERN_WILD = /.*/
export const PATTERN_GLUCOSE_VALUE = /^[0-9]{2,3}$/
export const PATTERN_INTEGER_NUMBER = /^\d+$/
export const PATTERN_FLOAT_NUMBER = /^\d*([.,]?)(\d+)?$/
export const PATTERN_STATE = /^((A[LKSZR])|(C[AOT])|(D[EC])|(F[ML])|(G[AU])|(HI)|(I[DLNA])|(K[SY])|(LA)|(M[EHDAINSOT])|(N[EVHJMYCD])|(MP)|(O[HKR])|(P[WAR])|(RI)|(S[CD])|(T[NX])|(UT)|(V[TIA])|(W[AVIY]))$/ //eslint-disable-line
export const PATTERN_ZIP_CODE = /^[0-9]{5}(?:-[0-9]{4})?$/
export const PATTERN_CANADIAN_POSTAL_CODE = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
export const PATTERN_UK_POSTAL_CODE = new RegExp(
  /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/,
)
export const PATTERN_LIBRE_PASSWORD = /.{8,36}$/
export const PATTERN_LIBRE_2FA_CODE = /\d{6}$/

const RegExpValidator = (regexp: RegExp, value: string) => {
  if (!value) {
    return false
  }

  return regexp.test(value)
}

export const validateIntegerNumberInput = (value: string) => {
  if (value.length === 0) {
    return value
  }

  const parsedValue = Number(value)

  if (!value.match(PATTERN_INTEGER_NUMBER) || isNaN(parsedValue)) {
    return null
  }

  const processedValue = value

  if (processedValue.length >= 2 && processedValue[0] === '0') {
    return processedValue.substring(1)
  }

  return value
}

export const validateFloatNumberInput = (value: string) => {
  if (value.length === 0) {
    return value
  }

  const parsedValue = Number.parseFloat(value)

  const processedValue = value.replace(',', '.')

  if (processedValue === '.') {
    return '0.'
  }

  if (!value.match(PATTERN_FLOAT_NUMBER) || isNaN(parsedValue)) {
    return null
  }

  if (processedValue.length >= 2 && processedValue[0] === '0' && processedValue[1] !== '.') {
    return processedValue.substring(1)
  }

  return processedValue
}

export const WildValidator = (value: string) => {
  return RegExpValidator(PATTERN_WILD, value)
}

export const GlucoseValueValidator = (value: string) => {
  return RegExpValidator(PATTERN_GLUCOSE_VALUE, value)
}

export const NameValidator = (value: string) => {
  return RegExpValidator(PATTERN_NAME, value)
}

export const StateValidator = (value: string) => {
  return RegExpValidator(PATTERN_STATE, value)
}

export const ZipCodeValidator = (value: string) => {
  return RegExpValidator(PATTERN_ZIP_CODE, value)
}

export const CanadianPostalCodeValidator = (value: string) => {
  return RegExpValidator(PATTERN_CANADIAN_POSTAL_CODE, value)
}

export const UkPostalCodeValidator = (value: string) => {
  return RegExpValidator(PATTERN_UK_POSTAL_CODE, value)
}

export const DOBValidator = (value: string) => {
  return RegExpValidator(PATTERN_DOB, value)
}

export const EmailValidator = (value: string) => {
  return RegExpValidator(PATTERN_EMAIL, value.trim())
}

export const PasswordLengthValidator = (value: string) => {
  return RegExpValidator(PATTERN_PASSWORD_LENGTH, value)
}

export const PasswordMatchValidator = (password: string, repeatedPassword: string) => {
  return password === repeatedPassword
}

export const UpperCaseValidator = (value: string) => {
  return RegExpValidator(PATTERN_UPPER_CASE, value)
}

export const DigitValidator = (value: string) => {
  return RegExpValidator(PATTERN_DIGIT, value)
}

export const SpecialCharacterValidator = (value: string) => {
  return RegExpValidator(PATTERN_SPECIAL_CHARACTER, value)
}

export const PasswordValidator = (value: string) => {
  return (
    PasswordLengthValidator(value) &&
    UpperCaseValidator(value) &&
    (DigitValidator(value) || SpecialCharacterValidator(value))
  )
}

export const PhoneNumberValidator = (value: string, country: CountryCode) => {
  return isValidPhoneNumber(value, country)
}

export const SMSCodeValidator = (value: string) => {
  return RegExpValidator(PATTERN_SMS_CODE, value)
}

export const CardNumberValidator = (value: string) => {
  return RegExpValidator(PATTERN_CARD_NUMBER, value)
}

export const ExpirationDateValidator = (value: string) => {
  return RegExpValidator(PATTERN_CARD_EXPIRE_DATE, value)
}

export const CvvValidator = (value: string) => {
  return RegExpValidator(PATTERN_CARD_CVV, value)
}

export const CardholderNameValidator = (value: string) => {
  return RegExpValidator(PATTERN_FULLNAME, value)
}

export const NonEmptyStringValidator = (value: string) => {
  return trim(value).length > 0
}

export const LibrePasswordValidator = (value: string) => {
  return RegExpValidator(PATTERN_LIBRE_PASSWORD, value)
}

export const Libre2FACodeValidator = (value: string) => {
  return RegExpValidator(PATTERN_LIBRE_2FA_CODE, value)
}
