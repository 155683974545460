import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@src/components/base'

export const EmptyState = () => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.emptyContainer}>
      <Text type="regular" style={styles.emptyText}>
        No nutritionists available for this date. Please select another date.
      </Text>
    </View>
  )
}

const themedStyles = StyleService.create({
  emptyContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
  },
  emptyText: {
    color: 'theme.text.secondary',
    textAlign: 'center',
    paddingHorizontal: 20,
  },
})
