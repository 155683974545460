import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { WithWalkthrough } from '@src/components/WithWalkthrough'
import { Text } from '@components/base'
import { StyleService, useStyleSheet } from '@src/style/service'

export interface SensorBenefitTooltipProps {
  tooltipText: string
  actionText: string
  secondaryText: string
}

const DISPLAY_INSETS = { top: 0, bottom: 0, left: 12, right: 12 }

export const SensorBenefitTooltip = ({
  tooltipText,
  actionText,
  secondaryText,
}: SensorBenefitTooltipProps) => {
  const styles = useStyleSheet(themedStyles)

  const [TooltipVisible, setTooltipVisible] = React.useState(false)
  const showTooltip = () => {
    setTooltipVisible(true)
  }

  const hideTooltip = () => {
    setTooltipVisible(false)
  }

  return (
    <Text type="regular">
      <View>
        <WithWalkthrough
          isVisible={TooltipVisible}
          placement="bottom"
          onClose={hideTooltip}
          contentStyle={styles.tooltipContentStyle}
          disableShadow
          backgroundColor="transparent"
          tooltipStyle={styles.tooltipStyle}
          content={
            <Text type="regular" style={styles.tooltipText}>
              {tooltipText}
            </Text>
          }
          accessibilityLabel="Benefit detail"
          displayInsets={DISPLAY_INSETS}
        >
          <TouchableOpacity onPress={showTooltip} accessibilityLabel="Benefit detail button">
            <Text type="regular" bold style={styles.supportLink}>
              {actionText}
            </Text>
          </TouchableOpacity>
        </WithWalkthrough>
      </View>
      <View>
        <Text type="regular">{secondaryText}</Text>
      </View>
    </Text>
  )
}

const themedStyles = StyleService.create({
  tooltipStyle: {
    maxWidth: 282,
    width: '100%',
  },
  tooltipContentStyle: {
    backgroundColor: 'theme.tooltip.background',
    padding: 12,
  },
  tooltipText: {
    color: 'theme.tooltip.textPrimary',
    textAlign: 'left',
  },
  supportLink: {
    color: 'theme.text.link',
  },
})
