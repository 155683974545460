import { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce, isEqual } from 'lodash'
import moment from 'moment'
import { DATE_FORMAT } from '@config/momentFormat'
import { interpolateEvents } from '@src/models/dailyCharts/chart.transforms'
import { MEAL_COMPARISON_EVENT_TYPES } from '@screens/Meals/constants'
import { getComparisonTimeBoundaries, withinMealComparisonTimeRange } from '@screens/Meals/utils'
import { ChartValue, DraftMeal, MealComparisonChartData } from '@screens/Meals/types'
import { dailyMeasurementTypeDefLookupSelector } from '@selectors/app'
import { mealsByIdSelector } from '@selectors/meals'
import { Charts, EventCollection, Meal } from '@src/types'
import { transformHistoryOrEventItem } from '@transforms/eventAndHistoryItemTransform'
import { useDispatchAsync } from '@utils'

const STATE_UPDATE_DEBOUNCE_TIME = 500

export const useUpdateDraftMeal = () => {
  const dispatch = useDispatch()

  const updateDraft = useCallback(
    (payload: Partial<DraftMeal>) => {
      dispatch({ type: 'meals/updateDraft', payload })
    },
    [dispatch],
  )

  const debouncedUpdateDraft = useRef(debounce(updateDraft, STATE_UPDATE_DEBOUNCE_TIME)).current

  const updateDraftMeal = useCallback(
    (payload: Partial<DraftMeal>, isDebounced?: boolean) =>
      isDebounced ? debouncedUpdateDraft(payload) : updateDraft(payload),
    [debouncedUpdateDraft, updateDraft],
  )

  return updateDraftMeal
}

const createMealMap = (meals: Meal[]) =>
  meals.reduce((acc, meal) => ({ ...acc, [meal.id]: meal }), {} as Record<string, Meal>)

export const useMeals = (mealIds: string[] = []) => {
  const dispatchAsync = useDispatchAsync()

  const storedMeals = useSelector(mealsByIdSelector(mealIds))

  const [meals, setMeals] = useState(createMealMap(storedMeals))
  const [isLoading, setLoading] = useState(false)
  const [hasError, setError] = useState(false)

  const loadedMealIds = useRef<string[]>([])

  const fetchMeal = useCallback(
    async (idList: string[], useCache?: boolean) => {
      setLoading(true)

      try {
        const result = await Promise.all(
          idList.map((id) =>
            dispatchAsync<Meal>({
              type: 'meals/fetchMeal',
              payload: { id },
              useCache,
            }),
          ),
        )
        setMeals((existingMeals) => ({
          ...existingMeals,
          ...createMealMap(result),
        }))
      } catch {
        setError(true)
      } finally {
        setLoading(false)
      }
    },
    [dispatchAsync],
  )

  useEffect(() => {
    if (isEqual(mealIds, loadedMealIds.current)) {
      return
    }

    const existingMealsIds = Object.keys(meals)
    const missingMealsIds = mealIds.filter((id) => !existingMealsIds.includes(id))

    if (missingMealsIds.length > 0) {
      fetchMeal(missingMealsIds, true)
      loadedMealIds.current = mealIds
    }
  }, [fetchMeal, mealIds, meals])

  const memoizedMeals = useMemo(() => Object.values(meals), [meals])

  return {
    meals: memoizedMeals,
    isLoading,
    hasError,
  }
}

export const useMeal = (mealId?: string) => {
  const { meals, isLoading, hasError } = useMeals(mealId ? [mealId] : undefined)

  return { meal: meals[0] as Meal | undefined, isLoading, hasError }
}

export const useMealsComparisonChartData = (meals: Meal[]) => {
  const [chartData, setChartData] = useState<MealComparisonChartData | null>(null)
  const [isLoading, setLoading] = useState(false)
  const [hasError, setError] = useState(false)

  const dailyMeasurements = useSelector(dailyMeasurementTypeDefLookupSelector)

  const dispatchAsync = useDispatchAsync()

  const fetchEvents = useCallback(
    async (mealsList: Meal[], useCache?: boolean) => {
      setLoading(true)

      try {
        const mealIds = mealsList.map((meal) => meal.id)
        const mealData = []
        const mealEvents = []
        let yLabel = ''

        for (const [index, meal] of mealsList.entries()) {
          const mealTime = moment(meal.occurredAt)
          const { startTime, endTime } = getComparisonTimeBoundaries(meal, true)

          const filter = {
            startDate: startTime.format(DATE_FORMAT),
            endDate: endTime.format(DATE_FORMAT),
            orderBy: 'occurred_at',
            order: 'ascending',
          }
          const eventsFilter = {
            ...filter,
            scope: 'events',
            types: MEAL_COMPARISON_EVENT_TYPES.reduce((acc, type) => {
              acc[type] = []
              return acc
            }, {} as Record<string, any[]>),
          }

          const [
            { charts },
            {
              events: { events },
            },
          ] = await Promise.all([
            dispatchAsync<Charts>({
              type: 'dailyCharts/fetch',
              payload: { filter },
              useCache,
            }),
            dispatchAsync<{ events: EventCollection }>({
              type: 'events/fetch',
              payload: { filter: eventsFilter },
              useCache,
            }),
          ])

          const { range, values, yAxis } = charts[0]
          const filteredChartData = withinMealComparisonTimeRange(meal, values as ChartValue[])
          const filteredEvents = events.filter(
            (event) =>
              // Do not include daily measurements
              !dailyMeasurements[transformHistoryOrEventItem(event).type] &&
              // Only include events within the time buffer
              moment(event.occurredAt).isBetween(startTime, endTime) &&
              // Remove the compared meals
              !mealIds.includes(event.id),
          )

          const transformedChartData = filteredChartData.map(({ x, y }) => ({
            index: `${index}`,
            x: x.diff(mealTime, 'minutes'),
            y,
          }))
          const transformedEvents = interpolateEvents(filteredChartData, filteredEvents, range).map(
            (event: any) => ({
              ...event,
              index: `${index}`,
              x: event.x.diff(mealTime, 'minutes'),
            }),
          )
          const mealDataWithEvents = [...transformedChartData, ...transformedEvents].sort(
            (a, b) => a.x - b.x,
          )

          mealData.push(...mealDataWithEvents)
          mealEvents.push(...transformedEvents)
          yLabel = yAxis
        }

        setChartData({
          data: mealData,
          events: mealEvents,
          yLabel,
        })
      } catch {
        setError(true)
      } finally {
        setLoading(false)
      }
    },
    [dailyMeasurements, dispatchAsync],
  )

  useEffect(() => {
    if (meals.length > 0) {
      fetchEvents(meals, true)
    }
  }, [fetchEvents, meals])

  return {
    chartData,
    isLoading,
    hasError,
  }
}
