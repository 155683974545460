import { Image, ImageStyle, StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native'
import React from 'react'
import { Icon, Text } from '@components/base'
import { StyleService, useStyleSheet } from '@style/service.ts'

export interface ProductSummaryProps {
  title: string
  description: string
  image?: { imageSource: any }
  imageStyle?: StyleProp<ViewStyle>
  onPress: () => void
}

export const ProductSummary = ({
  title,
  description,
  image,
  imageStyle,
  onPress,
}: ProductSummaryProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <TouchableOpacity accessibilityLabel={title} onPress={onPress} style={styles.card}>
      <View style={styles.rowText}>
        <Text type="large" bold>
          {title}
        </Text>
        <Text type="regular" style={styles.description}>
          {description}
        </Text>
      </View>

      <View style={[styles.rowImage, imageStyle]}>
        {image && <Image style={styles.image as ImageStyle} source={image.imageSource} />}
      </View>

      <Icon name="caret-right" weight="bold" style={styles.arrowIcon} />
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  card: {
    width: '100%',
    flexDirection: 'row',
    borderColor: 'theme.surface.base',
    backgroundColor: 'theme.surface.base2',
    borderRadius: 24,
    borderWidth: 2,
  },
  rowText: {
    flex: 1,
    marginVertical: 24,
    marginLeft: 24,
    gap: 4,
  },
  rowImage: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
    marginRight: 24,
    width: 113,
    height: 123,
  },
  description: {
    color: 'theme.text.secondary',
  },
  arrowIcon: {
    position: 'absolute',
    right: 16,
    top: 16,
    width: 16,
    height: 16,
    color: 'theme.surface.base0',
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
})
