import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'

interface ValidationResultProps {
  text: string
  valid: boolean
}

export const ValidationResult = (props: ValidationResultProps) => {
  const { text, valid } = props

  const styles = useStyleSheet(validationResultStyles)

  return (
    <View style={styles.container}>
      <Icon
        name={valid ? 'check' : 'x'}
        weight="bold"
        style={[styles.icon, valid && styles.validIcon]}
      />
      <Text type="regular" style={valid && styles.validText}>
        {text}
      </Text>
    </View>
  )
}

const validationResultStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 8,
    color: 'theme.text.tertiary',
  },
  validIcon: {
    color: 'theme.success.base',
  },
  validText: {
    textDecorationLine: 'line-through',
  },
})
