import React from 'react'
import { Image, ImageStyle, ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text } from '@components/base'
import { uxSensorModeKindSelector } from '@src/selectors/app'
import { SurveysConfigKind, UserExperienceSensorModeKind } from '@src/types'
import { LoadingContainer } from '@src/screens/Common/containers/LoadingContainer'

import {
  libre1SensorBoxImage,
  libre2SensorBoxImage,
  libre3SensorBoxImage,
  dexcomG6BoxImage,
  dexcomG7BoxImage,
  dexcomSteloBoxImage,
} from '@src/assets/images'
import { getHealthPermissionsModalStateInteractions } from '@src/screens/Home/utils/hooks'
import { useTutorialNavigation } from '@src/screens/Tutorials/hooks'
import { OwnSensorMode, SENSOR_NAMES } from '@src/screens/OwnSensorOnboarding/types'
import {
  isByosOnboardingSensorMode,
  isHealthPermissionRequired,
} from '@src/screens/OwnSensorOnboarding/utils'
import { AppStackParamList, OnboardingStackParamsList } from '@src/navigation/types'
import { useByosOnboardingTutorial, useShouldShowByosOnboarding } from './hooks'

const SENSOR_BOX_IMAGES: Partial<
  {
    [key in UserExperienceSensorModeKind]: {
      imageSource: any
    }
  }
> = {
  [UserExperienceSensorModeKind.OwnSensorLibre1]: libre1SensorBoxImage,
  [UserExperienceSensorModeKind.OwnSensorLibre2]: libre2SensorBoxImage,
  [UserExperienceSensorModeKind.OwnSensorLibre3]: libre3SensorBoxImage,
  [UserExperienceSensorModeKind.OwnSensorDexcomG6]: dexcomG6BoxImage,
  [UserExperienceSensorModeKind.OwnSensorDexcomG7]: dexcomG7BoxImage,
  [UserExperienceSensorModeKind.OwnSensorDexcomStelo]: dexcomSteloBoxImage,
}

export const ConfirmSensorSelectionScreen = () => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()

  const { shouldShow: shouldShowHealthPermissions } = getHealthPermissionsModalStateInteractions()

  const uxSensorModeKind = useSelector(uxSensorModeKindSelector)

  const isOnboardingInProgress = useShouldShowByosOnboarding()
  const { group: tutorialGroup, sensorKind: tutorialSensorKind } = useByosOnboardingTutorial()
  const navigateToTutorial = useTutorialNavigation(tutorialGroup, tutorialSensorKind)
  const navigation = useNavigation<
    StackNavigationProp<AppStackParamList & OnboardingStackParamsList>
  >()

  const sensorName = isByosOnboardingSensorMode(uxSensorModeKind)
    ? SENSOR_NAMES[uxSensorModeKind]
    : ''

  const onContinuePress = () => {
    if (
      shouldShowHealthPermissions() &&
      isHealthPermissionRequired(uxSensorModeKind as OwnSensorMode)
    ) {
      navigation.navigate('HealthPermission')
    } else {
      navigateToTutorial()
    }
  }

  const changeSensorKind = () => {
    if (isOnboardingInProgress) {
      navigation.navigate('Questionnaire', {
        questionnaire: SurveysConfigKind.OwnSensor,
      })
      return
    }
    navigation.replace('Questionnaire', {
      questionnaire: SurveysConfigKind.OwnSensor,
      nextScreen: { screen: 'ConfirmSensorSelection' },
    })
  }
  if (!isByosOnboardingSensorMode(uxSensorModeKind)) {
    navigation.replace('Drawer', { screen: 'Dashboard' })
    return <LoadingContainer />
  }

  const image = SENSOR_BOX_IMAGES[uxSensorModeKind]

  return (
    <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
        paddingTop: insets.top + 16,
        paddingBottom: insets.bottom + 16,
        justifyContent: 'space-between',
      }}
      bounces={false}
    >
      <View style={styles.contentContainer}>
        <Text type="title-2" style={styles.welcomeText}>
          Welcome to Nutrisense!
        </Text>
        <Text type="title-3">Let's get you started</Text>

        <Text type="large" style={styles.text}>
          You mentioned that you have a
        </Text>
        {image && <Image source={image.imageSource} style={styles.sensorBoxImage as ImageStyle} />}
        <Text type="regular" style={styles.text}>
          {sensorName}
        </Text>
      </View>

      <View style={styles.buttons}>
        <Button
          type="primary"
          size="block"
          accessibilityLabel="Continue"
          style={styles.button}
          onPress={onContinuePress}
        >
          Continue
        </Button>

        <Button
          type="outline"
          size="block"
          accessibilityLabel="Continue"
          style={styles.button}
          onPress={changeSensorKind}
        >
          Actually, I have a different sensor
        </Button>
      </View>
    </ScrollView>
  )
}

const themedStyle = StyleService.create({
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingBottom: 16,
    backgroundColor: 'theme.background',
  },
  buttons: {
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'stretch',
    flex: 1,
    marginHorizontal: 24,
  },
  button: {
    marginTop: 16,
  },
  sensorBoxImage: {
    marginTop: 88,
    minWidth: 140,
  },
  welcomeText: {
    marginBottom: 40,
  },
  text: {
    marginTop: 24,
  },
})
