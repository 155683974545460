import React, { useEffect } from 'react'
import { View, ScrollView } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@ui-kitten/components'
import { compact, get, isEmpty } from 'lodash'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useRoute } from '@react-navigation/native'
import { StyleService } from '@src/style/service'
import { Text, Button } from '@components/base'
import { HorizontalViewPager } from '@src/components/HorizontalViewPager/HorizontalViewPager'
import { useResponsiveStyleSheet } from '@src/hooks/useResponsiveStyleSheet'
import { DismissibleModal } from '@components'
import { clientConfigStoreStateSelector } from '@src/selectors/app'
import { useGoBack } from '@src/utils/navigation'
import { AppRouteProp } from '@src/navigation/types'
import { TutorialStep } from '@src/types'
import { LoadingIndicator } from '../LoadingIndicator'
import { Content } from './Content'

export const TutorialModal = () => {
  const styles = useResponsiveStyleSheet({ styles: themedStyles, smallScreenStyles })
  const theme = useTheme()
  const dispatch = useDispatch()
  const clientConfig = useSelector(clientConfigStoreStateSelector)
  const insets = useSafeAreaInsets()

  const route = useRoute<AppRouteProp<'ShowTutorial'>>()
  const goBack = useGoBack()

  useEffect(() => {
    dispatch({ type: 'app/config' })
  }, [dispatch])

  const tutorialConfig = get(clientConfig, 'onboarding.tutorials.gettingStarted', [])
  const tutorial: TutorialStep[] = compact(route.params?.tutorial || tutorialConfig)

  const buttonText = 'Hide Tutorial'

  return (
    <DismissibleModal
      containerStyle={{ paddingBottom: insets.bottom, paddingTop: insets.top }}
      modalStyle={styles.modal}
    >
      {isEmpty(tutorial) ? (
        <LoadingIndicator
          size="large"
          viewStyles={styles.loadingContainer}
          color={theme['theme.primary.base']}
        />
      ) : (
        <HorizontalViewPager dotStyle={styles.dot}>
          {tutorial.map((item, index) => (
            <View key={index} onStartShouldSetResponder={() => true} style={styles.contentHeight}>
              <ScrollView>
                <View style={styles.titleContainer}>
                  <Text type="title-3">{item.title}</Text>
                </View>
                <Content
                  item={item}
                  descriptionContainerStyle={styles.descriptionContainer}
                  descriptionStyle={styles.description}
                />
              </ScrollView>
            </View>
          ))}
        </HorizontalViewPager>
      )}
      <View style={styles.cancelButton}>
        <Button
          type="primary"
          size="block"
          testID="TutorialModal/SkipButton"
          onPress={goBack}
          accessibilityLabel={buttonText}
        >
          {buttonText}
        </Button>
      </View>
    </DismissibleModal>
  )
}

const themedStyles = StyleService.create({
  titleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 24,
    paddingBottom: 20,
  },
  modal: {
    height: '93%',
    width: '93%',
    backgroundColor: 'theme.background.modal',
  },
  contentHeight: {
    height: '100%',
  },
  loadingContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  cancelButton: {
    marginTop: 'auto',
    marginHorizontal: 16,
    marginBottom: 24,
  },
  dot: {
    backgroundColor: 'theme.surface.base',
    width: 6,
    height: 6,
    borderRadius: 3,
    margin: 3,
  },
  descriptionContainer: {
    marginTop: 10,
    marginHorizontal: 16,
  },
  description: {
    textAlign: 'left',
  },
})

const smallScreenStyles = StyleService.create({
  titleContainer: {
    paddingTop: 16,
    paddingBottom: 10,
  },
  title: {
    fontSize: 13,
  },
  cancelButton: {
    marginBottom: 14,
  },
  descriptionContainer: {
    marginTop: 6,
  },
  description: {
    fontSize: 11,
  },
})
