import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  SectionList,
  SectionListData,
  View,
} from 'react-native'
import { Divider } from '@ui-kitten/components'
import { useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ListInjectedProps } from '@src/components/list/types'
import { PlaceholderView, CommonRefreshControl } from '@components'
import { ItemType, getListItem } from '@src/components/list/ListItemFactory'
import { SearchBar } from '@src/components/SearchBar'
import { ListQuickFilter } from '@src/containers/ListQuickFilter'
import { withDataProvider } from '@src/components/list/DataProviderList'
import { ListFooter } from '@src/components/list/common/ListFooter'
import { isModified } from '@src/navigation/utils'
import { SectionHeader } from '@src/components/SectionHeader'
import { calendarDateSelector } from '@src/selectors/app'
import { HighlightsSection } from '@src/screens/Events/components/Highlights/HighlightsSection'
import { InformationPopups } from '@src/components/InformationPopups'
import { eventsSortSelector } from '@src/selectors/events'
import { EVENTS_DEFAULT_SORT_STATE } from '../models/constants'
import { EventsItemType } from '../models/events.types'
import { CollapsibleTimeline } from './CollapsibleTimeline'

export interface EventsListProps extends ListInjectedProps {
  items: SectionListData<any, { title: string; index: number }>[]
  filters: any
  sort: any
  query: string
  onQueryUpdated: (text: string) => void
  ListFooterComponent: React.ReactNode
  onViewableItemsChanged: (info: any) => void
  showSearchBar: boolean
  ChartComponent: React.ReactNode
}

const keyExtractor = (item: EventsItemType) => item.id

const EventsListComponent = (props: EventsListProps) => {
  const [chartExpanded, setChartExpanded] = useState(true)
  const [chartVisible, setChartVisible] = useState(false)

  const secondSectionRef = useRef<View>(null)
  const charWrapperRef = useRef<View>(null)
  const chartBottomThresholdRef = useRef(0)
  const calendar = useSelector(calendarDateSelector)
  const eventsSort = useSelector(eventsSortSelector)

  const styles = useStyleSheet(themedStyle)
  const {
    items,
    initialNumToRender,
    refreshing,
    isLoadingMore,
    loadedAll,
    onListScroll,
    onSortUpdated,
    onEndReached,
    onRefresh,
    sort,
    filters,
    query,
    onViewableItemsChanged,
    onQueryUpdated,
    listRef,
    showSearchBar,
    ChartComponent,
  } = props

  useEffect(() => {
    setChartVisible(isEqual(eventsSort, EVENTS_DEFAULT_SORT_STATE) && calendar.rangeDays === 1)
  }, [calendar.rangeDays, eventsSort])

  const handleChartCollapse = useCallback(
    (_event: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (charWrapperRef.current) {
        charWrapperRef.current.measure((_x, _y, _width, height, _pageX, pageY) => {
          chartBottomThresholdRef.current = height + pageY
        })
      }

      if (secondSectionRef.current) {
        secondSectionRef.current.measure((_x, _y, _width, _height, _pageX, pageY) => {
          if (pageY < chartBottomThresholdRef.current && chartExpanded) {
            setChartExpanded(false)
          } else if (pageY > chartBottomThresholdRef.current && !chartExpanded) {
            setChartExpanded(true)
          }
        })
      }
    },
    [chartExpanded],
  )

  const renderSectionHeader = useCallback(
    ({ section: { title } }: { section: { title: string } }) => {
      return (
        <View
          // important for the view to not be removed by Android;
          // if this view is removed the chart will not collapse anymore
          collapsable={false}
          ref={title === items[1]?.title ? secondSectionRef : null}
        >
          <SectionHeader title={title} />
        </View>
      )
    },
    [items],
  )

  const refreshControl = useMemo(
    () => <CommonRefreshControl refreshing={refreshing} onRefresh={onRefresh} />,
    [onRefresh, refreshing],
  )

  const listEmptyComponent = useMemo(
    () => <PlaceholderView showSearchResults={isModified(sort)} type="events" />,
    [sort],
  )

  const listFooterComponent = useMemo(
    () => (
      <ListFooter
        isLoadingMore={isLoadingMore}
        isListEmpty={items.length === 0}
        loadedAll={loadedAll}
        footerStyles={{ marginBottom: 90 }}
      />
    ),
    [isLoadingMore, items.length, loadedAll],
  )

  const headerComponent = (
    <View style={styles.listHeader}>
      <InformationPopups />
      {chartVisible && <HighlightsSection />}
    </View>
  )

  const renderItem = useCallback(({ item }: { item: ItemType }) => getListItem(item), [])

  return (
    <View style={styles.container}>
      <View>
        <View style={styles.quickFilters}>
          <ListQuickFilter
            filters={filters}
            sort={sort}
            useNestedQuickFilters
            onSortUpdated={onSortUpdated}
          />
        </View>
        <Divider />
        {showSearchBar && (
          <SearchBar testID="EventsList/SearchBar" value={query} onChangeText={onQueryUpdated} />
        )}
      </View>
      <View style={styles.container}>
        {!!ChartComponent && chartVisible && (
          <View
            // important for the view to not be removed by Android;
            // if this view is removed the chart will not collapse anymore
            collapsable={false}
            ref={charWrapperRef}
          >
            <CollapsibleTimeline expanded={chartExpanded} ChartComponent={ChartComponent} />
          </View>
        )}
        <SectionList
          ref={listRef}
          sections={items}
          keyExtractor={keyExtractor}
          initialNumToRender={initialNumToRender}
          stickySectionHeadersEnabled={false}
          renderItem={renderItem}
          renderSectionHeader={renderSectionHeader}
          refreshing={refreshing}
          onScroll={(event) => {
            !!ChartComponent && handleChartCollapse(event)
            onListScroll?.(event)
          }}
          ListHeaderComponent={headerComponent}
          scrollEventThrottle={16}
          onEndReached={onEndReached}
          onViewableItemsChanged={onViewableItemsChanged}
          refreshControl={refreshControl}
          ListEmptyComponent={listEmptyComponent}
          ListFooterComponent={listFooterComponent}
        />
      </View>
    </View>
  )
}

export const EventsList = withDataProvider(EventsListComponent)

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  quickFilters: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
  },

  filterIcon: {
    height: 32,
    borderRadius: 32,
    paddingHorizontal: 12,
    marginHorizontal: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  listHeader: { paddingBottom: 16 },
})
