import React, { useCallback } from 'react'
import { Image, ImageStyle, TouchableOpacity, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useDispatch, useSelector } from 'react-redux'
import { useFocusEffect } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import {
  arrowRightImage,
  bluetoothImage,
  cgmLibreImage,
  cgmSteloBrightImage,
} from '@src/assets/images'
import { Button, Text } from '@components/base'
import { openUrl, Storage, useGoBack, Zendesk } from '@utils'
import { Benefit, InlineAlert, ScrollViewWithFade } from '@src/components'
import { UiInlineAlert, UiStateNames } from '@src/types'
import { userSelector } from '@src/selectors/app'
import { markModalAsShown } from '@src/hooks/useOneTimeVisitedState'

const FADE_BUFFER = 24

// For users being switched from Libre to Stelo via Apple Health, soon to be deprecated once
// realtime Stelo API is enabled
export const UpgradedToStelo = () => {
  const styles = useStyleSheet(themedStyles)
  const user = useSelector(userSelector)
  const dispatch = useDispatch()

  const goBack = useGoBack()

  const onContactSupportPress = () => {
    openUrl(Zendesk.baseUrl(user?.email))
  }

  useFocusEffect(
    useCallback(() => {
      markModalAsShown(`${Storage.UPGRADED_TO_STELO_BANNER_VISITED_KEY}_${user?.id}`)
      dispatch({
        type: 'app/upsertUiState',
        payload: {
          name: UiStateNames.SteloShowUpgradedBanner,
          value: false,
        },
      })
    }, [dispatch, user?.id]),
  )

  const alertMessage = (
    <Text type="regular">
      Questions? Don't hesitate to reach out to our{' '}
      <TouchableOpacity accessibilityLabel="Contact Support" onPress={onContactSupportPress}>
        <Text type="regular" bold lineSpacing="none" style={styles.supportLink}>
          Support Team
        </Text>
      </TouchableOpacity>
      .
    </Text>
  )

  return (
    <SafeAreaView style={styles.content} edges={['bottom']}>
      <ScrollViewWithFade style={styles.scrollView} scrollViewStyle={styles.scrollViewContent}>
        <View style={styles.imagesContainer}>
          <View style={styles.sensorImageContainer}>
            <Image
              source={cgmLibreImage.imageSource}
              style={styles.libreSensorImage as ImageStyle}
            />
          </View>
          <View style={styles.arrowImageContainer}>
            <Image source={arrowRightImage.imageSource} style={styles.arrowImage as ImageStyle} />
          </View>
          <View style={styles.sensorImageContainer}>
            <Image
              source={cgmSteloBrightImage.imageSource}
              style={styles.steloSensorImage as ImageStyle}
            />
            <Image
              source={bluetoothImage.imageSource}
              style={styles.bluetoothImage as ImageStyle}
            />
          </View>
        </View>

        <View style={styles.title}>
          <Text type="title-2" style={styles.titleText}>
            You are upgraded!
          </Text>
        </View>
        <Text type="regular" style={styles.bodyText}>
          Nutrisense is moving to Stelo, a Dexcom glucose biosensor, and you are one of the first to
          receive it.
        </Text>

        <View style={styles.benefits}>
          <Benefit benefit="Automatic bluetooth syncing" />
          <Benefit benefit="15-day wear time for long-lasting tracking" />
          <Benefit benefit="Waterproof to 8ft" />
        </View>

        <InlineAlert
          style={styles.infoAlert}
          textStyle={styles.infoAlertText}
          iconStyle={styles.infoAlertIcon}
          category={UiInlineAlert.Info}
          message={alertMessage}
        />
      </ScrollViewWithFade>
      <View style={styles.buttons}>
        <Button
          accessibilityLabel="Confirm"
          onPress={goBack}
          style={styles.nextButton}
          size="block"
          type="primary"
        >
          Confirm
        </Button>
      </View>
    </SafeAreaView>
  )
}

const themedStyles = StyleService.create({
  content: {
    flex: 1,
    padding: 16,
    backgroundColor: 'theme.background',
  },
  scrollView: {
    top: -FADE_BUFFER,
  },
  scrollViewContent: {
    paddingVertical: FADE_BUFFER,
  },

  imagesContainer: {
    marginTop: 64,
    flexDirection: 'row',
    height: 100,
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  sensorImageContainer: {
    maxWidth: 100,
    flex: 1,
  },
  arrowImageContainer: {
    maxWidth: 50,
    flex: 1,
    marginHorizontal: 8,
  },
  libreSensorImage: {
    width: '100%',
    flex: 1,
    resizeMode: 'contain',
  },
  steloSensorImage: {
    width: '100%',
    flex: 1,
    resizeMode: 'contain',
  },
  arrowImage: {
    maxWidth: 50,
    marginTop: -30,
    flex: 1,
    resizeMode: 'contain',
  },
  bluetoothImage: {
    width: 32,
    height: 32,
    position: 'absolute',
    top: 4,
    right: 12,
  },
  title: {
    marginBottom: 32,
  },
  titleText: {
    textAlign: 'center',
  },
  bodyText: {
    textAlign: 'center',
  },
  benefits: {
    paddingVertical: 24,
    maxWidth: 280,
    flex: 1,
    width: '100%',
    alignSelf: 'center',
  },
  infoAlert: {
    borderWidth: 0,
    marginVertical: 16,
    backgroundColor: 'theme.surface.base2',
  },
  infoAlertText: {
    color: 'theme.text.secondary',
  },
  infoAlertIcon: {
    color: 'theme.text.secondary',
  },
  supportLink: {
    color: 'theme.text.link',
    marginTop: 'auto',
  },
  buttons: {
    gap: 8,
  },
  nextButton: {
    marginTop: -FADE_BUFFER,
    marginBottom: 8,
  },
  secondaryButtonText: {
    textAlign: 'center',
    maxWidth: 203,
  },
})
