import React from 'react'
import { useSelector } from 'react-redux'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Statistic } from '@src/types'
import {
  getMeasurementUnitSymbol,
  getPeriodComparisonText,
  getValueChangeText,
} from '@src/screens/Insights/utils/utils'
import {
  insightsCalendarSelector,
  statsSelector,
} from '@src/screens/Insights/models/insights.selectors'
import { Point } from '@src/screens/Insights/types'
import { Text } from '@components/base'
import { goalMetricsSelector, measurementsConfigSelector } from '@src/selectors/app'
import { unitSystemStoreStateSelector } from '@src/selectors/settings'
import { Feature, useFeatureFlag } from '@src/components'
import {
  ScoreSectionEnum,
  GlucoseKetonesEnum,
  MacrosSectionEnum,
  MeasurementEnum,
} from '../utils/pageConfig'
import { ScoreValueItem } from './ScoreValueItem'

type CustomOverview = {
  title?: string
  minDateRange?: number
}
export interface ScoreOverviewProps {
  data: Point[]
  statMetaKey: ScoreSectionEnum | GlucoseKetonesEnum | MacrosSectionEnum | MeasurementEnum
  statMetaSection: string
  defaultUnit?: string
  customOverview?: Partial<Record<'average' | 'min' | 'max', CustomOverview>>
}

// Default to not displaying min/max for date range of <= 1
const DEFAULT_MIN_DATE_RANGE_FOR_MIN = 2
const DEFAULT_MIN_DATE_RANGE_FOR_MAX = 2

export const ScoreOverview = ({
  data,
  statMetaKey,
  statMetaSection,
  defaultUnit,
  customOverview,
}: ScoreOverviewProps) => {
  const styles = useStyleSheet(themedStyles)

  const userGoalsEnabled = useFeatureFlag(Feature.UserGoals)

  const goalMetrics = useSelector(goalMetricsSelector)
  const calendar = useSelector(insightsCalendarSelector)
  const stats = useSelector(statsSelector)
  const stat = stats.find(
    (stat) => stat.meta.section === statMetaSection && stat.meta.key === statMetaKey,
  ) as Statistic
  const unitSystem = useSelector(unitSystemStoreStateSelector)
  const measurementsConfig = useSelector(measurementsConfigSelector)
  const measurementConfig = measurementsConfig.find(({ name }) => name === statMetaKey)

  const metric = goalMetrics.find(
    (metric) => metric.section === statMetaSection && metric.statKey === statMetaKey,
  )

  const unit = getMeasurementUnitSymbol({
    stat,
    defaultUnit,
    unitSystem,
    measurementConfig,
  })

  const valueChangeText = getValueChangeText(stat)
  const periodComparisonText = getPeriodComparisonText(calendar)
  const displayedValueChangeUnit = valueChangeText === 'No change' ? '' : unit

  // Create array of tuples with y and displayValue
  const valuesWithDisplay = data
    .map((item) => ({
      y: item.y,
      displayValue: item.displayValue,
    }))
    .filter((item) => item.y !== null && typeof item.y === 'number')
  const hasValues = valuesWithDisplay.length > 0 && valuesWithDisplay.some((value) => !!value.y)
  const min = hasValues
    ? valuesWithDisplay.reduce(
        (prev, curr) => (curr.y! < prev.y! ? curr : prev),
        valuesWithDisplay[0],
      )
    : { y: 'N/A', displayValue: 'N/A' }

  const max = hasValues
    ? valuesWithDisplay.reduce(
        (prev, curr) => (curr.y! > prev.y! ? curr : prev),
        valuesWithDisplay[0],
      )
    : { y: 'N/A', displayValue: 'N/A' }

  // Convert min.y and max.y to strings
  min.y = min.y !== 'N/A' ? `${min.y}` : min.y
  max.y = max.y !== 'N/A' ? `${max.y}` : max.y

  const average = stat.value

  const showAverage = customOverview ? customOverview.average : true
  const showMin = customOverview ? customOverview.min : true
  const showMax = customOverview ? customOverview.max : true

  const minDateRangeForMin = customOverview?.min?.minDateRange || DEFAULT_MIN_DATE_RANGE_FOR_MIN
  const minDateRangeForMax = customOverview?.max?.minDateRange || DEFAULT_MIN_DATE_RANGE_FOR_MAX

  const displayMin = showMin && calendar.rangeDays >= minDateRangeForMin
  const displayMax = showMax && calendar.rangeDays >= minDateRangeForMax

  const showDiff = !!stat.value && !!stat.valuePrev
  const showTarget =
    metric &&
    (metric.lowThresholdValue || metric.lowThresholdValue === 0) &&
    metric.highThresholdValue

  return (
    <View style={styles.container}>
      <View style={styles.scoreRow}>
        {showAverage && (
          <ScoreValueItem
            label={customOverview?.average?.title || 'Average'}
            value={average ? `${average}` : 'N/A'}
            displayValue={stat.displayValue}
            unit={unit}
            metricSection={stat.meta.section}
            metricKey={stat.meta.key}
          />
        )}
        {displayMin && (
          <ScoreValueItem
            label={customOverview?.min?.title || 'Min'}
            value={min.y}
            displayValue={min.displayValue}
            unit={unit}
            metricSection={stat.meta.section}
            metricKey={stat.meta.key}
          />
        )}
        {displayMax && (
          <ScoreValueItem
            label={customOverview?.max?.title || 'Max'}
            value={max.y}
            displayValue={max.displayValue}
            unit={unit}
            metricSection={stat.meta.section}
            metricKey={stat.meta.key}
          />
        )}
      </View>
      {userGoalsEnabled && (showDiff || showTarget) && (
        <View style={styles.diffRow}>
          {showDiff && (
            <Text type="regular" style={styles.textSecondary}>
              {`${valueChangeText}${displayedValueChangeUnit} ${periodComparisonText}`}
            </Text>
          )}
          {showTarget && (
            <View style={styles.targetContainer}>
              <Text type="regular" style={styles.textSecondary}>
                Your Target
              </Text>

              <Text type="regular" bold style={styles.targetValue}>
                {metric.lowThresholdValue as number}-{metric.highThresholdValue as number}
                {unit}
              </Text>
            </View>
          )}
        </View>
      )}
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    padding: 16,
    paddingBottom: 24,
  },
  scoreRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  diffRow: {
    flexDirection: 'row',
    marginTop: 24,
  },
  textSecondary: {
    color: 'theme.text.secondary',
  },
  targetContainer: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-end',
    marginLeft: 12,
  },
  targetValue: {
    marginLeft: 4,
    alignSelf: 'flex-end',
  },
})
