import moment from 'moment'
import { GroupableProduct, GroupedProducts, isProduct } from '@src/screens/Marketplace/types/types'
import {
  BillingProduct,
  Interval,
  Product,
  PromotionCode,
  Subscription,
  SubscriptionStatus,
  UiPillType,
} from '@src/types'
import { SubscriptionParams } from '@src/navigation/types'
import { LIMITS_STRING_VALUES } from '../constants/constants'

const FREE_SOFTWARE_ONLY_PRODUCTS = [
  BillingProduct.FreeSoftwareOnlyMedicalAnnualMembership,
  BillingProduct.FreeSoftwareOnlyVideoCallsAnnualMembership,
  BillingProduct.FreeSoftwareOnlyDppAnnualMembership,
  BillingProduct.SoftwareOnlyAnnualMembership,
]

export const calculateDiscount = (price: number, code: PromotionCode | null): number => {
  if (!code) {
    return 0
  }

  const { percentOff, amountOff } = code
  if (percentOff) {
    return (price * percentOff) / 100
  } else {
    return (amountOff || 0) / 100
  }
}

export const isOnCommitment = (commitmentEndAt: string | null) => {
  if (!commitmentEndAt) {
    return false
  }

  return moment().isBefore(commitmentEndAt)
}

export const getPriceDescription = (amount: number, interval: Interval) =>
  interval.suffix ? `$${amount}/${interval.suffix}` : `$${amount}`

export const getPriceRangeDescriptor = (groupableProduct: GroupableProduct) => {
  const suffix = groupableProduct.interval.suffix ? `/${groupableProduct.interval.suffix}` : ''
  const prefix = groupableProduct.interval.prefix

  const products = isProduct(groupableProduct) ? [groupableProduct] : groupableProduct.products
  const priceAmounts = products.map((price) => price.price)

  const minAmount = Math.min(...priceAmounts)
  const maxAmount = Math.max(...priceAmounts)

  return {
    amount: minAmount === maxAmount ? `$${minAmount}` : `$${minAmount}-$${maxAmount}`,
    suffix,
    prefix,
  }
}

export const getPriceRangeDescription = (groupableProduct: GroupableProduct) => {
  const price = getPriceRangeDescriptor(groupableProduct)
  return `${price.amount}${price.suffix}`
}

export const isSubscriptionParams = (params: any): params is SubscriptionParams =>
  !!params && params.subscriptionId !== undefined && params.subscriptionId !== null

const getSubscriptionPillStatus = (
  subscription: Pick<Subscription, 'cancelAt' | 'status' | 'paused'>,
): UiPillType => {
  if (isTrial(subscription)) {
    if (subscription.cancelAt) {
      const daysUntilCancellation = moment(subscription.cancelAt).diff(moment(), 'days')
      if (daysUntilCancellation <= 7) {
        return UiPillType.Warning
      }
    }
    return UiPillType.Info
  }

  if (subscription.status === SubscriptionStatus.PastDue) {
    return UiPillType.Alert
  }

  if (subscription.paused || subscription.cancelAt) {
    return UiPillType.Warning
  }

  return UiPillType.Success
}

const getSubscriptionPillLabel = (
  subscription: Pick<Subscription, 'paused' | 'status' | 'cancelAt'>,
  isScheduledCancelledCommitment: boolean,
) => {
  if (isTrial(subscription)) {
    return 'Complimentary'
  }

  if (subscription.status === SubscriptionStatus.PastDue) {
    return 'Past Due'
  }

  if (subscription.paused) {
    return 'Snoozed'
  }

  if (subscription.cancelAt) {
    if (isScheduledCancelledCommitment) {
      return 'Does not renew'
    }
    return 'Scheduled to cancel'
  }

  return 'Active'
}

export const getSubscriptionTagDescriptor = (
  subscription: Pick<Subscription, 'commitmentEndAt' | 'cancelAt' | 'status' | 'paused'>,
) => {
  const isScheduledCancelledCommitment =
    isOnCommitment(subscription.commitmentEndAt) && 0 < moment(subscription.cancelAt).diff(moment())

  return {
    isTrial: isTrial(subscription),
    pillLabel: getSubscriptionPillLabel(subscription, isScheduledCancelledCommitment),
    pillStatus: getSubscriptionPillStatus(subscription),
  }
}

export const isTrial = (subscription: Pick<Subscription, 'status'>) =>
  subscription.status === SubscriptionStatus.Trialing

export const isTrialUpgradable = (
  subscription: Pick<Subscription, 'status' | 'cancelAt' | 'trialEndAt'>,
) => {
  return isTrial(subscription) && subscription.cancelAt === subscription.trialEndAt
}

export const isProductUpgradable = (subscription: {
  primaryProduct: { eligibleUpgradeProducts: Pick<Product, 'id'>[] }
}) => subscription.primaryProduct.eligibleUpgradeProducts.length > 0

export const isFreeSoftwareOnlySubscription = (primaryProduct: Pick<Product, 'key'>) =>
  FREE_SOFTWARE_ONLY_PRODUCTS.includes(primaryProduct.key as BillingProduct)

export const getCancelAddonModalTitle = (addonLimit: number) => {
  return `You have reached your limit of ${
    LIMITS_STRING_VALUES[addonLimit] || addonLimit
  } additional order${
    addonLimit > 1 ? 's' : ''
  } for this month. Please reach out to our support team if you need more.`
}

export const getBasePlan = (products: Product[]) => {
  return products.find((product) => !product.commitmentMonths)
}

export const calculateSavings = ({
  item,
  product,
}: {
  item: GroupedProducts
  product: Product
}) => {
  const basePlanAmount = getBasePlan(item.products)?.price ?? 0
  return basePlanAmount > product.price ? (basePlanAmount - product.price) * 12 : 0
}
