import { useCallback, useMemo, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useIsFocused } from '@react-navigation/native'
import { createSelector } from 'reselect'
import moment from 'moment'
import { useDispatchAsync } from '@src/utils'
import { DateRange } from '@src/models/helper'
import { insightsCacheSelector } from '@src/screens/Insights/models/insights.selectors'
import { cacheKey } from '@src/screens/Insights/models/insights'
import { allTypes } from '@src/screens/Insights/helper'

const currentCacheMissingSelector = (key: string) =>
  createSelector([insightsCacheSelector], (cache) => !cache[key])

export const useFetchStats = () => {
  const dispatchAsync = useDispatchAsync()
  const isFocused = useIsFocused()

  const [isLoading, setLoading] = useState(false)

  // keep last used calendar for re-fetching functionality
  const calendarRef = useRef<DateRange>()

  const currentCacheKey = cacheKey({
    filter: {
      startDate: calendarRef.current?.startDate as string,
      endDate: calendarRef.current?.endDate as string,
      types: allTypes,
    },
  })

  const cacheIsMissing = useSelector(currentCacheMissingSelector(currentCacheKey))

  const fetchStats = useCallback(
    (useCache: boolean) => async ({ startDate, endDate }: DateRange) => {
      setLoading(true)
      calendarRef.current = { startDate, endDate }

      const filter = { startDate, endDate, types: allTypes }

      const shouldNotUseCache = moment().isBetween(startDate, endDate, 'day', '[]')

      try {
        await dispatchAsync({
          type: `insights/fetchStats`,
          useCache: shouldNotUseCache ? false : useCache,
          payload: {
            filter,
          },
        })
      } finally {
        setLoading(false)
      }
    },
    [dispatchAsync],
  )

  const fetchStatsWithCache = useMemo(() => fetchStats(true), [fetchStats])

  const refetch = useCallback(() => {
    if (!calendarRef.current) {
      return
    }

    return fetchStats(false)(calendarRef.current)
  }, [fetchStats])

  useEffect(() => {
    if (!isFocused || !cacheIsMissing || !calendarRef.current) {
      return
    }

    fetchStatsWithCache(calendarRef.current)
  }, [fetchStatsWithCache, isFocused, cacheIsMissing])

  return { fetchStats: fetchStatsWithCache, refetch, isLoading }
}
