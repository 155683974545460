import { gql } from '@apollo/client'

export const FETCH_CLIENT_CONFIG = gql`
  query clientConfig {
    clientConfig {
      measurements {
        name
        metric {
          units
          precision
          minThreshold
          maxThreshold
        }
        imperial {
          units
          precision
          minThreshold
          maxThreshold
        }
      }
      activityTypes
      mealTypes
      dayTabs {
        key
        value
      }
      timeZones
      events {
        filters {
          types {
            key
            value
          }
          orderBy
          order
        }
      }
      meals {
        filters {
          types
          orderBy
          order
        }
      }
      insights {
        statistics {
          name
          metric {
            ranges {
              from
              to
              type
              rating
            }
            precision
            units
          }
          imperial {
            ranges {
              from
              to
              type
              rating
            }
            precision
            units
          }
        }
      }
      # todo: remove "biteAi" when there are no more users on app versions < 2.7.0
      biteAi {
        units {
          key
          value
        }
        dailyValues {
          key
          value
        }
      }
      healthKit {
        ignore
        sourceIgnore
      }
      customIngredients {
        macros {
          name
          indent
        }
      }
      settings {
        cancellationPolicy
        privacyPolicy
        termsOfUse
        telehealth
        consumerHealthDataPrivacyPolicy
      }
      remindersSettings {
        hoursInterval {
          glucoseCheckOptions {
            text
            value
          }
        }
      }
      healthSettings {
        macroGoalSettings {
          caloriesMax
          proteinMax
          carbsMax
          fatMax
        }
        scanReminderOptions {
          text
          value
        }
        glucoseLowOptions {
          text
          value
        }
        glucoseHighOptions {
          text
          value
        }
        ketoneLowOptions {
          text
          value
        }
        ketoneHighOptions {
          text
          value
        }
        dashboardGraphOptions {
          text
          value
        }
        unitSystemOptions {
          text
          value
        }
        glucoseThresholdPresets {
          name
          description
          low
          high
        }
      }
      sensorSettings {
        baselineAdjustmentOptions {
          text
          value
        }
      }
      referral {
        title
        discount
        credits
        description
        message
      }
      onboarding {
        tutorials {
          key
          value {
            title
            description
            photoUrlLight
            photoUrlDark
            videoUrl
          }
        }
      }
      education {
        collections {
          title
          subtitle
          imageUrl
          url
          articles {
            title
            author
            subtitle
            imageUrl
            url
          }
        }
      }
      score {
        warning
        danger
        max
      }
      questionnaireUrl
      privacyCenterUrl
      usStates {
        name
        abbreviation
      }
      caProvinces {
        name
        abbreviation
      }
      goalOptions {
        text
        value
      }
      goalMetricOptions {
        text
        value
      }
      healthData {
        measurementTypeDefs {
          key
          label
          isDailyMeasurement
          icon
        }
        measurementSourceDefs {
          key
          label
          providesDailyMeasurements
          sourceTags
          iconDef {
            pack
            name
          }
        }
      }
      journal {
        range {
          min
          max
        }
        measurementTypes {
          type
          reverse
        }
      }
      chatbot {
        lastShownCutoffAt
        responseTimeoutInSeconds
      }
      reminders {
        allowedFrequencies {
          key
        }
        icon
        key
        title
      }
      appointmentTypes {
        key
        duration
        title
      }
      appointmentKinds {
        key
        duration
        title
      }
      healthMetrics {
        key
        label
        unit
        kind
      }
      researchFaqs {
        rootUrl
        gettingStartedUrl
        eligibilityUrl
        dataProtectionUrl
        sensorComparisonUrl
        disableLibreScanUrl
      }
      dailyActions {
        key
        title
        description
        category
      }
      insuranceKinds {
        kindDefs {
          key
          title
        }
      }
      dexcomClientId
      dexcomSteloClientId
    }
  }
`
