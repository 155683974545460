import React, { useContext } from 'react'
import { ActivityIndicator, ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import { ImageStyle } from 'react-native-fast-image'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button, Icon } from '@components/base'
import { topPriorityCoachAssignmentSelector } from '@src/screens/NutritionistHub/models/nutritionistHub.selectors'
import { CoachProfile } from '@src/types'
import { ImageWithPlaceholder } from '@src/components/Image/FastImageWithPlaceholder'
import { QuestionnaireContext } from '../questionnaire.navigator'

export const WeightLossExperienceDesiredWeightStartScreen = () => {
  const {
    sectionStartScreenConfig: { onContinueButtonPress, onCloseButtonPress },
  } = useContext(QuestionnaireContext)
  const styles = useStyleSheet(themedStyles)

  const insets = useSafeAreaInsets()

  const coachAssignment = useSelector(topPriorityCoachAssignmentSelector)
  const nutritionist = coachAssignment?.coach

  if (!nutritionist) {
    return null
  }

  const { photo, credentials } = nutritionist?.coachProfile || ({} as CoachProfile)

  const onContinuePress = () => {
    return onContinueButtonPress()
  }

  return (
    <View style={styles.topLevelContainer}>
      <ScrollView
        bounces={false}
        contentContainerStyle={[
          styles.container,
          styles.topSpacing,
          { paddingBottom: Math.max(insets.bottom, 16) },
        ]}
      >
        <View style={{ alignItems: 'center' }}>
          <Text type="title-1" style={styles.title}>
            Your Personalized Weight Loss Journey Starts Here
          </Text>

          {photo && (
            <ImageWithPlaceholder
              style={styles.nutritionistPhoto as ImageStyle}
              source={{ uri: photo }}
              placeholderComponent={
                <ActivityIndicator animating size="small" {...styles.spinner} />
              }
            />
          )}

          <Text type="regular" style={styles.nutritionistName}>
            {nutritionist.firstName} {nutritionist.lastName}
          </Text>

          {credentials && (
            <Text type="regular" style={styles.nutritionistCredentials}>
              {credentials}
            </Text>
          )}

          <Text type="regular" style={styles.description}>
            Hi! I’m {nutritionist?.firstName}! Before we begin, you can choose how you'd like to
            complete your health assessment.
          </Text>

          <Text type="regular" style={styles.description}>
            You can fill out the questions here at your own pace or together with me to guide you
            through it during our next session.
          </Text>
        </View>

        <View style={styles.buttonsContainer}>
          <Text type="regular" style={styles.timeNote}>
            Take less than 2 minutes to complete
          </Text>

          <Button
            type="primary"
            size="block"
            onPress={onContinuePress}
            accessibilityLabel="Start questionnaire section"
            accessoryRight={<Icon name="arrow-right" />}
          >
            Start Now
          </Button>

          <Button
            type="outline"
            size="block"
            onPress={onCloseButtonPress}
            accessibilityLabel="Skip"
          >
            With My Nutritionist
          </Button>
        </View>
      </ScrollView>
    </View>
  )
}

const themedStyles = StyleService.create({
  topLevelContainer: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  topSpacing: {
    paddingTop: 60,
  },
  container: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    paddingHorizontal: 24,
    lineHeight: 32,
  },
  nutritionistPhoto: {
    width: 102,
    height: 102,
    borderRadius: 102,
    marginTop: 24,
  },
  nutritionistName: {
    marginTop: 8,
    fontWeight: 700,
  },
  nutritionistCredentials: {
    color: 'theme.text.secondary',
    marginBottom: 8,
  },
  description: {
    textAlign: 'center',
    marginVertical: 12,
    color: 'theme.text.primary',
    fontSize: 17,
  },
  timeNote: {
    textAlign: 'center',
  },
  buttonsContainer: {
    gap: 16,
    width: '100%',
  },
  spinner: {
    color: 'theme.surface.base',
  },
})
