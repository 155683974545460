import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { insightsCalendarSelector } from '@src/screens/Insights/models/insights.selectors'
import { ChartQueryType, DetailedFetchKeyMap } from '@src/screens/Insights/helper'
import { Period } from '@src/types'
import { useChartData } from './useChartData'
import { ByHourChart, ByHourChartProps } from './ByHourChart'

export interface ByHourChartDataItem {
  x: Hour24
  y: number | null
}

interface ByHourChartManagerProps
  extends Pick<ByHourChartProps, 'yLabel' | 'periodsDisplayType' | 'precision'> {
  type: ChartQueryType
  section: string
}

const ByHourChartManagerComponent = ({
  type,
  section,
  yLabel,
  precision,
  periodsDisplayType,
}: ByHourChartManagerProps) => {
  const fetchKey = DetailedFetchKeyMap[type]
  const calendar = useSelector(insightsCalendarSelector)
  const chartData = useChartData(calendar, fetchKey, section, Period.Hour)

  const [currentData, previousData] = chartData.data

  const getCurrentPreviousChartRange = () => {
    if (!currentData?.range && !previousData?.range) {
      return undefined
    }

    const minValue = Math.min(
      ...([currentData?.range?.min, previousData?.range?.min].filter(Boolean) as number[]),
    )
    const maxValue = Math.max(
      ...([currentData?.range?.max, previousData?.range?.max].filter(Boolean) as number[]),
    )
    return {
      min: minValue,
      max: maxValue,
    }
  }

  return (
    <ByHourChart
      datesRange={{ startDate: calendar.startDate, endDate: calendar.endDate }}
      precision={precision}
      currentDataByHour={(currentData?.data || []) as ByHourChartDataItem[]}
      previousDataByHour={(previousData?.data || []) as ByHourChartDataItem[]}
      yLabel={yLabel}
      range={getCurrentPreviousChartRange()}
      periodsDisplayType={periodsDisplayType}
    />
  )
}

export const ByHourChartManager = memo(ByHourChartManagerComponent)
