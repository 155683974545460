import { useSelector } from 'react-redux'
import { userSelector } from '@src/selectors/app'
import { UserApprovalRequestState } from '@src/types'

export const useIsEligibleForStelo = () => {
  const user = useSelector(userSelector)
  const steloApprovalRequestState = user?.lastSteloApprovalRequest?.state

  return steloApprovalRequestState === UserApprovalRequestState.Approved
}
