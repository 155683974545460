import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { Autocomplete, AutocompleteItem } from '@ui-kitten/components'
import { Storage } from '@utils'
import { Apollo, App } from '@config'
import { UserMin } from '@src/types'
import { Login } from '@src/models/app.types'
import AutocompleteUserItem from './AutocompleteUserItem'

const SEARCH_DELAY = 200

interface AdminUserAutocompleteProps {
  users: { autocomplete: UserMin[] }
  placeholder: string
  onSelect: () => void
}

export const AdminUserAutocomplete = (props: AdminUserAutocompleteProps) => {
  const {
    users: { autocomplete: list },
    placeholder = 'Search Name',
    onSelect: onSelectFromProps,
  } = props

  const dispatch = useDispatch()

  const search = useCallback(
    (query: string) => {
      dispatch({
        type: 'users/autocomplete',
        payload: {
          query,
        },
      })
    },
    [dispatch],
  )

  const debouncedSearch = useMemo(() => debounce(search, SEARCH_DELAY), [search])

  const onChangeText = useCallback(
    (query: string) => {
      debouncedSearch(query)
    },
    [debouncedSearch],
  )

  const onSelect = (index: number) => {
    onSelectFromProps()
    const toBeImpersonated = list[index]

    const adminLogin = Storage.get<Login>('admin_login')
    const endpoint = Storage.get<string>('endpoint')

    if (!adminLogin?.token || !endpoint) {
      console.log('Impersonation Failed:')
      console.log('User: ', toBeImpersonated)
      console.log('Admin:', adminLogin)
      return
    }

    dispatch({
      type: 'app/logout',
      success: () => {
        Storage.set('login', adminLogin)
        Storage.set('admin_login', adminLogin)
        Storage.set('endpoint', endpoint)

        console.log('Impersonating:')
        console.log('User: ', toBeImpersonated)
        console.log('Admin:', adminLogin.user)
        console.log('Endpoint:', endpoint)

        Apollo.resetEndpoint({ ...App.build, endpoint })

        dispatch({
          payload: {
            email: adminLogin.user?.email,
            password: '',
            impersonate: toBeImpersonated.email,
          },
          type: 'app/login',
        })
      },
    })
  }

  return (
    <Autocomplete
      size="small"
      placeholder={placeholder}
      onChangeText={onChangeText}
      onSelect={onSelect}
    >
      {list.map((user) => (
        <AutocompleteItem key={user.id}>
          <AutocompleteUserItem email={user.email} fullName={user.fullName} />
        </AutocompleteItem>
      ))}
    </Autocomplete>
  )
}
