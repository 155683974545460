import React, { ReactNode } from 'react'
import { StyleProp, TextStyle } from 'react-native'
import { Text, TextProps } from '@components/base'
import { StyleService, useStyleSheet } from '@style/service.ts'

type WhiteTextProps = {
  children: ReactNode
  style?: StyleProp<TextStyle>
} & TextProps

export const WhiteText = (props: WhiteTextProps) => {
  const localStyles = useStyleSheet(themedStyle)

  return (
    <Text {...props} style={[localStyles.whiteText, props.style]}>
      {props.children}
    </Text>
  )
}

const themedStyle = StyleService.create({
  whiteText: {
    color: 'theme.solid.white',
    lineHeight: 0,
    fontWeight: 500,
  },
})
