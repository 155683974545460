import { gql } from '@apollo/client'

export const CORE_SENSOR_FIELDS = gql`
  fragment CoreSensorFields on Sensor {
    status
    serialNumber
    activationTime
    expirationTime
    daysRemaining
    baselineAdjustment
    model
    modelEnum
    description
    lastStatusChange {
      status
      createdAt
      failure
    }
    createdAt
  }
`
