import { gql } from '@apollo/client'
import { QUESTION_ITEM_FIELDS } from './question'

export const SURVEY_LINK_CONFIG_FIELDS = gql`
  ${QUESTION_ITEM_FIELDS}

  fragment SurveyLinkConfig on SurveyLinkConfig {
    currentItemIndex
    sectionsCount
    questionsCount
    items {
      ... on SurveyLinkConfigQuestionItem {
        type
        ...QuestionItemFields
      }
      ... on SurveyLinkConfigIntroItem {
        type
        header
        imageUrl
        introMarkdown
        icon
        footnote
        inlineAlert {
          type
          message
        }
      }
    }
  }
`
