import React, { useState, useContext, useRef } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { ScrollView } from 'react-native-gesture-handler'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button } from '@components/base'
import { NavigationContainer } from '@src/screens/Common/containers'
import { NavigatorContext, useSnack } from '@src/utils/navigatorContext'
import { ScrollViewWithFade } from '@src/components'
import { userSelector } from '@src/selectors/app'

interface CheckoutContainerProps {
  children?: React.ReactNode
  checkoutButtonTitle: string
  checkoutDisabled?: boolean
  showDiscountDisclosure?: boolean
  onCheckout: () => Promise<void> | void
}

export const CheckoutContainer = ({
  checkoutButtonTitle,
  checkoutDisabled = false,
  showDiscountDisclosure = false,
  onCheckout,
  children,
}: CheckoutContainerProps) => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const showSnack = useSnack()
  const dispatch = useDispatch()
  const scrollViewRef = useRef<ScrollView>(null)
  const user = useSelector(userSelector)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { enableLoader, disableLoader } = useContext(NavigatorContext)
  const address = user?.address
  const paymentMethod = user?.paymentMethod

  const readyToCheckout = !checkoutDisabled && !!paymentMethod && !!address && !!user.phoneNumber

  const onCheckoutPress = async () => {
    if (isSubmitting || !readyToCheckout) {
      return
    }

    setIsSubmitting(true)
    enableLoader()

    try {
      await onCheckout()
    } catch (error: any) {
      dispatch({ type: 'users/fetch' })
      console.error(error)
      showSnack(error?.message || 'Server error, please try again', null, 'error')
    } finally {
      disableLoader()
      setIsSubmitting(false)
    }
  }

  // we don't want to scroll to bottom when user already has address and payment method
  const shouldScrollToBottomWhenReadyToCheckoutRef = useRef(!readyToCheckout)

  const onContentSizeChange = () => {
    // content size changes when user adds discount code, payment method or shipping address
    if (shouldScrollToBottomWhenReadyToCheckoutRef.current && readyToCheckout) {
      shouldScrollToBottomWhenReadyToCheckoutRef.current = false
      scrollViewRef.current?.scrollToEnd({ animated: true })
    }
  }

  return (
    <NavigationContainer title="Checkout">
      <ScrollViewWithFade
        ref={scrollViewRef}
        style={styles.container}
        scrollViewStyle={[
          styles.contentContainer,
          { paddingBottom: Math.max(insets.bottom + 16, 40) },
        ]}
        onContentSizeChange={onContentSizeChange}
      >
        <View style={styles.content}>{children}</View>
        <View style={styles.footer}>
          {showDiscountDisclosure && (
            <Text type="small" style={styles.info}>
              At the end of your commitment, you’ll lock in a discounted month-to-month rate. All
              plans are billed monthly and auto-renew.
            </Text>
          )}
          <Button
            type="primary"
            size="block"
            style={styles.checkoutButton}
            onPress={onCheckoutPress}
            disabled={!readyToCheckout}
            accessibilityLabel={checkoutButtonTitle}
          >
            {checkoutButtonTitle}
          </Button>
        </View>
      </ScrollViewWithFade>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    flexGrow: 1,
  },
  content: {
    flex: 1,
  },
  footer: {
    justifyContent: 'flex-end',
    paddingHorizontal: 16,
  },
  info: {
    marginHorizontal: 32,
    marginTop: 16,
    textAlign: 'center',
    color: 'theme.text.secondary',
  },
  checkoutButton: {
    marginTop: 32,
  },
})
