import defaultReducers from '@src/models/defaultReducers'
import {
  LatestUserAppointment,
  CoachAssignmentCollection,
  CoachAssignmentSupportType,
  CoachAssignmentState,
  NoteCollection,
  AppointmentCollection,
  SuggestedAvailability,
  EhrAppointmentRecurrence,
  InsuranceCardSubmission,
  Slot,
  EhrAppointment,
  EhrAppointmentCollection,
  Suggested,
  DatesWithSlots,
} from '@src/types'
import { NutritionistHubStoreState } from './nutritionistHub.types'

export const reducers = {
  ...defaultReducers,
  updateLastUserAppointment: (
    state: NutritionistHubStoreState,
    { payload }: { payload: LatestUserAppointment },
  ) => {
    return {
      ...state,
      latestUserAppointment: payload,
    }
  },
  updateLastUserEhrAppointment: (
    state: NutritionistHubStoreState,
    { payload }: { payload: EhrAppointment },
  ) => {
    return {
      ...state,
      latestUserEhrAppointment: payload,
    }
  },
  updateInsurancePolicy: (state: NutritionistHubStoreState, { payload }: { payload: any }) => {
    return {
      ...state,
      insurancePolicy: payload,
    }
  },
  updateTopPriorityCoachAssignment: (
    state: NutritionistHubStoreState,
    { payload }: { payload: CoachAssignmentCollection },
  ) => {
    const supportTypePriorityOrder = {
      [CoachAssignmentSupportType.VideoCall]: 1,
      [CoachAssignmentSupportType.AsyncChat]: 2,
      [CoachAssignmentSupportType.VideoChat]: 3,
      [CoachAssignmentSupportType.MealPlan]: 4,
      [CoachAssignmentSupportType.SupplementReview]: 5,
    }

    const statePriorityOrder = {
      [CoachAssignmentState.Inactive]: 1,
      [CoachAssignmentState.Paused]: 2,
      [CoachAssignmentState.Pending]: 3,
      [CoachAssignmentState.Active]: 4,
    }

    const sortedCoachAssignments = payload.coachAssignments.sort(
      (a, b) =>
        statePriorityOrder[b.state] - statePriorityOrder[a.state] ||
        supportTypePriorityOrder[b.supportType] - supportTypePriorityOrder[a.supportType],
    )

    const topPriorityCoachAssignment = sortedCoachAssignments[0]

    return {
      ...state,
      topPriorityCoachAssignment,
    }
  },
  updatedMemberNotes: (
    state: NutritionistHubStoreState,
    { payload }: { payload: NoteCollection },
  ) => {
    return {
      ...state,
      allMemberNotes: payload.notes || [],
    }
  },
  updateMemberNotesUpdatedAtTimestamp: (
    state: NutritionistHubStoreState,
    { payload }: { payload: string },
  ) => {
    return {
      ...state,
      memberNotesUpdatedAt: payload,
    }
  },
  updateUpcomingAppointments: (
    state: NutritionistHubStoreState,
    { payload }: { payload: AppointmentCollection },
  ) => {
    return {
      ...state,
      upcomingAppointments: payload.appointments || [],
    }
  },
  updateUpcomingEhrAppointments: (
    state: NutritionistHubStoreState,
    { payload }: { payload: EhrAppointmentCollection },
  ) => {
    return {
      ...state,
      upcomingEhrAppointments: payload.appointments || [],
    }
  },
  updatePastAppointments: (
    state: NutritionistHubStoreState,
    { payload }: { payload: AppointmentCollection },
  ) => {
    return {
      ...state,
      pastAppointments: payload.appointments || [],
    }
  },
  updatePastEhrAppointments: (
    state: NutritionistHubStoreState,
    { payload }: { payload: EhrAppointmentCollection },
  ) => {
    return {
      ...state,
      pastEhrAppointments: payload.appointments || [],
    }
  },
  updatePendingAppointments: (
    state: NutritionistHubStoreState,
    { payload }: { payload: AppointmentCollection },
  ) => {
    return {
      ...state,
      pendingAppointments: payload.appointments || [],
    }
  },
  updatePendingEhrAppointments: (
    state: NutritionistHubStoreState,
    { payload }: { payload: EhrAppointmentCollection },
  ) => {
    return {
      ...state,
      pendingEhrAppointments: payload.appointments || [],
    }
  },
  updateSuggestedAvailability: (
    state: NutritionistHubStoreState,
    { payload }: { payload: SuggestedAvailability },
  ) => {
    return {
      ...state,
      suggestedAvailability: payload,
    }
  },
  updateEhrSuggestedAvailability: (
    state: NutritionistHubStoreState,
    { payload }: { payload: Suggested },
  ) => {
    return {
      ...state,
      ehrSuggestedAvailability: payload,
    }
  },
  updateAppointmentRecurrence: (
    state: NutritionistHubStoreState,
    { payload }: { payload: EhrAppointmentRecurrence | null },
  ) => {
    return {
      ...state,
      appointmentRecurrence: payload,
    }
  },
  updateInsuranceCardSubmission: (
    state: NutritionistHubStoreState,
    { payload }: { payload: InsuranceCardSubmission | null },
  ) => {
    return {
      ...state,
      insuranceCardSubmission: payload,
    }
  },
  updateEarlierAvailableSlots: (
    state: NutritionistHubStoreState,
    { payload }: { payload: { appointmentId: string; slots: Slot[] } },
  ) => {
    return {
      ...state,
      earlierAvailableSlotsByAppointmentId: { [payload.appointmentId]: payload.slots },
    }
  },
  updateEhrEarlierAvailableSlots: (
    state: NutritionistHubStoreState,
    { payload }: { payload: { appointmentId: string; slots: Slot[] } },
  ) => {
    return {
      ...state,
      ehrEarlierAvailableSlotsByAppointmentId: { [payload.appointmentId]: payload.slots },
    }
  },
  updateEhrAvailableDatesWithSlots: (
    state: NutritionistHubStoreState,
    { payload }: { payload: DatesWithSlots },
  ) => {
    return {
      ...state,
      ehrAvailableDatesWithSlots: payload,
    }
  },
}

export default reducers
