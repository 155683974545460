import React, { useEffect, useState } from 'react'
import { Image, ImageStyle, ScrollView, useWindowDimensions, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import pluralize from 'pluralize'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text } from '@components/base'
import { useGoBack } from '@src/utils'
import { OnboardingStackParamsList } from '@src/navigation/types'
import {
  freeTrialOfferImage,
  glucoseChartMealImage,
  glucoseScoreWheelImage,
  karaVideoImage,
} from '@src/assets/images'
import { BillingProductCategory, Product } from '@src/types'
import { productsByCategorySelector } from '@src/selectors/app'
import { LoadingContainer } from '@src/screens/Common/containers/LoadingContainer'
import { useIsDarkMode } from '@src/config/theme'
import { Benefit, Feature, useFeatureFlag } from '@src/components'
import { CheckoutKind } from '@src/screens/Marketplace/types/types'
import { useCanUpgradeToProduct } from '@src/screens/Marketplace/utils/hooks'
import { FreeTrialPlan } from './FreeTrialSubscription/FreeTrialPlan'
import { TrialStep } from './FreeTrialSubscription/TrialStep'
import { TrialBenefit } from './FreeTrialSubscription/TrialBenefit'

const PRODUCT_CATEGORY = BillingProductCategory.FreeTrial
const RUNNER_IMAGE_ASPECT_RATIO = 180 / 392

export const FreeTrialSubscriptionOffer = () => {
  const styles = useStyleSheet(themedStyle)
  const deprecatedStyles = useStyleSheet(deprecatedThemedStyle)
  const navigation = useNavigation()
  const goBack = useGoBack()
  const dispatch = useDispatch()
  const isDarkMode = useIsDarkMode()
  const route = useRoute<RouteProp<OnboardingStackParamsList, 'FreeTrialSubscriptionOffer'>>()
  const { product, subscription } = route.params

  const freeTrialProducts = useSelector(productsByCategorySelector(PRODUCT_CATEGORY))
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(product)
  const [productsLoaded, setProductsLoaded] = useState(false)

  const interval = selectedProduct?.interval.key || product.interval.key
  const trialDays = selectedProduct?.trialDays || product.trialDays

  const isNewFreeTrialEnabled = useFeatureFlag(Feature.ByosFreeTrialUpdate)
  const eligibleForUpgrade = useCanUpgradeToProduct(subscription, selectedProduct?.id)

  const dimensions = useWindowDimensions()
  const imageHeight = RUNNER_IMAGE_ASPECT_RATIO * dimensions.width

  useEffect(() => {
    if (!product.trialDays) {
      goBack()
    }
  }, [goBack, product.trialDays])

  useEffect(() => {
    dispatch({
      type: 'app/fetchProducts',
      payload: { category: PRODUCT_CATEGORY },
      complete: () => {
        setProductsLoaded(true)
      },
    })
  }, [dispatch])

  if (!trialDays) {
    return null
  }

  const onSubscribePress = () => {
    if (subscription && eligibleForUpgrade) {
      return navigation.navigate('SubscriptionCheckout', {
        subscriptionId: subscription.id,
        product: selectedProduct || product,
        checkoutKind: CheckoutKind.UpgradeProduct,
      })
    }

    navigation.navigate('SubscriptionCheckout', { product: selectedProduct || product })
  }

  const onPlanSelect = (product: Product) => {
    setSelectedProduct(product)
  }

  return isNewFreeTrialEnabled ? (
    <SafeAreaView edges={['top', 'bottom']} style={styles.container}>
      <ScrollView>
        <View style={styles.header}>
          <Image
            source={freeTrialOfferImage.imageSource}
            resizeMode="contain"
            style={{ height: imageHeight } as ImageStyle}
          />
          <View style={styles.textOverlayContainer}>
            <Text style={styles.title} type="title-1">
              First {pluralize('day', trialDays, true)} on us
            </Text>
          </View>
        </View>
        <View style={styles.contentContainer}>
          <View style={styles.trialSteps}>
            <Text style={styles.description} type="title-3">
              How your free trial works
            </Text>
            <TrialStep
              title="Today"
              description="Connect your sensor and activate it in our app."
              iconName="lock-simple-open"
              lightConnector
              darkConnector
            />
            <TrialStep
              title={`In ${pluralize('day', trialDays - 1, true)}`}
              description="We will check-in how you’re doing! And a quick reminder about your trial ending."
              iconName="bell"
              iconContainerStyle={{ transform: [{ rotate: '-15deg' }] }}
              lightConnector
            />
            <TrialStep
              title={`In ${pluralize('day', trialDays, true)}`}
              description={`Your ${interval} subscription will start on ${
                ' ' + moment().add(trialDays, 'days').format('MM/DD/YYYY')
              }`}
              iconName="star"
            />
          </View>
          <View style={styles.planSelection}>
            <Text type="regular">Select a plan</Text>
            <View style={styles.plans}>
              {!productsLoaded && <LoadingContainer loadingMessage="Loading products" />}
              {productsLoaded &&
                freeTrialProducts
                  .sort((a, b) => a.price - b.price) // put cheaper unit prices first
                  .map((product) => (
                    <FreeTrialPlan
                      product={product}
                      popular={product.interval.key === 'yearly'}
                      onSelect={onPlanSelect}
                      selected={selectedProduct?.key === product.key}
                      key={product.key}
                    />
                  ))}
            </View>
          </View>
          <View style={styles.benefitsHeader}>
            <Text type="regular">What's included?</Text>
          </View>
          <TrialBenefit
            title="Glucose Visualization"
            description="Understand how your meals & activities affect your glucose response"
            image={
              <Image
                source={glucoseScoreWheelImage(isDarkMode).imageSource}
                style={{ width: 74, height: 58 }}
              />
            }
          />
          <TrialBenefit
            title="Meal score"
            description={
              'Proprietary score to provide more detailed' +
              'insights on how a meal affected your blood glucose levels'
            }
            image={
              <Image source={glucoseChartMealImage.imageSource} style={{ width: 84, height: 98 }} />
            }
          />
          <TrialBenefit
            title="Access to video calls with a Nutritionist"
            description="Ability to sign up with 1:1 video calls (insurance-covered)"
            image={<Image source={karaVideoImage.imageSource} style={{ width: 70, height: 80 }} />}
          />
        </View>
      </ScrollView>
      <View style={styles.footer}>
        <Button
          type="primary"
          size="block"
          accessibilityLabel="Start my free trial"
          onPress={onSubscribePress}
        >
          Start my free trial
        </Button>
        <Text style={styles.buttonDescription} type="regular">
          Cancel anytime before trial end. Afterwards, subscription is charged
          {' ' + interval}
        </Text>
      </View>
    </SafeAreaView>
  ) : (
    <SafeAreaView edges={['top', 'bottom']} style={deprecatedStyles.contentContainer}>
      <Text style={deprecatedStyles.title} type="title-2">
        First {pluralize('day', trialDays, true)} free
      </Text>
      {!!product.description && (
        <Text style={deprecatedStyles.description} type="large">
          {product.description}
        </Text>
      )}
      <View style={deprecatedStyles.benefits}>
        {product.details?.map((benefit) => (
          <Benefit key={benefit} benefit={benefit} />
        ))}
      </View>
      <View style={deprecatedStyles.buttons}>
        <Button
          type="primary"
          size="block"
          accessibilityLabel="Try Free & Subscribe"
          onPress={onSubscribePress}
        >
          Try Free & Subscribe
        </Button>

        <Text style={deprecatedStyles.buttonDescription} type="regular">
          {pluralize('day', trialDays, true)} free, then ${product.price}/{product.interval.suffix}.
          Cancel Anytime
        </Text>
      </View>
    </SafeAreaView>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    paddingBottom: 16,
    backgroundColor: 'theme.background',
  },
  header: {
    alignItems: 'center',
  },
  textOverlayContainer: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    top: '40%',
    left: 0,
    right: 0,
    bottom: 0,
  },
  title: {
    color: 'theme.solid.white',
    marginTop: 24,
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 4, // Android shadow
  },
  contentContainer: {
    paddingHorizontal: 16,
  },
  trialSteps: {
    alignItems: 'flex-start',
  },
  planSelection: {
    alignItems: 'center',
    marginVertical: 16,
  },
  plans: {
    width: '100%',
    flexBasis: '100%',
    alignContent: 'stretch',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginVertical: 16,
  },
  benefitsHeader: {
    alignItems: 'center',
  },
  description: {
    alignSelf: 'center',
    marginVertical: 16,
    maxWidth: 300,
  },
  footer: {
    paddingHorizontal: 16,
    paddingTop: 16,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  buttonDescription: {
    marginTop: 16,
    color: 'theme.text.secondary',
    textAlign: 'center',
  },
})

const deprecatedThemedStyle = StyleService.create({
  contentContainer: {
    flex: 1,
    paddingHorizontal: 16,
    paddingBottom: 16,
    backgroundColor: 'theme.background',
  },
  benefits: {
    marginTop: 8,
  },
  content: {
    flex: 1,
    paddingHorizontal: 16,
  },
  title: {
    textAlign: 'center',
    marginTop: 24,
  },
  description: {
    alignSelf: 'center',
    marginTop: 16,
    maxWidth: 300,
  },
  detailsContainer: {
    flex: 1,
    marginTop: 32,
  },
  buttons: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  buttonDescription: {
    marginTop: 16,
    color: 'theme.text.secondary',
  },
})
