import { gql } from '@apollo/client'

export const QUESTION_FIELDS = gql`
  fragment QuestionFields on Question {
    key
    label
    note
    imageUrl
    required

    response {
      kind
      kindMetadata

      answersGroups {
        answersOrder
        answers {
          label
          value
          inlineAlert {
            type
            message
          }
          followups {
            kind
            question {
              key
              label
              note
              imageUrl
              required

              response {
                kind
                kindMetadata

                answersGroups {
                  answersOrder
                  answers {
                    label
                    value
                    inlineAlert {
                      type
                      message
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export const QUESTION_ITEM_FIELDS = gql`
  fragment QuestionItemFields on SurveyLinkConfigQuestionItem {
    key
    label
    note
    imageUrl
    required

    response {
      kind
      kindMetadata

      answersGroups {
        answersOrder
        answers {
          label
          value
          inlineAlert {
            type
            message
          }
          followups {
            kind
            question {
              key
              label
              note
              imageUrl
              required

              response {
                kind
                kindMetadata

                answersGroups {
                  answersOrder
                  answers {
                    label
                    value
                    inlineAlert {
                      type
                      message
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
