import React, { useEffect } from 'react'
import { ActivityIndicator, Image, TouchableOpacity, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { ImageStyle } from 'react-native-fast-image'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@src/components/base'
import { Device, ErrorMessages } from '@src/config'
import { nutritionistImage } from '@src/assets/images'
import { topPriorityCoachAssignmentSelector } from '@src/screens/NutritionistHub/models/nutritionistHub.selectors'
import { useSnack } from '@src/utils'
import { ImageWithPlaceholder } from '@src/components/Image/FastImageWithPlaceholder'
import { SurveysConfigKind, User } from '@src/types'
import { userSelector } from '@src/selectors/app'
import { ByosVideoCallsUpsellStep } from '../../constants'
import { recordByosVideoCallsUpsellInteraction } from '../../utils/utils'

const NUTRITIONIST_PHOTO_SIZE = 64
const defaultNutritionistPhotoUri = Device.web
  ? null
  : Image.resolveAssetSource(nutritionistImage.imageSource).uri

export const FreeConsultationUpsellCard = () => {
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const coachAssignment = useSelector(topPriorityCoachAssignmentSelector)
  const navigation = useNavigation()
  const user = useSelector(userSelector)

  const onPress = () => {
    recordByosVideoCallsUpsellInteraction(
      ByosVideoCallsUpsellStep.FreeConsultationActionItem,
      user as User,
    )
    navigation.navigate('Questionnaire', {
      questionnaire: SurveysConfigKind.Insurance,
      skipIntroScreen: true,
    })
  }

  const nutritionistPhotoUri =
    coachAssignment?.coach?.coachProfile?.photo || defaultNutritionistPhotoUri

  useEffect(() => {
    dispatch({
      type: 'nutritionistHub/fetchCoachAssignments',
      failure: (error: any) => {
        const errorMessage = error?.message || ErrorMessages.ServerError
        showSnack(errorMessage, null, 'error')
      },
    })
  }, [dispatch, showSnack])

  return (
    <TouchableOpacity
      onPress={onPress}
      style={styles.container}
      accessibilityLabel="Free Consultation"
    >
      <View style={styles.nutritionistPhotoAndDescriptionContainer}>
        {nutritionistPhotoUri && (
          <View style={styles.nutritionistPhotoContainer}>
            <ImageWithPlaceholder
              style={styles.nutritionistPhoto as ImageStyle}
              source={{ uri: nutritionistPhotoUri }}
              placeholderComponent={
                <ActivityIndicator animating size="small" {...styles.spinner} />
              }
            />
          </View>
        )}
        <View style={styles.descriptionContainer}>
          <Text type="regular" bold style={styles.primaryText}>
            Claim Your FREE Expert Nutritionist Consultation Today.
          </Text>
        </View>
        <Icon name="caret-right" size="16" weight="bold" style={styles.icon} />
      </View>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexDirection: 'row',
    borderRadius: 12,
    borderWidth: 1,
    borderColor: 'theme.surface.base',
    padding: 12,
    marginVertical: 12,
    marginHorizontal: 16,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'theme.marketing.yellow',
  },
  nutritionistPhotoAndDescriptionContainer: {
    gap: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  nutritionistPhotoContainer: {
    justifyContent: 'flex-end',
    zIndex: 99,
  },
  nutritionistPhoto: {
    width: NUTRITIONIST_PHOTO_SIZE,
    height: NUTRITIONIST_PHOTO_SIZE,
    borderRadius: NUTRITIONIST_PHOTO_SIZE / 2,
  },
  spinner: {
    color: 'theme.surface.base',
  },
  icon: {
    color: 'theme.surface.base0',
    marginRight: 16,
  },
  descriptionContainer: {
    flex: 1,
  },
  primaryText: {
    color: 'theme.marketing.onText',
  },
})
