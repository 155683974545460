import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Point } from '@src/screens/Insights/types'
import { insightsCalendarSelector } from '@src/screens/Insights/models/insights.selectors'
import { ByDayOfWeekChart } from './ByDayOfWeekChart'

interface ScoreChartProps {
  currentData: Point[]
  previousData: Point[]
  yLabel?: string
  range?: {
    min: number
    max: number
  }
  precision: 0 | 1
}

const ScoreChartComponent = ({
  currentData,
  previousData,
  precision,
  range,
  yLabel,
}: ScoreChartProps) => {
  const calendar = useSelector(insightsCalendarSelector)

  return (
    <ByDayOfWeekChart
      datesRange={{ startDate: calendar.startDate, endDate: calendar.endDate }}
      currentData={currentData}
      previousData={previousData}
      range={range}
      precision={precision}
      yLabel={yLabel}
    />
  )
}
export const ScoreChart = memo(ScoreChartComponent)
