import React, { useEffect, useState } from 'react'
import { NetworkProvider as OriginalNetworkProvider } from 'react-native-offline'
import { AppState } from 'react-native'
import Device from '../device'

export const NetworkProvider = ({ children }: { children: React.ReactNode }) => {
  const [shouldPing, setShouldPing] = useState(true)
  const [prevAppState, setPrevAppState] = useState(AppState.currentState)

  useEffect(() => {
    const subscription = AppState.addEventListener('change', (state) => {
      if (prevAppState === 'background' && state === 'active') {
        setShouldPing(false)
        setTimeout(() => {
          setShouldPing(true)
        }, 1000 * 3)
      }
      setPrevAppState(state)
    })

    return () => subscription.remove()
  }, [prevAppState])

  return (
    <OriginalNetworkProvider
      pingInterval={5000}
      pingOnlyIfOffline
      pingTimeout={10000}
      httpMethod="HEAD"
      pingServerUrl="https://www.google.com/"
      pingInBackground={false}
      shouldPing={!Device.web && shouldPing}
    >
      {children}
    </OriginalNetworkProvider>
  )
}
