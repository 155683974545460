import { Moment } from 'moment'
import { useSelector } from 'react-redux'
import { useFeatureFlag, Feature } from '@src/components/FeatureFlag'
import { useIsOwnSensorGlucoseDetected } from '@src/screens/OwnSensorOnboarding/hooks/useIsOwnSensorGlucoseDetected'
import { userSelector, uxSensorModeKindSelector } from '@src/selectors/app'
import { UserExperienceSensorModeKind } from '@src/types'
import { Storage } from '@src/utils'
import { useStorageValue } from '@src/utils/storage'

// For users transitioning from Libre to integration with Stelo via Apple Health
export const useShowSteloSetupPopup = () => {
  const isSteloTransitionGuideEnabled = useFeatureFlag(Feature.SteloTransitionGuide)
  const user = useSelector(userSelector)
  const isOwnSensorGlucoseDetected = useIsOwnSensorGlucoseDetected()
  const uxSensorModeKind = useSelector(uxSensorModeKindSelector)
  const [steloTutorialStartedAt, _] = useStorageValue<Moment>(
    `${Storage.STELO_TUTORIAL_FINISHED_AT_KEY}_${user?.id}`,
  )
  const steloSignUpEnabled = useFeatureFlag(Feature.SteloSignUp)

  if (!isSteloTransitionGuideEnabled) {
    return false
  }

  if (isOwnSensorGlucoseDetected) {
    return false
  }

  if (steloSignUpEnabled) {
    return false
  }

  // user started the onboarding tutorial already
  if (steloTutorialStartedAt) {
    return false
  }

  return uxSensorModeKind === UserExperienceSensorModeKind.DexcomStelo
}
