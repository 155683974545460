import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'

interface BenefitProps {
  benefit: string | React.ReactNode
}

export const Benefit = ({ benefit }: BenefitProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <View style={styles.item}>
      <Icon name="check" style={styles.checkmarkIcon} />
      {typeof benefit === 'string' ? (
        <Text type="regular" style={styles.detailText}>
          {benefit}
        </Text>
      ) : (
        <View style={styles.detailText}>{benefit}</View>
      )}
    </View>
  )
}

const themedStyle = StyleService.create({
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 12,
  },
  detailText: {
    flex: 1,
    paddingRight: 8,
  },
  checkmarkIcon: {
    color: 'theme.success.base',
    marginRight: 16,
  },
})
