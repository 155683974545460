import React, { useCallback } from 'react'
import { Image, ImageStyle, TouchableOpacity, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useDispatch, useSelector } from 'react-redux'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { arrowRightImage, cgmG7Image2, cgmSteloImage, cgmLibreImage } from '@src/assets/images'
import { Button, Text } from '@components/base'
import { openUrl, Storage, Zendesk } from '@utils'
import { Benefit, InlineAlert, ScrollViewWithFade } from '@src/components'
import { SensorModel, UiInlineAlert, UiStateNames } from '@src/types'
import { userSelector } from '@src/selectors/app'
import { markModalAsShown } from '@src/hooks/useOneTimeVisitedState'
import { sensorSelector } from '@src/selectors/sensor'
import { useIsEligibleForStelo } from '../hooks/useIsEligibleForStelo'
import { SensorBenefitTooltip } from './SensorBenefitTooltip'

const FADE_BUFFER = 16

// For users being switched from Dexcom (maybe also Libre) to Stelo with realtime API
export const MigratedToStelo = () => {
  const styles = useStyleSheet(themedStyles)
  const user = useSelector(userSelector)
  const dispatch = useDispatch()
  const currentSensor = useSelector(sensorSelector)
  const migratingFromLibre =
    currentSensor?.modelEnum &&
    [SensorModel.LibreUs, SensorModel.Libre3].includes(currentSensor.modelEnum)
  const isEligibleForStelo = useIsEligibleForStelo()

  const navigation = useNavigation()

  const onConfirmPress = () => {
    if (isEligibleForStelo) {
      return navigation.navigate('SteloSetup', { mode: 'migration' })
    }

    navigation.navigate('SteloEligibility', {
      nextScreen: {
        screen: 'SteloSetup',
        params: { nextScreen: 'SteloSetup', params: { mode: 'migration ' } },
      },
    })
  }

  const onContactSupportPress = () => {
    openUrl(Zendesk.baseUrl(user?.email))
  }

  useFocusEffect(
    useCallback(() => {
      markModalAsShown(`${Storage.STELO_SENSOR_MIGRATION_NOTICE_VISITED_KEY}_${user?.id}`)
      dispatch({
        type: 'app/upsertUiState',
        payload: {
          name: UiStateNames.SteloShowMigrationBanner,
          value: false,
        },
      })
    }, [dispatch, user?.id]),
  )

  const alertMessage = (
    <Text type="regular">
      Questions? Don't hesitate to reach out to our{' '}
      <TouchableOpacity accessibilityLabel="Contact Support" onPress={onContactSupportPress}>
        <Text type="regular" bold lineSpacing="none" style={styles.supportLink}>
          Support Team
        </Text>
      </TouchableOpacity>
      .
    </Text>
  )

  const waterproofBenefit = (
    <SensorBenefitTooltip
      tooltipText={
        'The Stelo biosensor is waterproof and may be submerged under eight feet of water ' +
        'for up to 24 hours without failure when properly installed.'
      }
      actionText="Waterproof "
      secondaryText="up to 8ft"
    />
  )

  const wearTimeBenefit = (
    <SensorBenefitTooltip
      tooltipText={
        'A study was conducted to assess the sensor life where 77.9% of ' +
        'sensors lasted the full 15 days. In other words, when using the product per ' +
        'the package labeling, approximately 20% of sensors may not last for the full ' +
        '15 days, and 10% of these sensors may last less than 12 days.'
      }
      actionText="15-day wear time "
      secondaryText="for long-lasting tracking"
    />
  )

  return (
    <SafeAreaView style={styles.content} edges={['bottom']}>
      <ScrollViewWithFade style={styles.scrollView} scrollViewStyle={styles.scrollViewContent}>
        <View style={styles.imagesContainer}>
          <View style={styles.sensorImageContainer}>
            {migratingFromLibre ? (
              <Image
                source={cgmLibreImage.imageSource}
                style={styles.libreSensorImage as ImageStyle}
              />
            ) : (
              <Image source={cgmG7Image2.imageSource} style={styles.g7SensorImage as ImageStyle} />
            )}
          </View>
          <View style={styles.arrowImageContainer}>
            <Image source={arrowRightImage.imageSource} style={styles.arrowImage as ImageStyle} />
          </View>
          <View style={styles.sensorImageContainer}>
            <Image
              source={cgmSteloImage.imageSource}
              style={styles.steloSensorImage as ImageStyle}
            />
          </View>
        </View>

        <View style={styles.title}>
          <Text type="title-2" style={styles.titleText}>
            You are upgraded!
          </Text>
        </View>
        <Text type="regular" style={styles.bodyText}>
          Nutrisense is moving to Stelo by Dexcom and you are one of the first to receive it.
        </Text>

        <View style={styles.benefits}>
          <Benefit benefit="Automatic bluetooth syncing" />
          <Benefit benefit={wearTimeBenefit} />
          <Benefit benefit={waterproofBenefit} />
        </View>

        <InlineAlert
          style={styles.infoAlert}
          textStyle={styles.infoAlertText}
          iconStyle={styles.infoAlertIcon}
          category={UiInlineAlert.Info}
          message={alertMessage}
        />
      </ScrollViewWithFade>
      <Button
        accessibilityLabel="Confirm"
        onPress={onConfirmPress}
        style={styles.nextButton}
        size="block"
        type="primary"
      >
        Confirm Eligibility
      </Button>
    </SafeAreaView>
  )
}

const themedStyles = StyleService.create({
  content: {
    flex: 1,
    padding: 16,
    backgroundColor: 'theme.background',
  },
  scrollView: {
    top: -FADE_BUFFER,
  },
  scrollViewContent: {
    paddingVertical: FADE_BUFFER,
  },
  imagesContainer: {
    marginTop: 64,
    marginBottom: 24,
    flexDirection: 'row',
    height: 100,
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  sensorImageContainer: {
    maxWidth: 90,
    flex: 1,
  },
  arrowImageContainer: {
    maxWidth: 60,
    flex: 1,
    marginHorizontal: 8,
  },
  g7SensorImage: {
    width: '100%',
    flex: 1,
    resizeMode: 'contain',
  },
  libreSensorImage: {
    width: '100%',
    marginTop: 16,
    flex: 1,
    resizeMode: 'contain',
  },
  steloSensorImage: {
    width: '90%',
    flex: 1,
    resizeMode: 'contain',
  },
  arrowImage: {
    maxWidth: 50,
    flex: 1,
    resizeMode: 'contain',
  },
  title: {
    marginBottom: 32,
  },
  titleText: {
    textAlign: 'center',
  },
  bodyText: {
    textAlign: 'center',
    marginHorizontal: 32,
    marginBottom: 16,
  },
  benefits: {
    maxWidth: 280,
    flex: 1,
    width: '100%',
    alignSelf: 'center',
  },
  infoAlert: {
    borderWidth: 0,
    marginVertical: 16,
    backgroundColor: 'theme.surface.base2',
  },
  infoAlertText: {
    color: 'theme.text.secondary',
  },
  infoAlertIcon: {
    color: 'theme.text.secondary',
  },
  supportLink: {
    color: 'theme.text.link',
  },
  nextButton: {
    marginTop: -FADE_BUFFER,
    marginBottom: 8,
  },
})
