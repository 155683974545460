import React from 'react'
import { View, ActivityIndicator } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@src/components/base'

export const LoadingState = () => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.loadingContainer}>
      <ActivityIndicator size="large" color="#0066cc" />
      <Text type="regular" style={styles.loadingText}>
        Loading available nutritionists...
      </Text>
    </View>
  )
}

const themedStyles = StyleService.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingText: {
    marginTop: 16,
    color: 'theme.text.secondary',
  },
})
