import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { View, FlatList } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { useGoBack } from '@src/utils/navigation'
import {
  EhrAppointment,
  EhrSlot,
  UiInlineAlert,
  EhrAppointmentProviderKind,
  EhrGetHealthieInstanceKind,
  Coach,
} from '@src/types'
import { useSnack } from '@src/utils/navigatorContext'
import { ErrorMessages } from '@src/config/logger'
import { userSelector } from '@src/selectors/app'
import { InlineAlert } from '@src/components'
import { NavigationContainer } from '@src/screens/Common/containers'
import { Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import {
  ehrSuggestedAvailabilitySelector,
  ehrAvailableDatesWithSlotsSelector,
} from '../../models/nutritionistHub.selectors'
import { DatePicker } from './DatePicker'
import { NutritionistCard } from './NutritionistCard'
import { LoadingState } from './LoadingState'
import { EmptyState } from './EmptyState'

const MAX_SLOTS_PER_NUTRITIONIST = 4

export const ChooseDifferentSlotScreen = () => {
  const styles = useStyleSheet(themedStyles)
  const insets = useSafeAreaInsets()
  const goBack = useGoBack()
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const showSnack = useSnack()
  const user = useSelector(userSelector)
  const ehrSuggestedAvailability = useSelector(ehrSuggestedAvailabilitySelector)
  const ehrAvailableDatesWithSlots = useSelector(ehrAvailableDatesWithSlotsSelector)

  // States
  const [selectedDate, setSelectedDate] = useState<string | null>(null)
  const [selectedSlot, setSelectedSlot] = useState<EhrSlot | null>(null)
  const [loading, setLoading] = useState(false)

  const { availableDates, providersMap } = useMemo(() => {
    const availableDatesArray = ehrAvailableDatesWithSlots?.dates?.map((item) => item.date) || []

    const provMap = new Map<string, Coach>()
    if (ehrAvailableDatesWithSlots?.providers) {
      ehrAvailableDatesWithSlots.providers.forEach((provider) => {
        provMap.set(provider.id, provider as Coach)
      })
    }

    return { availableDates: availableDatesArray, providersMap: provMap }
  }, [ehrAvailableDatesWithSlots])

  const availableNutritionists = useMemo(() => {
    if (!selectedDate || !ehrAvailableDatesWithSlots?.dates) {
      return []
    }

    const dateWithSlots = ehrAvailableDatesWithSlots.dates.find((d) => d.date === selectedDate)
    if (!dateWithSlots) {
      return []
    }

    const nutritionistMap = new Map()

    dateWithSlots.slots.forEach((slot: EhrSlot) => {
      const provider = providersMap.get(slot.providerId)

      if (!nutritionistMap.has(slot.providerId) && provider) {
        // Get provider details from the providers map
        nutritionistMap.set(slot.providerId, {
          providerId: slot.providerId,
          providerName: provider.fullName,
          photo: provider.photo,
          bio: provider.bio,
          credentials: provider.credentials,
          specialties: provider.specialties || [],
          slots: [],
        })
      }

      const nutritionist = nutritionistMap.get(slot.providerId)
      if (nutritionist.slots.length < MAX_SLOTS_PER_NUTRITIONIST) {
        nutritionist.slots.push(slot)
      }
    })

    return Array.from(nutritionistMap.values())
  }, [selectedDate, ehrAvailableDatesWithSlots, providersMap])

  useEffect(() => {
    if (availableDates.length > 0 && !selectedDate) {
      setSelectedDate(availableDates[0])
    }
  }, [availableDates, selectedDate])

  const fetchAvailableDatesWithSlots = useCallback(() => {
    if (!ehrSuggestedAvailability) {
      return
    }

    setLoading(true)

    dispatch({
      type: 'nutritionistHub/fetchEhrAvailableDatesWithSlots',
      payload: {
        appointmentKind: ehrSuggestedAvailability.appointmentKind,
        providerKind: EhrAppointmentProviderKind.Coach,
        getHealthieInstanceKind: EhrGetHealthieInstanceKind.BerryStreet,
        timeZone: user?.timeZone,
      },
      success: () => {
        setLoading(false)
      },
      failure: (error: any) => {
        const errorMessage = error?.message || ErrorMessages.ServerError
        showSnack(errorMessage, null, 'error')
        setLoading(false)
      },
    })
  }, [ehrSuggestedAvailability, user?.timeZone, dispatch, showSnack])

  useEffect(() => {
    fetchAvailableDatesWithSlots()
  }, [fetchAvailableDatesWithSlots])

  const confirmSlot = () => {
    if (!selectedSlot) {
      return
    }

    dispatch({
      type: 'nutritionistHub/bookAppointment',
      payload: {
        appointmentKind: ehrSuggestedAvailability?.appointmentKind,
        timeZone: user?.timeZone,
        date: selectedSlot.time,
        coachId: selectedSlot.providerId,
        pendingAppointmentId: undefined,
      },
      success: (response: EhrAppointment) => {
        dispatch({ type: 'nutritionistHub/fetchUpcomingAppointments' })
        dispatch({ type: 'nutritionistHub/fetchEhrSuggestedAvailability' })

        if (response?.meetingAt) {
          navigation.navigate('NutritionistHub', {
            inlineAlert: {
              type: UiInlineAlert.Success,
              message: 'Appointment booked successfully',
            },
          })
        } else {
          goBack()
        }
      },
      failure: (error: any) => {
        const errorMessage = error?.message || ErrorMessages.ServerError
        showSnack(errorMessage, null, 'error')
      },
    })
  }

  return (
    <NavigationContainer
      title="Schedule New Call"
      leftIcon={{ pack: 'eva', name: 'arrow-back' }}
      goBack={goBack}
    >
      <View style={styles.container}>
        <Text type="regular" style={styles.reminderText}>
          We'll send a reminder before your call for you to prepare.
        </Text>

        <DatePicker
          dates={availableDates}
          selectedDate={selectedDate}
          onDateSelect={setSelectedDate}
        />

        <View style={styles.timezoneContainer}>
          <InlineAlert category={UiInlineAlert.Info} message={`Timezone: ${user?.timeZone}`} />
        </View>

        {loading ? (
          <LoadingState />
        ) : (
          <FlatList
            data={availableNutritionists}
            renderItem={({ item: nutritionist }) => (
              <NutritionistCard
                providerId={nutritionist.providerId}
                providerName={nutritionist.providerName}
                photo={nutritionist.photo}
                credentials={nutritionist.credentials}
                slots={nutritionist.slots}
                selectedSlot={selectedSlot}
                onSlotSelect={setSelectedSlot}
                onConfirmSlot={confirmSlot}
              />
            )}
            keyExtractor={(nutritionist) => nutritionist.providerId}
            contentContainerStyle={{ paddingBottom: insets.bottom + 80, gap: 32 }}
            showsVerticalScrollIndicator={false}
            style={styles.nutritionistsList}
            ListEmptyComponent={<EmptyState />}
          />
        )}
      </View>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  reminderText: {
    textAlign: 'center',
    paddingHorizontal: 32,
    paddingVertical: 16,
    color: 'theme.text.secondary',
  },
  timezoneContainer: {
    padding: 16,
  },
  nutritionistsList: {
    flex: 1,
    paddingHorizontal: 16,
    gap: 32,
  },
})
