import React, { useState } from 'react'
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Checkbox, Text } from '@components/base'
import { NavigationContainer } from '@src/screens/Common/containers'
import {
  AppStackParamList,
  OnboardingStackParamsList,
  SubscriptionsStackParamsList,
} from '@src/navigation/types'
import { ChoiceItem, ChoiceList } from '@components/Choice'

const CHOICES: ChoiceItem[] = [
  { id: 'yes', body: 'Yes' },
  { id: 'no', body: 'No' },
]

export const EligibilityCheck = () => {
  const styles = useStyleSheet(themedStyles)
  const insets = useSafeAreaInsets()
  const navigation = useNavigation()
  const route = useRoute<
    | RouteProp<OnboardingStackParamsList, 'EligibilityCheck'>
    | RouteProp<SubscriptionsStackParamsList, 'EligibilityCheck'>
    | RouteProp<AppStackParamList, 'EligibilityCheck'>
  >()
  const { product, savings, checkoutOptionKinds, nextScreen } = route.params
  const [selectedChoice, setSelectedChoice] = useState<ChoiceItem>()
  const [confirmed, setConfirmed] = useState<boolean>()

  const getEligibilityCheckNextScreen = () => {
    if (nextScreen) {
      return nextScreen
    }

    if (product.ownSensor && product.trialDays) {
      return { screen: 'FreeTrialSubscriptionOffer', params: { product } }
    }

    return {
      screen: 'SubscriptionCheckout',
      params: { product, savings, checkoutOptionKinds },
    }
  }

  const handleNextPress = () => {
    if (selectedChoice?.id !== 'no' || !confirmed) {
      return
    }

    const nextScreen = getEligibilityCheckNextScreen()
    if (!nextScreen) {
      return
    }
    navigation.navigate(nextScreen.screen as any, nextScreen.params)
  }

  return (
    <NavigationContainer title="Eligibility Check" style={styles.container} allowBackNavigation>
      <ScrollView
        style={styles.container}
        contentContainerStyle={[styles.contentContainer, { paddingBottom: insets.bottom + 16 }]}
      >
        <View style={styles.content}>
          <Text type="large" bold style={styles.title}>
            Are any of the following statements true?
          </Text>
          <Text type="regular" style={styles.listTitle}>
            Currently:
          </Text>
          <View style={styles.list}>
            {product.eligibilityListItems.map((item) => (
              <Text type="regular" key={item}>
                •{'  '}
                {item}
              </Text>
            ))}
          </View>
          <ChoiceList
            items={CHOICES}
            selectedItemId={selectedChoice?.id}
            onPress={setSelectedChoice}
          />
          <TouchableOpacity
            activeOpacity={0.7}
            accessibilityLabel={
              !confirmed ? 'Confirm my understanding' : 'Withdraw my confirmation'
            }
            onPress={() => setConfirmed(!confirmed)}
            style={styles.confirmContainer}
          >
            <Text type="regular" style={styles.confirmLabel}>
              I understand that if any of the above statements are true I may not be eligible for
              the program
            </Text>
            <Checkbox
              checked={!!confirmed}
              onChange={() => setConfirmed((prevConfirmed) => !prevConfirmed)}
            />
          </TouchableOpacity>
        </View>
        <Button
          type="primary"
          size="block"
          accessibilityLabel="Next"
          disabled={!confirmed || !selectedChoice || selectedChoice['id'] === 'yes'}
          onPress={handleNextPress}
        >
          Next
        </Button>
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    flexGrow: 1,
    paddingHorizontal: 16,
  },
  content: {
    flexGrow: 1,
    marginBottom: 32,
  },
  title: {
    marginTop: 24,
  },
  listTitle: {
    marginTop: 24,
  },
  list: {
    marginTop: 16,
    marginBottom: 32,
  },
  confirmContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 16,
    paddingVertical: 20,
    paddingHorizontal: 24,
    backgroundColor: 'theme.surface.base2',
  },
  confirmLabel: {
    marginRight: 20,
    flexShrink: 1,
  },
})
