import { useSelector } from 'react-redux'
import PushNotificationIOS from '@react-native-community/push-notification-ios'
import { useEffect, useState } from 'react'
import { NotificationSettings, RESULTS, checkNotifications } from 'react-native-permissions'
import { AppState, AppStateStatus, PushNotificationPermissions } from 'react-native'
import { Bugsnag, Device } from '@src/config'
import { unitSystemStoreStateSelector } from '@src/selectors/settings'
import { getGlucoseUnit, getGlucoseValue } from '@src/utils/units'
import terraHealthKit from '@src/services/TerraHealthKit/terraHealthKit'
import { useDispatchAsync } from '@src/utils'
import HealthKit from '@src/services/HealthKit'
import { ListProviders } from '@src/types'
import { userSelector } from '@src/selectors/app'
import { UnitSystem } from '..'
import { TERRA_APPLE_HEALTH } from '../models/settings.types'
import { GenerateTerraSdkTokenQuery } from '../graphql/generateTerraSdkToken.generated'

export const useGlucosePrecision = () => {
  const unitSystem = useSelector(unitSystemStoreStateSelector)
  return unitSystem === UnitSystem.Metric ? 1 : 0
}

type NotificationPermissions = { disabled: boolean } & (
  | PushNotificationPermissions
  | NotificationSettings
)

export const useNotificationsPermissionsStatus = () => {
  const [currentPermissions, setCurrentPermissions] = useState<NotificationPermissions>()
  const [nextState, setNextState] = useState<AppStateStatus | null>(null)

  useEffect(() => {
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      setNextState(nextAppState)
    })

    return () => {
      subscription.remove()
    }
  }, [])

  useEffect(() => {
    if (AppState.currentState !== 'active') {
      return
    }
    if (Device.ios) {
      PushNotificationIOS.checkPermissions((permissions) => {
        setCurrentPermissions({
          disabled:
            permissions.authorizationStatus ===
            PushNotificationIOS.AuthorizationStatus.UNAuthorizationStatusDenied,
          ...permissions,
        })
      })
    } else {
      checkNotifications().then(({ status, settings }) => {
        setCurrentPermissions({
          disabled: status === RESULTS.DENIED,
          ...settings,
        })
      })
    }
  }, [nextState])

  return currentPermissions
}

export const useGetGlucoseDescriptor = () => {
  const unitSystem = useSelector(unitSystemStoreStateSelector)

  return (valueInMgPerDl: number) => ({
    value: getGlucoseValue(unitSystem, valueInMgPerDl),
    unit: getGlucoseUnit(unitSystem),
  })
}

export const useTerraAppleHealth = () => {
  const dispatchAsync = useDispatchAsync()
  const user = useSelector(userSelector)

  const userId = user?.id

  const initConnection = async () => {
    if (!userId) {
      return
    }

    try {
      await HealthKit.initialize()
      const response: ListProviders = await dispatchAsync({ type: 'settings/fetchTerraProviders' })
      const terraAppleHealthProvider = response.providers.find(
        (provider) => provider.name === TERRA_APPLE_HEALTH,
      )

      if (terraAppleHealthProvider) {
        const { token } = await dispatchAsync<GenerateTerraSdkTokenQuery['generateTerraSdkToken']>({
          type: 'settings/generateTerraSdkToken',
        })
        const result = await terraHealthKit.initializeConnection(token, userId)
        return result.success
      }

      await dispatchAsync({
        type: 'settings/update',
        payload: { healthKitSync: true },
      })
      return true
    } catch (error: any) {
      Bugsnag.notify(error)
      return false
    }
  }

  return initConnection
}
