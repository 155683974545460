import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ScrollView } from 'react-native'
import { useNavigation } from '@react-navigation/core'
import { StackNavigationProp } from '@react-navigation/stack'
import { useSelector } from 'react-redux'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { useFocusEffect } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { userSelector } from '@src/selectors/app'
import { Product } from '@src/types'
import { NavigationContainer } from '@src/screens/Common/containers'
import { CommonRefreshControl } from '@src/components'
import {
  CommonAuthorizedStackParamList,
  AppStackParamList,
  DrawerParamList,
} from '@src/navigation/types'
import { useCancelRoute } from '@src/utils/hooks'
import { openUrl, Zendesk, useDispatchAsync } from '@src/utils'
import {
  useProducts,
  useGroupedProducts,
  useInsuranceCoverage,
  useSelectedAddonModal,
} from '../utils/hooks'
import { GroupableProduct, GroupedProducts, isGroupedProducts } from '../types/types'
import { Services } from './Services'
import { DietitianAddons } from './DietitianAddons'
import { Addons } from './Addons'
import { SubscriptionPlans } from './SubscriptionPlans'

export const Marketplace = () => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const navigation = useNavigation<
    DrawerNavigationProp<DrawerParamList> &
      StackNavigationProp<CommonAuthorizedStackParamList & AppStackParamList>
  >()
  const dispatch = useDispatchAsync()

  const {
    activeSubscriptions,
    products,
    upcomingSubscriptionPhases,
    dietitianAddons,
    oneTimeAddons,
  } = useProducts()

  const coreProducts = products.filter((product) => product.core && product.productGroup)
  const coreProductItem: GroupedProducts | undefined = useGroupedProducts(
    coreProducts,
  ).find((item) => isGroupedProducts(item)) as GroupedProducts | undefined

  const [refreshing, setRefreshing] = useState(false)

  const refreshingRef = useRef(refreshing)

  const onRefresh = useCallback(async () => {
    refreshingRef.current = true
    setRefreshing(true)

    try {
      await Promise.all([
        dispatch({ type: 'users/fetch' }),
        dispatch({ type: 'app/fetchProducts' }),
        dispatch({ type: 'marketplace/fetchSubscriptionSchedules' }),
      ])
    } finally {
      refreshingRef.current = false
      setRefreshing(false)
    }
  }, [dispatch])

  useEffect(() => {
    onRefresh()
  }, [onRefresh])

  useFocusEffect(
    useCallback(() => {
      if (refreshingRef.current) {
        return
      }

      dispatch({ type: 'users/fetch' })
    }, [dispatch]),
  )

  const onPlanSelected = (product: Product) => {
    navigation.navigate('SubscriptionCheckout', {
      product,
    })
  }

  const { cancel } = useCancelRoute()

  const user = useSelector(userSelector)

  useEffect(() => {
    if (cancel === false) {
      openUrl(Zendesk.baseUrl(user?.email))
      navigation.setParams({ cancel: undefined })
    }
  }, [cancel, navigation, user?.email])

  const insuranceCoverageAvailable = useInsuranceCoverage()
  const confirmDietitianAddonPurchase = (item: GroupableProduct) => {
    if (isGroupedProducts(item)) {
      navigation.navigate('DietitianMenuCheckout', { groupedProducts: item })
      return
    }

    if (insuranceCoverageAvailable(item)) {
      navigation.navigate('CheckoutWithInsuranceOption', { product: item })
      return
    }

    if (item.dietitian) {
      navigation.navigate('SubscriptionCheckout', { product: item })
    } else {
      navigation.navigate('DietitianMenuCheckout', { selection: { product: item } })
    }
  }

  const scrollViewRef = useRef<ScrollView>(null)
  const onAddonSelected = useSelectedAddonModal()

  return (
    <NavigationContainer title="Programs">
      <ScrollView
        ref={scrollViewRef}
        style={styles.container}
        contentContainerStyle={{ paddingBottom: insets.bottom + 16 }}
        refreshControl={<CommonRefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
      >
        {activeSubscriptions.length > 0 || upcomingSubscriptionPhases.length > 0 ? (
          <>
            <Services
              products={products}
              subscriptions={activeSubscriptions}
              upcomingSubscriptionPhases={upcomingSubscriptionPhases}
            />
            {dietitianAddons.length > 0 && (
              <DietitianAddons
                products={dietitianAddons}
                onItemSelected={confirmDietitianAddonPurchase}
              />
            )}
            {oneTimeAddons.length > 0 && (
              <Addons addons={oneTimeAddons} onItemSelected={onAddonSelected} />
            )}
          </>
        ) : (
          !!coreProductItem && (
            <SubscriptionPlans item={coreProductItem} onProductSelected={onPlanSelected} />
          )
        )}
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
})
