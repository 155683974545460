import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { useNavigation } from '@react-navigation/native'
import { Icon, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { DexcomCgm } from '@src/assets/svgs/dexcomCgm'

export const CgmUpsellCard = () => {
  const theme = useTheme()
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()

  const handlePress = () => {
    navigation.navigate('Subscriptions')
  }

  return (
    <TouchableOpacity accessibilityLabel="cgmUpsellAction" onPress={handlePress}>
      <View style={styles.container}>
        <DexcomCgm />
        <View style={styles.textContainer}>
          <Text type="regular" bold>
            Unlock real-time insights
          </Text>
          <Text type="small">
            Buy a CGM to see live how your body reacts to specific foods and activities.
          </Text>
        </View>

        <Icon name="caret-right" size={28} color={theme['theme.primary.base']} />
      </View>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  container: {
    padding: 12,
    backgroundColor: 'theme.secondary.dark',
    borderRadius: 12,
    borderWidth: 1,
    borderColor: 'theme.surface.base',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textContainer: {
    flex: 1,
    gap: 4,
  },
})
