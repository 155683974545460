import React, { useEffect } from 'react'
import { TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/core'
import { StackNavigationProp } from '@react-navigation/stack'
import { useDispatch } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { CircleIcon } from '@components'
import { AppStackParamList } from '@src/navigation/types'
import { useLibre3NativeEnabled } from '@src/utils/hooks'
import { IconName, Text } from '@components/base'
import { User } from '@src/utils'
import { MobileAppFeature } from '@src/types'
import { useIsByosScannableSensorMode } from '@src/screens/OwnSensorOnboarding/hooks'
import { ActionsMenuWrapperModal } from './ActionsMenuWrapperModal'

interface RouteInfo {
  name: keyof AppStackParamList | ''
  text: string
  icon: IconName
  onPress?: () => void
}

export const ActionsModal = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const dispatch = useDispatch()

  const { uxSensorModePendingLibre3, currentSensorIsLibre3 } = useLibre3NativeEnabled()
  const isActivateOwnSensorFeatureAvailable = User.hasFeature(MobileAppFeature.ActivateOwnSensor)
  const isByosScannableSensorMode = useIsByosScannableSensorMode()

  useEffect(() => {
    dispatch({ type: 'users/fetch' })
  }, [dispatch])

  const routes: RouteInfo[] = [
    {
      name: 'AddMeal',
      text: 'Add a Meal',
      icon: 'fork-knife',
    },
    {
      name: 'AddActivity',
      text: 'Add Activity',
      icon: 'pulse',
    },
    {
      name: 'AddJournalEntry',
      text: 'Journal',
      icon: 'pen',
    },
    {
      name: 'AddMeasurement',
      text: 'Add a Measurement',
      icon: 'ruler',
    },
  ]

  const shouldShowActivateSensor =
    isActivateOwnSensorFeatureAvailable || uxSensorModePendingLibre3 || currentSensorIsLibre3

  if (shouldShowActivateSensor) {
    const navigateTo =
      uxSensorModePendingLibre3 || currentSensorIsLibre3 || isByosScannableSensorMode
        ? 'AddScan'
        : 'SensorSettings'

    routes.unshift({
      name: '',
      text: 'Activate Sensor',
      icon: 'scan',
      onPress: () => navigation.replace(navigateTo),
    })
  }

  const onActionPress = (routeInfo: RouteInfo) => {
    if (routeInfo.name) {
      navigation.replace(`${routeInfo.name}`)
    } else {
      routeInfo.onPress && routeInfo.onPress()
    }
  }

  const renderActionItem = (routeInfo: RouteInfo, index: number) => {
    const { text, icon } = routeInfo
    const spacingStyle = { marginBottom: index === routes.length - 1 ? 0 : 16 }

    return (
      <TouchableOpacity
        testID={`ActionsModal/${text}`}
        key={text}
        style={[styles.action, spacingStyle]}
        activeOpacity={0.6}
        onPress={() => onActionPress(routeInfo)}
        accessibilityLabel={text}
      >
        <CircleIcon name={icon} style={styles.icon} />
        <Text type="large" bold>
          {text}
        </Text>
      </TouchableOpacity>
    )
  }

  return <ActionsMenuWrapperModal>{routes.reverse().map(renderActionItem)}</ActionsMenuWrapperModal>
}

const themedStyle = StyleService.create({
  action: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  disabledAction: {
    opacity: 0.3,
  },
  icon: {
    marginRight: 16,
    padding: 16,
  },
})
