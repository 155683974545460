import { IconName } from '@src/components/base/Icon'
import { EhrCoachSpecialty } from '@src/types'

export interface Specialty {
  id: number
  key: string
  name: string
  order: number
}

export const specialtyToIconMap: Record<EhrCoachSpecialty, IconName> = {
  weight_concerns: 'scales',

  eating_disorders: 'fork-knife',
  gut_health: 'microscope',
  prediabetes_diabetes: 'chart-line',
  autoimmune_conditions: 'first-aid',
  chronic_fatigue: 'moon-stars',
  food_allergies: 'fork-knife',
  heart_disease: 'heart',
  cancer: 'first-aid',
  thyroid_disorders: 'thermometer',

  mindful_eating: 'yin-yang',

  prenatal_nutrition: 'baby',
  fertility: 'heart',
  womens_health: 'gender-female',

  mens_health: 'gender-male',

  sports_performance: 'barbell',

  healthy_aging: 'calendar',
  health_optimization: 'calendar',

  bariatric: 'stethoscope',
}
