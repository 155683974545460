import React from 'react'
import { WeightLossExperienceProvider } from './context'
import { PersonalPlan } from './PersonalPlan'

export const WeightLossExperiencePersonalPlanContainer = () => {
  return (
    <WeightLossExperienceProvider>
      <PersonalPlan />
    </WeightLossExperienceProvider>
  )
}
