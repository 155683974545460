import React from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { EntryStatistics } from '@src/types'
import { objectEntries } from '@src/utils'

interface GlucoseStatisticsProps {
  statistics: EntryStatistics
  mode: 'small' | 'large'
  style?: StyleProp<ViewStyle>
}

type DisplayedStatistics = Extract<
  keyof EntryStatistics,
  'glucoseMax' | 'aucScore' | 'glucoseDelta' | 'glucose2hrDelta'
>

const STATISTICS_CONFIG = {
  glucoseMax: {
    iconColor: 'theme.error.base',
    iconName: 'caret-up',
    title: 'Peak',
  },
  aucScore: {
    iconColor: 'theme.info.base',
    iconName: 'wave-triangle',
    title: 'AUC',
  },
  glucoseDelta: {
    iconColor: 'theme.success.base',
    iconName: 'triangle',
    title: 'Delta',
  },
  glucose2hrDelta: {
    iconColor: 'theme.primary.base',
    iconName: 'clock-countdown',
    title: '2h Delta',
  },
} as const

const displayStat = (statistics: EntryStatistics, key: DisplayedStatistics) => {
  const stat = statistics[key]
  return stat ?? '-'
}

export const GlucoseStatistics = ({ statistics, style }: GlucoseStatisticsProps) => {
  const styles = useStyleSheet(themedStyle)
  const theme = useTheme()

  return (
    <View style={[styles.container, style]}>
      {objectEntries(STATISTICS_CONFIG).map(([statKey, { iconColor, iconName, title }]) => (
        <View key={statKey} style={styles.row}>
          <Icon name={iconName} color={theme[iconColor]} style={styles.icon} weight="bold" />
          <Text type="small" style={styles.infoLabel} numberOfLines={1}>
            {title}
          </Text>
          <View style={styles.divider} />
          <Text type="small" style={styles.infoText} numberOfLines={1}>
            {displayStat(statistics, statKey)}
          </Text>
        </View>
      ))}
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    paddingTop: 8,
    minWidth: 120,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 8,
    marginBottom: 2,
  },
  infoLabel: {
    flexShrink: 1,
  },
  infoText: {
    textAlign: 'right',
  },
  divider: {
    flexGrow: 1,
    alignSelf: 'stretch',
    borderBottomWidth: 1,
    borderBottomColor: 'theme.primary.base',
    marginBottom: 4,
    marginHorizontal: 4,
  },
})
