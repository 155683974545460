import React from 'react'
import { View } from 'react-native'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button } from '@src/components/base'
import { EhrSlot } from '@src/types'

interface TimeSlotProps {
  slot: EhrSlot
  isSelected: boolean
  onSelect: (slot: EhrSlot) => void
  onConfirm: () => void
}

export const TimeSlot = ({ slot, isSelected, onSelect, onConfirm }: TimeSlotProps) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.slotItemContainer}>
      <Button
        style={styles.slotButton}
        type="outline"
        onPress={() => onSelect(slot)}
        accessibilityLabel=""
        size="block"
      >
        {moment(slot.time).format('hh:mm A')}
      </Button>
      {isSelected && (
        <Button
          style={styles.slotButton}
          type="primary"
          onPress={onConfirm}
          accessibilityLabel=""
          size="block"
        >
          Confirm
        </Button>
      )}
    </View>
  )
}

const themedStyles = StyleService.create({
  slotItemContainer: {
    flex: 1,
    flexDirection: 'row',
    gap: 8,
  },
  slotButton: {
    flexGrow: 1,
  },
})
