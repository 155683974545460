import React from 'react'
import { ScrollView, TouchableOpacity } from 'react-native'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@src/components/base'

interface DatePickerProps {
  dates: string[]
  selectedDate: string | null
  onDateSelect: (date: string) => void
}

export const DatePicker = ({ dates, selectedDate, onDateSelect }: DatePickerProps) => {
  const styles = useStyleSheet(themedStyles)

  const renderDateItem = (date: string) => {
    const isSelected = date === selectedDate
    const day = moment(date).format('ddd')
    const dayNumber = moment(date).format('D')

    return (
      <TouchableOpacity
        key={date}
        style={[styles.dateItem, isSelected && styles.selectedDateItem]}
        onPress={() => onDateSelect(date)}
        accessibilityLabel={`Select ${day} ${dayNumber}`}
      >
        <Text type="regular" style={[styles.dateText, isSelected && styles.selectedDateText]}>
          {day}
        </Text>
        <Text type="large" bold style={[styles.dateNumber, isSelected && styles.selectedDateText]}>
          {dayNumber}
        </Text>
      </TouchableOpacity>
    )
  }

  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      style={styles.datePickerContainer}
      contentContainerStyle={styles.datePickerContent}
    >
      {dates.map(renderDateItem)}
    </ScrollView>
  )
}

const themedStyles = StyleService.create({
  datePickerContainer: {
    flexDirection: 'row',
    flexGrow: 0,
    paddingHorizontal: 16,
  },
  datePickerContent: {
    paddingRight: 16,
  },
  dateItem: {
    width: 75,
    height: 64,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
    backgroundColor: 'theme.background.light_',
    borderWidth: 1,
    borderColor: 'theme.surface.base1',
  },
  selectedDateItem: {
    backgroundColor: 'theme.primary.base',
  },
  dateText: {
    color: 'theme.text.primary',
  },
  dateNumber: {
    fontSize: 20,
    color: 'theme.text.primary',
  },
  selectedDateText: {
    color: 'theme.solid.white',
  },
})
