import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { View, TouchableWithoutFeedback, Alert } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Checkbox, Text } from '@components/base'
import { NutrisenseLogo } from '@components'
import { useSignIn } from '@src/hooks/useSignIn'
import { SignInButton, SignInButtonVariant } from '@screens/SignIn'
import {
  consumerHealthDataPrivacyPolicySelector,
  privacyPolicySelector,
  telehealthSelector,
  termsOfUseSelector,
} from '@selectors/app'
import { openUrl } from '@utils'
import { useSnack } from '@utils/navigatorContext'
import { useAllowSignInWithApple, useAllowSignInWithGoogle } from '@utils/hooks'

const confirmTermsOfService = () => {
  return new Promise<boolean>((resolve) => {
    Alert.alert(
      'Terms of Service and Policies',
      // eslint-disable-next-line max-len
      "I confirm that I acknowledge and agree to Nutrisense's Terms of Service, Telehealth Consent, Privacy Policy and Consumer Health Data Privacy Policy",
      [
        { text: 'Cancel', onPress: () => resolve(false) },
        { text: 'Agree', onPress: () => resolve(true), style: 'cancel' },
      ],
      { cancelable: true },
    )
  })
}

export const CreateAccountOptionsStelo = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const showSnack = useSnack()
  const termsOfUseUrl = useSelector(termsOfUseSelector)
  const privacyPolicyUrl = useSelector(privacyPolicySelector)
  const telehealthPolicyUrl = useSelector(telehealthSelector)
  const consumerHealthDataPrivacyPolicyUrl = useSelector(consumerHealthDataPrivacyPolicySelector)
  const allowSignInWithGoogle = useAllowSignInWithGoogle()
  const allowSignInWithApple = useAllowSignInWithApple()
  const { signUpWithApple, signUpWithGoogle } = useSignIn()
  const [termsAccepted, setTermsAccepted] = useState(false)

  const handleSignUp = async (callback: () => Promise<void> | void) => {
    if (!termsAccepted) {
      const confirmed = await confirmTermsOfService()

      if (!confirmed) {
        return
      }

      setTermsAccepted(true)
    }

    try {
      await callback()
    } catch (error: any) {
      showSnack(error.message)
    }
  }

  const onSignUpWithEmailPress = () => {
    handleSignUp(() => {
      navigation.navigate('CreateAccountStelo')
    })
  }

  const onSignUpWithGooglePress = () => {
    handleSignUp(signUpWithGoogle)
  }

  const onSignUpWithApplePress = () => {
    handleSignUp(signUpWithApple)
  }

  return (
    <SafeAreaView edges={['top', 'bottom']} style={styles.container}>
      <View style={styles.headerContainer} testID="SigninScreen/Logo">
        <NutrisenseLogo style={styles.nutrisenseLogo} />
        <Text type="title-3">Create your account</Text>
      </View>
      <View style={styles.mainContent}>
        <SignInButton
          variant={SignInButtonVariant.Email}
          style={styles.signInButton}
          onPress={onSignUpWithEmailPress}
        />
        {allowSignInWithGoogle && (
          <SignInButton
            variant={SignInButtonVariant.Google}
            style={styles.signInButton}
            onPress={onSignUpWithGooglePress}
          >
            Sign Up with Google
          </SignInButton>
        )}
        {allowSignInWithApple && (
          <SignInButton
            variant={SignInButtonVariant.Apple}
            style={styles.signInButton}
            onPress={onSignUpWithApplePress}
          >
            Sign Up with Apple
          </SignInButton>
        )}
      </View>
      <View style={styles.bottom}>
        <Checkbox
          style={styles.checkbox}
          checked={termsAccepted}
          testID="SignInOptionsScreen/TermsOfService"
          onChange={() => setTermsAccepted((prev) => !prev)}
          text={
            <Text type="regular" style={styles.termsOfService}>
              You acknowledge and agree to our{' '}
              <TouchableWithoutFeedback
                accessibilityLabel="Terms of Service"
                onPress={() => openUrl(termsOfUseUrl)}
                testID="SignInOptionsScreen/TermsOfServiceButton"
              >
                <Text type="regular" bold style={styles.link}>
                  Terms of Service
                </Text>
              </TouchableWithoutFeedback>
              ,{' '}
              <TouchableWithoutFeedback
                accessibilityLabel="Telehealth Consent"
                onPress={() => openUrl(telehealthPolicyUrl)}
              >
                <Text type="regular" bold style={styles.link}>
                  Telehealth Consent
                </Text>
              </TouchableWithoutFeedback>
              ,{' '}
              <TouchableWithoutFeedback
                accessibilityLabel="Privacy Policy"
                onPress={() => openUrl(privacyPolicyUrl)}
              >
                <Text type="regular" bold style={styles.link}>
                  Privacy Policy
                </Text>
              </TouchableWithoutFeedback>{' '}
              and{' '}
              <TouchableWithoutFeedback
                accessibilityLabel="Consumer Health Data Privacy Policy"
                onPress={() => openUrl(consumerHealthDataPrivacyPolicyUrl)}
              >
                <Text type="regular" bold style={styles.link}>
                  Consumer Health Data Privacy Policy
                </Text>
              </TouchableWithoutFeedback>
            </Text>
          }
        />
      </View>
    </SafeAreaView>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  headerContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  nutrisenseLogo: {
    marginBottom: 16,
  },
  mainContent: {
    borderRadius: 24,
    backgroundColor: 'theme.surface.base2',
    paddingHorizontal: 16,
    paddingVertical: 24,
    gap: 16,
  },
  signInButton: {
    backgroundColor: 'theme.background',
  },
  bottom: {
    flex: 1,
    justifyContent: 'flex-end',
    marginTop: 24,
    marginHorizontal: 8,
  },
  checkbox: {
    alignItems: 'flex-start',
  },
  termsOfService: {
    paddingLeft: 8,
    flex: 1,
  },
  link: {
    color: 'theme.text.link',
  },
})
