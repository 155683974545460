import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { ImageStyle } from 'react-native-fast-image'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Icon } from '@src/components/base'
import { ImageWithPlaceholder } from '@src/components/Image/FastImageWithPlaceholder'
import { EhrSlot } from '@src/types'
import { TimeSlot } from './TimeSlot'

interface NutritionistCardProps {
  providerId: string
  providerName: string
  photo?: string
  credentials?: string
  slots: EhrSlot[]
  selectedSlot: EhrSlot | null
  onSlotSelect: (slot: EhrSlot) => void
  onConfirmSlot: () => void
}

export const NutritionistCard = ({
  providerId,
  providerName,
  photo,
  credentials,
  slots,
  selectedSlot,
  onSlotSelect,
  onConfirmSlot,
}: NutritionistCardProps) => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()

  const showBio = () => {
    navigation.navigate('NutritionistInfoModal', {
      nutritionistId: providerId,
    })
  }

  return (
    <View style={styles.nutritionistCard}>
      <View style={styles.nutritionistHeader}>
        <View style={styles.nutritionistPhotoContainer}>
          {photo ? (
            <ImageWithPlaceholder
              style={styles.nutritionistPhoto as ImageStyle}
              source={{ uri: photo }}
              placeholderComponent={
                <View style={styles.nutritionistAvatarPlaceholder}>
                  <Icon name="user" style={styles.avatarIcon} />
                </View>
              }
            />
          ) : (
            <View style={styles.nutritionistAvatarPlaceholder}>
              <Icon name="user" style={styles.avatarIcon} />
            </View>
          )}
          <View style={styles.nutritionistInfo}>
            <Text type="regular" bold style={styles.nutritionistName}>
              {providerName}
            </Text>
            {credentials && (
              <Text type="small" style={styles.nutritionistCredentials}>
                {credentials}
              </Text>
            )}
          </View>
        </View>

        <TouchableOpacity style={styles.bioButton} onPress={showBio} accessibilityLabel="Show Bio">
          <Text type="regular" bold style={styles.bioButtonText}>
            Show Bio
          </Text>
        </TouchableOpacity>
      </View>

      <View style={styles.availableSlotsContainer}>
        <Text type="small" style={styles.availableSlotsText}>
          Available Slots
        </Text>
        <View style={styles.timeSlotsList}>
          {slots.map((slot) => (
            <TimeSlot
              key={slot.time}
              slot={slot}
              isSelected={
                selectedSlot?.time === slot.time && selectedSlot?.providerId === providerId
              }
              onSelect={onSlotSelect}
              onConfirm={onConfirmSlot}
            />
          ))}
        </View>
      </View>
    </View>
  )
}

const themedStyles = StyleService.create({
  nutritionistCard: {
    borderRadius: 12,
  },
  nutritionistHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  nutritionistPhotoContainer: {
    flexDirection: 'row',
    flexShrink: 1,
    gap: 8,
    alignItems: 'center',
  },
  nutritionistPhoto: {
    width: 48,
    height: 48,
    borderRadius: 24,
  },
  nutritionistName: {
    color: 'theme.text.primary',
  },
  nutritionistCredentials: {
    color: 'theme.text.secondary',
  },
  nutritionistAvatarPlaceholder: {
    width: 48,
    height: 48,
    borderRadius: 24,
    backgroundColor: 'theme.surface.base1',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarIcon: {
    width: 24,
    height: 24,
    color: 'theme.text.tertiary',
  },
  nutritionistInfo: {
    overflow: 'hidden',
    flexShrink: 1,
    flexGrow: 0,
  },
  bioButton: {
    borderRadius: 40,
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderWidth: 2,
    borderColor: 'theme.surface.base1',
    lineHeight: 16,
  },
  bioButtonText: {
    color: 'theme.text.primary',
  },
  availableSlotsContainer: {
    marginTop: 8,
  },
  availableSlotsText: {
    marginBottom: 16,
    color: 'theme.text.secondary',
  },
  timeSlotsList: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: 8,
  },
})
