export const EXPANDED_LIST_HEADER_HEIGHT = 197
export const LIST_SECTION_HEADER_HEIGHT = 32
export const LIST_VERTICAL_PADDINGS = 92

export enum ByosVideoCallsUpsellStep {
  InitialModal = 'initial_modal',
  ChatlikeNotification = 'chatlike_notification',
  ActionItem = 'action_item',
  FreeConsultationModal = 'free_consultation_modal',
  FreeConsultationChatlikeNotification = 'free_consultation_chatlike_notification',
  FreeConsultationActionItem = 'free_consultation_action_item',
}

interface OwnSensorGoalSurveyMap {
  [key: string]: string
}

export const OWN_SENSOR_GOAL_SURVEY_RESPONSE_MAP: OwnSensorGoalSurveyMap = {
  manage_pre_diabetes: 'managing pre diabetes/diabetes',
  chronic_fatigue: 'chronic fatigue/low energy levels',
  food_intolerances: 'intolerances/food allergies',
  cgm_data_interpretation: 'interpreting CGM data',
  mindful_eating: 'mindful eating/intuitive eating',
}
