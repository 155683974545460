import { gql } from '@apollo/client'

export const UPSERT_DEXCOM_CONNECTION = gql`
  mutation upsertDexcomConnection(
    $authorizationCode: String!
    $dexcomConnectionKind: DexcomConnectionKind!
  ) {
    upsertDexcomConnection(
      authorizationCode: $authorizationCode
      dexcomConnectionKind: $dexcomConnectionKind
    ) {
      status
      lastSyncedAt
    }
  }
`
