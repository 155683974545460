import { gql } from '@apollo/client'

export const FETCH_SUGGESTED_AVAILABILITY = gql`
  query schedulingSuggestedAvailability {
    schedulingSuggestedAvailability {
      meetingAt
      appointmentType
      nutritionistId
      nutritionistName
      title
      nutritionistProfile {
        id
        fullName
        bio
        photo
        credentials
        specialties {
          key
          name
        }
      }
    }
  }
`
