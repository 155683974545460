import { MMKV, useMMKVObject } from 'react-native-mmkv'

const storage = new MMKV()

export function get<T>(key: string): T | null
export function get<T>(
  key: string,
  defaultValue: T,
): T extends number ? number : T extends boolean ? boolean : T
// eslint-disable-next-line prefer-arrow-functions/prefer-arrow-functions
export function get<T>(key: string, defaultValue?: T) {
  const value = storage.getString(key)

  if (value !== undefined) {
    return JSON.parse(value) as T
  }

  return defaultValue ?? null
}

export const set = (key: string, value: any) => {
  storage.set(key, JSON.stringify(value))
}

export const remove = (key: string) => {
  storage.delete(key)
}

export const clear = () => storage.clearAll()

export const useStorageValue = <T>(key: string) => {
  const [value, valueSetter] = useMMKVObject<T>(key)

  return [value, valueSetter] as const
}
