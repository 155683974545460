import React from 'react'
import { useSelector } from 'react-redux'
import { Period } from '@src/types'
import { insightsCalendarSelector } from '@src/screens/Insights/models/insights.selectors'
import { useChartData } from '@src/screens/Insights/components/ScoreChart/useChartData'
import { DetailedFetchKeyMap } from '@src/screens/Insights/helper'
import { MealTypeChart, MealTypeChartDataItem } from './MealTypeChart'

export const MealTypeChartController = () => {
  const calendar = useSelector(insightsCalendarSelector)

  const chartData = useChartData(calendar, DetailedFetchKeyMap.MEALS_BY_TYPE, 'score', Period.Day)

  const [currentData, previousData] = chartData.data

  return (
    <MealTypeChart
      currentData={(currentData?.data || []) as MealTypeChartDataItem[]}
      previousData={(previousData?.data || []) as MealTypeChartDataItem[]}
      datesRange={{ startDate: calendar.startDate, endDate: calendar.endDate }}
    />
  )
}
