import { useSelector } from 'react-redux'
import { unitSystemStoreStateSelector } from '@src/selectors/settings'
import { insightsRangeThresholdSelector } from '@src/selectors/insights'
import { RangeItem } from '@src/screens/Insights/types'

export const MAX_CHART_VALUE = 1000

const RANGE_BUFFER = 0.065

export const useDefaultRangeConfig = (metric: string) => {
  const unitSystem = useSelector(unitSystemStoreStateSelector)
  const allRangeConfigs = useSelector(insightsRangeThresholdSelector)

  const rangeConfig = allRangeConfigs.find(({ name }) => name === metric)
  return rangeConfig?.[unitSystem]
}

// Uses the default range config values to build chart-friendly, bounded ranges
//
export const useDefaultChartRanges = (metric: string): RangeItem[] => {
  const rangeConfig = useDefaultRangeConfig(metric)

  if (!rangeConfig) {
    return []
  }

  const { precision, ranges: initialRanges } = rangeConfig

  const { maxRange } = initialRanges.reduce((acc, range) => {
    // Once the max range is set to null, it means we have the highest unbounded (open) range
    if (acc.maxRange === null) {
      return acc
    }

    if (acc.maxRange === undefined || range.to === null || range.to > acc.maxRange) {
      acc.maxRange = range.to
    }

    return acc
  }, {} as { maxRange: number | null | undefined })

  const ranges = initialRanges.map((range) => {
    let { from, to } = range

    if (!from || (precision === 0 && from === 1)) {
      from = 0
    }
    if (!to) {
      to = MAX_CHART_VALUE
    }

    if (to === maxRange) {
      // Range buffer is added to the top range to make sure the chart line color is not cut off
      to += to * RANGE_BUFFER
    } else {
      // Close the gap between the ranges. Precision is 0 for integers and 1 for floats.
      // e.g. for ranges 1-4 and 5-7, precision is 0, close the gap between 4 and 5
      // e.g. for ranges 0-0.8, 0.9-1.1, precision is 1, close the gap between 0.8 and 0.9
      to += precision === 0 ? 1 : 0.1
    }

    return { ...range, from, to }
  })

  return ranges
}
