import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { AppStackParamList } from '@src/navigation/types'
import { TutorialGroups, TutorialSensorKind } from '@src/screens/Tutorials/models/tutorials.types'
import { useGetTutorialId } from '@src/screens/Tutorials/hooks'
import { useIsEligibleForStelo } from '@src/screens/Dexcom/Stelo/hooks/useIsEligibleForStelo'
import { useShowMigratedSteloSetupPopup } from './useShowMigratedSteloSetupPopup'
import { useShowPurchasedSteloSetupPopup } from './useShowPurchasedSteloSetupPopup'
import { useShowSteloRealtimeUpgradePopup } from './useShowSteloRealtimeUpgradePopup'
import { useShowSteloSetupPopup } from './useShowSteloSetupPopup'

const DEFAULT_STELO_TUTORIAL_ID = '17'

export const useShowSteloSetupPopupOrchestrator = () => {
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const showLibreToAppleHealthPopup = useShowSteloSetupPopup()
  const showLibreG7ToSteloRealtimePopup = useShowMigratedSteloSetupPopup()
  const showPurchasedSteloSetupPopup = useShowPurchasedSteloSetupPopup()
  const showSteloRealtimeUpgradePopup = useShowSteloRealtimeUpgradePopup()
  const tutorialId = useGetTutorialId(TutorialGroups.Dexcom, TutorialSensorKind.DexcomSteloRealtime)

  const isEligibleForStelo = useIsEligibleForStelo()

  const shouldShow =
    showLibreToAppleHealthPopup ||
    showLibreG7ToSteloRealtimePopup ||
    showPurchasedSteloSetupPopup ||
    showSteloRealtimeUpgradePopup

  const getPopupConfig = () => {
    if (showPurchasedSteloSetupPopup) {
      return {
        onPress: () => {
          if (isEligibleForStelo) {
            return navigation.navigate('CMSTutorial', {
              tutorialId: tutorialId || DEFAULT_STELO_TUTORIAL_ID,
            })
          }

          navigation.navigate('SteloEligibility', {
            nextScreen: {
              screen: 'CMSTutorial',
              params: { tutorialId: tutorialId || DEFAULT_STELO_TUTORIAL_ID },
            },
          })
        },
        headlineText: 'Received your Stelo shipment?',
        bodyText: 'Tap to start your setup.',
      }
    }

    if (showLibreG7ToSteloRealtimePopup) {
      return {
        onPress: () => {
          if (isEligibleForStelo) {
            return navigation.navigate('SteloSetup', { mode: 'migration' })
          }
          navigation.navigate('SteloEligibility', {
            nextScreen: { screen: 'SteloSetup', params: { mode: 'migration' } },
          })
        },
        headlineText: 'Received your Stelo shipment?',
        bodyText: 'Tap to start your setup.',
      }
    }

    if (showSteloRealtimeUpgradePopup) {
      return {
        onPress: () => {
          if (isEligibleForStelo) {
            return navigation.navigate('SteloSetup', { mode: 'upgrade' })
          }
          navigation.navigate('SteloEligibility', {
            nextScreen: { screen: 'SteloSetup', params: { mode: 'upgrade' } },
          })
        },
        headlineText: 'Ready for the Stelo integration?',
        bodyText: 'Tap to start your setup.',
      }
    }

    return {
      onPress: () => {
        if (isEligibleForStelo) {
          return navigation.navigate('SteloSetup', { mode: 'migration' })
        }
        navigation.navigate('SteloEligibility', {
          nextScreen: { screen: 'SteloSetup', params: { mode: 'migration' } },
        })
      },
      headlineText: 'Received your Stelo shipment?',
      bodyText: 'Tap to start your setup.',
    }
  }

  const { onPress, headlineText, bodyText } = getPopupConfig()

  return { onPress, headlineText, bodyText, shouldShow }
}
