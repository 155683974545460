import React from 'react'
import { Image, ImageStyle } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { BottomSheet } from '@src/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@src/components/base'
import { freeConsultationImage } from '@src/assets/images'
import { SurveysConfigKind, User } from '@src/types'
import { userSelector } from '@src/selectors/app'
import { AppStackParamList } from '@src/navigation/types'
import { ByosVideoCallsUpsellStep } from '../constants'
import { recordByosVideoCallsUpsellInteraction } from '../utils/utils'

export const FreeNutritionistConsultationModal = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const user = useSelector(userSelector)

  const onClaimNowPress = () => {
    recordByosVideoCallsUpsellInteraction(
      ByosVideoCallsUpsellStep.FreeConsultationModal,
      user as User,
    )
    navigation.replace('Questionnaire', {
      questionnaire: SurveysConfigKind.Insurance,
      skipIntroScreen: true,
    })
  }

  const onDismiss = () => {
    recordByosVideoCallsUpsellInteraction(
      ByosVideoCallsUpsellStep.FreeConsultationModal,
      user as User,
    )
  }

  return (
    <BottomSheet
      primaryButton={{ text: 'Claim now', onPress: onClaimNowPress }}
      showDismissButton
      onDismiss={onDismiss}
      dismissButtonText="No thanks"
      buttonDirection="column-reverse"
    >
      <Image source={freeConsultationImage.imageSource} style={styles.image as ImageStyle} />
      <Text style={styles.title} type="title-2">
        Claim Your FREE Expert Nutritionist Consultation Today
      </Text>
      <Text style={styles.content} type="regular">
        Transform your CGM data into real insights with a one-on-one expert consultation. Ask a
        credentialed nutritionist questions about your glucose patterns, diet choices, and health
        concerns—at no cost to you.
      </Text>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  title: {
    textAlign: 'center',
    marginVertical: 16,
  },
  content: {
    textAlign: 'center',
    marginBottom: 16,
  },
  image: {
    aspectRatio: 3 / 2,
    width: '100%',
    height: undefined,
    borderRadius: 20,
  },
})
