import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { User } from '@src/types'
import { userSelector } from '@src/selectors/app'
import { CreatingPlan } from './CreatingPlan'
import { PlanDetails } from './PlanDetails'
import { ScheduleCalls } from './ScheduleCalls'
import { Completed } from './Completed'

export enum Steps {
  CreatingPlan = 'CREATING_PLAN',
  PlanDetails = 'PLAN_DETAILS',
  ScheduleInitialCalls = 'SCHEDULE_INITIAL_CALLS',
  Completed = 'COMPLETED',
}

const StepsConfig = [
  {
    screen: CreatingPlan,
    title: 'Creating the personal plan for you!',
  },
  {
    screen: PlanDetails,
    title: 'Great news! 🎉',
  },
  {
    screen: ScheduleCalls,
    title: 'Schedule Initial Calls',
  },
  {
    screen: Completed,
    title: null,
  },
]

interface ContextData {
  currentUser?: User | null
  isLoading: boolean
  goToNextStep: () => void
  goToPreviousStep: () => void
  fakeLoadingTotalTime: number
  currentStepIndex: number
  showBackButton: boolean
  stepConfig: typeof StepsConfig[number]
}

// We want to simulate a loading time for the user to see the loading screen
// To feel that we are creating the personal plan for the user
const FAKE_LOADING_TOTAL_TIME = 3000

export const WeightLossExperienceContext = createContext({} as ContextData)

const WeightLossExperienceProvider = ({ children }: { children: ReactNode }) => {
  const currentUser = useSelector(userSelector)

  const [fakeLoading, setFakeLoading] = useState(true)
  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setFakeLoading(false)
      setCurrentStepIndex((prev) => prev + 1)
    }, FAKE_LOADING_TOTAL_TIME)
  }, [])

  const goToNextStep = () => {
    setCurrentStepIndex((prev) => prev + 1)
  }

  const goToPreviousStep = () => {
    if (currentStepIndex === 0) {
      return
    }

    setCurrentStepIndex((prev) => prev - 1)
  }

  const stepConfig = useMemo(() => {
    return StepsConfig[currentStepIndex]
  }, [currentStepIndex])

  const showBackButton = currentStepIndex > 0 && currentStepIndex < StepsConfig.length - 1

  return (
    <WeightLossExperienceContext.Provider
      value={{
        currentUser: currentUser as User,
        isLoading: fakeLoading,
        goToNextStep,
        goToPreviousStep,
        currentStepIndex,
        fakeLoadingTotalTime: FAKE_LOADING_TOTAL_TIME,
        showBackButton,
        stepConfig,
      }}
    >
      {children}
    </WeightLossExperienceContext.Provider>
  )
}

const useWeightLossExperience = (): ContextData => {
  const context = useContext(WeightLossExperienceContext)

  return context
}

export { WeightLossExperienceProvider, useWeightLossExperience }
