import { LinkingContext, useNavigation, useRoute } from '@react-navigation/native'
import { createStackNavigator, StackNavigationProp } from '@react-navigation/stack'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import {
  QuestionnaireStackParamList,
  QuestionnaireScreenName,
  AppRouteProp,
  RootStackParamList,
} from '@src/navigation/types'
import {
  Link,
  Maybe,
  Outcome,
  SurveysQuestionKey,
  Survey,
  SurveysConfigKind,
  SurveyLinkCollection,
  SurveysUserLinkState,
  UiIcon,
  SurveyLink,
  SurveyLinkConfigItem,
  SurveyLinkConfigQuestionItem,
  SurveyConfigItem,
} from '@src/types'
import { useSnack } from '@src/utils/navigatorContext'
import { BREAKING_OUTCOME } from '@src/models/app.reducers'
import { useDispatchAsync } from '@src/utils'
import { fadeFromBottomModalOptions } from '@src/navigation/utils'
import { CompleteSurveyMutation } from '@src/graphql/completeSurvey.generated'
import { Analytics, CustomEventTypes } from '@src/config'
import { OutlinedIcon, useActionBottomSheet } from '@src/components'
import { UI_ICONS_MAPPING } from '@src/components/base'
import { getDeepLinkRoute } from '@src/utils/openUrl'
import { SaveSurveyResponseMutation } from '@src/graphql/saveSurveyResponse.generated'
import { useQuestionnaireState, useCloseSurvey } from './hooks'
import { QuestionnaireCompletedScreen } from './screens/QuestionnaireCompletedScreen'
import { QuestionnaireQuestionScreen } from './screens/QuestionnaireQuestionScreen'
import { QuestionnaireSectionStartScreen } from './screens/QuestionnaireSectionStartScreen'
import { GoalsQuestionnaireStartScreen } from './screens/GoalsQuestionnaireStartScreen'
import { QuestionnaireLoadingScreen } from './screens/QuestionnaireLoadingScreen'
import { HealthQuestionnaireRejectionScreen } from './screens/HealthQuestionnaireRejectionScreen'
import { HealthQuestionnaireWarningModal } from './screens/HealthQuestionnaireWarningModal'
import { QuestionnaireRejectionScreen } from './screens/QuestionnaireRejectionScreen'
import { InsuranceQuestionnaireCompletedScreen } from './screens/InsuranceQuestionnaireCompletedScreen'
import { InsuranceQuestionnaireStartScreen } from './screens/InsuranceQuestionnaireStartScreen'
import { WeightLossExperienceDesiredWeightStartScreen } from './screens/WeightLossExperienceDesiredWeightStartScreen'
import { getInlineFollowups } from './utils'

interface QuestionnaireStartScreenProps {
  questionnaireKind: SurveysConfigKind
  onStartButtonPress: () => void
  onCloseButtonPress: () => void
}

interface QuestionnaireSectionStartScreenProps {
  currentIntroScreenIndex: Maybe<number> | undefined
  sectionsCount: number
  findItemByIndex: (index: number) => SurveyLinkConfigItem
  onContinueButtonPress: (shouldIncrementItemIndex?: boolean) => void
  onCloseButtonPress: () => void
  onCompleteLaterButtonPress: () => void
}

interface QuestionnaireQuestionScreenProps {
  getQuestionsCount: () => number
  findItemByIndex: (index: number) => SurveyLinkConfigItem
  getAnsweredQuestionsCount: (index: number) => number
  onNextButtonPress: (confirmAnswer?: boolean) => void
  onBackButtonPress: () => void
  questionnaireForm: UseFormReturn
  onCloseButtonPress: () => void
  header?: string | null
  showProgressIndicator?: boolean
  breakingOutcome?: string
  onAnswerChanged?: () => void
  questionnaireKind: SurveysConfigKind
}

type QuestionnaireCompletedScreenProps = {
  onCloseButtonPress: () => void
  header: string
  intro: string
  icon?: UiIcon
  title?: Maybe<string>
  primaryActionText?: Maybe<string>
  footnote?: Maybe<string>
  secondaryActionLink?: Maybe<Link>
}

type QuestionnaireRejectedScreenProps = {
  onCloseButtonPress: () => void
}

interface QuestionnaireContextType {
  allowEarlyExit?: boolean
  showAlertOnClose?: boolean
  currentItemIndex: number
  findItemByIndex: (index: number) => SurveyLinkConfigItem
  startScreenConfig: QuestionnaireStartScreenProps
  sectionStartScreenConfig: QuestionnaireSectionStartScreenProps
  questionScreenConfig: QuestionnaireQuestionScreenProps
  completedScreenConfig: QuestionnaireCompletedScreenProps
  rejectedScreenConfig: QuestionnaireRejectedScreenProps
}

export const QuestionnaireContext = createContext<QuestionnaireContextType>(
  {} as QuestionnaireContextType,
)

const QuestionnaireStack = createStackNavigator<QuestionnaireStackParamList>()

export const QuestionnaireNavigator = () => {
  const dispatch = useDispatch()
  const dispatchAsync = useDispatchAsync()
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()
  const linking = useContext(LinkingContext)

  const showSnack = useSnack()
  const loadingRef = useRef(false)
  const showActionBottomSheet = useActionBottomSheet()
  const close = useCloseSurvey()

  const { params } = useRoute<AppRouteProp<'Questionnaire'>>()
  const {
    questionnaire: kind,
    nextScreen,
    allowEarlyExit = false,
    showEligibilitySuccessNote = false,
    showCompletedScreen = true,
    skipIntroScreen = false,
  } = params

  const [breakingOutcome, setBreakingOutcome] = useState<string>()

  const onAnswerChanged = useCallback(() => {
    setBreakingOutcome(undefined)
  }, [])

  const replaceWithScreen = (
    screenName: QuestionnaireScreenName,
    params: Readonly<object | undefined>,
  ) =>
    navigation.reset({
      key: screenName,
      routes: [{ name: screenName, params }],
    })

  const questionnaireState = useQuestionnaireState(kind)

  const initialRouteName = useMemo(() => {
    if (!('isLastQuestion' in questionnaireState)) {
      return undefined
    }

    if (!skipIntroScreen) {
      if (kind === SurveysConfigKind.Goals) {
        return QuestionnaireScreenName.GoalsQuestionnaireStart
      }
      if (kind === SurveysConfigKind.Insurance) {
        return QuestionnaireScreenName.InsuranceQuestionnaireStart
      }
      if (kind === SurveysConfigKind.WeightLossExperienceDesiredWeight) {
        return QuestionnaireScreenName.WeightLossExperienceDesiredWeightStart
      }
      if (questionnaireState.hasIntroScreen) {
        return QuestionnaireScreenName.QuestionnaireSectionStart
      }
    }

    return QuestionnaireScreenName.QuestionnaireQuestion
  }, [kind, questionnaireState, skipIntroScreen])

  const surveyLinkIsFinished =
    'surveyLink' in questionnaireState && questionnaireState.surveyLink?.finished
  const allowMultipleSubmissions =
    'surveyLink' in questionnaireState &&
    questionnaireState.surveyLink?.survey.behavior.allowMultipleSubmissions

  const surveyCanBeFilled = !surveyLinkIsFinished || allowMultipleSubmissions

  useEffect(() => {
    if (surveyCanBeFilled) {
      return
    }
    if (!nextScreen) {
      return navigation.goBack()
    }
    navigation.replace(nextScreen.screen, nextScreen.params)
  }, [navigation, nextScreen, surveyCanBeFilled])

  if ('loading' in questionnaireState || 'error' in questionnaireState || !surveyCanBeFilled) {
    return (
      <QuestionnaireStack.Navigator
        initialRouteName={QuestionnaireScreenName.QuestionnaireLoading}
        screenOptions={{
          headerShown: false,
        }}
        key={'loading' in questionnaireState ? 'loading' : 'error'}
      >
        <QuestionnaireStack.Screen
          name={QuestionnaireScreenName.InsuranceQuestionnaireCompleted}
          component={InsuranceQuestionnaireCompletedScreen}
        />
        <QuestionnaireStack.Screen
          name={QuestionnaireScreenName.QuestionnaireLoading}
          component={QuestionnaireLoadingScreen}
          initialParams={{
            error: 'error' in questionnaireState,
            errorMessage: 'message' in questionnaireState ? questionnaireState.message : undefined,
          }}
        />
        <QuestionnaireStack.Screen
          name={QuestionnaireScreenName.HealthQuestionnaireRejectionReason}
          component={HealthQuestionnaireRejectionScreen}
        />
      </QuestionnaireStack.Navigator>
    )
  }

  const {
    currentItemIndex,
    setCurrentItemIndex,
    hasIntroScreen,
    currentIntroScreenIndex,
    getQuestionsCount,
    getAnsweredQuestionsCount,
    questionnaireForm,
    surveyLink,
    loadSurveyLink,
    isLastQuestion,
    findItemByIndex,
    getSectionsCount,
    updateConfig,
  } = questionnaireState

  const completeSurvey = async () => {
    let response: CompleteSurveyMutation

    try {
      response = await dispatchAsync({
        type: 'app/completeSurvey',
        payload: {
          id: surveyLink?.id,
        },
      })
    } catch {
      showSnack('Failed to save survey', null, 'error')
      return
    }

    const survey = response.completeSurvey.survey as Survey
    const outcome = response.completeSurvey.outcome as Outcome | undefined
    const completionDeepLink = response.completeSurvey.completionDeepLink

    dispatch({
      type: 'users/fetch',
    })

    const deepLinkRoute = completionDeepLink
      ? getDeepLinkRoute(completionDeepLink, linking.options?.config)
      : undefined

    const deepLinkRouteParams = { ...nextScreen?.params, ...deepLinkRoute?.params }

    if (kind === SurveysConfigKind.OwnSensor && !!outcome) {
      setBreakingOutcome(outcome.reason.message.content)
      loadSurveyLink()
    } else if (nextScreen?.screen) {
      navigation.replace(nextScreen.screen, nextScreen.params)
    } else if (outcome) {
      navigation.replace(QuestionnaireScreenName.QuestionnaireRejection, {
        outcome,
        nextScreen: deepLinkRoute?.name,
        nextScreenParams: deepLinkRouteParams,
      })
    } else if (survey.completedScreen && showCompletedScreen) {
      if (kind === SurveysConfigKind.Insurance) {
        navigation.replace(QuestionnaireScreenName.InsuranceQuestionnaireCompleted, {
          nextScreen: deepLinkRoute?.name,
          nextScreenParams: deepLinkRouteParams,
        })
        return
      }

      navigation.replace(QuestionnaireScreenName.QuestionnaireCompleted, {
        nextScreen: deepLinkRoute?.name,
        nextScreenParams: deepLinkRouteParams,
      })
    } else if (deepLinkRoute) {
      navigation.replace(deepLinkRoute.name as any, deepLinkRouteParams)
    } else {
      if (survey.completionFlashMessage) {
        showSnack(survey.completionFlashMessage)
      }
      close({ navigateToDashboard: true })
    }
  }

  const onQuestionnaireStartButtonPress = () => {
    if (hasIntroScreen) {
      navigation.navigate(QuestionnaireScreenName.QuestionnaireSectionStart, {
        currentItemIndex: currentIntroScreenIndex,
        shouldIncrementItemIndex: false,
      })

      return
    }

    navigation.navigate(QuestionnaireScreenName.QuestionnaireQuestion, { currentItemIndex })
  }

  /**
    If the user begins answering a survey that has already started,
    we always want to display the intro screen of the section before showing the current item.

    In this case, we should NOT increment the item index,
    as the back-end will have returned the correct current item index.

    During survey fulfillment, we should ALWAYS increment the item index
    to ensure we correctly display the next item.
  */
  const onSectionContinueButtonPress = (shouldIncrementItemIndex = true) => {
    let itemIndex = currentItemIndex

    if (shouldIncrementItemIndex) {
      itemIndex += 1
    }

    navigation.navigate(QuestionnaireScreenName.QuestionnaireQuestion, {
      currentItemIndex: itemIndex,
    })

    setCurrentItemIndex(itemIndex)
  }

  const getAllAnswersOnScreen = () => {
    const question = findItemByIndex(currentItemIndex) as SurveyLinkConfigQuestionItem
    const allAnswers = questionnaireForm.getValues()
    const inlineFollowupsSurveysQuestionKey = getInlineFollowups(question).map(
      (followup) => followup.question.key,
    )

    const SurveysQuestionKeyOnScreenSet = new Set([
      question.key,
      ...inlineFollowupsSurveysQuestionKey,
    ])

    const answersOnScreen: [string, any][] = Object.keys(allAnswers).flatMap((key) => {
      return SurveysQuestionKeyOnScreenSet.has(key as SurveysQuestionKey)
        ? [[key, typeof allAnswers[key] === 'undefined' ? '' : allAnswers[key]]]
        : []
    })

    return answersOnScreen
  }

  const onQuestionNextButtonPress = (confirmAnswer?: boolean) => {
    if (loadingRef.current) {
      return
    }

    loadingRef.current = true
    const saveSurveyResponsePromises: Promise<unknown>[] = []
    const answersOnScreen = getAllAnswersOnScreen()
    const warnBeforeStopping =
      confirmAnswer !== undefined ? !confirmAnswer : surveyLink?.survey.behavior.warnBeforeStopping

    const onFailure = (e: any) => {
      if (typeof e === 'object' && e.message === BREAKING_OUTCOME) {
        dispatch({ type: 'users/fetch' })
        dispatch({
          type: 'app/surveyQuestionnaire',
          success: ({ surveyLinks }: SurveyLinkCollection) => {
            const stoppedSurveyLink = surveyLinks.find(
              (link) =>
                link.state === SurveysUserLinkState.Stopped &&
                link.id === surveyLink?.id &&
                link.outcome,
            )

            if (stoppedSurveyLink?.survey.kind === SurveysConfigKind.Health) {
              navigation.reset({
                index: 0,
                routes: [
                  {
                    name: QuestionnaireScreenName.HealthQuestionnaireRejectionReason,
                    params: { outcome: stoppedSurveyLink.outcome },
                  },
                ],
              })
            } else if (stoppedSurveyLink?.outcome) {
              const deepLinkRoute = stoppedSurveyLink.completionDeepLink
                ? getDeepLinkRoute(stoppedSurveyLink.completionDeepLink, linking.options?.config)
                : undefined

              const deepLinkRouteParams = { ...nextScreen?.params, ...deepLinkRoute?.params }

              navigation.replace(QuestionnaireScreenName.QuestionnaireRejection, {
                outcome: stoppedSurveyLink.outcome,
                nextScreen: deepLinkRoute?.name,
                nextScreenParams: deepLinkRouteParams,
              })
            } else if (warnBeforeStopping) {
              navigation.navigate(QuestionnaireScreenName.HealthQuestionnaireWarningModal)
            }
          },
        })
      }
    }

    let saveSurveyResponseSuccessCallback:
      | ((response: SaveSurveyResponseMutation['saveSurveyResponse']) => void)
      | undefined = undefined

    answersOnScreen.forEach(([key, value], index) => {
      const answer = (value !== null && typeof value === 'object' && !Array.isArray(value)
        ? value
        : { value }) as {
        value: any | any[]
      }

      if (index === answersOnScreen.length - 1) {
        saveSurveyResponseSuccessCallback = async (
          response: SaveSurveyResponseMutation['saveSurveyResponse'],
        ) => {
          updateConfig({
            items: response.surveyLink.config.items as SurveyLinkConfigItem[],
            questionsCount: response.surveyLink.config.questionsCount,
          })

          if (isLastQuestion()) {
            await completeSurvey()
            return
          }

          const nextItem = findItemByIndex(currentItemIndex + 1)

          const newItemIndex = currentItemIndex + 1

          if (nextItem.type === SurveyConfigItem.IntroScreen) {
            replaceWithScreen(QuestionnaireScreenName.QuestionnaireSectionStart, {
              currentItemIndex: newItemIndex,
              shouldIncrementItemIndex: true, // User is fulfilling the survey, so we should increment the item index.
            })
          }

          if (nextItem.type === SurveyConfigItem.Question) {
            navigation.navigate(QuestionnaireScreenName.QuestionnaireQuestion, {
              currentItemIndex: newItemIndex,
            })
          }

          setCurrentItemIndex(newItemIndex)
        }
      }

      saveSurveyResponsePromises.push(
        dispatchAsync({
          type: 'app/saveSurveyResponse',
          payload: {
            surveyLinkId: surveyLink?.id,
            questionKey: key,
            answer,
            warnBeforeStopping,
          },
        }),
      )

      if (surveyLink?.survey.behavior.trackResponses) {
        Analytics.trackSurveyResponse({ surveyKind: kind, questionKey: key, answer: answer.value })
      }
    })

    Promise.all(saveSurveyResponsePromises)
      .then((responses) => responses[responses.length - 1])
      .then(saveSurveyResponseSuccessCallback)
      .catch(onFailure)
      .finally(() => {
        loadingRef.current = false
      })
  }

  const onQuestionBackButtonPress = () => {
    if (loadingRef.current) {
      return
    }

    if (currentItemIndex === 0) {
      navigation.goBack()
      return
    }

    const previousItem = findItemByIndex(currentItemIndex - 1)

    let newItemIndex = currentItemIndex - 1

    if (previousItem.type === SurveyConfigItem.IntroScreen) {
      newItemIndex -= 1
    }

    navigation.navigate(QuestionnaireScreenName.QuestionnaireQuestion, {
      currentItemIndex: newItemIndex,
    })
    setCurrentItemIndex(newItemIndex)
  }

  const exitEarly = (surveyLink: SurveyLink | null) => {
    if (loadingRef.current) {
      return
    }

    loadingRef.current = true

    if (!surveyLink) {
      return close({ navigateToDashboard: true })
    }

    const deepLinkRoute = surveyLink.earlyExitDeepLink
      ? getDeepLinkRoute(surveyLink.earlyExitDeepLink, linking.options?.config)
      : undefined

    Analytics.track(CustomEventTypes.SurveyEarlyExitConfirmed, {
      surveyKind: kind,
      itemIndex: currentItemIndex,
      questionKey: (findItemByIndex(currentItemIndex) as SurveyLinkConfigQuestionItem).key,
      introScreen: false,
    })

    if (!deepLinkRoute || !deepLinkRoute.name) {
      return close({ navigateToDashboard: true })
    }

    navigation.replace(deepLinkRoute.name as any, deepLinkRoute.params)
  }

  const onCloseButtonPress = () => {
    if (!surveyLink?.survey.behavior.showAlertOnClose) {
      return exitEarly(surveyLink)
    }

    Analytics.track(CustomEventTypes.SurveyEarlyExitAlert, {
      surveyKind: kind,
      itemIndex: currentItemIndex,
      questionKey: (findItemByIndex(currentItemIndex) as SurveyLinkConfigQuestionItem).key,
      introScreen: false,
    })

    const {
      title = 'Don’t have time?',
      body = "No worries, we'll save your progress and you can complete the questionnaire later.",
      icon,
      confirmTitle = 'OK',
      cancelTitle,
    } = surveyLink.survey.showAlertOnCloseBehavior || {}

    const onConfirm = () => exitEarly(surveyLink)
    const onCancel = () => {
      // The modal will auto-dismiss, so we don't need to explicitly close/navigate in any way
      Analytics.track(CustomEventTypes.SurveyEarlyExitCanceled, {
        surveyKind: kind,
        itemIndex: currentItemIndex,
        questionKey: (findItemByIndex(currentItemIndex) as SurveyLinkConfigQuestionItem).key,
        introScreen: false,
      })
    }

    const primaryButton = {
      text: confirmTitle,
      onPress: onConfirm,
    }

    const secondaryButton = cancelTitle ? { text: cancelTitle, onPress: onCancel } : undefined

    showActionBottomSheet({
      title,
      body,
      icon: icon && <OutlinedIcon iconName={UI_ICONS_MAPPING[icon]} />,
      primaryButton,
      secondaryButton,
    })
  }

  const onCompleteLaterButtonPress = () => {
    Analytics.track(CustomEventTypes.SurveyEarlyExitConfirmed, {
      surveyKind: kind,
      itemIndex: currentItemIndex,
      introScreen: true,
    })
    close({ navigateToDashboard: true })
  }

  return (
    <QuestionnaireContext.Provider
      value={{
        allowEarlyExit: surveyLink?.survey.behavior.allowEarlyExit || allowEarlyExit,
        showAlertOnClose: surveyLink?.survey.behavior.showAlertOnClose,
        currentItemIndex,
        findItemByIndex,
        startScreenConfig: {
          onStartButtonPress: onQuestionnaireStartButtonPress,
          questionnaireKind: kind,
          onCloseButtonPress: () => {
            if (kind === SurveysConfigKind.Insurance) {
              close()
              return
            }

            onCloseButtonPress()
          },
        },
        sectionStartScreenConfig: {
          onContinueButtonPress: onSectionContinueButtonPress,
          currentIntroScreenIndex,
          sectionsCount: getSectionsCount(),
          findItemByIndex,
          onCloseButtonPress,
          onCompleteLaterButtonPress,
        },
        questionScreenConfig: {
          findItemByIndex,
          getQuestionsCount,
          getAnsweredQuestionsCount,
          onNextButtonPress: onQuestionNextButtonPress,
          onBackButtonPress: onQuestionBackButtonPress,
          questionnaireForm,
          header: surveyLink?.survey.header,
          showProgressIndicator: surveyLink?.survey.behavior.showProgressIndicator,
          breakingOutcome,
          onCloseButtonPress,
          questionnaireKind: kind,
          onAnswerChanged,
        },
        completedScreenConfig: {
          onCloseButtonPress: () => {
            close({ navigateToDashboard: true })
          },
          title: surveyLink?.survey.completedScreen?.title,
          header: surveyLink?.survey.completedScreen?.header || '',
          intro: surveyLink?.survey.completedScreen?.intro || '',
          icon: surveyLink?.survey.completedScreen?.icon,
          primaryActionText: surveyLink?.survey.completedScreen?.primaryActionText,
          footnote: surveyLink?.survey.completedScreen?.footnote,
          secondaryActionLink: surveyLink?.survey.completedScreen?.secondaryActionLink,
        },
        rejectedScreenConfig: {
          onCloseButtonPress: () => {
            close({ navigateToDashboard: true })
          },
        },
      }}
    >
      <QuestionnaireStack.Navigator
        initialRouteName={initialRouteName}
        screenOptions={{
          headerShown: false,
        }}
      >
        <QuestionnaireStack.Screen
          name={QuestionnaireScreenName.InsuranceQuestionnaireCompleted}
          component={InsuranceQuestionnaireCompletedScreen}
        />
        <QuestionnaireStack.Screen
          name={QuestionnaireScreenName.InsuranceQuestionnaireStart}
          component={InsuranceQuestionnaireStartScreen}
          initialParams={{ allowEarlyExit, showEligibilitySuccessNote }}
        />
        <QuestionnaireStack.Screen
          name={QuestionnaireScreenName.WeightLossExperienceDesiredWeightStart}
          component={WeightLossExperienceDesiredWeightStartScreen}
        />
        <QuestionnaireStack.Screen
          name={QuestionnaireScreenName.QuestionnaireSectionStart}
          component={QuestionnaireSectionStartScreen}
          initialParams={{
            currentItemIndex: currentIntroScreenIndex,
            shouldIncrementItemIndex: false,
          }}
        />
        <QuestionnaireStack.Screen
          name={QuestionnaireScreenName.QuestionnaireQuestion}
          component={QuestionnaireQuestionScreen}
          initialParams={{ currentItemIndex }}
        />
        <QuestionnaireStack.Screen
          name={QuestionnaireScreenName.GoalsQuestionnaireStart}
          component={GoalsQuestionnaireStartScreen}
        />
        <QuestionnaireStack.Screen
          name={QuestionnaireScreenName.QuestionnaireCompleted}
          component={QuestionnaireCompletedScreen}
        />
        <QuestionnaireStack.Screen
          name={QuestionnaireScreenName.QuestionnaireRejection}
          component={QuestionnaireRejectionScreen}
        />
        <QuestionnaireStack.Screen
          name={QuestionnaireScreenName.HealthQuestionnaireRejectionReason}
          component={HealthQuestionnaireRejectionScreen}
        />
        <QuestionnaireStack.Group screenOptions={fadeFromBottomModalOptions}>
          <QuestionnaireStack.Screen
            name={QuestionnaireScreenName.HealthQuestionnaireWarningModal}
            component={HealthQuestionnaireWarningModal}
          />
        </QuestionnaireStack.Group>
      </QuestionnaireStack.Navigator>
    </QuestionnaireContext.Provider>
  )
}
