import React, { useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import Animated from 'react-native-reanimated'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button } from '../base/Button'
import { Icon } from '../base/Icon'
import { Text } from '../base'

type PercentageLoadingButtonProps = {
  loading: boolean
  loadingTotalTime: number
  buttonText: string
  onClick: () => void
}

export const PercentageLoadingButton = (props: PercentageLoadingButtonProps) => {
  const { loading, loadingTotalTime, buttonText, onClick } = props

  const styles = useStyleSheet(themedStyles)
  const theme = useTheme()

  const [percentage, setPercentage] = useState(0)

  const startTimeRef = useRef<number | null>(null)
  const animationFrameRef = useRef<number | null>(null)

  useEffect(() => {
    if (!loading) {
      return
    }

    const updateProgress = (timestamp: number) => {
      if (startTimeRef.current === null) {
        startTimeRef.current = timestamp
      }

      const elapsed = timestamp - startTimeRef.current
      const progress = Math.min(elapsed / loadingTotalTime, 1)
      const newPercentage = Math.floor(progress * 100)

      setPercentage(newPercentage)

      if (progress < 1) {
        animationFrameRef.current = requestAnimationFrame(updateProgress)
      } else {
        startTimeRef.current = null
      }
    }

    animationFrameRef.current = requestAnimationFrame(updateProgress)

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current)
      }
    }
  }, [loading, loadingTotalTime])

  if (loading) {
    return (
      <View style={styles.container}>
        <Animated.View
          style={[
            styles.progressBar,
            {
              width: `${percentage}%`,
              borderTopRightRadius: percentage === 100 ? 40 : 0,
              borderBottomRightRadius: percentage === 100 ? 40 : 0,
            },
          ]}
        />
        <Text
          type="regular"
          style={[
            styles.progressText,
            { color: percentage < 50 ? theme['theme.text.primary'] : theme['theme.solid.white'] },
          ]}
        >{`${percentage}%`}</Text>
      </View>
    )
  }

  return (
    <Button
      type="primary"
      size="block"
      accessoryRight={<Icon name="arrow-right" />}
      accessibilityLabel="Continue"
      onPress={onClick}
    >
      {buttonText}
    </Button>
  )
}

const themedStyles = StyleService.create({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: 'theme.surface.base2',
    borderRadius: 40,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    backgroundColor: 'theme.primary.base',
    transition: 'width 0.05s linear',
    borderRadius: '40px 0 0 40px',
  },
  progressText: {
    fontWeight: 700,
    fontSize: 18,
  },
})
