import { get } from 'lodash'
import { create } from 'dva-core'
import createLoading from 'dva-loading'
import { Reducer } from 'redux'
import { Dictionary } from '@stripe/stripe-react-native'
import { RootStoreState } from '@models/app.types'
import models from './models'
import loadingOpts from './loadingOpts'
import persistEnhancer from './persist'
import batchEnhancer from './batchEnhancer'

const dva = (hooksAndOpts: Dictionary<any>) => {
  const dvaApp = create(hooksAndOpts)

  // HMR workaround
  if (!dvaApp._models.length < hooksAndOpts.models.length) {
    hooksAndOpts.models.forEach((model: any) => dvaApp.model(model))
  }

  return dvaApp
}

const initialState = Object.fromEntries(
  Object.entries(models).map(([key, model]) => [key, model.state]),
) as RootStoreState

const loadingPlugin = createLoading(loadingOpts)

const createDvaStore = () => {
  const dvaApp = dva({
    initialState,
    models: Object.values(models),
    extraEnhancers: [persistEnhancer, batchEnhancer],
    extraReducers: loadingPlugin.extraReducers,
    onEffect: loadingPlugin.onEffect,
    onReducer: (reducer: Reducer<any>) => {
      return (state: any, action: any) => {
        const selectGlobal = (path: string) => get(state, path)

        if (action.type === 'reset') {
          return initialState
        }

        return reducer(state, {
          ...action,
          selectGlobal,
        })
      }
    },
    onError: (e: string) => {
      console.log('dvaError', e)
    },
  })

  dvaApp.start()

  return dvaApp._store
}

export default createDvaStore
