import { useSelector } from 'react-redux'
import { skippedSurveysSelector, userSelector } from '@src/selectors/app'
import { SurveysConfigKind, UserApprovalRequestState } from '@src/types'

export enum SteloFlowStep {
  NotApplicable,
  EligibilitySurvey,
  Skipped,
  Rejected,
  PendingApproval,
  Complete,
}

export const useSteloFlowStep = (): SteloFlowStep => {
  const user = useSelector(userSelector)
  const skippedSurveys = useSelector(skippedSurveysSelector)

  if (skippedSurveys[SurveysConfigKind.SteloEligibility]) {
    return SteloFlowStep.Skipped
  }

  if (!user?.lastSteloApprovalRequest) {
    return SteloFlowStep.NotApplicable
  }

  switch (user.lastSteloApprovalRequest.state) {
    case UserApprovalRequestState.Init:
    case UserApprovalRequestState.PendingMember:
    case UserApprovalRequestState.PendingUpdate:
      return SteloFlowStep.EligibilitySurvey
    case UserApprovalRequestState.DidNotComplete:
    case UserApprovalRequestState.Rejected:
    case UserApprovalRequestState.ConsentRejected: // not used today
      return SteloFlowStep.Rejected
    // The following two are not used today, but we should handle them just in case
    case UserApprovalRequestState.PendingRejection:
    case UserApprovalRequestState.PendingApproval:
      return SteloFlowStep.PendingApproval
    case UserApprovalRequestState.Approved:
      return SteloFlowStep.Complete
    case UserApprovalRequestState.Stale:
    case UserApprovalRequestState.ConsentWithdrawn: // not used today
    default:
      return SteloFlowStep.NotApplicable
  }
}
