import React from 'react'
import { ScrollView, View, Image, ImageStyle as RNImageStyle } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button, Icon } from '@components/base'
import { topPriorityCoachAssignmentSelector } from '@src/screens/NutritionistHub/models/nutritionistHub.selectors'
import { CoachProfile, SurveysConfigKind } from '@src/types'
import { weightLossExperienceCompletedImage } from '@src/assets/images'
import { userPropertiesSelector } from '@src/selectors/app'
import { NutritionistBubble } from '@src/components/NutritionistBubble'
import { AppStackParamList } from '@src/navigation/types'
import { Feature, useFeatureFlag } from '@src/components'

export const WeightLossExperienceDesiredWeightCompletedScreen = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const insets = useSafeAreaInsets()
  // 50% of users will see the long survey and the other half the short version with just one question
  const weightLossExperienceLongSurveyEnabled = useFeatureFlag(
    Feature.WeightLossExperienceLongSurvey,
  )
  const coachAssignment = useSelector(topPriorityCoachAssignmentSelector)
  const { weightLossExperience } = useSelector(userPropertiesSelector) ?? {}

  const nutritionist = coachAssignment?.coach

  if (!nutritionist || !weightLossExperience) {
    return null
  }
  const parsedWeightLossExperienceProperty = JSON.parse(weightLossExperience?.value || '{}')

  const { photo } = nutritionist?.coachProfile || ({} as CoachProfile)

  const onContinuePress = () => {
    const surveyToRedirect = weightLossExperienceLongSurveyEnabled
      ? SurveysConfigKind.WeightLossExperienceGoalsAndHabits
      : SurveysConfigKind.WeightLossExperiencePace

    navigation.replace('Questionnaire', {
      questionnaire: surveyToRedirect,
    })
  }

  return (
    <View style={styles.topLevelContainer}>
      <ScrollView
        bounces={false}
        contentContainerStyle={[styles.container, { paddingBottom: Math.max(insets.bottom, 16) }]}
      >
        <View style={{ alignItems: 'center' }}>
          <Text type="title-1" style={styles.title}>
            Nutrisense creates long-term results
          </Text>

          <Image
            resizeMethod="resize"
            source={weightLossExperienceCompletedImage.imageSource}
            style={styles.image as RNImageStyle}
          />

          {parsedWeightLossExperienceProperty.realistic ? (
            <Text type="regular" style={styles.losingText}>
              Losing{' '}
              <Text type="regular" style={styles.desiredWeightLoss}>
                {parsedWeightLossExperienceProperty.desired_weight_loss_amount}
              </Text>{' '}
              lbs is a realistic target!
            </Text>
          ) : (
            <Text type="regular" style={styles.losingText}>
              Losing{' '}
              <Text type="regular" style={styles.desiredWeightLoss}>
                {parsedWeightLossExperienceProperty.desired_weight_loss_amount}
              </Text>{' '}
              will be challenging but we&apos;ll tackle it together!
            </Text>
          )}

          <NutritionistBubble
            photoSrc={photo}
            label="Let's personalize your weight loss journey together!"
          />
        </View>

        <Button
          type="primary"
          size="block"
          onPress={onContinuePress}
          accessibilityLabel="Complete the questionnaire"
          accessoryRight={<Icon name="arrow-right" />}
        >
          Got it!
        </Button>
      </ScrollView>
    </View>
  )
}

const themedStyles = StyleService.create({
  topLevelContainer: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  container: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingTop: 60,
    paddingBottom: 16,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    maxWidth: 210,
    lineHeight: 32,
    marginBottom: 24,
  },
  image: {
    maxWidth: 308,
    height: undefined,
    maxHeight: 164,
    alignSelf: 'center',
    aspectRatio: 3 / 1,
  },
  losingText: {
    fontWeight: 700,
    marginVertical: 32,
  },
  desiredWeightLoss: {
    color: 'theme.success.base',
    fontWeight: 700,
    fontSize: 24,
  },
  timeNote: {
    textAlign: 'center',
  },
  buttonsContainer: {
    gap: 16,
    width: '100%',
  },
  spinner: {
    color: 'theme.surface.base',
  },
})
