import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { Divider } from '@ui-kitten/components'
import { NavigationContainer } from '@screens/Common/containers'
import { StyleService, useStyleSheet } from '@style/service.ts'
import { useDispatchAsync } from '@utils'
import { CommonRefreshControl } from '@components'
import { Product, UiPillType } from '@src/types.ts'
import { userSelector } from '@selectors/app.ts'
import {
  useEligibleCgmUpgradeProducts,
  useProducts,
  useSelectedAddonModal,
} from '@screens/Marketplace/utils/hooks.ts'
import { PurchasedProductSummary } from '@screens/Marketplace/components/NewMarketplace/PurchasedProductSummary.tsx'
import {
  ProductSummary,
  ProductSummaryProps,
} from '@screens/Marketplace/components/NewMarketplace/ProductSummary.tsx'
import { Text } from '@components/base'
import {
  cgmSteloImage,
  marketplaceByosImage,
  marketplaceHandWPatchesImage,
  marketplaceIphoneWAppImage,
  marketplaceNNImage,
} from '@assets/images'
import { getSubscriptionTagDescriptor } from '@screens/Marketplace/utils/utils.ts'

export const NewMarketplace = () => {
  const user = useSelector(userSelector)
  const scrollViewRef = useRef<ScrollView>(null)
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const dispatchAsync = useDispatchAsync()
  const navigation = useNavigation()
  const onAddonSelected = useSelectedAddonModal()

  const [refreshing, setRefreshing] = useState(false)

  const refreshingRef = useRef(refreshing)

  const { activeSubscriptions, oneTimeAddons, upcomingSubscriptionPhases, products } = useProducts()

  const coreSubscription = activeSubscriptions.find(
    (sub) => sub.id === user?.lastCoreSubscription?.id,
  )
  const secondarySubscriptions = user?.lastCoreSubscription
    ? activeSubscriptions.filter((s) => !s.primaryProduct.core)
    : []

  const eligibleUpgradeProducts = coreSubscription?.primaryProduct.eligibleUpgradeProducts || []
  const cgmProducts = useEligibleCgmUpgradeProducts(products, eligibleUpgradeProducts)

  const showCgmProducts = cgmProducts.length > 0
  const showByosProducts = eligibleUpgradeProducts.some((product) => product.ownSensor)

  const onVideoCallsPress = () => {
    navigation.navigate('ProductExplanation', { screen: 'VideoCalls' })
  }

  const onCgmProgramsPress = () => {
    navigation.navigate('ProductExplanation', { screen: 'CgmProducts' })
  }

  const onByosPress = () => {
    navigation.navigate('ProductExplanation', { screen: 'OwnSensor' })
  }

  const onRefresh = useCallback(async () => {
    refreshingRef.current = true
    setRefreshing(true)

    try {
      await Promise.all([
        dispatchAsync({ type: 'users/fetch' }),
        dispatchAsync({ type: 'app/fetchProducts' }),
        dispatchAsync({ type: 'marketplace/fetchSubscriptionSchedules' }),
      ])
    } finally {
      refreshingRef.current = false
      setRefreshing(false)
    }
  }, [dispatchAsync])

  useEffect(() => {
    onRefresh()
  }, [onRefresh])

  useFocusEffect(
    useCallback(() => {
      if (refreshingRef.current) {
        return
      }

      dispatchAsync({ type: 'users/fetch' })
    }, [dispatchAsync]),
  )

  const getAddonImageProps = (
    addon: Product,
  ): Pick<ProductSummaryProps, 'image' | 'imageStyle'> => {
    if (addon.patch) {
      return { image: marketplaceHandWPatchesImage }
    }

    if (addon.cgm) {
      return {
        image: cgmSteloImage,
        imageStyle: { alignSelf: 'center', paddingHorizontal: 4 },
      }
    }

    return { image: undefined }
  }

  return (
    <NavigationContainer title="Programs & Add-ons">
      <ScrollView
        ref={scrollViewRef}
        style={styles.container}
        contentContainerStyle={{ paddingBottom: insets.bottom + 16 }}
        refreshControl={<CommonRefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
      >
        {coreSubscription && (
          <PurchasedProductSummary
            subscription={coreSubscription}
            product={coreSubscription.primaryProduct}
            {...getSubscriptionTagDescriptor(coreSubscription)}
          />
        )}

        {secondarySubscriptions.map((subscription) => (
          <PurchasedProductSummary
            key={`subscription-${subscription.id}`}
            subscription={subscription}
            product={subscription.primaryProduct}
            {...getSubscriptionTagDescriptor(subscription)}
          />
        ))}

        {upcomingSubscriptionPhases.map((phase) => (
          <PurchasedProductSummary
            key={`phase-${phase.id}`}
            product={phase.primaryProduct!}
            pillLabel="Scheduled to start"
            pillStatus={UiPillType.Success}
          />
        ))}

        <Divider appearance="large" />

        <View style={styles.sectionContainer}>
          <Text type="title-3">Programs</Text>

          <View style={styles.productsContainer}>
            {showCgmProducts && (
              <ProductSummary
                title="Glucose Programs"
                description="Optimize your health with glucose monitoring and dietitian support."
                image={marketplaceIphoneWAppImage}
                onPress={onCgmProgramsPress}
                imageStyle={{ marginRight: 32 }}
              />
            )}
            <ProductSummary
              title="Nutritionist Video Calls"
              description="In-network, in-the-moment video consultations with expert nutritionists."
              image={marketplaceNNImage}
              onPress={onVideoCallsPress}
            />
            {showByosProducts && (
              <ProductSummary
                title="Bringing My Own CGM"
                description="Activate your own sensor seamlessly with the Nutrisense app."
                image={marketplaceByosImage}
                imageStyle={{ alignSelf: 'center' }}
                onPress={onByosPress}
              />
            )}
          </View>
        </View>

        {oneTimeAddons.length > 0 && (
          <>
            <Divider appearance="large" />

            <View style={styles.sectionContainer}>
              <Text type="title-3">Add-ons</Text>

              <View style={styles.productsContainer}>
                {oneTimeAddons.map((addon) => {
                  return (
                    <ProductSummary
                      key={addon.key}
                      title={addon.title}
                      description={addon.description || ''}
                      onPress={() => onAddonSelected(addon)}
                      {...getAddonImageProps(addon)}
                    />
                  )
                })}
              </View>
            </View>
          </>
        )}
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  sectionContainer: {
    margin: 16,
  },
  productsContainer: {
    gap: 12,
    marginTop: 12,
  },
})
