import { useState, useMemo, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { uniqBy, lowerCase, debounce } from 'lodash'
import moment from 'moment'
import { BaseIngredient } from '@screens/Ingredients/types'
import {
  NORMAL_GRAM,
  normalizeNutrisenseItem,
  normalizeNutritionixItem,
  updateIngredientsData,
} from '@screens/Ingredients/utils'
import { ingredientsSelector } from '@selectors/ingredients'
import { recentMealsSelector } from '@selectors/meals'
import Nutritionix from '@services/Nutritionix'
import { useDebouncedCallback } from '@utils/hooks'
import { useSnack } from '@utils/navigatorContext'
import { Ingredient, MealKind } from '@src/types'

const SEARCH_DELAY = 500

const filterByQuery = <T extends Ingredient | BaseIngredient>(items: T[], query: string) => {
  const trimmedQuery = query.trim()

  return trimmedQuery.length === 0
    ? items
    : items.filter(({ description }) => lowerCase(description).includes(lowerCase(trimmedQuery)))
}

export const useRecentIngredientsForMealType = (mealKind?: MealKind, query = '') => {
  const recentMeals = useSelector(recentMealsSelector)
  const [recentIngredients, setRecentIngredients] = useState<BaseIngredient[]>([])

  const getRecentIngredients = useDebouncedCallback(async () => {
    const meals = mealKind ? recentMeals.filter((meal) => meal.kind === mealKind) : recentMeals

    const recentIngredients = meals
      .sort((a, b) => moment(b.occurredAt).valueOf() - moment(a.occurredAt).valueOf())
      .flatMap((meal) => filterByQuery(meal.ingredients, query))
    const uniqueIngredients = uniqBy(recentIngredients, 'description').map((ingredient) => ({
      ...ingredient,
      // Safeguard against old data (some saved ingredients have servingUnits as 'Grams'/'grams')
      servingUnits:
        ingredient.servingUnits.toLowerCase() === 'grams' ? NORMAL_GRAM : ingredient.servingUnits,
    }))
    const updatedIngredients = await updateIngredientsData(uniqueIngredients)

    setRecentIngredients(updatedIngredients)
  }, SEARCH_DELAY)

  useEffect(() => {
    getRecentIngredients()
  }, [getRecentIngredients, query])

  return recentIngredients
}

export const useIngredientSearch = (query = '') => {
  const showSnack = useSnack()

  const [isLoading, setLoading] = useState(false)
  const [loadedQuery, setLoadedQuery] = useState<string | null>(null)
  const [ingredients, setIngredients] = useState<BaseIngredient[]>([])

  const trimmedQuery = query.trim()

  const fetchIngredients = useDebouncedCallback(async (query: string) => {
    if (!query) {
      setIngredients([])
      return
    }

    setLoading(true)

    try {
      const response = await Nutritionix.searchItems(query)

      if (!response) {
        showSnack('Failed to fetch ingredients', null, 'error')
      } else {
        setIngredients(response.map(normalizeNutritionixItem))
      }
    } finally {
      setLoading(false)
      setLoadedQuery(query)
    }
  }, SEARCH_DELAY)

  useEffect(() => {
    fetchIngredients(trimmedQuery)
  }, [fetchIngredients, trimmedQuery])

  return { ingredients, isLoading: isLoading || trimmedQuery !== loadedQuery }
}

export const useCustomIngredients = (query = '') => {
  const dispatch = useDispatch()
  const customIngredients = useSelector(ingredientsSelector)

  const ingredients = useMemo(() => {
    return filterByQuery(customIngredients, query).map(normalizeNutrisenseItem)
  }, [customIngredients, query])

  useEffect(() => {
    if (customIngredients.length > 0) {
      return
    }

    dispatch({
      type: 'ingredients/fetch',
      payload: {
        pagination: {
          page: 1,
          pageSize: 100,
        },
        filter: {
          order: 'desc',
          orderBy: 'created_at',
        },
      },
    })
  }, [dispatch, customIngredients.length])

  return ingredients
}

export const useDescriptionSuggestions = (query: string) => {
  const [suggestions, setSuggestions] = useState<BaseIngredient[]>([])

  const trimmedQuery = query.trim()

  const loadSuggestions = async (query: string) => {
    if (!query) {
      setSuggestions([])
      return
    }

    try {
      const items = await Nutritionix.searchItemsWithNutrients(query)

      if (items.length > 0) {
        setSuggestions(uniqBy(items.map(normalizeNutritionixItem), 'description'))
      }
    } catch {
      // Do nothing - this endpoint returns an error if query can't be parsed
      // e.g. queries ending with a special character return 500 error
    }
  }

  const debouncedLoadSuggestions = useRef(debounce(loadSuggestions, SEARCH_DELAY)).current

  useEffect(() => {
    debouncedLoadSuggestions(trimmedQuery)
  }, [debouncedLoadSuggestions, trimmedQuery])

  return suggestions
}
