import React from 'react'
import { useTheme } from '@ui-kitten/components'
import { Image, ImageStyle, View } from 'react-native'
import Chart from 'react-native-f2chart'
import { StyleService, useStyleSheet } from '@src/style/service'
import { insightsLineChart } from '@src/components/charts'
import type { InsightsLineChartConfig } from '@src/components/charts/insightsLineChart'
import { CommonChart } from '@src/components'
import { useShowDexcomSteloLogo } from '@src/utils/hooks'
import { poweredBySteloLogoImage } from '@src/assets/images'
import { useIsDarkMode } from '@src/config/theme'

interface LineChartProps extends Omit<InsightsLineChartConfig, 'style'> {
  chartRef: React.MutableRefObject<Chart | null>
  height?: number
  isAnimationDisabled?: boolean
  isScanDisabled?: boolean
  onLoad?: () => void
}

export const LineChart = ({
  chartRef,
  data,
  height = 180,
  ranges,
  yLabel,
  yTicks,
  yMin,
  yMax,
  isAnimationDisabled,
  isScanDisabled,
  onLoad,
}: LineChartProps) => {
  const theme = useTheme()
  const styles = useStyleSheet(themedStyle)
  const showLogo = useShowDexcomSteloLogo()
  const isDarkMode = useIsDarkMode()
  const logoComponent = (
    <View style={styles.logoContainer}>
      <Image
        source={poweredBySteloLogoImage(isDarkMode).imageSource}
        style={styles.logoStyle as ImageStyle}
      />
    </View>
  )

  // make sure we're not displaying a value outside of the allowed range
  // so points do not appear outside of the chart
  const adjustedData = data.map((item) => {
    let y = item.y

    if (!y || !yMin || !yMax) {
      return item
    }

    if (y < yMin) {
      y = yMin
    }

    if (y > yMax) {
      y = yMax
    }

    return { ...item, y }
  })

  // make sure we're not displaying a range overlay outside of the allowed range
  // so overlay doesn't appear outside of the chart
  const adjustedRanges = ranges
    .filter((range) => {
      if (!yMin || !yMax) {
        return true
      }

      return (!range.to || range.to >= yMin) && (!range.from || range.from <= yMax)
    })
    .map((range) => {
      let from = range.from
      let to = range.to
      if (from && yMin && from < yMin) {
        from = yMin
      }
      if (to && yMax && to > yMax) {
        to = yMax
      }
      return { ...range, from, to }
    })

  const chartScript = insightsLineChart({
    data: adjustedData,
    ranges: adjustedRanges,
    yLabel,
    yTicks,
    yMin,
    yMax,
    isAnimationDisabled,
    isScanDisabled,
    style: getChartStyles(theme),
  })

  return (
    <View style={styles.container}>
      {showLogo && logoComponent}
      <CommonChart chartRef={chartRef} chartScript={chartScript} height={height} onLoad={onLoad} />
    </View>
  )
}

const getChartStyles = (theme: Record<string, string>): InsightsLineChartConfig['style'] => ({
  line: {
    good: theme['theme.range.good'],
    ok: theme['theme.range.regular'],
    bad: theme['theme.range.bad'],
    critical: theme['theme.range.bad'],
    default: theme['theme.tooltip.surface'],
  },
  axisLine: theme['theme.surface.base'],
  axisLabel: theme['theme.text.secondary'],
  goodRangeBackground: theme['theme.range.good'],
  tooltipBackground: theme['theme.tooltip.background'],
  tooltipMarkerFill: theme['theme.solid.black'],
  tooltipMarkerStroke: theme['theme.solid.white'],
  tooltipTextColor: theme['theme.tooltip.textPrimary'],
  tooltipDateTextColor: theme['theme.tooltip.textSecondary'],
  noDataTextColor: theme['theme.text.tertiary'],
})

const themedStyle = StyleService.create({
  container: {
    marginTop: -24,
  },
  logoContainer: {
    alignSelf: 'flex-end',
    marginRight: 16,
  },
  logoStyle: {
    width: 104,
    height: 20,
  },
})
