import React from 'react'
import { Image, ImageStyle, StyleProp, TouchableOpacity, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { Feature, InlineAlert, useFeatureFlag } from '@src/components'
import { UiInlineAlert } from '@src/types'
import { NavigationContainer } from '@src/screens/Common/containers'
import { scanImage, shoppingCartPlusImage } from '@src/assets/images'
import { Storage } from '@src/utils'
import { OnboardingFlowType } from '../constants'

export const GettingStarted = () => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const navigation = useNavigation()
  const steloSignUpEnabled = useFeatureFlag(Feature.SteloSignUp)

  const onOwnSensorPress = () => {
    Storage.set(Storage.ONBOARDING_FLOW_TYPE_SELECTED_KEY, OnboardingFlowType.OwnSensor)
    navigation.navigate('OwnSensorSelection')
  }

  const onPurchaseSensorPress = () => {
    if (steloSignUpEnabled) {
      Storage.set(Storage.ONBOARDING_FLOW_TYPE_SELECTED_KEY, OnboardingFlowType.PurchaseStelo)
      navigation.navigate('CreateAccountOptionsStelo')
    } else {
      Storage.set(Storage.ONBOARDING_FLOW_TYPE_SELECTED_KEY, OnboardingFlowType.Purchase)
      navigation.navigate('CreateAccountOptions')
    }
  }

  return (
    <NavigationContainer title="Getting Started" allowBackNavigation={false}>
      <View style={[styles.content, { marginBottom: insets.bottom + 16 }]}>
        <Text type="large" style={styles.centerAlignedText}>
          Are you bringing you own CGM or would like to purchase one?
        </Text>
        <TouchableOpacity
          onPress={onOwnSensorPress}
          accessibilityLabel="Bringing my own CGM"
          style={styles.actionAreaContainer}
        >
          <Image
            source={scanImage.imageSource}
            style={styles.image as StyleProp<ImageStyle>}
            resizeMode="contain"
          />

          <View style={styles.titleTextContainer}>
            <Text type="title-3">Bringing my own CGM</Text>
            <Icon name="caret-right" size="16" weight="bold" style={styles.icon} />
          </View>

          <Text type="regular" style={styles.centerAlignedText}>
            Activate your sensor seamlessly with the Nutrisense app
          </Text>
        </TouchableOpacity>

        <TouchableOpacity
          onPress={onPurchaseSensorPress}
          accessibilityLabel="Purchase a CGM"
          style={styles.actionAreaContainer}
        >
          <Image
            source={shoppingCartPlusImage.imageSource}
            style={styles.image as StyleProp<ImageStyle>}
            resizeMode="contain"
          />

          <View style={styles.titleTextContainer}>
            <Text type="title-3">Purchase a CGM</Text>
            <Icon name="caret-right" size="16" weight="bold" style={styles.icon} />
          </View>

          <Text type="regular" style={styles.centerAlignedText}>
            Acquire our top market sensors and use them in the app
          </Text>
        </TouchableOpacity>

        <InlineAlert
          category={UiInlineAlert.Neutral}
          message="A CGM or Continuous Glucose Monitor is a medical device that continuously 
          tracks glucose levels in real-time."
        />
      </View>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  centerAlignedText: {
    textAlign: 'center',
    marginBottom: 16,
    maxWidth: '80%',
  },
  content: {
    flex: 1,
    margin: 16,
    alignItems: 'center',
  },
  actionAreaContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 40,
    borderColor: 'theme.surface.base1',
    borderWidth: 2,
    marginBottom: 16,
    padding: 8,
    width: '100%',
    gap: 8,
  },
  image: {
    height: 64,
    width: 64,
  },
  titleTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    color: 'theme.text.primary',
  },
})
