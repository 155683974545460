import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button } from '@components/base'
import { Carousel, Feature, useFeatureFlag } from '@components'
import {
  walkthroughAppDarkImage,
  walkthroughAppImage,
  walkthroughAppSteloDarkImage,
  walkthroughAppSteloImage,
  walkthroughEducationDarkImage,
  walkthroughEducationImage,
  walkthroughNutritionistsImage,
} from '@src/assets/images'
import { Storage } from '@src/utils'
import { Analytics, CustomEventTypes } from '@src/config'
import { useIsDarkMode } from '@src/config/theme'
import { AppStackParamList, AuthStackParamList } from '@src/navigation/types'

const getData = ({
  isDarkMode,
  steloSignUpEnabled,
}: {
  isDarkMode: boolean
  steloSignUpEnabled: boolean
}) => {
  const getAppImage = () => {
    if (steloSignUpEnabled) {
      return isDarkMode
        ? walkthroughAppSteloDarkImage.imageSource
        : walkthroughAppSteloImage.imageSource
    }
    return isDarkMode ? walkthroughAppDarkImage.imageSource : walkthroughAppImage.imageSource
  }

  return [
    {
      title: '1:1 Nutritionist Support',
      description: `Learning good habits is hard. Your personal nutrition coach will help you make lasting changes, and enjoy the process.`,
      image: walkthroughNutritionistsImage.imageSource,
    },
    {
      title: 'World Class App',
      description: `Your next level upgrade is here. Make sense of your numbers and chart a course to a healthier you.`,
      image: getAppImage(),
      fullScreenImage: true,
    },
    {
      title: 'Tools for continued education',
      description: `Taking care of your health means becoming a lifelong learner. Our team produces helpful articles, tips, & tricks weekly!`,
      image: (isDarkMode ? walkthroughEducationDarkImage : walkthroughEducationImage).imageSource,
      fullScreenImage: true,
    },
  ]
}

const keyExtractor = (item: ReturnType<typeof getData>[0]) => item.title

export const WalkthroughCarousel = () => {
  const styles = useStyleSheet(themedStyle)
  const isDarkMode = useIsDarkMode()
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const onboardingFlowWithGoalQuestionEnabled = useFeatureFlag(
    Feature.OnboardingFlowWithGoalQuestion,
  )
  const steloSignUpEnabled = useFeatureFlag(Feature.SteloSignUp)

  const newMarketplaceEnabled = useFeatureFlag(Feature.NewMarketplace)

  const navigation = useNavigation<StackNavigationProp<AuthStackParamList & AppStackParamList>>()

  const onSlideChange = (newSlideIndex: number) => {
    setCurrentSlideIndex(newSlideIndex)
  }

  const data = getData({ isDarkMode, steloSignUpEnabled })

  const isLastSlide = currentSlideIndex === data.length - 1

  const onButtonPress = () => {
    Storage.set(Storage.WALKTHROUGH_SHOWN_KEY, true)
    Analytics.track(CustomEventTypes.WalkthroughScreenClosed)
    if (onboardingFlowWithGoalQuestionEnabled) {
      if (newMarketplaceEnabled) {
        navigation.navigate('NewGettingStarted')
      } else {
        navigation.navigate('GettingStarted')
      }
      return
    }
    navigation.replace('Auth')
  }

  return (
    <Carousel
      data={data}
      keyExtractor={keyExtractor}
      onSlideChange={onSlideChange}
      showBackButton={onboardingFlowWithGoalQuestionEnabled}
    >
      {isLastSlide ? (
        <Button
          type="primary"
          size="block"
          onPress={onButtonPress}
          accessibilityLabel="Get Started"
          style={styles.button}
        >
          Get Started
        </Button>
      ) : (
        <Button
          type="transparent"
          size="block"
          onPress={onButtonPress}
          accessibilityLabel="Skip"
          style={styles.button}
        >
          Skip
        </Button>
      )}
    </Carousel>
  )
}

const themedStyle = StyleService.create({
  button: {
    marginBottom: 16,
    marginHorizontal: 24,
  },
})
