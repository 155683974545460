import { inspect } from 'util'
import moment from 'moment'
import { get, isEmpty } from 'lodash'
import { DocumentNode } from 'graphql'
import { ApolloError } from '@apollo/client'
import { Dictionary } from '@stripe/stripe-react-native'
import { Apollo, Bugsnag, Logger, LoggerScreens } from '@config'
import { DATE_FORMAT } from '@src/config/momentFormat'
import { filterSensitiveData } from '@src/utils/logging'
import defaultReducers from './defaultReducers'

const timestamp = () => moment().format('h:m:s.SSS')

export interface EffectParams {
  name: string
  query?: DocumentNode | ((payload: any, args: any) => DocumentNode)
  dataPath?: string | ((payload: any, args: any) => string)
  caching?: boolean | ((variable: any, args: any) => boolean)
  cacheKey?: string | ((variables: any, args: any) => string)
  tracking?: boolean
  variables?: any | ((payload: any, args: any) => any)
  isSuccess?: boolean | ((response: any) => boolean)
  onSuccess?: (response: any, args: any) => void
  onFailure?: (response: any, args: any) => void
  validation?:
    | { valid: boolean; error: string }
    | ((payload: any) => { valid: boolean; error: string })
  apiFunction?: Promise<any | undefined>
  optimistic?: boolean | ((variables: any, args: any) => boolean)
  warnings?: boolean
  debug?: boolean
  debugCaching?: boolean
  reducers?: any[]
  errorReducers?: any[]
  cacheReducers?: any[]
  optimisticReducers?: any[]
}

type Callback = (response: any, params: any) => void

const Model = {
  // State
  //
  defaultState: {
    cache: {},
  },

  // Effects
  //

  /* eslint-disable max-len */

  /*
    buildEffect()

    How effects made with buildEffect() work:
      1. If validation function is provided, it is called to see if payload is valid
        a. If not valid, runs failure callbacks and reducers, exits
        b. If valid, continues
      2. Executes all provided argument functions (query, cacheKey, dataPath, variables) using payload as the argument
      3. If query is provided, uses the query and variables to fetch data
        a. If caching is enabled, cache key is valid, and data is in cache, cached data is returned
        b. If caching is disabled, cache key is missing, or data is not in cache, request is performed
        c. If error is thrown, runs failure callbacks and reducers, exits
      4. If provided, isSuccess check is run on the response, defaults to true if there was no request
        a. If response is successful and valid, runs success callbacks and reducers, exits
          - If a reducer payload function is provided, it is run with the response as its argument,
            if the effect had no query, the effect payload is used as the argument to the payload function
          - If a reducer payload function is not provided, the response is passed as the payload,
            if the effect had no query, the effect payload is passed to the reducer
        b. If response is not successful or invalid, runs failure callbacks and reducers, exits

    Parameters :
      name: [required, string] name of effect, make sure it is namespaced (events/fetch)
      validation: [optional, fn(payload)] function to check if payload is valid
      query: [optional, gql] graphql query to run when making the request
      dataPath: [optional, string] key to extract data from the response, if not provided the response will be passed as is
      caching: [optional, bool, false] flag that determines if cache should be used for reading data
      cacheKey: [optional, fn(payload)] generate cache key from user payload
      isSuccess: [optional, fn(response), true] function which determines if response is valid
      onSuccess: [optional, fn(response)] function to run if request is successful and valid
      onFailure: [optional, fn(response)] function to run if request failed or is invalid
      tracking: [optional, bool, true] if true, sends tracking event to analytics provider
      reducers: [optional, [{name: string, payload: fn(response)}]] array of reducers to run if request is successful and valid
      errorReducers: [optional [{name: string, payload: fn(response)}]] array of reducers to run if request failed or is invalid
      cacheReducers: [optional [{name: string, payload: fn(response)}]] array of reducers to run before success/failure reducers
      warnings: [optional, bool, true] used to disable warnings
      debug: [optional, bool, false] used to enabled debug logging
  */

  /* eslint-enable max-len */

  buildEffect: ({
    name: nameParam,
    query: queryParam,
    dataPath: dataPathParam,
    caching: cachingParam,
    cacheKey: cacheKeyParam = `${nameParam}:${moment().format(DATE_FORMAT)}`,
    variables: variablesParam,
    isSuccess: isSuccessParam,
    onSuccess: onSuccessParam,
    onFailure: onFailureParam,
    validation: validationParam,
    apiFunction: apiFunctionParams,
    tracking = true,
    optimistic: optimisticParam = false,
    warnings: warningsParam = true,
    debug: debugParam = false,
    debugCaching: debugCachingParam = false,
    reducers = [],
    errorReducers = [],
    cacheReducers = [],
    optimisticReducers = [],
  }: EffectParams) => {
    const namespace = nameParam.split('/')[0]

    // dva gives us warning if the effect is prefixed with same model namespace:
    // "app/updateAppState should not be prefixed with namespace app"
    // updateAppState can be dispatched from both app and non-app namespaces
    // so we need to make sure we dispatch the action with the correct namespace
    const updateAppStateActionType = namespace === 'app' ? 'updateAppState' : 'app/updateAppState'

    const makeRequest = function* (query: any, variables: any, dataPath: any, { call, put }: any) {
      const apolloClient: Awaited<ReturnType<typeof Apollo.getClient>> = yield call(
        Apollo.getClient,
      )
      const request = { query, variables }

      if (debugParam) {
        console.log(`${timestamp()}:${nameParam} Request: `, request)
      }

      let response: Awaited<ReturnType<typeof apolloClient.query>>
      try {
        response = yield call(apolloClient.query, request)
        yield put({
          type: updateAppStateActionType,
          payload: { lastRequestStatus: 200 },
        })
      } catch (error: any) {
        if (
          error instanceof ApolloError &&
          error.networkError &&
          'statusCode' in error.networkError &&
          error.networkError.statusCode === 401
        ) {
          yield put({ type: 'app/logout' })
        }
        yield put({
          type: updateAppStateActionType,
          payload: { lastRequestStatus: error.networkError?.statusCode || 500 },
        })

        throw error
      }

      if (response.errors?.length) {
        throw response.errors[0]
      }
      response = dataPath ? get(response, `data.${dataPath}`) : get(response, 'data')
      if (debugParam) {
        console.log(`${timestamp()}:${nameParam} Response: `, response)
      }
      return response
    }

    const make3rdPartyRequest = function* (
      apiFunction: Promise<any | undefined>,
      variables: any,
      { call }: any,
    ): any {
      if (debugParam) {
        console.log(`${timestamp()}:${nameParam} Request: `, apiFunction)
      }
      const response = yield call(apiFunction, variables)
      if (debugParam) {
        console.log(`${timestamp()}:${nameParam} Response: `, response)
      }
      return response
    }

    const onSuccess = function* (
      response: any,
      reducers: any,
      success: ((response: any, params: any) => void) | undefined,
      complete: ((response: any, params: any) => void) | undefined,
      {
        all,
        call,
        put,
        select,
        pagination,
        filter,
        metadata,
        caching,
        cacheKey,
        effectPayload,
        variables,
      }: any,
    ) {
      // Run success callback
      function* handleSuccessCallback(callback?: Callback) {
        if (callback) {
          yield callback(response, { call, put, select })
        }
      }

      yield handleSuccessCallback(success)
      yield handleSuccessCallback(complete)

      // Insert caching reducers if caching is enabled
      const allReducers = [...reducers, ...(caching ? [{ name: 'cacheSet' }] : [])]

      // batch reducer params

      const batchedReducers = []
      for (const reducer of allReducers) {
        const { transform, unless: unlessParam, replaceFake } = reducer

        let { payload, dataPath, storePath } = reducer
        const { metadata: reducerMetadata } = reducer

        const unless: boolean | undefined =
          typeof unlessParam === 'function'
            ? yield unlessParam(response, { call, put, select, payload, effectPayload, variables })
            : unlessParam

        if (unless) {
          continue
        }

        if (typeof payload === 'function') {
          const payloadResult = payload(response, {
            call,
            put,
            select,
            payload,
            effectPayload,
            variables,
          })
          payload = (yield Array.isArray(payloadResult)
            ? all(payloadResult)
            : payloadResult) as Dictionary<any>
        } else {
          payload = payload || response
        }

        if (typeof payload === 'undefined' && warningsParam) {
          console.warn(
            `${nameParam} Warning: reducer payload is empty for ${JSON.stringify(reducer)}`,
          )
        }

        dataPath =
          typeof dataPath === 'function'
            ? ((yield dataPath(response, { call, put, select, payload, effectPayload })) as string)
            : dataPath || namespace

        storePath =
          typeof storePath === 'function'
            ? ((yield storePath(response, { call, put, select, payload, effectPayload })) as string)
            : storePath || dataPath

        const reducerParams = {
          type: reducer.name,
          dataPath,
          storePath,
          payload,
          transform,
          cacheKey,
          pagination,
          filter,
          replaceFake,
          metadata: metadata || reducerMetadata,
          select,
        }
        if (debugParam) {
          console.log(`${timestamp()}:${nameParam} Reducer: `, reducerParams)
        }
        batchedReducers.push(reducerParams)
      }

      // should be debounced by batchSubscribe
      for (const reducer of batchedReducers) {
        yield put(reducer)
      }
    }

    const onError = function* (
      response: any,
      reducers: any,
      failure: ((response: any, params: any) => void) | undefined,
      complete: ((response: any, params: any) => void) | undefined,
      { call, put, select, metadata, effectPayload }: any,
    ) {
      // Run error callbacks
      function* handleFailureCallback(callback?: Callback) {
        if (callback) {
          yield callback(response, { call, put, select })
        }
      }

      yield handleFailureCallback(onFailureParam)
      yield handleFailureCallback(failure)
      yield handleFailureCallback(complete)

      // Run error reducers
      const batchedReducers = []
      for (const reducer of reducers) {
        let { payload } = reducer
        const { storePath = namespace } = reducer
        payload =
          typeof payload === 'function'
            ? payload(response, { call, put, payload, effectPayload })
            : payload || response

        if (isEmpty(payload) && warningsParam) {
          console.warn(`${nameParam} Warning: reducer payload is empty for ${reducer.name}`)
        }

        const reducerParams = { type: reducer.name, payload, metadata, storePath }
        if (debugParam) {
          console.log(`${timestamp()}:${nameParam} Error Reducer: `, reducerParams)
        }
        batchedReducers.push(reducerParams)
      }

      // should be debounced by batchSubscribe
      for (const reducer of batchedReducers) {
        yield put(reducer)
      }
    }

    const runEffect = function* (
      { payload, useCache = true, metadata, success, failure, complete }: any,
      { all, call, put, select }: any,
    ) {
      if (debugParam) {
        console.log(`${timestamp()}:${nameParam} Dispatch: `, {
          payload,
          useCache,
          success,
          failure,
          complete,
        })
      }

      if (tracking) {
        // filter sensitive data before sending it to 3rd party
        const loggingPayload = inspect(filterSensitiveData(payload))

        yield Logger.sendInfo(LoggerScreens.EffectParams, `effect/${nameParam}`, {
          namespace,
          payload: loggingPayload,
        })
        yield Bugsnag.leaveBreadcrumb(`effect/${nameParam}`, { payload: loggingPayload }, 'request')
      }

      // Validate payload
      //
      const validation: Dictionary<any> =
        typeof validationParam === 'function' ? yield validationParam(payload) : validationParam
      if (validation && !validation.valid) {
        if (warningsParam) {
          console.warn(`${nameParam} Error: `, validation.error)
        }
        // run failure callbacks and all error reducers
        yield onError(validation.error, errorReducers, failure, complete, {
          call,
          put,
          metadata,
        })
        return
      }

      // Run parameter callbacks
      //
      /* eslint-disable max-len */
      const query: string =
        typeof queryParam === 'function' ? yield queryParam(payload, { call, select }) : queryParam
      const dataPath: string =
        typeof dataPathParam === 'function'
          ? yield dataPathParam(payload, { call, select })
          : dataPathParam
      const variables: Dictionary<any> =
        typeof variablesParam === 'function'
          ? yield variablesParam(payload, { call, put, select })
          : variablesParam || payload
      const optimistic: boolean | undefined =
        typeof optimisticParam === 'function'
          ? yield optimisticParam(variables, { call, select })
          : optimisticParam
      const cacheKey: string | undefined =
        typeof cacheKeyParam === 'function'
          ? yield cacheKeyParam(variables, { call, select })
          : cacheKeyParam
      const caching: boolean | undefined =
        typeof cachingParam === 'function'
          ? yield cachingParam(variables, { call, select })
          : cachingParam || false
      /* eslint-enable max-len */

      if (debugParam) {
        console.log(`${nameParam} Effect: `, {
          query,
          dataPath,
          caching,
          cacheKey,
          variables,
          validation,
          isSuccessParam,
          onSuccessParam,
          onFailureParam,
          validationParam,
        })
      }

      // Run GraphQL Query and process response
      //
      try {
        const { pagination, filter } = payload || {}
        const usingCache = caching && useCache

        if (optimistic) {
          yield onSuccess(payload, optimisticReducers, success, complete, {
            all,
            call,
            put,
            select,
            pagination,
            filter,
            cacheKey,
            metadata,
            effectPayload: payload,
            variables,
            caching: false,
          })
        }
        let cachedData
        if (usingCache) {
          const cache: Dictionary<any> = yield select((state: any) =>
            get(state, `[${namespace}].cache`),
          )
          cachedData = get(cache, cacheKey as string)
        }

        if (debugParam || debugCachingParam) {
          console.log(nameParam, { cacheKey, usingCache })
        }

        // For effects with no queries, payload will be passed directly to the reducers
        let response
        if (apiFunctionParams) {
          response = (yield make3rdPartyRequest(apiFunctionParams, variables, {
            call,
          })) as Dictionary<any>
        } else if (query && usingCache && cachedData) {
          if (debugParam || debugCachingParam) {
            console.log(`${timestamp()}:${nameParam} Cache Hit!`)
          }
          cachedData.lastTouch = moment()
          response = cachedData.response
        } else if (query) {
          if (debugParam || debugCachingParam) {
            console.log(`${timestamp()}:${nameParam} Cache Miss!`)
          }
          response = (yield makeRequest(query, variables, dataPath, {
            call,
            put,
          })) as Dictionary<any>
        } else {
          response = payload
        }
        if ((apiFunctionParams || query) && isEmpty(response) && warningsParam) {
          console.warn(`${nameParam} Warning: response is empty`)
        }

        // Run success or failure callbacks and appropriate reducers
        const isSuccess =
          typeof isSuccessParam === 'function' ? isSuccessParam(response) : isSuccessParam || true

        const successReducers = [...cacheReducers, ...reducers]

        if (isSuccess) {
          if (typeof onSuccessParam === 'function') {
            yield onSuccessParam(response, {
              call,
              put,
              select,
              effectPayload: payload,
            })
          }

          // success callback has already been called so skip it this time if effect is optimistic
          yield onSuccess(
            response,
            successReducers,
            optimistic ? undefined : success,
            optimistic ? undefined : complete,
            {
              all,
              call,
              put,
              select,
              pagination,
              filter,
              metadata,
              caching,
              usingCache,
              cacheKey,
              effectPayload: payload,
              variables,
            },
          )
        } else {
          if (warningsParam) {
            console.warn(`${nameParam} Error: `, JSON.stringify(response))
          }
          yield onError(response, errorReducers, failure, complete, {
            call,
            put,
            select,
            metadata,
            effectPayload: payload,
          })
        }
      } catch (error: any) {
        if (warningsParam) {
          console.warn(`${nameParam} Error: `, JSON.stringify(error))
        }

        yield onError(error, errorReducers, failure, complete, {
          call,
          put,
          select,
          metadata,
          effectPayload: payload,
        })
      }
    }

    return runEffect
  },

  defaultEffects: {},

  // Reducers
  //

  defaultReducers,
}

export default Model
