import React from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useTheme } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { LoadingIndicator } from '@src/components'

interface LoadingContainerProps {
  error?: boolean
  loadingMessage?: string
  secondaryMessage?: string
  errorMessage?: string
  children?: React.ReactNode
}

export const LoadingContainer = (props: LoadingContainerProps) => {
  const { error, loadingMessage = 'Loading...', secondaryMessage, errorMessage, children } = props
  const styles = useStyleSheet(themedStyles)

  const theme = useTheme()

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.loadingContainer}>
        {!error ? (
          <>
            <LoadingIndicator
              size="large"
              viewStyles={styles.spinner}
              color={theme['theme.text.primary']}
            />
            <Text type="large" bold style={styles.text}>
              {loadingMessage}
            </Text>
            {secondaryMessage && (
              <Text type="regular" style={styles.secondaryText}>
                {secondaryMessage}
              </Text>
            )}
          </>
        ) : (
          <>
            <Icon name="warning" style={styles.icon} />
            <Text type="large" bold style={styles.text}>
              {errorMessage}
            </Text>
          </>
        )}
      </View>
      {!!error && children}
    </SafeAreaView>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    paddingBottom: 16,
    backgroundColor: 'theme.background',
  },
  loadingContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    flex: 0,
  },
  text: {
    marginTop: 32,
  },
  secondaryText: {
    marginTop: 16,
  },
  icon: {
    width: 48,
    color: 'theme.error.base',
  },
})
