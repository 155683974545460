import React from 'react'
import { SteloNoticePopup } from './SteloNoticePopup'
import { useShowSteloSetupPopupOrchestrator } from './hooks/useShowSteloSetupPopupOrchestrator'

// Handles several cases where the Stelo setup popup should be shown
export const SteloSetupPopup = () => {
  const { onPress, headlineText, bodyText, shouldShow } = useShowSteloSetupPopupOrchestrator()

  if (!shouldShow) {
    return null
  }

  return <SteloNoticePopup onPress={onPress} headlineText={headlineText} bodyText={bodyText} />
}
