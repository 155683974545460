import { useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigation } from '@react-navigation/native'
import { useDispatchAsync } from '@src/utils'
import { SurveysConfigKind, SurveyLink, SurveysUserLinkState, Maybe } from '@src/types'
import { QuestionnaireScreenName } from '@src/navigation/types'
import { QuestionnaireConfigInteractor } from '../QuestionnaireConfigInteractor'

type UseQuestionnaireStateLoading = { loading: true }
type UseQuestionnaireStateError = { error: true; message: string }

export const useLoadSurveyLink = (surveyKind: SurveysConfigKind) => {
  const navigation = useNavigation()
  const dispatchAsync = useDispatchAsync()
  const questionnaireForm = useForm({ mode: 'all' })
  const [surveyLink, setSurveyLink] = useState<null | SurveyLink>(null)

  const [questionnaireConfigInteractor, setQuestionnaireConfigInteractor] = useState<
    UseQuestionnaireStateLoading | QuestionnaireConfigInteractor | UseQuestionnaireStateError
  >({ loading: true })

  const { setValue: questionnaireSetValue } = questionnaireForm

  const handleSurveyLink = useCallback(
    (surveyLink: SurveyLink) => {
      try {
        const { responses } = surveyLink

        // Fill questionnaire form with existing responses
        responses.forEach((response) => {
          const answer =
            Object.keys(response.answer).length > 1
              ? {
                  value: {
                    ...response.answer,
                    value: response.answer.value,
                  },
                }
              : response.answer
          questionnaireSetValue(response.questionKey, answer.value)
        })

        setQuestionnaireConfigInteractor(new QuestionnaireConfigInteractor(surveyLink.config))
        setSurveyLink(surveyLink)
      } catch (e: any) {
        console.error('Error fetching existing survey', e)
        setQuestionnaireConfigInteractor({
          error: true,
          message: e.message,
        })
      }
    },
    [questionnaireSetValue],
  )

  const handleSurveyLinkError = useCallback((message: string) => {
    setQuestionnaireConfigInteractor({
      error: true,
      message,
    })
  }, [])

  const loadSurveyLink = useCallback(async () => {
    try {
      const latestSurvey = await dispatchAsync<Maybe<SurveyLink>>({
        type: 'app/latestSurveyLinkByKind',
        payload: {
          kind: surveyKind,
        },
      })

      // User is not eligible for our app
      if (
        surveyKind === SurveysConfigKind.Health &&
        latestSurvey?.state === SurveysUserLinkState.Stopped &&
        latestSurvey.outcome
      ) {
        navigation.reset({
          index: 0,
          routes: [
            {
              name: QuestionnaireScreenName.HealthQuestionnaireRejectionReason,
              params: { outcome: latestSurvey.outcome },
            },
          ],
        })
        return
      }

      if (
        latestSurvey &&
        (!latestSurvey.finished || !latestSurvey.survey.behavior.allowMultipleSubmissions)
      ) {
        handleSurveyLink(latestSurvey)
        return
      }

      const newSurvey = await dispatchAsync<SurveyLink>({
        type: 'app/startQuestionnaire',
        payload: {
          kind: surveyKind,
        },
      })

      return handleSurveyLink(newSurvey)
    } catch (e: any) {
      console.error('Error fetching existing survey', e)
      handleSurveyLinkError(e.message)
    }
  }, [dispatchAsync, surveyKind, navigation, handleSurveyLinkError, handleSurveyLink])

  return {
    loadSurveyLink,
    surveyLink,
    questionnaireConfigInteractor,
    questionnaireForm,
  }
}
