import React, { useRef, useState } from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Image, ImageStyle, TouchableOpacity, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigationContainer } from '@src/screens/Common/containers'
import { ScrollViewWithFade } from '@src/components'
import { cgmSteloImage } from '@src/assets/images'
import { Button, Checkbox, Icon, Text } from '@src/components/base'
import { useDispatchAsync } from '@src/utils'
import { showSnack } from '@src/services/Bluetooth/utils'

export interface ConfirmSteloEligibilityProps {
  actionCallback: () => void
  ineligibleCallback: () => void
  allowExit?: boolean
  mode: 'checkout' | 'eligibility'
}

export const ConfirmSteloEligibility = ({
  actionCallback,
  ineligibleCallback,
  allowExit = false,
  mode,
}: ConfirmSteloEligibilityProps) => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const titleText = mode === 'checkout' ? 'Stelo Checkout' : 'Stelo Eligibility'
  const actionText = mode === 'checkout' ? 'Add to Cart' : 'Confirm Eligibility'
  const dispatchAsync = useDispatchAsync()
  const loadingRef = useRef(false)

  const [acknowledged, setAcknowledged] = useState(false)

  const toggleAcknowledged = () => setAcknowledged((prev) => !prev)

  const onConfirmPress = async () => {
    await updateEligibility(true)

    actionCallback()
  }

  const onIneligiblePress = async () => {
    await updateEligibility(false)

    ineligibleCallback()
  }

  const updateEligibility = async (isEligible: boolean) => {
    if (loadingRef.current) {
      return
    }

    loadingRef.current = true
    try {
      await dispatchAsync({
        type: 'app/updateDexcomSteloEligibility',
        payload: { isEligible },
      })

      await dispatchAsync({ type: 'users/fetch' })
    } catch {
      showSnack('Failed to update Stelo eligibility', null, 'warning')
      return
    } finally {
      loadingRef.current = false
    }
  }

  return (
    <NavigationContainer
      style={styles.container}
      title={titleText}
      allowBackNavigation={allowExit}
      {...(allowExit && {
        leftIcon: <Icon name="x" size="20" weight="bold" style={styles.exitIcon} />,
      })}
    >
      <ScrollViewWithFade scrollViewStyle={styles.scrollViewContent}>
        <Image
          resizeMode="contain"
          source={cgmSteloImage.imageSource}
          style={styles.image as ImageStyle}
        />
        <Text style={styles.title} type="title-3">
          Your Nutrisense Program includes Stelo Glucose Biosensors.
        </Text>

        <View style={styles.card}>
          <Text type="large" bold>
            Stelo is designed for its intended users:
          </Text>
          <View style={styles.cardContent}>
            <View style={styles.cardRow}>
              <Icon name="check-circle" weight="bold" style={styles.checkIcon} />
              <Text type="regular" style={styles.cardRowText}>
                For people NOT on insulin, ages 18 years and older
              </Text>
            </View>
            <View style={styles.cardRow}>
              <Icon name="x-circle" weight="bold" style={styles.xIcon} />
              <Text type="regular" style={styles.cardRowText}>
                Don’t use if: on insulin, on dialysis, or if you have problematic hypoglycemia.
              </Text>
            </View>
          </View>
        </View>

        <Text type="large" bold style={styles.safetyInformationTitle}>
          Safety Information
        </Text>
        <Text type="regular" style={styles.safetyInformation}>
          <Text type="regular" bold style={styles.safetyInformation}>
            1) STELO IMPORTANT INFORMATION:
          </Text>{' '}
          Consult your healthcare provider before making any medication adjustments based on your
          sensor readings and do not take any other medical action based on your sensor readings
          without consulting your healthcare provider. Do not use if you have problematic
          hypoglycemia. Failure to use Stelo and its components according to the instructions for
          use provided and to properly consider all indications, contraindications, warnings, and
          cautions in those instructions for use may result in you missing a severe hypoglycemia
          (low blood glucose) or hyperglycemia (high blood glucose) occurrence. If your sensor
          readings are not consistent with your symptoms, a blood glucose meter may be an option as
          needed and consult your healthcare provider. Seek medical advice and attention when
          appropriate, including before making any medication adjustments and/or for any medical
          emergency.{'\n'}
          <Text type="regular" bold style={styles.safetyInformation}>
            2) INDICATIONS FOR USE:
          </Text>{' '}
          The Stelo Glucose Biosensor System is an over-the-counter (OTC) integrated Continuous
          Glucose Monitor (iCGM) intended to continuously measure, record, analyze, and display
          glucose values in people 18 years and older not on insulin. The Stelo Glucose Biosensor
          System helps to detect normal (euglycemic) and low or high (dysglycemic) glucose levels.
          The Stelo Glucose Biosensor System may also help the user better understand how lifestyle
          and behavior modification, including diet and exercise, impact glucose excursion. The user
          is not intended to take medical action based on the device output without consultation
          with a qualified healthcare professional.{'\n'}
          <Text type="regular" bold style={styles.safetyInformation}>
            3) CONTRAINDICATIONS:
          </Text>{' '}
          No MRI/CT/diathermy – MR unsafe.{'\n'}
          <Text type="regular" bold style={styles.safetyInformation}>
            4) WARNINGS:
          </Text>{' '}
          Choking hazard: The Stelo Glucose Biosensor System contains small parts and may pose a
          choking hazard if swallowed. Don't use if you have problematic hypoglycemia: The Stelo
          Glucose Biosensor System hasn't been designed for these populations. Consult with your
          healthcare provider to discuss which Dexcom product is right for you. Don't use if you are
          on dialysis: The Stelo Glucose Biosensor System performance hasn't been evaluated in this
          population and sensor readings may be inaccurate. Don't ignore low/high symptoms: Consult
          your healthcare provider when your sensor readings are not consistent with your symptoms
          of low or high glucose. Don’t change medications: Consult your healthcare provider before
          making any medication adjustments based on your sensor readings.
        </Text>
      </ScrollViewWithFade>
      <View style={[styles.actionsContainer, { paddingBottom: insets.bottom + 16 }]}>
        <TouchableOpacity
          activeOpacity={0.7}
          style={styles.consent}
          accessibilityLabel="I acknowledge"
          onPress={toggleAcknowledged}
        >
          <Text type="regular">I acknowledge.</Text>
          <Checkbox checked={acknowledged} onChange={toggleAcknowledged} />
        </TouchableOpacity>
        <View style={styles.buttonsContainer}>
          <Button
            accessibilityLabel={actionText}
            size="block"
            type="primary"
            disabled={!acknowledged}
            onPress={onConfirmPress}
          >
            {actionText}
          </Button>
          <Button
            accessibilityLabel="I am ineligible"
            size="block"
            type="transparent"
            onPress={onIneligiblePress}
          >
            I am ineligible
          </Button>
        </View>
      </View>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  exitIcon: {
    marginLeft: 16,
  },
  scrollViewContent: {
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  image: {
    marginTop: 8,
    maxWidth: 148,
    width: '100%',
    alignSelf: 'center',
  },
  title: {
    maxWidth: 300,
    textAlign: 'center',
    alignSelf: 'center',
    marginBottom: 45,
    marginTop: 16,
  },
  card: {
    paddingVertical: 24,
    paddingHorizontal: 16,
    marginBottom: 20,
    borderRadius: 8,
    backgroundColor: 'theme.surface.base2',
  },
  cardContent: {
    marginTop: 16,
    gap: 12,
  },
  cardRow: {
    flexDirection: 'row',
    gap: 8,
  },
  cardRowText: {
    flex: 1,
  },
  checkIcon: {
    color: 'theme.success.light_',
  },
  xIcon: {
    color: 'theme.error.light_',
  },
  safetyInformationTitle: {
    marginBottom: 8,
  },
  safetyInformation: {
    color: 'theme.text.secondary',
  },
  actionsContainer: {
    marginHorizontal: 16,
  },
  consent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'theme.surface.base2',
    paddingHorizontal: 24,
    paddingVertical: 13,
    borderRadius: 12,
    marginBottom: 16,
  },
  buttonsContainer: {
    gap: 8,
  },
})
