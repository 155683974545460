import React, { useEffect, useState } from 'react'
import { Image, ImageStyle, Platform, TouchableOpacity, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Analytics, Bugsnag, CustomEventTypes } from '@src/config'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Input, Radio, Text } from '@components/base'
import {
  cgmG6Image,
  cgmG7Image,
  cgmLibre14dayImage,
  cgmLibre2Image,
  cgmLibre3Image,
  cgmSteloImage,
} from '@src/assets/images'
import { Storage } from '@src/utils'
import { InlineAlert, ScrollViewWithFade } from '@src/components'
import { UiInlineAlert } from '@src/types'
import { NavigationContainer } from '../Common/containers'
import { OwnSensorInfoText, OwnSensorOption, OwnSensorSurveyAnswers } from './constants'

interface SensorSupportData {
  [key: string]:
    | {
        text: OwnSensorInfoText
        supported: boolean
        showLearnMoreLink: boolean
      }
    | undefined
}

type SensorImageMap = {
  [K in OwnSensorOption]: any
}

const sensorSupportData: SensorSupportData = {
  [OwnSensorOption.Other]: {
    text: OwnSensorInfoText.OtherSensor,
    supported: true,
    showLearnMoreLink: false,
  },
  [OwnSensorOption.FreeStyleLibre14Day]: {
    text: OwnSensorInfoText.Libre,
    supported: true,
    showLearnMoreLink: true,
  },
  [OwnSensorOption.FreeStyleLibre2]: {
    text: OwnSensorInfoText.Libre,
    supported: true,
    showLearnMoreLink: true,
  },
  [OwnSensorOption.FreeStyleLibre3]: {
    text: OwnSensorInfoText.Libre3,
    supported: true,
    showLearnMoreLink: false,
  },
  [OwnSensorOption.DexcomG6]: {
    text: OwnSensorInfoText.DexcomG6,
    supported: true,
    showLearnMoreLink: true,
  },
  [`${OwnSensorOption.DexcomG7}_android`]: {
    text: OwnSensorInfoText.AndroidDexcomG7,
    supported: false,
    showLearnMoreLink: false,
  },
  [`${OwnSensorOption.DexcomG7}_ios`]: {
    text: OwnSensorInfoText.iosDexcomG7,
    supported: true,
    showLearnMoreLink: true,
  },
  [`${OwnSensorOption.DexcomStelo}_android`]: {
    text: OwnSensorInfoText.AndroidDexcomStelo,
    supported: false,
    showLearnMoreLink: false,
  },
  [`${OwnSensorOption.DexcomStelo}_ios`]: {
    text: OwnSensorInfoText.iosDexcomStelo,
    supported: true,
    showLearnMoreLink: true,
  },
}

const sensorImageMap: SensorImageMap = {
  [OwnSensorOption.FreeStyleLibre14Day]: cgmLibre14dayImage,
  [OwnSensorOption.FreeStyleLibre2]: cgmLibre2Image,
  [OwnSensorOption.FreeStyleLibre3]: cgmLibre3Image,
  [OwnSensorOption.DexcomG6]: cgmG6Image,
  [OwnSensorOption.DexcomG7]: cgmG7Image,
  [OwnSensorOption.DexcomStelo]: cgmSteloImage,
  [OwnSensorOption.Other]: undefined,
}

export const SensorSelectionScreen = () => {
  const styles = useStyleSheet(themedStyle)
  const [selectedSensor, setSelectedSensor] = useState<OwnSensorOption | undefined>()
  const [infoText, setInfoText] = useState<OwnSensorInfoText | undefined>()
  const [selectionSupported, setSelectionSupported] = useState<boolean>(false)
  const navigation = useNavigation()
  const insets = useSafeAreaInsets()

  useEffect(() => {
    setInfoText(undefined)
    setSelectionSupported(false)

    if (!selectedSensor) {
      return
    }

    const selectionData =
      sensorSupportData[selectedSensor] || sensorSupportData[`${selectedSensor}_${Platform.OS}`]

    if (!selectionData) {
      Bugsnag.notify(
        new Error(
          `OwnSensor signup flow - sensor selection step - No data found for sensor: ${selectedSensor}`,
        ),
      )
      return
    }

    if (selectedSensor !== OwnSensorOption.Other) {
      setInfoText(selectionData.text)
    }
    setSelectionSupported(selectionData.supported)
  }, [selectedSensor])

  const onNextPress = () => {
    if (selectedSensor === OwnSensorOption.Other) {
      setInfoText(OwnSensorInfoText.OtherSensor)
      return
    }

    if (!selectedSensor) {
      Bugsnag.notify(
        new Error(
          `OwnSensor signup flow sensor selection - submission attempted without sensor selected`,
        ),
      )
      return
    }

    Analytics.track(CustomEventTypes.OwnSensorSelected, { sensorType: selectedSensor })

    Storage.set(Storage.ONBOARDING_SENSOR_TYPE_SELECTED_KEY, OwnSensorSurveyAnswers[selectedSensor])
    navigation.navigate('CreateAccountOptions')
  }

  const sensorKindClicked = (sensor: OwnSensorOption) => {
    if (sensor === selectedSensor) {
      return
    }

    setSelectedSensor(sensor)
  }

  return (
    <NavigationContainer title="Bringing my own CGM">
      <View style={[styles.container, { paddingBottom: insets.bottom + 16 }]}>
        <Text type="large" bold style={styles.titleText}>
          Choose the CGM you have from the list below.
        </Text>
        <View style={styles.content}>
          <ScrollViewWithFade style={{ flex: 1 }}>
            {Object.values(OwnSensorOption).map((sensor) => (
              <TouchableOpacity
                key={sensor}
                accessibilityLabel={sensor}
                onPress={() => sensorKindClicked(sensor)}
                style={styles.selectionOption}
              >
                <View style={styles.labelWithImage}>
                  <Image
                    source={sensorImageMap[sensor]?.imageSource}
                    style={styles.sensorImage as ImageStyle}
                  />
                  <Text type="regular">{sensor}</Text>
                </View>
                <Radio
                  style={styles.radio}
                  checked={selectedSensor === sensor}
                  onPress={() => sensorKindClicked(sensor)}
                />
              </TouchableOpacity>
            ))}
          </ScrollViewWithFade>
          {selectedSensor === OwnSensorOption.Other && (
            <Input style={{ marginBottom: 10 }} placeholder="Type your answer" />
          )}
          <View style={styles.actionAndInfoContainer}>
            {!!infoText && <InlineAlert category={UiInlineAlert.Warning} message={infoText} />}
            {!!selectedSensor && selectionSupported && (
              <Button
                type="primary"
                accessibilityLabel="Next"
                size="block"
                onPress={onNextPress}
                disabled={!selectionSupported}
              >
                Next
              </Button>
            )}
          </View>
        </View>
      </View>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    backgroundColor: 'theme.background',
  },
  content: {
    flex: 1,
    justifyContent: 'space-between',
  },
  titleText: {
    marginBottom: 16,
  },
  radio: {
    marginLeft: 16,
  },
  sensorImage: {
    maxWidth: 40,
    maxHeight: 40,
    resizeMode: 'contain',
  },
  selectionOption: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: 16,
    borderWidth: 2,
    borderColor: 'theme.surface.base1',
    padding: 16,
    marginBottom: 8,
  },
  labelWithImage: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  actionAndInfoContainer: {
    gap: 8,
  },
})
