import React, { forwardRef, useState } from 'react'
import { TouchableOpacity } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { Icon, Input, InputProps, InputRef } from '@components/base'

export const SecureInput = forwardRef<InputRef, InputProps>((props, ref) => {
  const [secureTextEntry, setSecureTextEntry] = useState(true)
  const theme = useTheme()

  const iconColor = theme['theme.text.primary']

  return (
    <Input
      {...props}
      ref={ref}
      secureTextEntry={secureTextEntry}
      accessoryRight={
        <TouchableOpacity
          accessibilityLabel="Toggle visibility"
          activeOpacity={0.7}
          onPress={() => setSecureTextEntry((prevSecure) => !prevSecure)}
        >
          <Icon color={iconColor} name={secureTextEntry ? 'eye-closed' : 'eye'} />
        </TouchableOpacity>
      }
    />
  )
})
