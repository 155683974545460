import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import Chart from 'react-native-f2chart'
import { ChartQueryType, DetailedFetchKeyMap } from '@src/screens/Insights/helper'
import { Point, YAxisConfig } from '@src/screens/Insights/types'
import { unitSystemStoreStateSelector } from '@src/selectors/settings'
import { useDefaultChartRanges } from '@src/hooks/useDefaultRangeConfig'
import { ScoreSectionEnum } from '@src/screens/Insights/utils/pageConfig'
import { useDisableInAppScan } from '@src/hooks/useDisableInAppScan'
import { LineChart } from './LineChart'

interface LineChartControllerProps {
  data: Point[]
  type: ChartQueryType
  yAxisConfig?: YAxisConfig
  range?: {
    min?: number | null
    max?: number | null
  }
  yLabel?: string
}

export const LineChartController = ({
  data,
  type,
  yAxisConfig,
  yLabel,
  range,
}: LineChartControllerProps) => {
  const chartRef = useRef<Chart | null>(null)
  const isScanDisabled = useDisableInAppScan()

  const fetchKey = DetailedFetchKeyMap[type]
  const unitSystem = useSelector(unitSystemStoreStateSelector)

  let rangeKey = fetchKey
  if (type === 'DAILY_SCORE') {
    rangeKey = ScoreSectionEnum.DAILY_AVERAGE
  }
  if (type === 'GLUCOSE_AVERAGE') {
    rangeKey = 'mean'
  }

  const ranges = useDefaultChartRanges(rangeKey)
  const min = range?.min || yAxisConfig?.[unitSystem].min
  const max = range?.max || yAxisConfig?.[unitSystem].max

  return (
    <LineChart
      chartRef={chartRef}
      data={data}
      isScanDisabled={isScanDisabled}
      ranges={ranges}
      yMin={min}
      yMax={max}
      yLabel={yLabel}
      height={ranges.length > 0 ? 180 : 220}
    />
  )
}
