import {
  initTerra,
  initConnection,
  checkAuth,
  getUserId,
  Connections,
  CustomPermissions,
  setIgnoredSources,
} from 'terra-react'

import Config from 'react-native-config'
import { Dispatch } from 'react'
import { AnyAction } from 'redux'
import { getBundleId } from 'react-native-device-info'
import { Apollo } from '@src/config/apollo'
import { Analytics, Bugsnag, CustomEventTypes, Device } from '@src/config'
import HealthKit from '@src/services/HealthKit'

const SUFFICIENT_PERMISSIONS = [
  CustomPermissions.WEIGHT,
  CustomPermissions.STEPS,
  CustomPermissions.SLEEP_ANALYSIS,
  CustomPermissions.WORKOUT_TYPES,
  CustomPermissions.BLOOD_GLUCOSE,
]

const getDevId = () => {
  if (Device.web) {
    return ''
  }
  const endpointEnv = Apollo.endpointEnv
  if (endpointEnv === 'production') {
    return Config.TERRA_PRODUCTION_DEV_ID
  } else if (endpointEnv === 'staging') {
    return Config.TERRA_STAGING_DEV_ID
  }

  return Config.TERRA_DEVELOPMENT_DEV_ID
}

let initializedState: { devId: string; userId: string | null } = { devId: getDevId(), userId: null }

const validateHealthKitPermissions = async () => {
  const permissionDetermined = await HealthKit.isPermissionsStatusDetermined()
  if (!permissionDetermined) {
    await HealthKit.initialize()
  }
}

const isInitializedForUser = (userId: string) => {
  if (initializedState.userId === userId && initializedState.devId === getDevId()) {
    return true
  }
  return false
}
// Initialize SDK, required to be called before any other SDK methods
const initialize = async (userId: any) => {
  if (isInitializedForUser(userId)) {
    return
  }
  const devId = getDevId()
  // We don't want to collect measurements synced from our app to Apple Health
  setIgnoredSources([getBundleId()])
  await initTerra(devId, userId)
  initializedState = { devId, userId }
}

// Migrate from Healthkit to Terra SDK connection
const migrateFromHealthKit = async (dispatch: Dispatch<AnyAction>, userId: string) => {
  if (Device.web) {
    return
  }

  await validateHealthKitPermissions()
  await initialize(userId)
  dispatch({
    type: 'settings/generateTerraSdkToken',
    success: async (response: { token: string }) => {
      const result = await initConnection(
        Connections.APPLE_HEALTH,
        response.token,
        true,
        SUFFICIENT_PERMISSIONS,
      )
      if (result.success) {
        Analytics.track(CustomEventTypes.TerraHealthKitSyncEnabled, {
          source: 'Migration from HealthKit',
        })
      } else {
        Analytics.track(CustomEventTypes.TerraHealthKitSyncFailed, {
          source: 'Migration from HealthKit',
        })
        Bugsnag.notify(`Migration to Terra <> Apple health failed ${result.error}`)
      }
    },
  })
}

// We need to call checkAuth and refresh connection for the user, won't interrupt user flow
// https://docs.tryterra.co/docs/react-native-project#test-authentication
const validateConnection = async (dispatch: Dispatch<AnyAction>, userId: string) => {
  if (Device.web) {
    return
  }

  await validateHealthKitPermissions()
  await initialize(userId)
  const authResult = await checkAuth(Connections.APPLE_HEALTH, getDevId())
  if (!authResult.success) {
    dispatch({
      type: 'settings/generateTerraSdkToken',
      success: async (response: { token: string }) => {
        const result = await initConnection(
          Connections.APPLE_HEALTH,
          response.token,
          true,
          SUFFICIENT_PERMISSIONS,
        )
        if (!result.success) {
          Bugsnag.notify(`Failed to re-initialize Terra <> Apple health connection ${result.error}`)
        }
      },
    })
  }
}

// Initialize Terra <> Apple health connection for new users, will return userId if successful
const initializeConnection = async (token: string, userId: string) => {
  if (Device.web) {
    return { success: false }
  }
  await initialize(userId)
  const authState = await checkAuth(Connections.APPLE_HEALTH, getDevId())
  if (authState.success) {
    const userIdResult = await getUserId(Connections.APPLE_HEALTH)
    if (userIdResult.success && userIdResult.userId) {
      return { success: true, userId: userIdResult.userId }
    }
  }
  const connectionResult = await initConnection(
    Connections.APPLE_HEALTH,
    token,
    true,
    SUFFICIENT_PERMISSIONS,
  )

  if (connectionResult.success) {
    const userIdResult = await getUserId(Connections.APPLE_HEALTH)
    Analytics.track(CustomEventTypes.TerraHealthKitSyncEnabled, {
      source: 'New connection initialization',
    })
    return { success: true, userId: userIdResult.userId }
  }
  Analytics.track(CustomEventTypes.TerraHealthKitSyncFailed, {
    source: 'New connection initialization',
  })
  Bugsnag.notify(`Failed to initialize Terra <> Apple health connection ${connectionResult.error}`)

  return { success: false }
}

export default {
  migrateFromHealthKit,
  initializeConnection,
  validateConnection,
}
