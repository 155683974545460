import { useSelector } from 'react-redux'
import { Feature, useFeatureFlag } from '@src/components'
import { userSelector } from '@src/selectors/app'
import { Storage } from '@src/utils'
import {
  useIsDexcomSteloSensorMode,
  useHasSyncedSteloRealtime,
  useHasSteloMigrationNotice,
} from '@src/utils/hooks'
import { useStorageValue } from '@src/utils/storage'

export const useShouldShowSteloMigrationNotice = () => {
  const isSteloSignupEnabled = useFeatureFlag(Feature.SteloSignUp)
  const isDexcomSteloSensorMode = useIsDexcomSteloSensorMode()
  const user = useSelector(userSelector)
  const userId = user?.id

  const [hasViewedSteloMigrationModal] = useStorageValue(
    `${Storage.STELO_SENSOR_MIGRATION_NOTICE_VISITED_KEY}_${userId}`,
  )
  const hasSyncedSteloRealtime = useHasSyncedSteloRealtime()
  const shouldShowNotice = useHasSteloMigrationNotice()

  if (!isSteloSignupEnabled) {
    return false
  }

  if (!isDexcomSteloSensorMode) {
    return false
  }

  // member has already seen the modal
  if (hasViewedSteloMigrationModal) {
    return false
  }

  // member has created a stelo connection
  if (hasSyncedSteloRealtime) {
    return false
  }

  // member has recently been switched to Stelo and has not seen the notice yet
  return shouldShowNotice
}
