import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sortBy } from 'lodash'
import { StyleService } from '@src/style/service'
import { sensorSettingsSelector } from '@src/selectors/app'
import { SettingsModalSharedContainer } from '@src/screens/Settings/components/SettingsModalShared'
import { OptionsSlider } from '@src/screens/Settings/components/OptionsSlider'
import { useGoBack } from '@src/utils/navigation'
import { sensorSelector } from '@src/selectors/sensor'

export const ManualCalibrationModal = () => {
  const goBack = useGoBack()

  const dispatch = useDispatch()

  const sensor = useSelector(sensorSelector)

  const { baselineAdjustmentOptions } = useSelector(sensorSettingsSelector)

  const options = sortBy(baselineAdjustmentOptions, 'value')

  const minLabel = options[0].text
  const maxLabel = options[options.length - 1].text

  const [baselineAdjustment, setBaselineAdjustment] = useState(() => {
    return (
      options.find((option) => option.value === sensor?.baselineAdjustment) || {
        text: '0',
        value: 0,
      }
    )
  })

  useEffect(() => {
    if (!sensor) {
      goBack()
    }
  }, [goBack, sensor])

  if (!sensor) {
    return null
  }

  const onSaveButtonPress = () => {
    if (baselineAdjustment.value !== sensor.baselineAdjustment) {
      dispatch({
        type: 'sensor/updateSettings',
        payload: {
          ...sensor,
          baselineAdjustment: baselineAdjustment.value,
        },
      })
    }

    goBack()
  }

  return (
    <SettingsModalSharedContainer
      onSaveButtonPress={onSaveButtonPress}
      onCancelButtonPress={goBack}
      title="Sensor calibration"
    >
      <OptionsSlider
        style={styles.lastSlider}
        label={`Baseline: ${baselineAdjustment.text}`}
        minLabel={minLabel}
        maxLabel={maxLabel}
        selectedOptions={[baselineAdjustment]}
        options={options}
        showSelection={false}
        onChange={setBaselineAdjustment}
      />
    </SettingsModalSharedContainer>
  )
}

const styles = StyleService.create({
  lastSlider: {
    paddingBottom: 20,
  },
})
