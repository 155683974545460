import React, { TouchableOpacity, View } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { Icon, Pill as PillComponent, Text } from '@components/base'
import { StyleService, useStyleSheet } from '@style/service.ts'
import { Maybe, Pill, UiPillFill, UiPillSize } from '@src/types.ts'

interface CgmProductBoxProps {
  title: string
  selected: boolean
  onPress: () => void
  pill?: Maybe<Pill>
}

export const CgmProductBox = ({ title, selected, onPress, pill }: CgmProductBoxProps) => {
  const styles = useStyleSheet(themedStyle)
  const theme = useTheme()

  return (
    <TouchableOpacity
      accessibilityLabel={title}
      onPress={onPress}
      style={[
        styles.card,
        { borderColor: selected ? theme['theme.success.base'] : theme['theme.surface.base'] },
      ]}
    >
      <View style={styles.row}>
        <Text type="large" bold>
          {title}
        </Text>

        <View
          style={[
            styles.circle,
            {
              backgroundColor: selected
                ? theme['theme.success.base']
                : theme['theme.surface.base2'],
              borderColor: selected ? theme['theme.success.base'] : theme['theme.surface.base'],
            },
          ]}
        >
          {selected && <Icon name="check" style={styles.icon} />}
        </View>
      </View>

      {pill && (
        <View style={styles.row}>
          <PillComponent
            text={pill.text}
            fill={pill.fill || UiPillFill.Brick}
            size={UiPillSize.M}
          />
        </View>
      )}
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  card: {
    backgroundColor: 'theme.surface.base3',
    justifyContent: 'space-between',
    borderRadius: 24,
    borderWidth: 2,
    paddingVertical: 16,
    paddingHorizontal: 18,
    width: '48%',
    height: 105,
  },
  circle: {
    borderRadius: 50,
    borderWidth: 2,
    height: 32,
    width: 32,
  },
  icon: {
    color: 'theme.solid.white',
  },
  text: {
    color: 'theme.text.primary',
  },
})
