import React, { View } from 'react-native'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { useNavigation } from '@react-navigation/core'
import { NavigationContainer } from '@screens/Common/containers'
import { StyleService, useStyleSheet } from '@style/service.ts'
import { BillingProduct, Product, SubscriptionCheckoutOptionKind } from '@src/types.ts'
import { userSelector } from '@selectors/app.ts'
import { useEligibleCgmUpgradeProducts, useProducts } from '@screens/Marketplace/utils/hooks.ts'
import { CgmProductBox } from '@screens/Marketplace/components/NewMarketplace/CgmProductSelection/CgmProductBox.tsx'
import { Button, Text } from '@components/base'
import { CgmBenefitLabel } from '@screens/Marketplace/components/NewMarketplace/CgmProductSelection/CgmBenefitLabel.tsx'
import { getPriceRangeDescription } from '@screens/Marketplace/utils/utils.ts'
import { CheckoutKind } from '@screens/Marketplace/types/types.ts'

export const CgmProductSelection = () => {
  const user = useSelector(userSelector)
  const navigation = useNavigation()
  const { activeSubscriptions, products } = useProducts()
  const [selectedProduct, setSelectedProduct] = useState<Product>()

  const styles = useStyleSheet(themedStyle)

  const coreSubscription = activeSubscriptions.find(
    (sub) => sub.id === user?.lastCoreSubscription?.id,
  )
  const eligibleUpgradeProducts = coreSubscription?.primaryProduct?.eligibleUpgradeProducts || []
  const cgmProducts = useEligibleCgmUpgradeProducts(products, eligibleUpgradeProducts)

  const productWithHighestPrice = cgmProducts.sort((a, b) => b.price - a.price)[0]

  const buttonDisabled = !selectedProduct || !coreSubscription

  const onNextPress = () => {
    if (buttonDisabled) {
      return
    }

    navigation.navigate('SubscriptionCheckout', {
      subscriptionId: coreSubscription.id,
      product: selectedProduct,
      checkoutKind: CheckoutKind.UpgradeProduct,
      checkoutOptionKinds: [SubscriptionCheckoutOptionKind.Prescription],
    })
  }

  const goBack = () => navigation.navigate('Marketplace')

  return (
    <NavigationContainer title="Program Duration" goBack={goBack}>
      <View style={styles.container}>
        <View>
          <View style={styles.boxes}>
            {cgmProducts.map((product) => {
              return (
                <CgmProductBox
                  key={product.key}
                  title={product.title}
                  selected={selectedProduct === product}
                  onPress={() => {
                    setSelectedProduct(product)
                  }}
                  pill={product.pill}
                />
              )
            })}
          </View>
          <View style={styles.benefits}>
            <Text type="title-3" style={{ marginBottom: 21 }}>
              What you get:
            </Text>

            {cgmProducts[0].includedOptions?.map((text, ix) => {
              return <CgmBenefitLabel key={`cgm-benefit-label-${ix}`} label={text} />
            })}
          </View>
        </View>

        {selectedProduct && (
          <View style={styles.footer}>
            <View>
              <Text type="title-3">Total</Text>
              <Text type="title-2">{getPriceRangeDescription(selectedProduct)}</Text>
              <Text type="large" bold style={styles.monthlyPrice}>
                {selectedProduct.key !== BillingProduct.NutrisenseMonthly
                  ? getPriceRangeDescription(productWithHighestPrice!)
                  : ' '}
              </Text>
            </View>
            <View>
              <Button
                accessibilityLabel="Next"
                size="block"
                type="primary"
                style={styles.button}
                onPress={onNextPress}
                disabled={buttonDisabled}
              >
                Next
              </Button>
            </View>
          </View>
        )}
      </View>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    margin: 16,
    justifyContent: 'space-between',
  },
  boxes: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    gap: 8,
  },
  benefits: {
    marginTop: 24,
  },
  footer: {
    marginBottom: 18,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    width: 142,
  },
  monthlyPrice: {
    color: 'theme.text.tertiary',
    textDecorationLine: 'line-through',
  },
})
