import { useSelector } from 'react-redux'
import { uiStatesSelector } from '@src/selectors/app'
import { UiStateNames } from '@src/types'

export const useSteloShowUpgraded = () => {
  const uiStates = useSelector(uiStatesSelector)

  const steloShowUpgradedBanner = uiStates.find(
    (uiState) => uiState.name === UiStateNames.SteloShowUpgradedBanner,
  )

  return steloShowUpgradedBanner?.value || false
}
