import React, { forwardRef } from 'react'
import { useRoute } from '@react-navigation/native'

export const useForceLandscapeOrientation = () => {
  const route = useRoute()
  const params = route.params || {}

  if ('forceLandscapeOrientation' in params) {
    return params.forceLandscapeOrientation as boolean
  }

  return false
}

export const withLandscapeOrientation = (Component: any) => {
  return forwardRef((props: any, ref) => {
    const isLandscape = useForceLandscapeOrientation()
    return <Component ref={ref} isLandscape={isLandscape} {...props} />
  })
}
