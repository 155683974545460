import React from 'react'
import { Image, ImageStyle, View } from 'react-native'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Text } from '@src/components/base/Text'
import { NutritionistBubble } from '@src/components/NutritionistBubble'
import { topPriorityCoachAssignmentSelector } from '@src/screens/NutritionistHub/models/nutritionistHub.selectors'
import { StyleService, useStyleSheet } from '@src/style/service'
import { weightLossExperienceResultGraphImage } from '@src/assets/images'
import { userPropertiesSelector } from '@src/selectors/app'
import { SHORT_MONTH_AND_DATE_FORMAT } from '@src/config/momentFormat'
import { Button } from '@src/components/base/Button'
import { Icon } from '@src/components/base/Icon'
import { Bugsnag } from '@src/config'
import { useWeightLossExperience } from './context'

export const PlanDetails = () => {
  const { goToNextStep } = useWeightLossExperience()
  const styles = useStyleSheet(themedStyles)
  const coachAssignment = useSelector(topPriorityCoachAssignmentSelector)
  const { weightLossExperience } = useSelector(userPropertiesSelector) ?? {}
  let parsedWeightLossExperienceProperty = {} as any

  try {
    parsedWeightLossExperienceProperty = JSON.parse(weightLossExperience?.value || '{}')
  } catch {
    Bugsnag.notify('Error parsing weight loss experience property')
  }

  const fourWeekBackgroundColor = '#B9E5F9'
  const fourWeekTextColor = '#236582'
  const goalDateBackgroundColor = '#4EBD60'
  const goalDateTextColor = '#0A4013'

  return (
    <>
      <View style={{ alignItems: 'center' }}>
        <NutritionistBubble
          label="You'll make great progress in just your first 4 calls!"
          photoSrc={coachAssignment?.coach?.coachProfile?.photo}
        />

        <Text type="regular" style={styles.description}>
          With regular sessions and lifestyle changes, we predict you&apos;sll hit your{' '}
          {parsedWeightLossExperienceProperty.desired_weight} lbs goal in{' '}
          {calculateDateDifference(parsedWeightLossExperienceProperty.date_to_reach_desired_weight)}
          !
        </Text>

        <View style={{ position: 'relative' }}>
          <Image
            resizeMethod="resize"
            source={weightLossExperienceResultGraphImage.imageSource}
            style={styles.image as ImageStyle}
          />

          <View style={styles.currentWeight}>
            <Text type="regular" style={styles.currentWeightText}>
              {parsedWeightLossExperienceProperty.current_weight}
            </Text>
          </View>

          <View style={[styles.fourWeekWeight, { backgroundColor: fourWeekBackgroundColor }]}>
            <Text type="regular" style={[styles.fourWeekWeightText, { color: fourWeekTextColor }]}>
              {parsedWeightLossExperienceProperty.four_week_weight}
            </Text>
          </View>

          <Text type="regular" style={[styles.goalDateText, { color: goalDateTextColor }]}>
            {moment(parsedWeightLossExperienceProperty.date_to_reach_desired_weight).format(
              SHORT_MONTH_AND_DATE_FORMAT,
            )}
          </Text>

          <View style={[styles.goalWeight, { backgroundColor: goalDateBackgroundColor }]}>
            <Text type="regular" style={styles.goalWeightText}>
              {parsedWeightLossExperienceProperty.desired_weight} lb
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.bottomContainer}>
        <Text type="regular" style={styles.note}>
          Lets start with booking your first calls and we can book the remaining later on!
        </Text>
        <Button
          type="primary"
          size="block"
          accessoryRight={<Icon name="arrow-right" />}
          accessibilityLabel="Continue"
          onPress={goToNextStep}
        >
          Schedule Initial Calls
        </Button>
      </View>
    </>
  )
}

const themedStyles = StyleService.create({
  description: {
    fontSize: 15,
    textAlign: 'center',
    marginTop: 100,
  },
  image: {
    maxWidth: 330,
    height: undefined,
    maxHeight: 210,
    alignSelf: 'center',
    aspectRatio: 16 / 9,
    marginTop: 32,
  },
  currentWeight: {
    position: 'absolute',
    backgroundColor: 'theme.text.inColor',
    top: 67,
    left: 18,
    width: 45,
    borderRadius: 16,
  },
  currentWeightText: {
    color: 'theme.text.primary',
    textAlign: 'center',
    fontSize: 15,
    fontWeight: 700,
  },
  fourWeekWeight: {
    position: 'absolute',
    top: 97,
    width: 60,
    left: 130,
  },
  fourWeekWeightText: {
    fontSize: 13,
    fontWeight: 700,
    textAlign: 'center',
  },
  goalDateText: {
    fontSize: 13,
    fontWeight: 700,
    textAlign: 'center',
    position: 'absolute',
    top: 118,
    right: 38,
  },
  goalWeight: {
    position: 'absolute',
    bottom: 56,
    right: 15,
    width: 87,
  },
  goalWeightText: {
    color: 'theme.solid.white',
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'center',
  },
  note: {
    fontSize: 15,
    textAlign: 'center',
    marginBottom: 24,
    maxWidth: 300,
  },
  bottomContainer: {
    width: '100%',
    alignItems: 'center',
  },
})

export const calculateDateDifference = (dateString?: string): string => {
  if (!dateString) {
    return ''
  }

  const targetDate = moment(dateString)
  const now = moment()

  const weeksDifference = targetDate.diff(now, 'weeks')
  const monthsDifference = targetDate.diff(now, 'months')

  if (weeksDifference < 8) {
    return `${weeksDifference} week${weeksDifference !== 1 ? 's' : ''}`
  } else {
    return `${monthsDifference} month${monthsDifference !== 1 ? 's' : ''}`
  }
}
