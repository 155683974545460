import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { View } from 'react-native'
import { Product } from '@src/types.ts'
import {
  ProductSummary,
  ProductSummaryProps,
} from '@screens/Marketplace/components/NewMarketplace/ProductSummary.tsx'
import { openUrl } from '@utils'
import { useSelectedAddonModal } from '@screens/Marketplace/utils/hooks.ts'
import {
  cgmSteloImage,
  nutritionistHubDexaScanImage,
  nutritionistHubNutritionCoachingImage,
} from '@assets/images'
import { StyleService, useStyleSheet } from '@src/style/service'

interface ProductsGridV2Props {
  products: Product[]
}

const centeredImageStyles = { alignSelf: 'center' } as const

export const ProductGridV2 = ({ products }: ProductsGridV2Props) => {
  const navigation = useNavigation()
  const selectedAddonModal = useSelectedAddonModal()
  const styles = useStyleSheet(themedStyle)

  const onProductPress = (product: Product) => {
    if (product.url) {
      openUrl(product.url)
      return
    }

    if (product.dietitian) {
      navigation.navigate('SubscriptionCheckout', { product })
      return
    }

    if (product.addon && !product.dietitianMenu) {
      selectedAddonModal(product)
      return
    }

    navigation.navigate('DietitianMenuCheckout', {
      selection: { product },
      goBackOnSuccess: true,
    })
  }

  const getAddonImageProps = (
    product: Product,
  ): Pick<ProductSummaryProps, 'image' | 'imageStyle'> => {
    if (product.cgm) {
      return {
        image: cgmSteloImage,
        imageStyle: { ...centeredImageStyles, paddingHorizontal: 8 },
      }
    }

    if (product.key === 'dexa_scan') {
      return {
        image: nutritionistHubDexaScanImage,
        imageStyle: { ...centeredImageStyles, width: 140, maxWidth: '37%', marginRight: 32 },
      }
    }

    if (product.dietitian) {
      return {
        image: nutritionistHubNutritionCoachingImage,
        imageStyle: { ...centeredImageStyles, maxWidth: 110, paddingHorizontal: 4 },
      }
    }

    return { image: undefined }
  }

  return (
    <View style={styles.container}>
      {products.map((product) => {
        return (
          <ProductSummary
            key={product.key}
            title={product.title}
            description={product.shortDescription || product.description || ''}
            onPress={() => onProductPress(product)}
            {...getAddonImageProps(product)}
          />
        )
      })}
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    gap: 12,
  },
})
