import React, { RefObject, useMemo, useRef, useState } from 'react'
import {
  FlatList,
  NativeScrollEvent,
  NativeSyntheticEvent,
  View,
  useWindowDimensions,
} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useStyleSheet } from '@src/style/service'
import { Button } from '@components/base'
import { HealthPermission } from '@src/screens/Home/components/Permissions/HealthPermission'
import { AppStackParamList } from '@src/navigation/types'
import { StyleService } from '@src/style/service'
import {
  getHealthPermissionsModalStateInteractions,
  getPermissionsModalStateInteractions,
} from '@src/screens/Home/utils/hooks'
import { NotificationPermission } from './NotificationPermission'

export interface PermissionsCarouselScreenProps {
  width: number
  onAllowButtonPressCallback: () => void
}

const {
  shouldShow: shouldShowHealthPermissions,
  markAsShown: markHealthPermissionsAsShown,
} = getHealthPermissionsModalStateInteractions()
const {
  shouldShow: shouldShowNotification,
  markAsShown: markPermissionsAsShown,
} = getPermissionsModalStateInteractions()

// During onboarding a bunch of screens are stacked on top of each other
// PermissionsCarousel expects HealthPermission screen to be the last carousel item
//  to ensure correct navigation to the next onboarding screen
const keyExtractor = (_item: unknown, index: number): string => index.toString()

export const PermissionsCarousel = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const carouselRef = useRef<FlatList>()
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  const permissionScreens = useMemo(() => {
    const screens = [] as { renderScreen: (width: number, callback: () => void) => JSX.Element }[]
    if (shouldShowNotification()) {
      screens.push({
        renderScreen: (width: number, onAllowButtonPressCallback: () => void) => (
          <NotificationPermission
            width={width}
            onAllowButtonPressCallback={onAllowButtonPressCallback}
          />
        ),
      })
      markPermissionsAsShown()
    }

    if (shouldShowHealthPermissions()) {
      screens.push({
        renderScreen: (width: number) => {
          return <HealthPermission width={width} />
        },
      })
      markHealthPermissionsAsShown()
    }

    return screens
    // We only want this useEffect to run on component mount, so deps must be empty
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dimensions = useWindowDimensions()
  const itemWidth = dimensions.width

  const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const index = Math.round(event.nativeEvent.contentOffset.x / itemWidth)
    setActiveSlideIndex(index)
  }

  const goToSlide = (index: number) => {
    carouselRef.current?.scrollToIndex({ index })
  }

  const lastSlideIndex = permissionScreens.length - 1

  const goToNextSlide = () => {
    if (activeSlideIndex < lastSlideIndex) {
      goToSlide(activeSlideIndex + 1)
    } else {
      if (navigation.canGoBack()) {
        navigation.goBack()
      } else {
        navigation.replace('Drawer', { screen: 'Dashboard' })
      }
    }
  }

  return (
    <SafeAreaView edges={['top']} style={styles.content}>
      <View style={styles.contentContainer}>
        <Button
          type="transparent"
          size="small"
          onPress={goToNextSlide}
          style={styles.notNowButton}
          accessibilityLabel="Not now"
        >
          Not Now
        </Button>
        <FlatList
          ref={carouselRef as RefObject<FlatList>}
          data={permissionScreens}
          renderItem={({ item }: { item: typeof permissionScreens[0] }) =>
            item.renderScreen(itemWidth, goToNextSlide)
          }
          horizontal
          keyExtractor={keyExtractor}
          onScroll={onScroll}
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        />
      </View>
    </SafeAreaView>
  )
}

const themedStyle = StyleService.create({
  content: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  contentContainer: {
    flexGrow: 1,
    borderRadius: 16,
    backgroundColor: 'theme.background',
  },
  notNowButton: {
    alignSelf: 'flex-end',
    marginTop: 12,
    paddingRight: 16,
  },
})
