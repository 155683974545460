import React, { useEffect } from 'react'
import { View, TouchableOpacity, ScrollView } from 'react-native'
import { ImageStyle } from 'react-native-fast-image'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useSelector, useDispatch } from 'react-redux'
import { ActivityIndicator } from 'react-native'
import moment from 'moment'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { NutritionistSpecialtiesList } from '@src/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text, Icon } from '@src/components/base'
import { NavigationContainer } from '@src/screens/Common/containers'
import { useGoBack } from '@src/utils/navigation'
import { ImageWithPlaceholder } from '@src/components/Image/FastImageWithPlaceholder'
import { useSnack } from '@src/utils/navigatorContext'
import { ErrorMessages } from '@src/config/logger'
import { EhrAppointment } from '@src/types'
import { RootStackParamList } from '@src/navigation/types'
import { useVideoCallDurations } from '../../hooks/useVideoCallDurations'
import { ehrSuggestedAvailabilitySelector } from '../../models/nutritionistHub.selectors'

const SLOT_TAKEN_ERROR = 'slot is already taken'

export const NutritionistSwitchModal = () => {
  const styles = useStyleSheet(themedStyles)
  const insets = useSafeAreaInsets()
  const goBack = useGoBack()
  const suggestedAvailability = useSelector(ehrSuggestedAvailabilitySelector)
  const { getAppointmentDuration } = useVideoCallDurations()
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()

  const showSnack = useSnack()

  const showBio = () => {
    if (!suggestedAvailability?.providerId) {
      return
    }

    navigation.navigate('NutritionistInfoModal', {
      nutritionistId: suggestedAvailability.providerId,
    })
  }

  useEffect(() => {
    dispatch({ type: 'nutritionistHub/fetchEhrSuggestedAvailability' })
  }, [dispatch])

  useEffect(() => {
    if (suggestedAvailability?.providerId) {
      dispatch({
        type: 'nutritionistHub/fetchCoachAssignments',
        payload: { coachId: suggestedAvailability.providerId },
      })
    }
  }, [dispatch, suggestedAvailability?.providerId])

  const duration = getAppointmentDuration(suggestedAvailability?.appointmentKind)

  const onConfirm = () => {
    if (!suggestedAvailability) {
      return
    }

    dispatch({
      type: 'nutritionistHub/bookAppointment',
      payload: {
        appointmentKind: suggestedAvailability.appointmentKind,
        date: suggestedAvailability.meetingAt,
        coachId: suggestedAvailability.providerId,
      },
      success: (response: EhrAppointment) => {
        dispatch({ type: 'nutritionistHub/fetchUpcomingAppointments' })
        dispatch({ type: 'nutritionistHub/fetchSuggestedAvailability' })

        if (response?.meetingAt) {
          showSnack('Appointment rescheduled successfully', null, 'success')
          navigation.replace('Drawer', {
            screen: 'Dashboard',
            params: { screen: 'NutritionistHub' },
          })
        } else {
          goBack()
        }
      },
      failure: (error: any) => {
        const errorMessage = error?.message || ErrorMessages.ServerError
        showSnack(errorMessage, null, 'error')

        if (errorMessage.includes(SLOT_TAKEN_ERROR)) {
          navigation.navigate('CallScheduling', {
            appointmentKind: suggestedAvailability.appointmentKind,
            allowBackNavigation: true,
            errorMessage:
              'The suggested appointment time is no longer available. Please choose a different time slot',
          })
        }
      },
    })
  }

  const onChooseDifferentSlot = () => {
    navigation.navigate('ChooseDifferentSlotScreen')
  }

  if (!suggestedAvailability) {
    return (
      <NavigationContainer
        title="Confirm New Nutritionist"
        leftIcon={{ pack: 'eva', name: 'close' }}
        goBack={goBack}
      >
        <Text type="regular" style={styles.message}>
          Unfortunately your nutritionist is no longer available. We've assigned you a great new
          match and suggested your next appointment. Click below to confirm or reschedule.
        </Text>
      </NavigationContainer>
    )
  }

  const { meetingAt } = suggestedAvailability

  return (
    <NavigationContainer
      title="Confirm New Nutritionist"
      leftIcon={{ pack: 'eva', name: 'close' }}
      goBack={goBack}
    >
      <ScrollView
        style={[styles.container, { paddingBottom: insets.bottom }]}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <View style={styles.content}>
          <Text type="regular" style={styles.message}>
            Unfortunately your nutritionist is no longer available. We've assigned you a great new
            match and suggested your next appointment. Click below to confirm or reschedule.
          </Text>

          <View style={styles.nutritionistCard}>
            <View style={styles.nutritionistHeader}>
              <View style={styles.nutritionistPhotoContainer}>
                <ImageWithPlaceholder
                  style={styles.nutritionistPhoto as ImageStyle}
                  source={{ uri: suggestedAvailability?.providerProfile?.photo || '' }}
                  placeholderComponent={
                    <ActivityIndicator animating size="small" {...styles.spinner} />
                  }
                />
                <View style={styles.nutritionistInfo}>
                  <Text type="regular" bold>
                    {suggestedAvailability?.providerProfile?.fullName}
                  </Text>
                  {suggestedAvailability?.providerProfile?.credentials && (
                    <Text type="small">{suggestedAvailability?.providerProfile?.credentials}</Text>
                  )}
                </View>
              </View>

              <TouchableOpacity
                style={styles.bioButton}
                onPress={showBio}
                accessibilityLabel="Show Bio"
              >
                <Text type="regular" bold style={styles.bioButtonText}>
                  Show Bio
                </Text>
              </TouchableOpacity>
            </View>

            <Text type="regular" style={styles.fitMessage}>
              Great fit for you!
            </Text>

            <View style={styles.specialtiesContainer}>
              <Text type="small" style={styles.specialtiesTitle}>
                Specialties
              </Text>
              <NutritionistSpecialtiesList
                specialties={suggestedAvailability?.providerProfile?.specialties || []}
              />
            </View>
          </View>

          <View style={styles.appointmentContainer}>
            <Text type="small" style={styles.appointmentTitle}>
              New Appointment
            </Text>
            <View style={styles.appointmentCard}>
              <View style={styles.calendarIconContainer}>
                <Icon name="calendar" style={styles.calendarIcon} />
              </View>
              <View style={styles.appointmentDetails}>
                <Text type="regular" bold>
                  {moment(meetingAt).format('ddd, MMM D')}
                </Text>
                <Text type="small" style={styles.appointmentTime}>
                  {moment(meetingAt).format('h:mm a')} -{' '}
                  {moment(meetingAt).add(duration, 'minutes').format('h:mm a')}
                </Text>
              </View>
              <View style={styles.suggestedTag}>
                <Text type="small" style={styles.suggestedText}>
                  Suggested
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.buttonsContainer}>
          <Button type="primary" size="block" onPress={onConfirm} accessibilityLabel="Confirm">
            Confirm
          </Button>
          <TouchableOpacity
            onPress={onChooseDifferentSlot}
            style={styles.differentTimeButton}
            accessibilityLabel="Choose a Different Time"
          >
            <Text type="large" bold style={styles.differentTimeText}>
              Choose a Different Time
            </Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'theme.background',
  },
  content: {
    flex: 1,
    padding: 16,
  },
  message: {
    textAlign: 'center',
    marginBottom: 24,
  },
  nutritionistCard: {
    paddingTop: 20,
    paddingBottom: 16,
    paddingHorizontal: 20,
    backgroundColor: 'theme.background.light_',
    borderColor: 'theme.surface.base2',
    borderWidth: 2,
    borderRadius: 24,
    marginBottom: 24,
  },
  nutritionistHeader: {
    flexDirection: 'row',
    flexShrink: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  nutritionistPhotoContainer: {
    flexShrink: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  nutritionistPhoto: {
    width: 48,
    height: 48,
    borderRadius: 24,
    backgroundColor: 'theme.secondary.base',
  },
  spinner: {
    width: 24,
    height: 24,
  },
  nutritionistInfo: {
    flexShrink: 1,
    overflow: 'hidden',
    marginLeft: 12,
    justifyContent: 'center',
  },
  bioButton: {
    borderRadius: 40,
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderWidth: 2,
    borderColor: 'theme.surface.base0',
    alignSelf: 'flex-end',
  },
  bioButtonText: {
    color: 'theme.text.primary',
  },
  fitMessage: {
    alignSelf: 'flex-end',
  },
  specialtiesContainer: {
    marginTop: 8,
    flexDirection: 'column',
    gap: 8,
  },
  specialtiesTitle: {
    color: 'theme.text.secondary',
  },
  appointmentContainer: {
    marginTop: 8,
  },
  appointmentTitle: {
    marginBottom: 8,
  },
  appointmentCard: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: 'theme.surface.base2',
    borderWidth: 2,
    borderRadius: 16,
    padding: 16,
  },
  calendarIconContainer: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: 'theme.info.lightest',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 12,
  },
  calendarIcon: {
    width: 24,
    height: 24,
    color: 'theme.info.darkest',
  },
  appointmentDetails: {
    flex: 1,
  },
  appointmentTime: {
    color: 'theme.text.secondary',
  },
  suggestedTag: {
    backgroundColor: 'theme.info.lightest',
    borderRadius: 16,
    paddingVertical: 4,
    paddingHorizontal: 12,
  },
  suggestedText: {
    color: 'theme.info.darkest',
  },
  buttonsContainer: {
    padding: 16,
  },
  differentTimeButton: {
    alignItems: 'center',
    paddingVertical: 16,
  },
  differentTimeText: {
    color: 'theme.text.primary',
  },
})

export default NutritionistSwitchModal
