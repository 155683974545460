import { useFeatureFlag, Feature } from '@src/components/FeatureFlag'
import { useIsOwnSensorGlucoseDetected } from '@src/screens/OwnSensorOnboarding/hooks/useIsOwnSensorGlucoseDetected'
import {
  useIsDexcomSteloSensorMode,
  useHasSyncedSteloRealtime,
  useMarkedForTransitionToSteloRealtime,
} from '@src/utils/hooks'

export const useShowPurchasedSteloSetupPopup = () => {
  const isSteloSignupEnabled = useFeatureFlag(Feature.SteloSignUp)
  const isDexcomSteloSensorMode = useIsDexcomSteloSensorMode()
  const hasSyncedSteloRealtime = useHasSyncedSteloRealtime()
  const isMarkedForTransitionToSteloRealtime = useMarkedForTransitionToSteloRealtime()
  const isOwnSensorGlucoseDetected = useIsOwnSensorGlucoseDetected()

  if (!isSteloSignupEnabled) {
    return false
  }

  if (!isDexcomSteloSensorMode) {
    return false
  }

  // this is an existing user who has already synced via apple health (should see different popup)
  if (isOwnSensorGlucoseDetected) {
    return false
  }

  // member has never created a stelo connection
  if (hasSyncedSteloRealtime) {
    return false
  }

  // member is transitioning from other sensor model, should not see this popup
  return !isMarkedForTransitionToSteloRealtime
}
