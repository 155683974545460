import { useSelector } from 'react-redux'
import { Feature, useFeatureFlag } from '@src/components'
import { shouldShowModalOnce } from '@src/hooks/useOneTimeVisitedState'
import { userSelector } from '@src/selectors/app'
import { Storage } from '@src/utils'
import { useSteloShowUpgraded } from './useSteloShowUpgraded'

export const useShowUpgradedToSteloAnnouncement = () => {
  const isUpgradedToSteloAnnouncementEnabled = useFeatureFlag(Feature.UpgradedToSteloAnnouncement)
  const user = useSelector(userSelector)
  const shouldShowAnnouncement = useSteloShowUpgraded()
  const shouldShow = shouldShowModalOnce(
    `${Storage.UPGRADED_TO_STELO_BANNER_VISITED_KEY}_${user?.id}`,
  )

  if (!isUpgradedToSteloAnnouncementEnabled) {
    return false
  }

  if (!shouldShowAnnouncement) {
    return false
  }

  return shouldShow
}
