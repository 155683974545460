import type React from 'react'
import { View } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import Svg, { Circle } from 'react-native-svg'
import Animated, {
  useSharedValue,
  useAnimatedProps,
  withTiming,
  Easing,
} from 'react-native-reanimated'
import { useEffect } from 'react'
import { Icon, IconProps } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'

interface ProgressIconProps {
  name: IconProps['name']
  progress: number
  size?: number
  strokeWidth?: number
  style?: any
  iconStyle?: any
  progressColor?: string
  animationDuration?: number
}

const AnimatedCircle = Animated.createAnimatedComponent(Circle)

export const ProgressIcon = ({
  name,
  progress,
  size = 53,
  strokeWidth = 3,
  style,
  iconStyle,
  progressColor,
  animationDuration = 500,
}: ProgressIconProps): React.ReactElement => {
  const styles = useStyleSheet(themedStyle)
  const theme = useTheme()

  const normalizedProgress = Math.min(100, Math.max(0, progress))

  const radius = (size - strokeWidth) / 2
  const circumference = 2 * Math.PI * radius
  const center = size / 2

  const actualProgressColor = progressColor || theme['theme.info.light_']

  const strokeDashoffset = useSharedValue(circumference)

  useEffect(() => {
    const newDashoffset = circumference - (normalizedProgress / 100) * circumference

    strokeDashoffset.value = withTiming(newDashoffset, {
      duration: animationDuration,
      easing: Easing.bezier(0.25, 0.1, 0.25, 1),
    })
  }, [normalizedProgress, circumference, animationDuration, strokeDashoffset])

  const animatedCircleProps = useAnimatedProps(() => {
    return {
      strokeDashoffset: strokeDashoffset.value,
    }
  })

  return (
    <View style={[styles.container, { width: size, height: size }, style]}>
      <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} style={styles.svg}>
        <Circle
          cx={center}
          cy={center}
          r={radius}
          fill={styles.circle.backgroundColor}
          strokeWidth={strokeWidth}
        />

        <AnimatedCircle
          cx={center}
          cy={center}
          r={radius}
          fill="none"
          stroke={actualProgressColor}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          animatedProps={animatedCircleProps}
          strokeLinecap="round"
          rotation="-90"
          origin={`${center}, ${center}`}
        />
      </Svg>

      <View style={styles.iconContainer}>
        <Icon name={name} style={iconStyle} />
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'theme.surface.base2',
    borderRadius: 1000,
    marginRight: 12,
  },
  svg: {
    position: 'absolute',
  },
  circle: {
    backgroundColor: 'theme.surface.base3',
  },
  iconContainer: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
