import { useSelector } from 'react-redux'
import { useFeatureFlag, Feature } from '@src/components/FeatureFlag'
import { uxSensorModeKindSelector } from '@src/selectors/app'
import { UserExperienceSensorModeKind } from '@src/types'
import { useHasSyncedSteloRealtime, useViewedSteloRealtimeApiUpgradeBanner } from '@src/utils/hooks'

// For users transitioning from Stelo via Apple Health to the Stelo realtime API
export const useShowSteloRealtimeUpgradePopup = () => {
  const isSteloTransitionGuideEnabled = useFeatureFlag(Feature.SteloTransitionGuide)
  const uxSensorModeKind = useSelector(uxSensorModeKindSelector)
  const hasSyncedSteloRealtime = useHasSyncedSteloRealtime()
  const viewedSteloRealtimeApiUpgradeBanner = useViewedSteloRealtimeApiUpgradeBanner()

  if (!isSteloTransitionGuideEnabled) {
    return false
  }

  if (uxSensorModeKind !== UserExperienceSensorModeKind.DexcomStelo) {
    return false
  }

  // Already synced with Stelo realtime successfully
  if (hasSyncedSteloRealtime) {
    return false
  }

  // Have viewed the one-time upgrade announcement banner
  return viewedSteloRealtimeApiUpgradeBanner
}
