import React from 'react'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { Image, ImageStyle, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StackNavigationProp } from '@react-navigation/stack'
import { AppStackParamList } from '@src/navigation/types'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useGoBack } from '@src/utils'
import { NavigationContainer } from '@screens/Common/containers'
import { Button, Text } from '@components/base'
import { Feature, InlineAlert, ScrollViewWithFade, useFeatureFlag } from '@src/components'
import { UiInlineAlert } from '@src/types'
import { nutrisenseSteloConnectionImage } from '@src/assets/images'
import { TutorialGroups, TutorialSensorKind } from '@src/screens/Tutorials/models/tutorials.types'
import { useTutorialNavigation } from '@src/screens/Tutorials/hooks'

const FADE_BUFFER = 24

export const SteloSetup = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const route = useRoute<RouteProp<AppStackParamList, 'SteloSetup'>>()
  const isSteloTransitionGuideEnabled = useFeatureFlag(Feature.SteloTransitionGuide)
  const tutorialSensorKind = isSteloTransitionGuideEnabled
    ? TutorialSensorKind.DexcomSteloRealtime
    : TutorialSensorKind.DexcomStelo
  const navigateToTutorial = useTutorialNavigation(TutorialGroups.Dexcom, tutorialSensorKind)

  const { mode } = route.params || {}
  const goBack = useGoBack()
  const bodyText1 =
    mode === 'migration'
      ? "You're about to switch to Stelo for a better tracking experience. We'll guide you step " +
        "by step—don't worry, it's quick and easy!"
      : "You're about to switch to our Stelo real time integration for a better tracking experience."
  const bodyText2 =
    mode === 'migration'
      ? "If you're not ready right now, you can always come back and complete the setup later."
      : 'In the following screen, you will be asked to enter your username and password that ' +
        'you set up for your Stelo account.'
  const accessibilityLabel =
    mode === 'migration' ? 'Switch to Stelo' : 'Upgrade to Stelo realtime integration'

  const handleExitPress = () => {
    if (navigation.canGoBack()) {
      return goBack()
    }

    navigation.replace('Drawer', { screen: 'Dashboard' })
  }

  const handleNextStepPress = () => {
    // Libre and Dexcom G7 users who need to see the tutorial.
    if (mode === 'migration') {
      return navigateToTutorial()
    }

    // For users who are switching from apple health to Stelo realtime integration
    navigation.navigate('DexcomSensorSettings', { autoToggleOn: true })
  }

  return (
    <NavigationContainer
      title="Stelo Migration"
      leftIcon={{ pack: 'eva', name: 'close' }}
      goBack={handleExitPress}
    >
      <SafeAreaView style={styles.content} edges={['bottom']}>
        <ScrollViewWithFade style={styles.scrollView} scrollViewStyle={styles.scrollViewContent}>
          <View style={styles.headerImageContainer}>
            <Image
              source={nutrisenseSteloConnectionImage.imageSource}
              style={styles.headerImageStyle as ImageStyle}
            />
          </View>
          <View style={styles.textHeader}>
            <Text type="title-2" style={styles.textBody}>
              Let's Get You Set Up with Stelo!
            </Text>
          </View>
          <View style={styles.textBodyContainer}>
            <Text type="regular" style={styles.textBody}>
              {bodyText1}
            </Text>
          </View>
          <View style={styles.textBodyContainer}>
            <Text type="regular" style={styles.textBody}>
              {bodyText2}
            </Text>
          </View>
          {mode === 'migration' && (
            <View style={styles.alertContainer}>
              <InlineAlert
                category={UiInlineAlert.Warning}
                message={
                  'Once you switch to Stelo, other kinds of sensors will no longer work in the app. ' +
                  'Make sure to use any remaining sensors before switching.'
                }
              />
            </View>
          )}
        </ScrollViewWithFade>
        <View style={styles.buttons}>
          <Button
            accessibilityLabel={accessibilityLabel}
            onPress={handleNextStepPress}
            style={styles.nextStepButton}
            size="block"
            type="primary"
          >
            Continue setup
          </Button>
          <Button
            accessibilityLabel="Do setup later"
            type="outline"
            size="block"
            textStyle={styles.secondaryButtonText}
            onPress={handleExitPress}
          >
            Do this later
          </Button>
        </View>
      </SafeAreaView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  content: {
    flex: 1,
    padding: 16,
    backgroundColor: 'theme.background',
  },
  scrollView: {
    top: -FADE_BUFFER,
  },
  scrollViewContent: {
    paddingVertical: FADE_BUFFER,
  },
  headerImageContainer: {
    alignItems: 'center',
  },
  headerImageStyle: {
    width: 252,
    height: 140,
  },
  textHeader: {
    marginVertical: 24,
    marginHorizontal: 16,
  },
  textBodyContainer: {
    marginBottom: 16,
  },
  alertContainer: {
    marginTop: 16,
  },
  textBody: {
    textAlign: 'center',
  },
  buttons: {
    gap: 8,
  },
  nextStepButton: {
    marginTop: -FADE_BUFFER,
    marginBottom: 8,
  },
  secondaryButtonText: {
    textAlign: 'center',
  },
})
