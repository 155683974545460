import Config from 'react-native-config'
import { StreamChat as StreamClient } from 'stream-chat'
import { Env } from '@config/apollo'

const getApiKey = (endpointEnv: Env) => {
  if (endpointEnv === 'production') {
    return Config.STREAM_CHAT_PRODUCTION_API_KEY
  } else if (endpointEnv === 'staging') {
    return Config.STREAM_CHAT_STAGING_API_KEY
  }

  return Config.STREAM_CHAT_DEVELOPMENT_API_KEY
}

class StreamChat {
  private client: StreamClient | null = null

  configure(endpointEnv: Env) {
    this.client = new StreamClient(getApiKey(endpointEnv))
  }

  getClient() {
    return this.client as StreamClient
  }
}

export default new StreamChat()
