import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Pill, Text } from '@src/components/base'
import { hasValue } from '@src/utils'
import { CustomEventTypes } from '@src/config'
import { UiPillType } from '@src/types'
import { Feature, useFeatureFlag } from '@src/components/FeatureFlag'
import { AppointmentDetails } from '../utils'
import {
  DEFAULT_VIDEO_CALL_INFO,
  INSURANCE_BASED_VIDEO_CALLS_PROMOTION_SLOGAN,
  FULL_DAY_MONTH_AND_DATE_FORMAT,
  VIDEO_CALL_DETAILS_MODAL_TIME_FORMAT,
} from '../constants'
import { useNutritionistHubTracker } from '../hooks'
import { Card } from './Card'

interface AppointmentScheduledInfoCardProps {
  appointmentDetails: AppointmentDetails
}

export const AppointmentScheduledInfoCard = (props: AppointmentScheduledInfoCardProps) => {
  const styles = useStyleSheet(themedStyles)
  const { appointmentDetails } = props
  const navigation = useNavigation()
  const track = useNutritionistHubTracker()
  const isRdsSwitchWhenRdIsLeavingEnabled = useFeatureFlag(Feature.RdsSwitchWhenRdIsLeaving)

  const primaryDescription = appointmentDetails.meetingAt
    ? moment(appointmentDetails.meetingAt).format(FULL_DAY_MONTH_AND_DATE_FORMAT)
    : ''
  const secondaryDescription = appointmentDetails.meetingAt
    ? moment(appointmentDetails.meetingAt).format(VIDEO_CALL_DETAILS_MODAL_TIME_FORMAT)
    : INSURANCE_BASED_VIDEO_CALLS_PROMOTION_SLOGAN

  const handleButtonPress = () => {
    if (isRdsSwitchWhenRdIsLeavingEnabled) {
      navigation.navigate('NutritionistSwitchModal')
      return
    }

    track(CustomEventTypes.NutritionistHubViewCallDetailsTapped)
    if (!hasValue(appointmentDetails, 'meetingAt')) {
      return
    }

    if (appointmentDetails.isCallConfirmed) {
      return navigation.navigate('VideoCallConfirmedModal', {
        id: appointmentDetails.id,
        date: appointmentDetails.meetingAt,
        title: appointmentDetails.title,
        addToGoogleCalendarLink: appointmentDetails.addToGoogleCalendarLink,
        unauthenticatedIcsLink: appointmentDetails.unauthenticatedIcsLink,
        nutritionistName: appointmentDetails.providerName,
        appointmentKind: appointmentDetails.kind,
        chargeKind: appointmentDetails.attendances[0].chargeKind,
      })
    } else if (appointmentDetails.isCallInReview) {
      return navigation.navigate('VideoCallInReviewModal', {
        date: appointmentDetails.meetingAt,
        title: appointmentDetails.title,
        nutritionistName: appointmentDetails.providerName,
      })
    }
  }

  return (
    <Card buttonText="View Details" onButtonPress={handleButtonPress}>
      <View style={styles.header}>
        <Icon name="video-camera" size="32" />
        {appointmentDetails.isCallInReview && (
          <Pill status={UiPillType.Warning} text="PENDING" size="s" />
        )}
      </View>
      <Text type="large" lineSpacing="tight" style={styles.title}>
        {appointmentDetails.title || DEFAULT_VIDEO_CALL_INFO.title}
      </Text>
      {primaryDescription && (
        <Text type="small" bold lineSpacing="none" style={styles.description}>
          {primaryDescription}
        </Text>
      )}
      <Text type="small" lineSpacing="none" style={styles.description}>
        {secondaryDescription}
      </Text>
    </Card>
  )
}

const themedStyles = StyleService.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  title: {
    marginBottom: 8,
  },
  description: {
    color: 'theme.text.secondary',
  },
})
