import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { useRoute } from '@react-navigation/native'
import { BottomSheet } from '@src/components'
import { Text, Icon } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ImageWithPlaceholder } from '@src/components/Image/FastImageWithPlaceholder'
import { ImageStyle } from '@src/components/LoadingFastImage'
import { NutritionistSpecialtiesList } from '@src/components'
import { Coach } from '@src/types'
import { AppRouteProp } from '@src/navigation/types'
import {
  ehrSuggestedAvailabilitySelector,
  ehrAvailableDatesWithSlotsSelector,
} from '../models/nutritionistHub.selectors'

export const NutritionistInfoModal = () => {
  const route = useRoute<AppRouteProp<'NutritionistInfoModal'>>()
  const styles = useStyleSheet(themedStyles)

  const suggestedAvailability = useSelector(ehrSuggestedAvailabilitySelector)
  const ehrAvailableDatesWithSlots = useSelector(ehrAvailableDatesWithSlotsSelector)

  const nutritionistId = route.params.nutritionistId

  const provider = ehrAvailableDatesWithSlots?.providers?.find(
    (provider) => provider.id === nutritionistId,
  ) as Coach | undefined

  const coach =
    suggestedAvailability?.providerId === nutritionistId
      ? suggestedAvailability.providerProfile
      : provider

  if (!coach) {
    return null
  }

  const { photo, fullName, credentials, bio, specialties } = coach

  return (
    <BottomSheet header="Bio">
      <View style={styles.modalContent}>
        <View style={styles.nutritionistContainer}>
          <View style={styles.nutritionistHeader}>
            {photo ? (
              <ImageWithPlaceholder
                style={styles.nutritionistPhoto as ImageStyle}
                source={{ uri: photo }}
                placeholderComponent={
                  <View style={styles.nutritionistAvatarPlaceholder}>
                    <Icon name="user" style={styles.avatarIcon} />
                  </View>
                }
              />
            ) : (
              <View style={styles.nutritionistAvatarPlaceholder}>
                <Icon name="user" style={styles.avatarIcon} />
              </View>
            )}

            <View style={styles.nameContainer}>
              <Text type="regular" bold>
                {fullName}
              </Text>
              {credentials && (
                <Text type="small" style={styles.credentials}>
                  {credentials}
                </Text>
              )}
            </View>
          </View>

          {bio && (
            <View style={styles.bioContainer}>
              <Text type="small" style={styles.sectionTitle}>
                About
              </Text>
              <Text type="regular" style={styles.bio}>
                {bio}
              </Text>
            </View>
          )}

          {specialties && specialties.length > 0 && (
            <View style={styles.specialtiesContainer}>
              <Text type="small" style={styles.sectionTitle}>
                Specialties
              </Text>
              <NutritionistSpecialtiesList specialties={specialties} size="small" />
            </View>
          )}
        </View>
      </View>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  modalContent: {
    flex: 0,
    flexBasis: 'auto',
    marginTop: 16,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  title: {
    flex: 1,
  },
  message: {
    marginBottom: 24,
    textAlign: 'center',
    color: 'theme.text.secondary',
  },
  nutritionistPhoto: {
    width: 64,
    height: 64,
    borderRadius: 32,
  },
  nutritionistAvatarPlaceholder: {
    width: 64,
    height: 64,
    borderRadius: 32,
    backgroundColor: 'theme.surface.base1',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarIcon: {
    width: 24,
    height: 24,
    color: 'theme.text.tertiary',
  },
  nutritionistContainer: {
    backgroundColor: 'theme.background.modal',
    borderRadius: 12,
  },
  nutritionistHeader: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,
    marginBottom: 24,
  },
  nameContainer: {
    flex: 1,
  },
  credentials: {
    color: 'theme.text.secondary',
  },
  sectionTitle: {
    color: 'theme.text.secondary',
  },
  specialtiesContainer: {
    marginTop: 16,
    flexDirection: 'column',
    gap: 12,
  },
  bio: {
    color: 'theme.text.primary',
  },
  bioContainer: {
    marginTop: 20,
    gap: 12,
  },
})
