import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Icon } from '@src/components/base'
import { specialtyToIconMap } from '@src/config/specialtiesIcons'
import { EhrCoachSpecialtyDef } from '@src/types'

interface NutritionistSpecialtiesListProps {
  specialties: Array<EhrCoachSpecialtyDef>
  size?: 'small' | 'medium'
}

export const NutritionistSpecialtiesList = ({
  specialties,
  size = 'medium',
}: NutritionistSpecialtiesListProps) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.specialtiesList}>
      {specialties.map((specialty) => (
        <View key={specialty.name} style={styles.specialtyChip}>
          <Icon name={specialtyToIconMap[specialty.key]} style={styles.specialtyIcon} />
          <Text type={size === 'small' ? 'small' : 'regular'} style={styles.specialtyText}>
            {specialty.name}
          </Text>
        </View>
      ))}
    </View>
  )
}

const themedStyles = StyleService.create({
  specialtiesList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 4,
  },
  specialtyChip: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'theme.surface.base1',
    borderRadius: 8,
    paddingVertical: 2,
    paddingHorizontal: 8,
  },
  specialtyIcon: {
    height: 16,
    width: 16,
    marginRight: 4,
    color: 'theme.text.tertiary',
  },
  specialtyText: {
    color: 'theme.text.secondary',
  },
})
