const SENSITIVE_KEYS = [
  'password',
  'token',
  'providerToken',
  'accessToken',
  'refreshToken',
  'idToken',
]

const FILTER_MASK = '[FILTERED]'

export const filterSensitiveData = (
  data: Record<string, unknown> | Array<Record<string, unknown>> | undefined,
): any => {
  if (Array.isArray(data)) {
    return data.map((element) => filterSensitiveData(element))
  }

  if (data && typeof data === 'object') {
    const newData = { ...data }
    Object.keys(newData).forEach((key) => {
      if (SENSITIVE_KEYS.includes(key) && newData[key] && typeof newData[key] === 'string') {
        newData[key] = FILTER_MASK
      } else if (typeof newData[key] === 'object') {
        newData[key] = filterSensitiveData(newData[key] as any)
      }
    })
    return newData
  }

  return data
}
