import React from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import { Button } from '@components/base'
import { LoadingContainer } from '@src/screens/Common/containers/LoadingContainer'

export const QuestionnaireLoadingScreen = () => {
  const params = useRoute().params as { error: boolean; errorMessage: string }

  const navigation = useNavigation()

  const { error, errorMessage = 'Failed to load questionnaire' } = params

  return (
    <LoadingContainer errorMessage={errorMessage} error={error}>
      <Button type="primary" size="block" accessibilityLabel="Go Back" onPress={navigation.goBack}>
        Go Back
      </Button>
    </LoadingContainer>
  )
}
