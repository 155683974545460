import {
  SurveyLink,
  SurveysConfigKind,
  SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind as FollowupKinds,
  SurveyLinkConfigQuestionItem,
} from '@src/types'

export const lastFinishedSurveyOfKind = (
  kind: SurveysConfigKind,
  surveyLinks: (Pick<SurveyLink, 'finished' | 'startedAt'> & {
    survey: { kind: SurveysConfigKind }
  })[],
) => {
  const surveyLink = surveyLinks
    .filter((surveyLink) => {
      return surveyLink.survey.kind === kind && surveyLink.finished
    })
    .sort((a, b) => b.startedAt - a.startedAt)[0]

  return surveyLink as typeof surveyLink | undefined
}

export const getInlineFollowups = (question: SurveyLinkConfigQuestionItem) => {
  const questionAnswers =
    question.response.answersGroups?.flatMap((answerGroup) => answerGroup.answers) || []

  return questionAnswers?.flatMap((answer) => {
    return answer.followups.filter((followup) => followup.kind === FollowupKinds.Inline)
  })
}
