import React from 'react'
import { View } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button, Icon } from '@components/base'
import { AppRouteProp } from '@src/navigation/types'
import { useDexcomIntegration, useIsLibreLinkup, useLibre3NativeEnabled } from '@src/utils/hooks'

interface NoSensorViewProps {
  onActivateSensorPress(): void
  onGetMoreSensorsPress: () => void
  getMoreSensorsText: string
  activateDisabled: boolean
}

export const NoSensorView = ({
  onActivateSensorPress,
  onGetMoreSensorsPress,
  getMoreSensorsText,
  activateDisabled,
}: NoSensorViewProps) => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const navigation = useNavigation()
  const route = useRoute<AppRouteProp<'SensorSettings'>>()

  const isLibreLinkup = useIsLibreLinkup()
  const { uxSensorModePendingLibre3: isLibre3Native } = useLibre3NativeEnabled()
  const isDexcomIntegration = useDexcomIntegration()

  const isLinkupOrLibre3 = isLibreLinkup || isLibre3Native

  const goToCGMEducation = () => {
    navigation.navigate('CGMEducation', { parentScreen: route.name })
  }

  return (
    <View style={[styles.container, { paddingBottom: insets.bottom + 16 }]}>
      <View style={styles.mainContent}>
        <Icon name="circle-dashed" weight="thin" style={styles.icon} />
        <Text type="regular" style={styles.text}>
          You do not have an active sensor.
        </Text>
      </View>
      <View style={styles.buttonsContainer}>
        <Button
          type="primary"
          size="block"
          onPress={onActivateSensorPress}
          disabled={activateDisabled}
          accessibilityLabel="Activate new sensor"
        >
          Activate New Sensor
        </Button>
        {!isLinkupOrLibre3 && !isDexcomIntegration && (
          <Button
            type="transparent"
            size="block"
            onPress={goToCGMEducation}
            style={styles.secondaryButton}
            accessibilityLabel="How to apply my CGM"
          >
            How to apply my CGM
          </Button>
        )}
        {isLinkupOrLibre3 && !isDexcomIntegration && (
          <Button
            type="transparent"
            size="block"
            onPress={onGetMoreSensorsPress}
            style={styles.secondaryButton}
            accessibilityLabel={getMoreSensorsText}
          >
            {getMoreSensorsText}
          </Button>
        )}
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexGrow: 1,
    justifyContent: 'space-between',
    backgroundColor: 'theme.background',
  },
  mainContent: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    marginHorizontal: 48,
    textAlign: 'center',
  },
  icon: {
    width: 80,
    height: 80,
    marginBottom: 32,
    color: 'theme.text.primary',
  },
  buttonsContainer: {
    marginTop: 24,
    marginHorizontal: 16,
  },
  secondaryButton: {
    marginTop: 8,
  },
})
