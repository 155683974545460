import React, { useRef, useState } from 'react'
import { Keyboard, TouchableWithoutFeedback, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { AsYouType } from 'libphonenumber-js'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Checkbox, Input, Text } from '@components/base'
import { SecureInput } from '@components/inputs/SecureInput'
import { PasswordValidationInfo } from '@components/inputs/PasswordValidationInfo/PasswordValidationInfo'
import {
  termsOfUseSelector,
  privacyPolicySelector,
  telehealthSelector,
  consumerHealthDataPrivacyPolicySelector,
} from '@selectors/app'
import { openUrl, Validators } from '@utils'
import { Asset } from '@utils/image'
import { useSnack } from '@utils/navigatorContext'
import { AddressCountries, LegalPolicyConsentKind } from '@src/types'
import { getCountryCodeByCountry } from '@src/utils/phoneInput'

interface SignUpFormProps {
  photo?: Partial<Asset>
  onSuccess?: () => void
  showTermsOfUseConsentCheckbox?: boolean
  showPhoneNumber?: boolean
}

const COUNTRY_CODE = getCountryCodeByCountry(AddressCountries.Us)

export const SignUpForm = ({
  photo,
  onSuccess,
  showTermsOfUseConsentCheckbox = false,
  showPhoneNumber = false,
}: SignUpFormProps) => {
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const termsOfUseUrl = useSelector(termsOfUseSelector)
  const privacyPolicyUrl = useSelector(privacyPolicySelector)
  const telehealthPolicyUrl = useSelector(telehealthSelector)
  const consumerHealthDataPrivacyPolicyUrl = useSelector(consumerHealthDataPrivacyPolicySelector)
  const [passwordWasFocused, setPasswordWasFocused] = useState(false)

  const {
    control,
    formState: { errors, isValid },
    setFocus,
    watch,
    handleSubmit,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      phoneNumber: '',
      confirmPassword: '',
      termsAccepted: false,
      phoneNumberConsent: false,
    },
  })

  const isLoading = useRef(false)

  const onSignUpPress = handleSubmit(
    ({ firstName, lastName, email, password, phoneNumber, phoneNumberConsent }) => {
      Keyboard.dismiss()

      if (isLoading.current) {
        return
      }

      isLoading.current = true

      const policyConsentKinds = [
        LegalPolicyConsentKind.TermsOfUse,
        LegalPolicyConsentKind.PrivacyPolicy,
        LegalPolicyConsentKind.Telehealth,
        LegalPolicyConsentKind.ConsumerHealthDataPrivacyPolicy,
      ]

      if (phoneNumberConsent) {
        policyConsentKinds.push(LegalPolicyConsentKind.PhoneNumber)
      }

      dispatch({
        type: 'users/create',
        payload: {
          firstName,
          lastName,
          email,
          password,
          photo,
          phoneNumber,
          policyConsentKinds,
        },
        success: () => {
          dispatch({ type: 'app/config' })
          onSuccess?.()
        },
        failure: ({ status }: { status: string }) => {
          if (status === 'exists') {
            showSnack('Member with this email already exists!', null, 'error')
          } else {
            showSnack('Server error, please try again', null, 'error')
          }
        },
        complete: () => {
          isLoading.current = false
        },
      })
    },
  )

  const termsAccepted = watch('termsAccepted')

  const termsOfUseCheckboxContent = (
    <Text type="regular" style={styles.checkBoxContent}>
      I agree to the{' '}
      <TouchableWithoutFeedback
        accessibilityLabel="Terms & Conditions"
        onPress={() => openUrl(termsOfUseUrl)}
      >
        <Text type="regular" bold>
          Terms & Conditions
        </Text>
      </TouchableWithoutFeedback>
      ,{' '}
      <TouchableWithoutFeedback
        accessibilityLabel="Telehealth Consent"
        onPress={() => openUrl(telehealthPolicyUrl)}
      >
        <Text type="regular" bold>
          Telehealth Consent
        </Text>
      </TouchableWithoutFeedback>
      ,{' '}
      <TouchableWithoutFeedback
        accessibilityLabel="Privacy Policy"
        onPress={() => openUrl(privacyPolicyUrl)}
      >
        <Text type="regular" bold>
          Privacy Policy
        </Text>
      </TouchableWithoutFeedback>
      ,{' '}
      <TouchableWithoutFeedback
        accessibilityLabel="Consumer Health Data Privacy Policy"
        onPress={() => openUrl(consumerHealthDataPrivacyPolicyUrl)}
      >
        <Text type="regular" bold>
          Consumer Health Data Privacy Policy
        </Text>
      </TouchableWithoutFeedback>
    </Text>
  )

  const phoneNumberConsentCheckboxContent = (
    <Text type="regular" style={styles.checkBoxContent}>
      <Text type="regular" bold>
        You consent to automated SMS updates for your video calls.
      </Text>{' '}
      Reply STOP to opt out or HELP for assistance. Standard rates may apply. Consent is optional.
    </Text>
  )

  const phoneNumber = watch('phoneNumber')

  return (
    <View accessible={false} testID="SignupScreen/SignupForm">
      <Controller
        control={control}
        name="firstName"
        rules={{ required: true, validate: Validators.NameValidator }}
        render={({ field }) => (
          <Input
            {...field}
            autoCapitalize="words"
            hasError={!!errors.firstName}
            label="First Name"
            onSubmitEditing={() => setFocus('lastName')}
            placeholder="Enter your first name"
            returnKeyType="next"
            style={styles.textInput}
            testID="SignupScreen/FirstName"
          />
        )}
      />
      <Controller
        control={control}
        name="lastName"
        rules={{ required: true, validate: Validators.NameValidator }}
        render={({ field }) => (
          <Input
            {...field}
            autoCapitalize="words"
            hasError={!!errors.lastName}
            label="Last Name"
            onSubmitEditing={() => setFocus(showPhoneNumber ? 'phoneNumber' : 'email')}
            placeholder="Enter your last name"
            returnKeyType="next"
            style={styles.textInput}
            testID="SignupScreen/LastName"
          />
        )}
      />
      {showPhoneNumber && (
        <Controller
          control={control}
          name="phoneNumber"
          rules={{
            required: true,
            validate: (value) => Validators.PhoneNumberValidator(value, COUNTRY_CODE),
          }}
          render={({ field }) => (
            <Input
              {...field}
              label="Phone Number"
              placeholder="Enter phone number"
              keyboardType="phone-pad"
              hasError={!!errors.phoneNumber}
              style={styles.textInput}
              returnKeyType="next"
              onSubmitEditing={() => setFocus('email')}
              onChangeText={(text: string) => {
                const newText = text.trim()
                const formattedNewText = new AsYouType(COUNTRY_CODE).input(newText)

                if (
                  newText.length < (phoneNumber || '').length &&
                  formattedNewText === phoneNumber
                ) {
                  // do not re-format on character removal
                  field.onChange(newText)
                  return
                }

                field.onChange(formattedNewText)
              }}
            />
          )}
        />
      )}
      <Controller
        control={control}
        name="email"
        rules={{ required: true, validate: Validators.EmailValidator }}
        render={({ field }) => (
          <Input
            {...field}
            autoCapitalize="none"
            autoCorrect={false}
            hasError={!!errors.email}
            keyboardType="email-address"
            label="Email"
            onChangeText={(text) => field.onChange(text.trim())}
            onSubmitEditing={() => setFocus('password')}
            placeholder="Enter your email address"
            returnKeyType="next"
            style={styles.textInput}
            testID="SignupScreen/Email"
          />
        )}
      />
      <Controller
        control={control}
        name="password"
        rules={{ required: true, validate: Validators.PasswordValidator }}
        render={({ field }) => (
          <SecureInput
            {...field}
            onFocus={() => {
              setPasswordWasFocused(true)
            }}
            hasError={!!errors.password}
            label="Password"
            onSubmitEditing={() => (termsAccepted ? onSignUpPress() : undefined)}
            placeholder="Set your password"
            returnKeyType="done"
            style={styles.textInput}
            testID="SignupScreen/Password"
          />
        )}
      />
      {passwordWasFocused ? (
        <PasswordValidationInfo password={watch('password')} />
      ) : (
        <View style={styles.passwordValidationInfoMargin} />
      )}
      {showPhoneNumber && (
        <Controller
          name="phoneNumberConsent"
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              style={styles.checkbox}
              checked={field.value}
              hasError={!!errors.termsAccepted}
              text={phoneNumberConsentCheckboxContent}
              testID="SignupScreen/PhoneNumberConsentCheckBox"
            />
          )}
        />
      )}
      {showTermsOfUseConsentCheckbox && (
        <Controller
          name="termsAccepted"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Checkbox
              {...field}
              style={styles.checkbox}
              checked={field.value}
              hasError={!!errors.termsAccepted}
              text={termsOfUseCheckboxContent}
              testID="SignupScreen/CheckBox"
            />
          )}
        />
      )}
      <Button
        allowPressWhenDisabled
        accessibilityLabel="Create account"
        disabled={!isValid}
        onPress={onSignUpPress}
        size="block"
        testID="SignupScreen/SignupButton"
        type="primary"
      >
        Create Account
      </Button>
    </View>
  )
}

const themedStyle = StyleService.create({
  textInput: {
    marginBottom: 16,
  },
  checkbox: {
    marginBottom: 24,
    marginHorizontal: 8,
    alignItems: 'flex-start',
  },
  checkBoxContent: {
    marginLeft: 8,
    flex: 1,
  },
  termsText: {
    marginLeft: 8,
  },
  passwordValidationInfoMargin: {
    height: 16,
  },
})
