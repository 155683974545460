import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFocusEffect } from '@react-navigation/native'
import { userSelector } from '@src/selectors/app'
import Storage from '@src/utils/storage'
import {
  AvailableDates,
  AvailableSlots,
  EhrAppointmentKind,
  EhrAppointmentProviderKind,
  EhrSlot,
  InsuranceBerryStreetAppointmentType,
  Slot,
  Slots,
} from '@src/types'
import { Analytics, CustomEventTypes } from '@src/config'
import { MixpanelEventProperties } from '@src/config/analytics'
import {
  insurancePolicySelector,
  latestUserAppointmentSelector,
  latestUserEhrAppointmentSelector,
} from './models/nutritionistHub.selectors'
import { isUnusedCallsInfoAvailable } from './utils'

export const useAvailableDates = (
  appointmentType?: InsuranceBerryStreetAppointmentType,
  appointmentId?: string,
) => {
  const user = useSelector(userSelector)
  const [availableDates, setAvailableDates] = useState<string[]>([])
  const [selectedDate, setSelectedDate] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [error, setError] = useState(false)

  useEffect(() => {
    setLoading(true)
    dispatch({
      type: 'nutritionistHub/fetchAvailableDays',
      payload: {
        appointmentType,
        timeZone: user?.timeZone,
        appointmentId,
      },
      success: (response: AvailableDates) => {
        setAvailableDates(response.dates)
        if (response.dates.length > 0) {
          setSelectedDate(response.dates[0])
        }
      },
      failure: () => {
        setError(true)
      },
      complete: () => {
        setLoading(false)
      },
    })
  }, [appointmentType, dispatch, user?.timeZone, appointmentId])

  return {
    availableDates,
    loading,
    selectedDate,
    setSelectedDate,
    error,
  }
}

export const useEhrAvailableDates = (
  appointmentKind: EhrAppointmentKind,
  appointmentId?: string,
) => {
  const user = useSelector(userSelector)
  const [availableDates, setAvailableDates] = useState<string[]>([])
  const [selectedDate, setSelectedDate] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [error, setError] = useState(false)

  useEffect(() => {
    setLoading(true)
    dispatch({
      type: 'nutritionistHub/fetchEhrAvailableDays',
      payload: {
        appointmentKind,
        timeZone: user?.timeZone,
        providerKind: EhrAppointmentProviderKind.Coach,
        appointmentId,
        userState: user?.address?.state,
      },
      success: (response: AvailableDates) => {
        setAvailableDates(response.dates)
        if (response.dates.length > 0) {
          setSelectedDate(response.dates[0])
        }
      },
      failure: () => {
        setError(true)
      },
      complete: () => {
        setLoading(false)
      },
    })
  }, [appointmentKind, dispatch, user?.timeZone, appointmentId, user?.address?.state])

  return {
    availableDates,
    loading,
    selectedDate,
    setSelectedDate,
    error,
  }
}

export const useAvailableSlots = (
  date: string | null,
  appointmentType?: InsuranceBerryStreetAppointmentType,
) => {
  const user = useSelector(userSelector)
  const [availableSlots, setAvailableSlots] = useState<Slot[]>([])
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!date) {
      return
    }
    setLoading(true)
    setAvailableSlots([])
    dispatch({
      type: 'nutritionistHub/fetchAvailableSlots',
      payload: {
        appointmentType,
        timeZone: user?.timeZone,
        date,
      },
      success: (response: AvailableSlots) => {
        setAvailableSlots(response.slots)
      },
      complete: () => setLoading(false),
    })
  }, [dispatch, date, appointmentType, user?.timeZone])

  return {
    availableSlots,
    loading,
  }
}

export const useEhrAvailableSlots = (appointmentKind: EhrAppointmentKind, date: string | null) => {
  const user = useSelector(userSelector)
  const [availableSlots, setAvailableSlots] = useState<EhrSlot[]>([])
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!date) {
      return
    }
    setLoading(true)
    setAvailableSlots([])
    dispatch({
      type: 'nutritionistHub/fetchEhrAvailableSlots',
      payload: {
        appointmentKind,
        timeZone: user?.timeZone,
        providerKind: EhrAppointmentProviderKind.Coach,
        date,
      },
      success: (response: Slots) => {
        setAvailableSlots(response.slots)
      },
      complete: () => setLoading(false),
    })
  }, [dispatch, date, appointmentKind, user?.timeZone])

  return {
    availableSlots,
    loading,
  }
}

export const useNutritionistHubTracker = () => {
  const insurancePolicy = useSelector(insurancePolicySelector)
  const user = useSelector(userSelector)
  const latestAppointment = useSelector(latestUserAppointmentSelector)
  const latestEhrAppointment = useSelector(latestUserEhrAppointmentSelector)

  const track = (event: CustomEventTypes, additionalProps?: MixpanelEventProperties) => {
    let defaultProps

    if (user?.migrationFromInsuranceEnabled) {
      defaultProps = {
        insurancePolicyState: insurancePolicy?.lastState?.kind,
        latestAppointment: latestEhrAppointment && {
          lastState: latestEhrAppointment.lastState.kind,
          appointmentKind: latestEhrAppointment.kind,
          date: latestEhrAppointment.meetingAt,
        },
      }
    } else {
      defaultProps = {
        insurancePolicyState: insurancePolicy?.lastState?.kind,
        latestAppointment: latestAppointment && {
          status: latestAppointment.status,
          meetingStatus: latestAppointment.meetingStatus,
          appointmentType: latestAppointment.appointmentType,
          date: latestAppointment.date,
        },
      }
    }
    Analytics.track(event, { ...defaultProps, ...additionalProps })
  }
  return track
}

export const useInitialAppointmentType = () => {
  const dispatch = useDispatch()
  const insurancePolicy = useSelector(insurancePolicySelector)

  useEffect(() => {
    dispatch({
      type: 'nutritionistHub/fetchInsurancePolicy',
    })
  }, [dispatch])

  if (!insurancePolicy || !insurancePolicy.initialAppointmentType) {
    return
  }

  return insurancePolicy.initialAppointmentType.key
}

export const useInitialAppointmentKind = () => {
  const dispatch = useDispatch()
  const insurancePolicy = useSelector(insurancePolicySelector)

  useEffect(() => {
    dispatch({
      type: 'nutritionistHub/fetchInsurancePolicy',
    })
  }, [dispatch])

  if (!insurancePolicy || !insurancePolicy.initialAppointmentKind) {
    return
  }

  return insurancePolicy.initialAppointmentKind.key
}

export const useNoMoreAllotedCallsAlert = () => {
  const insurancePolicy = useSelector(insurancePolicySelector)
  const [showNoAllotedCallsAlert, setShowNoAllotedCallsAlert] = useState(false)
  const noAllotedCallsAlertClosedKey = `${Storage.NO_ALLOTED_CALLS_ALERT_CLOSED_KEY}_${insurancePolicy?.id}`

  const onClose = () => {
    if (insurancePolicy) {
      Storage.set(noAllotedCallsAlertClosedKey, true)
    }
    setShowNoAllotedCallsAlert(false)
  }

  useFocusEffect(
    useCallback(() => {
      if (!insurancePolicy?.eligible) {
        return
      }

      if (!isUnusedCallsInfoAvailable(insurancePolicy) || insurancePolicy.hasUnusedCalls) {
        return
      }

      const isAllotedCallsAlertClosed = Storage.get<boolean>(noAllotedCallsAlertClosedKey)
      if (!isAllotedCallsAlertClosed) {
        setShowNoAllotedCallsAlert(true)
      }
    }, [setShowNoAllotedCallsAlert, insurancePolicy, noAllotedCallsAlertClosedKey]),
  )

  return { showNoAllotedCallsAlert, onClose }
}

export { useAddInsuranceNavigation } from './hooks/useAddInsuranceNavigation'
