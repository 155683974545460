import { Moment } from 'moment'
import { ChartRangeName, RangeItem } from '@src/screens/Insights/types'
import { fontFamily } from '@components/charts/common-styles'
import { base64Url } from '@components/charts/fonts/nbInternationalBase64Url'

export interface DataPoint {
  x: Moment
  y: number | null
  dataType?: 'data' | 'empty' | 'event' | 'placeholder'
  displayDate: string
  displayValue?: string | null
  displayY?: number | null
  tag: string | null
}

export interface Range {
  min: number
  max: number
  goal?: number | null
  goalMin?: number
  goalMax?: number
  tickCount?: number
}

export interface DataDimension {
  values: DataPoint[]
  range: Range
  title: string
  description: string
  type: string
  xAxis: string
  yAxis: string
  meta: {
    [key: string]: any
  }
}

export interface TimelineChartConfig {
  data: DataDimension
  events: DataPoint[]
  ranges: RangeItem[]
  formattedDates: { [key: string]: string }
  tickCount: number
  customTooltip: boolean
  showPoints: boolean
  showTooltip: boolean
  showLastReadingTooltip: boolean
  showEvents: boolean
  padding: string
  placeholder: string | null
  placeholderTitle?: string
  hasChartData: boolean
  disableAnimation: boolean
  yLabel: string
  style: {
    guideLine: string
    lineColor: string
    tooltipMarkerColor: string
    pointColorMeal: string
    pointColorActivity: string
    pointColorJournalEntry: string
    pointColorOther: string
    strokeColor: string
    fontColor: string
    tooltipColor: string
    tooltipTextSecondaryColor: string
    crosshairColor: string
    goodRangeBackground: string
    axisLine: string
    axisTextColor: string
    placeholderTextColor: string
  }
  lineRatingColor: Record<ChartRangeName, string>
}

export const timelineChart = ({
  style,
  data,
  ranges,
  events,
  padding,
  customTooltip,
  showPoints,
  showTooltip,
  showLastReadingTooltip,
  showEvents,
  formattedDates,
  tickCount,
  placeholder,
  placeholderTitle = 'No Data Available',
  hasChartData,
  disableAnimation,
  yLabel,
  lineRatingColor,
}: TimelineChartConfig) => `(function() {
  var nbInternationalFont = new FontFace('${fontFamily}', 'url(${base64Url})');
  nbInternationalFont.load().then(function(font) {
    document.fonts.add(font);

  const chartEl = document.getElementById("chart");
  const canvasOffsetLeft = chartEl.offsetLeft;
  const canvasWidth = chartEl.clientWidth;
  const primaryFormattedDates = ${JSON.stringify(formattedDates)};
  const ranges = ${JSON.stringify(ranges)};

  const style = document.createElement('style');
  style.textContent = \`
    .f2-tooltip {
      padding: 5px 10px;
      background-color: ${style.tooltipColor};
    }
    .f2-tooltip:after {
      display: none;
    }
  \`;
  document.body.append(style)

  chart = new F2.Chart({
    id: 'chart',
    padding: ${padding},
    pixelRatio: window.devicePixelRatio,
  });

  const customTooltipMarker = chart.guide().point({
    position: ['0', -100],
    style: {
      r: 4,
      lineWidth: 4,
      stroke:'${style.tooltipMarkerColor}',
    },
  })

  let chartConfig = {
    x: {
      type: 'timeCat',
      tickCount: ${tickCount},
      formatter: (value) => {
        return primaryFormattedDates[value]
      }
    }
  }

  const source = ${JSON.stringify(data.values)}

  chart.source(source, chartConfig);

  chart.legend(false)

  if(${showTooltip}) {
    chart.tooltip({
      custom: true,
      offsetY: 40,
      layout: 'Vertical',
      showTitle: true,
      showCrosshairs: true,
      crosshairsType: 'y',
      showItemMarker: false,
      titleGap: 4,
      showTooltipMarker: false,
      crosshairsStyle: {
        stroke: '${style.crosshairColor}',
        lineWidth: 1,
      },
      background: {
        radius: 4,
        fill: '${style.tooltipColor}',
        padding: [ 6, 6 ]
      },
      titleStyle: {
        fontSize: 26,
        fontWeight: 'bold',
        fill: '${style.fontColor}',
        textAlign: 'center',
        textBaseline: 'middle'
      },
      nameStyle: {
        fill: '${style.fontColor}',
        textAlign: 'center',
        textBaseline: 'middle',
      },
      valueStyle: {
        fontSize: 10,
        fontWeight: '600',
        fill: '${style.fontColor}',
        textAlign: 'center',
        textBaseline: 'bottom',
      },
      onChange: function(obj) {
        const items = obj.items
        items[0].occurredAt = items[0].origin.x
        obj.items = items
        obj.type = 'tooltip'
        window.ReactNativeWebView.postMessage(stringify(obj))
        customTooltipMarker.position = [items[0].origin.x, items[0].origin.y]
        customTooltipMarker.repaint()
      },
      onShow: function(obj) {
        const { y: clickY, items = [] } = obj

        const dataItem = items.find(i => i.name === 'data')
        const eventItem = items.find(i => i.name === 'event')
        const item = (dataItem || eventItem)
          ? { ...eventItem, ...dataItem }
          : items.find(i => i.name === 'empty')

        // Clean up duplicates from tooltip items.
        items.splice(0)
        if (item) {
          const { displayValue, origin } = item
          item.name = ''
          item.title = origin.displayValue || ''
          item.value = origin.displayDate

          items.push(item)
        }

        const tooltipEl = document.querySelector(".f2-tooltip");
        const currentData = items[0];
        const canShowGlucoseScore = currentData.origin.canShowGlucoseScore !== false;
        const title = currentData.title;
        let description = currentData.origin.description;
        const imageUrl = currentData.origin.imageUrl;
        const liveGlucose = currentData.origin.tag === 'LiveGlucose'
        const bluetoothSvg = liveGlucose ? '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path stroke="#3599EF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m8.938 2.25 4.5 3.375L8.938 9V2.25ZM8.938 9l4.5 3.375-4.5 3.375V9ZM4.438 5.625 8.938 9M4.438 12.375 8.938 9"/></svg>' : ''

        let valueContent = items.map(
          i => '<div style="font-size: 10px; font-weight: 400; color: ${
            style.tooltipTextSecondaryColor
          };">' + i.value + '</div>').join('<br/>'
        )
        let valueHtml = valueContent
        const unitHtml = '<div style="font-size: 10px; display: inline; color: ${
          style.fontColor
        }; margin-left: 2px; font-weight: 400;">${yLabel}</div>'
        if (title) {
          if (canShowGlucoseScore) {
            const itemTime = new Date(currentData.origin.x).getTime();
            const currentTime = new Date().getTime();
            const timeDiffInMinutes = (currentTime - itemTime) / (1000 * 60);
            if (timeDiffInMinutes < 16 && timeDiffInMinutes > 0) {
              description = Math.floor(timeDiffInMinutes) + ' min ago'
              if (timeDiffInMinutes < 1) {
                description = 'Now'
              }
              valueContent = '<div style="font-size: 10px; font-weight: 400; color: ${
                style.tooltipTextSecondaryColor
              };">' + description + '</div>'
            }
          }
          valueHtml = canShowGlucoseScore ? (
            '<div style="font-size: 16px; color: ${
              style.fontColor
            }; font-weight: 700; margin-bottom: 3px;">'
              + title + unitHtml + bluetoothSvg + '</div>' + valueContent
           ) : valueContent
        }
        const descriptionHtml =
          description ? '<div style="font-size: 10px; font-weight: 600; color: ${
            style.fontColor
          }">' + description + '</div>' : ''
        const imageHtml = imageUrl ?
          '<img style="margin-top: 3px" alt="Meal" src="' + imageUrl + '" width="100px" />' :
          ''

        if (${customTooltip}) {
          tooltipEl.innerHTML = '<div style="max-width: 100px;">' + valueHtml + imageHtml + descriptionHtml + '</div>';
        } else {
          tooltipEl.innerHTML = '<div style="max-width: 100px;">' + valueHtml + '</div>';
        }


        // Force the browser to calculate the tooltip dimensions.
        tooltipEl.style.left = '-9999px'; // Temporarily place it off-screen.
        tooltipEl.style.opacity = 1;     // Ensure it's rendered.

        // Wait for the browser to calculate dimensions.
        setTimeout(() => {
          const left = canvasOffsetLeft + currentData.x - tooltipEl.clientWidth / 2;
          const top = currentData.y - tooltipEl.clientHeight / 2;
          tooltipEl.style.left = left + 'px';
        }, 0);

        tooltipEl.style.top = '20px';
        tooltipEl.style.fontFamily = '${fontFamily}';
      },
      onHide: function(obj) {
        const tooltipEl = document.querySelector(".f2-tooltip");
        tooltipEl.style.opacity = 0;
        window.ReactNativeWebView.postMessage(stringify({ type: 'tooltip', items: []}))
      }
    });
  } else {
    chart.tooltip(false);
  }

  chart.axis('y', {
    grid: (text, index, total) => {
      return index !== 0 ? {
        lineDash: [2, 2],
        stroke: '${style.guideLine}',
      } : null;
    },
    label: {
      fontSize: 10,
      fontWeight: 700,
      fontFamily: '${fontFamily}',
      fill: '${style.axisTextColor}',
    },
    line: {
      stroke: '${style.axisLine}',
    }
  });

  chart.axis('x', {
    label: function label(text, index, total) {
      var cfg = {
        fontSize: 10,
        fontWeight: 700,
        fontFamily: '${fontFamily}',
        textAlign: 'center',
        fill: '${style.axisTextColor}',
      };
      if (index === 0) {
        cfg.textAlign = 'left';
      }
      if (index > 0 && index === total - 1) {
        cfg.textAlign = 'right';
      }
      return cfg;
    },
    line: {
      stroke: '${style.axisLine}',
    }
  });

  /* Good ranges background */
  if (${hasChartData}) {
    chart.guide().rect({
      start: ['min', ${data.range.goalMin}],
      end: ['max', ${data.range.goalMax}],
      style: {
        fill: '${style.goodRangeBackground}',
        opacity: 0.1,
      }
    });
  }

  /* Chart ranges line color */
  ${ranges.map(
    ({ from, to, rating }) => `
      chart.guide().regionFilter({
        start: ['min', ${from}],
        end: ['max', ${to}],
        color: '${lineRatingColor[rating]}',
      })
    `,
  )}

  chart.line({connectNulls: ${showPoints}, startOnZero: false})
    .position('x*y')
    .shape('smooth')
    .style({
      lineCap: 'butt',
    })
    .color('dataType', (dataType) => {
      if (dataType === 'data' || dataType === 'placeholder') {
        return '${style.lineColor}';
      } else {
        return 'transparent';
      }
    });

  if (${showEvents}) {
    chart.point()
      .position('x*y')
      .size('tag', (tag) => tag === 'Glucose' ? ${showPoints ? 4 : 0} : 0)
      .style({
        fill: '${style.lineColor}',
        stroke: '${style.lineColor}',
        lineWidth: '1'
      });

    ${JSON.stringify(events)}.forEach((item) => {
      chart.guide().point({
        position: [item.x, item.displayY],
        style: {
          r: item.tag ? 5 : 0,
          fill: (() => {
            if (item.tag === 'Meal') {
              return '${style.pointColorMeal}';
            } else if (item.tag === 'Activity') {
              return '${style.pointColorActivity}';
            } else if (item.tag == 'JournalEntry') {
              return '${style.pointColorJournalEntry}';
            } else {
              return '${style.pointColorOther}';
            }
          })(),
          stroke: '${style.strokeColor}',
          lineWidth: 2,
          shadowColor: 'rgba(0, 0, 0, 0.16)',
          shadowBlur: 4,
          shadowOffsetX: 0,
          shadowOffsetY: 2
        },
      })
    })
  }

  chart.scale('y', {
    min: ${data.range.min},
    max: ${data.range.max},
    tickCount: ${data.range.tickCount} || 8,
    nice: false
  });

  const canvas = chart.get('canvas');
  const group = canvas.addGroup();

  if (${!hasChartData}) {
    group.addShape('text', {
      attrs: {
        text: '${placeholderTitle}',
        // logic for centering placeholder text
        x: (canvas.get('width') - ${placeholderTitle.length * 6.5}) / 2,
        y: 102,
        fontSize: 16,
        fontWeight: 700,
        fontFamily: '${fontFamily}',
        fill: '${style.placeholderTextColor}'
      }
    })

    if (${!!placeholder}) {
      group.addShape('text', {
        attrs: {
          text: '${placeholder}',
          // logic for centering placeholder text
          x: (canvas.get('width') - ${(placeholder || '').length * 6.5}) / 2,
          y: 120,
          fontSize: 13,
          fontWeight: 600,
          fontFamily: '${fontFamily}',
          fill: '${style.placeholderTextColor}'
        }
      })
    }
  }

  const yLabelGroup = canvas.addGroup();
  const yLabel = '${yLabel}';
  if (yLabel) {
    yLabelGroup.addShape('text', {
      attrs: {
        textAlign: 'right',
        x: ${padding}[3],
        y: 20,
        text: yLabel,
        fontSize: '10',
        fontFamily: '${fontFamily}',
        fill: '${style.axisTextColor}'
      }
    });
  }

  ${disableAnimation ? 'chart.animate(false);' : ''}

  chart.render();

  if (${showTooltip && showLastReadingTooltip}) {
    const lastDataPoint = source.reverse().find(item => item.tag === 'Glucose' && Number(item.y) > 0)

    setTimeout(() => {
      const { x, y } = chart.getPosition(lastDataPoint);
      chart.showTooltip({ x, y });
    }, 200);
  }

  window.ReactNativeWebView.postMessage(stringify({ type: 'loaded' }))
})
})(); true;
`
