import React from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { FlatListWithCollapsibleHeader } from '@src/components/FlatListWithCollapsibleHeader'
import { Feature, useFeatureFlag } from '@src/components'
import { AppRouteProp } from '@src/navigation/types'
import { NavigationContainer } from '@src/screens/Common/containers'
import { useProgram, useProgramsWithContent } from '../hooks'
import { ModuleWithContent } from '../models/courses.types'
import {
  ProgramCard,
  PROGRAM_CARD_MAX_HEIGHT,
  PROGRAM_CARD_MIN_HEIGHT,
} from '../components/ProgramCard'
import { LoadingModuleCard, ModuleCard } from '../components/ModuleCard'

export const ProgramContainer = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const insets = useSafeAreaInsets()
  const { params } = useRoute<AppRouteProp<'CoursesProgram'>>()
  const { programs } = useProgramsWithContent()

  const programId = params?.programId || programs[0]?.id

  const { program, modules, isLoading } = useProgram(programId)

  const allCourseModulesUnlocked = useFeatureFlag(Feature.AllCourseModulesUnlocked)

  const renderCard = ({ item: module }: { item: ModuleWithContent }) => {
    if (isLoading) {
      return <LoadingModuleCard />
    }

    const startableModule = modules.find((module) => !module.progress?.complete)
    const allNotCompletedModules = modules.filter(
      (module) => module.progress === null || !module.progress?.complete,
    )
    const isStartable = allCourseModulesUnlocked
      ? allNotCompletedModules.includes(module)
      : module === startableModule

    return (
      <ModuleCard
        module={module}
        isStartable={isStartable}
        onPress={(module, locked) =>
          navigation.navigate('CoursesModule', {
            programId,
            moduleId: module.id,
            locked,
          })
        }
      />
    )
  }

  return (
    <NavigationContainer
      title="Learn"
      shouldOverlayNavigationBar
      leftIconTintColor="theme.solid.white"
      navigationBarProps={{
        backgroundColor: 'transparent',
        titleStyle: styles.navigationTitle,
      }}
    >
      <FlatListWithCollapsibleHeader
        animatedHeader={(headerHeight) => (
          <ProgramCard program={program} animatedHeight={headerHeight} style={styles.header} />
        )}
        headerHeightMin={PROGRAM_CARD_MIN_HEIGHT}
        headerHeightMax={PROGRAM_CARD_MAX_HEIGHT}
        style={styles.container}
        bounces={false}
        contentContainerStyle={{ paddingBottom: insets.bottom + 16 }}
        renderItem={renderCard}
        data={modules}
      />
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  navigationTitle: {
    color: 'theme.solid.white',
  },
  header: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
})
