import { SurveyConfigItem, SurveyLinkConfig, SurveyLinkConfigItem } from '@src/types'

export class QuestionnaireConfigInteractor {
  private questionnaireConfig: SurveyLinkConfig

  constructor(questionnaireConfig: SurveyLinkConfig) {
    this.questionnaireConfig = questionnaireConfig
  }

  public updateConfig = ({
    items,
    questionsCount,
  }: {
    items: SurveyLinkConfigItem[]
    questionsCount: number
  }) => {
    this.questionnaireConfig = {
      ...this.questionnaireConfig,
      items,
      questionsCount,
    }
  }

  public getCurrentItemIndex = () => {
    return this.questionnaireConfig.currentItemIndex
  }

  public setCurrentItemIndex = (newItemIndex: number) => {
    this.questionnaireConfig.currentItemIndex = newItemIndex
  }

  public getQuestionsCount = () => {
    return this.questionnaireConfig.questionsCount
  }

  public getAnsweredQuestionsCount = (currentItemIndex: number) => {
    return (
      this.questionnaireConfig.items
        .slice(0, currentItemIndex)
        .filter((item) => item.type === SurveyConfigItem.Question).length + 1
    )
  }

  public isLastQuestion = (currentItemIndex: number) => {
    const item = this.findItemByIndex(currentItemIndex)
    if (item.type === SurveyConfigItem.Question) {
      const allQuestions = this.questionnaireConfig.items.filter(
        (item) => item.type === SurveyConfigItem.Question,
      )
      return allQuestions.indexOf(item) >= allQuestions.length - 1
    }

    return false
  }

  public findItemByIndex = (index: number) => {
    return this.questionnaireConfig.items[index]
  }

  public getSectionsCount = () => {
    return this.questionnaireConfig.sectionsCount
  }

  public getCurrentIntroScreenIndex = () => {
    for (let i = this.questionnaireConfig.currentItemIndex - 1; i >= 0; i--) {
      if (this.questionnaireConfig.items[i].type === SurveyConfigItem.IntroScreen) {
        return i
      }
    }
    return -1
  }
}
