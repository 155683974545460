import { gql } from '@apollo/client'
import { CORE_MEAL_FIELDS } from '@src/graphql/fragments/meal'
import { CORE_MEASUREMENT_FIELDS } from '@src/graphql/fragments/measurement'

const ALL_EVENTS = gql`
  ${CORE_MEAL_FIELDS}
  ${CORE_MEASUREMENT_FIELDS}
  query allEvents($filter: DateFilter, $pagination: Pagination) {
    allEvents(filter: $filter, pagination: $pagination) {
      events {
        events {
          ...CoreMealFields
          ... on Activity {
            id
            type
            title
            description
            favorite
            startedAt
            endedAt
            status
            length
            statistics {
              aucScore
              glucoseMax
              glucoseMin
              glucoseDelta
              glucose2hrDelta
              glucoseScoreDelta
              glucoseScorePeak
              glucoseScoreAuc
              glucoseScore2hrDelta
              glucoseScore
            }
            glucose {
              range
              healthyRange
              values {
                timestamp
                value
                displayValue
              }
            }
            occurredAt
            createdAt
            externalSource
          }
          ... on Measurement {
            ...CoreMeasurementFields
            values {
              ... on BloodPressureMeasurement {
                systolic
                diastolic
              }
              ... on SingleValueMeasurement {
                value
              }
            }
          }
          ... on JournalEntry {
            id
            title
            body
            occurredAt
            createdAt
            creatorId
            score
            measurements {
              ...CoreMeasurementFields
              values {
                ... on SingleValueMeasurement {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ALL_EVENTS
