import React, { useEffect } from 'react'
import { ScrollView, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import moment from 'moment'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { ErrorMessages } from '@src/config'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Storage, useDispatchAsync, useSnack } from '@src/utils'
import { NotificationBanner } from '@src/components/notifications/NotificationBanner'
import { BillingProduct, NotificationEngineScreen, UiInlineAlert } from '@src/types'
import { Feature, InlineAlert, useFeatureFlag } from '@src/components'
import { FULL_DISPLAY_TIME_FORMAT_WITH_COMMA } from '@config/momentFormat.ts'
import { useFeeProduct } from '@screens/NutritionistHub/hooks/useFeeProduct.ts'
import { useCallSoonAlert } from '@screens/NutritionistHub/hooks/useCallSoonAlert.ts'
import { MemberTopNotes } from '@src/screens/NutritionistHub/components/MemberNotes/MemberTopNotes'
import { useStorageValue } from '@src/utils/storage'
import { UpcomingCalls } from '@screens/NutritionistHub/components/UpcomingCalls/UpcomingCalls.tsx'
import {
  insurancePolicySelector,
  latestUserAppointmentSelector,
} from '../models/nutritionistHub.selectors'
import { useNoMoreAllotedCallsAlert } from '../hooks'
import { useNutritionistMenuProducts } from '../hooks/useNutritionistMenuProducts'
import { useNutritionistHubModals } from '../hooks/useNutritionistHubModals'
import { useInsuranceCardSubmissionData } from '../hooks/useInsuranceCardSubmissionData'
import { useEarlierSlotAvailableAlert } from '../hooks/useEarlierSlotAvailableAlert'
import { ChatCard } from './ChatCard'
import { VideoCallInfoCard } from './VideoCallInfoCard'
import { NutritionistInfoCard } from './NutritionistInfoCard'
import { NutritionistMenuSection } from './NutritionistMenu/NutritionistMenuSection'

export const NutritionistHubScreen = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const latestAppointment = useSelector(latestUserAppointmentSelector)
  const insurancePolicy = useSelector(insurancePolicySelector)
  const isFocused = useIsFocused()
  const showSnack = useSnack()
  const dispatch = useDispatch()
  const dispatchAsync = useDispatchAsync()
  const { showNoAllotedCallsAlert, onClose: onAlertClose } = useNoMoreAllotedCallsAlert()
  const { showCallSoonAlert, onClose: onShowCallSoonAlertClose } = useCallSoonAlert(
    latestAppointment,
  )
  const {
    showAlert: showEarlierSlotAvailableAlert,
    onPress: onEarlierSlotAvailableAlertPress,
  } = useEarlierSlotAvailableAlert()
  const noShowFeeProduct = useFeeProduct(BillingProduct.VideoCallNoShowFee)
  const insuranceCardSubmissionData = useInsuranceCardSubmissionData(insurancePolicy)

  const memberNotesFeatureEnabled = useFeatureFlag(Feature.MemberNotes)
  const videoCallRetentionEnabled = useFeatureFlag(Feature.VideoCallRetention)
  const insuranceCardSubmissionEnabled = useFeatureFlag(Feature.InsuranceCardSubmission)
  const earlierAvailableSlotsCheckFeatureEnabled = useFeatureFlag(
    Feature.VideoCallsEarlierAvailableSlotsCheck,
  )
  const [shouldShowDot, setShouldShowDot] = useStorageValue(Storage.NUTRITIONIST_HUB_RED_DOT)

  const nutritionistMenuProducts = useNutritionistMenuProducts()

  const onSubmitInsuranceCardAlertClose = () => {
    insurancePolicy && insurancePolicy.lastState && navigation.navigate('SubmitInsuranceCardScreen')
  }

  const showSubmitInsuranceCardAlert = insuranceCardSubmissionData.isInsuranceCardSubmissionPending

  const { handleModals: handleHubModals } = useNutritionistHubModals()

  useEffect(() => {
    if (!shouldShowDot || !isFocused) {
      return
    }

    setShouldShowDot(false)
  }, [dispatch, isFocused, setShouldShowDot, shouldShowDot])

  useEffect(() => {
    if (!isFocused) {
      return
    }
    const asyncEffect = async () => {
      try {
        const promises = [
          dispatchAsync({ type: 'nutritionistHub/fetchVideoCallInfo' }),
          dispatchAsync({ type: 'nutritionistHub/fetchInsurancePolicy' }),
          dispatchAsync({ type: 'users/fetch' }),
        ]

        if (latestAppointment?.id && earlierAvailableSlotsCheckFeatureEnabled) {
          promises.push(
            dispatchAsync({
              type: 'nutritionistHub/fetchEarlierAvailableSlots',
              payload: { appointmentId: latestAppointment.id },
            }),
          )
        }

        await Promise.all(promises)

        handleHubModals()
      } catch (error: any) {
        const errorMessage = error?.message || ErrorMessages.ServerError
        showSnack(errorMessage, null, 'error')
      }
    }
    asyncEffect()
  }, [
    dispatchAsync,
    handleHubModals,
    showSnack,
    isFocused,
    latestAppointment?.id,
    earlierAvailableSlotsCheckFeatureEnabled,
  ])

  return (
    <ScrollView>
      <NotificationBanner screen={NotificationEngineScreen.NutritionistHub} />
      <View style={styles.container}>
        <View style={styles.videoCallContainer}>
          {insuranceCardSubmissionEnabled && showSubmitInsuranceCardAlert && (
            <TouchableOpacity
              onPress={onSubmitInsuranceCardAlertClose}
              accessibilityLabel="Submit insurance card"
            >
              <InlineAlert
                style={styles.alert}
                message="Your consult is on hold. We need your insurance card to re-run the benefits check."
                onClose={onSubmitInsuranceCardAlertClose}
                closeIcon="caret-right"
              />
            </TouchableOpacity>
          )}
          {showNoAllotedCallsAlert && (
            <InlineAlert
              style={styles.alert}
              message="You have exhausted your primary coverage for insurance-covered video calls for this year.
              However, you may be eligible for additional coverage.
              Please ask your nutritionist if you are interested in applying."
              onClose={onAlertClose}
            />
          )}
          {showCallSoonAlert && noShowFeeProduct && (
            <InlineAlert
              category={UiInlineAlert.Info}
              style={styles.alert}
              message={`Keep your goals in sight! Join your call at ${moment(
                latestAppointment!.date,
              ).format(FULL_DISPLAY_TIME_FORMAT_WITH_COMMA)} to steer clear of a $${
                noShowFeeProduct.price
              } fee. You nutritionist will wait for 10 minutes before marking you as a no show.`}
              onClose={onShowCallSoonAlertClose}
            />
          )}
          <NutritionistInfoCard />
          {showEarlierSlotAvailableAlert && (
            <TouchableOpacity
              onPress={onEarlierSlotAvailableAlertPress}
              accessibilityLabel="Reschedule your call"
            >
              <InlineAlert
                category={UiInlineAlert.Info}
                style={styles.alert}
                // eslint-disable-next-line max-len
                message="An earlier slot with your nutritionist is now available. Reschedule your call to kickstart your health journey sooner."
                onClose={onEarlierSlotAvailableAlertPress}
                closeIcon="caret-right"
              />
            </TouchableOpacity>
          )}
          <View style={styles.cardContainer}>
            <VideoCallInfoCard appointment={latestAppointment} />
            <ChatCard />
          </View>
        </View>

        {memberNotesFeatureEnabled && (
          <>
            <View style={styles.divider} />
            <MemberTopNotes />
          </>
        )}

        {videoCallRetentionEnabled && latestAppointment && (
          <>
            <View style={styles.divider} />
            <UpcomingCalls />
          </>
        )}
        {nutritionistMenuProducts.length > 0 && (
          <>
            <View style={styles.divider} />
            <NutritionistMenuSection
              style={styles.nutritionistMenuContainer}
              products={nutritionistMenuProducts}
            />
          </>
        )}
      </View>
    </ScrollView>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    marginHorizontal: 16,
    marginTop: 8,
  },
  videoCallContainer: { marginBottom: 16 },
  divider: {
    height: 8,
    backgroundColor: 'theme.surface.base2',
    marginHorizontal: -16,
  },
  cardContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 12,
    minHeight: 200,
  },
  alert: {
    marginBottom: 16,
  },
  nutritionistMenuContainer: {
    marginVertical: 16,
  },
})
