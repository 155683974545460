import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { StyleService } from '@src/style/service'
import { InlineAlert } from '@src/components/InlineAlert'
import { UiInlineAlert } from '@src/types'
import { userSelector } from '@src/selectors/app'
import { Storage } from '@src/utils'
import { useShowSteloSyncDelayBanner } from '@src/utils/hooks'

export const SteloSyncDelayBanner = () => {
  const user = useSelector(userSelector)
  const dismissalKey = `${Storage.STELO_SYNC_DELAY_NOTICE_DISMISSED_KEY}_${user?.id}`
  const showSteloDelayBanner = useShowSteloSyncDelayBanner()

  const [showPopup, setShowPopup] = useState(showSteloDelayBanner)

  if (!showPopup) {
    return null
  }

  const onDismiss = () => {
    Storage.set(dismissalKey, true)
    setShowPopup(false)
  }

  return (
    <InlineAlert
      style={styles.popup}
      category={UiInlineAlert.Info}
      message={
        'We are aware of a 3-hour delay in receiving data from Stelo. ' +
        'We are working on an update as fast as we can!'
      }
      onClose={onDismiss}
    />
  )
}

const styles = StyleService.create({
  popup: {
    marginHorizontal: 16,
    marginTop: 8,
  },
})
