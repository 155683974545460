import React from 'react'
import { TouchableOpacity, useWindowDimensions, View } from 'react-native'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/core'
import { StackNavigationProp } from '@react-navigation/stack'
import { useTheme } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { Device } from '@src/config'
import { useDefaultRangeConfig } from '@src/hooks/useDefaultRangeConfig'
import { InsightsStackParamsList } from '@src/navigation/types'
import { insightsCalendarSelector } from '@src/screens/Insights/models/insights.selectors'
import {
  getMeasurementUnitSymbol,
  getPeriodComparisonText,
  getShortStatTitle,
  getValueChangeText,
  getValueColor,
} from '@src/screens/Insights/utils/utils'
import { usePageConfig } from '@src/screens/Insights/utils/pageConfig'
import { Statistic } from '@src/types'
import { unitSystemStoreStateSelector } from '@src/selectors/settings'
import { measurementsConfigSelector } from '@src/selectors/app'
import { ValueWithEmoji } from './ValueWithEmoji'
interface StatCardProps {
  item: Statistic
}

export const StatCard = ({ item }: StatCardProps) => {
  const theme = useTheme()
  const styles = useStyleSheet(themedStyle)
  const dimensions = useWindowDimensions()

  const calendar = useSelector(insightsCalendarSelector)
  const navigation = useNavigation<
    StackNavigationProp<InsightsStackParamsList, 'InsightsDashboard'>
  >()
  const config = usePageConfig(item.meta.section, item.meta.key)
  const unitSystem = useSelector(unitSystemStoreStateSelector)
  const measurementsConfig = useSelector(measurementsConfigSelector)
  const measurementConfig = measurementsConfig.find(({ name }) => name === item.meta.key)
  const unit = getMeasurementUnitSymbol({
    stat: item,
    defaultUnit: config?.units,
    unitSystem,
    measurementConfig,
  })
  const { ranges = [] } = useDefaultRangeConfig(item.meta.key) ?? {}

  const handleItemPress = () => navigation.navigate('InsightsDetailedPage', { statistic: item })

  const valueChangeText = getValueChangeText(item)
  const periodComparisonText = getPeriodComparisonText(calendar)
  const displayedUnit = valueChangeText === 'No change' ? '' : unit

  const title = getShortStatTitle(item.meta.key, item.meta.section)

  const hasSmallScreen = Device.hasSmallScreen(dimensions)

  const renderStatValue = () => {
    if (item.value === 0) {
      return (
        <Text type="small" bold style={styles.noValue}>
          N/A
        </Text>
      )
    }

    const color = theme[getValueColor(item, ranges)]

    if (item.meta.section === 'journal') {
      return (
        <ValueWithEmoji
          type={hasSmallScreen ? 'small' : 'regular'}
          itemKey={item.meta.key}
          value={item.value}
          emojiSize={20}
          bold
          style={{ color }}
        />
      )
    }

    const value = item.displayValue || item.value
    const showUnit = !item.displayValue || item.value.toString() === item.displayValue

    if (hasSmallScreen) {
      return (
        <Text type="small" bold style={{ color }}>
          {`${value}${showUnit ? unit : ''}`}
        </Text>
      )
    }

    return (
      <View style={styles.statContainer}>
        <Text type="title-3" style={{ color }}>
          {value}
        </Text>
        {showUnit && (
          <Text type="small" bold style={[styles.statUnit, { color }]}>
            {unit}
          </Text>
        )}
      </View>
    )
  }

  return (
    <TouchableOpacity
      accessibilityLabel="StatCard"
      style={styles.wrapper}
      onPress={handleItemPress}
    >
      <View style={styles.titleWrapper}>
        <Text type={hasSmallScreen ? 'small' : 'large'} style={styles.title}>
          {title}
        </Text>
        {!!item.value && !!item.valuePrev && (
          <Text type="regular" style={styles.valueChange}>
            {`${valueChangeText}${displayedUnit} ${periodComparisonText}`}
          </Text>
        )}
      </View>

      {renderStatValue()}

      <Icon
        name="caret-right"
        weight="bold"
        style={[styles.iconForward, hasSmallScreen && styles.iconForwardSmall]}
      />
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  wrapper: {
    flexDirection: 'row',
    height: 80,
    alignItems: 'center',
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: 'theme.surface.base2',
  },
  titleWrapper: {
    justifyContent: 'space-between',
    marginRight: 'auto',
  },
  title: {
    textTransform: 'capitalize',
  },
  valueChange: {
    color: 'theme.text.secondary',
  },
  noValue: {
    color: 'theme.text.secondary',
  },
  iconForward: {
    width: 16,
    height: 16,
    marginLeft: 16,
    color: 'theme.surface.base0',
  },
  iconForwardSmall: {
    marginLeft: 8,
  },
  statContainer: {
    flexDirection: 'row',
  },
  statUnit: {
    alignSelf: 'flex-end',
  },
})
