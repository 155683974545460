import React from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ScrollableAvoidKeyboard } from '@components'
import { SignUpForm } from '@screens/SignUp'
import { NavigationContainer } from '@src/screens/Common/containers'

export const CreateAccountStelo = () => {
  const styles = useStyleSheet(themedStyle)

  const insets = useSafeAreaInsets()

  return (
    <NavigationContainer title="Create Account" style={styles.container}>
      <ScrollableAvoidKeyboard
        style={styles.container}
        contentContainerStyle={[styles.content, { paddingBottom: insets.bottom + 16 }]}
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
        extraScrollHeight={200}
      >
        <SignUpForm showPhoneNumber />
      </ScrollableAvoidKeyboard>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  content: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: 600,
    paddingHorizontal: 16,
    paddingTop: 24,
  },
})
