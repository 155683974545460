import React from 'react'
import { View } from 'react-native'
import { chunk } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { Product, UiPillFill, UiPillSize } from '@src/types'
import { GroupableProduct, GroupedProducts } from '../types/types'
import { calculateSavings } from '../utils/utils'
import { SubscriptionItem } from './SubscriptionItem'

export interface SubscriptionPlansProps {
  item: GroupedProducts
  selectedProduct?: Product
  onProductSelected: (item: Product) => void
}

export const SubscriptionPlans = ({
  item,
  selectedProduct,
  onProductSelected,
}: SubscriptionPlansProps) => {
  const styles = useStyleSheet(themedStyle)

  const includedOptions = item.products[0]?.includedOptions || []

  const handleItemPress = (item: GroupableProduct) => {
    onProductSelected(item as Product)
  }

  return (
    <View style={styles.container}>
      {includedOptions.length > 0 && (
        <>
          <Text type="regular" bold>
            Every plan includes:
          </Text>
          {includedOptions.map((option) => (
            <View key={option} style={styles.row}>
              <Icon name="check" style={styles.icon} />
              <Text type="regular" style={styles.optionText}>
                {option}
              </Text>
            </View>
          ))}
        </>
      )}
      <View style={styles.subscriptions}>
        {chunk(item.products, 2).map((plansPair) => {
          const firstPlan = plansPair[0]
          const secondPlan = plansPair[1] as Product | undefined

          const productSavings = plansPair.map((product) => calculateSavings({ item, product }))
          const productTags = productSavings.map((savings) =>
            savings > 0 ? `Save $${savings}` : undefined,
          )

          const getPillConfig = (text: string | undefined) => {
            if (!text) {
              return undefined
            }

            return {
              text,
              fill: UiPillFill.Citron,
              size: UiPillSize.M,
            }
          }

          return (
            <View style={styles.plansRow} key={firstPlan.id}>
              <SubscriptionItem
                title={firstPlan.title}
                description={firstPlan.description}
                item={firstPlan}
                savings={productSavings[0]}
                pill={getPillConfig(productTags[0])}
                onItemPress={handleItemPress}
                selectMode
                style={styles.subscriptionItem}
                selected={firstPlan === selectedProduct}
              />
              {secondPlan && (
                <SubscriptionItem
                  title={secondPlan.title}
                  description={secondPlan.description}
                  item={secondPlan}
                  savings={productSavings[1]}
                  pill={getPillConfig(productTags[1])}
                  onItemPress={handleItemPress}
                  selectMode
                  style={styles.subscriptionItem}
                  selected={secondPlan === selectedProduct}
                />
              )}
            </View>
          )
        })}
      </View>
      <Text type="small" style={styles.info}>
        At the end of your commitment, your subscription will continue month-to-month at the same
        rate. All plans are billed monthly and auto-renew.
      </Text>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexGrow: 1,
    paddingTop: 24,
    paddingHorizontal: 16,
    backgroundColor: 'theme.background',
  },
  subscriptions: {
    marginTop: 24,
    marginBottom: 12,
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  row: {
    flexDirection: 'row',
    paddingTop: 12,
  },
  plansRow: {
    flexDirection: 'row',
    paddingBottom: 12,
    gap: 12,
  },
  icon: {
    marginRight: 16,
    color: 'theme.success.base',
  },
  optionText: {
    flex: 1,
  },
  info: {
    marginHorizontal: 32,
    textAlign: 'center',
    color: 'theme.text.secondary',
  },
  subscriptionItem: {
    flex: 1,
  },
})
