import React, { useCallback } from 'react'
import { ScrollView, View } from 'react-native'
import { useDispatch } from 'react-redux'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { RouteProp, useFocusEffect, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Controller, useForm } from 'react-hook-form'
import { StyleService, useStyleSheet } from '@style/service.ts'
import { NavigationContainer } from '@screens/Common/containers'
import { Button, Input, Text } from '@components/base'
import { AppStackParamList } from '@navigation/types'
import { EhrChartNoteKind, EhrGetHealthieInstanceKind } from '@src/types.ts'
import { useSnack } from '@utils'
import { CustomEventTypes, ErrorMessages } from '@config'
import { navigateToCheckInCompletedModal } from '@screens/NutritionistHub/components/VideoCallCheckIn/utils.ts'
import { useNutritionistHubTracker } from '@screens/NutritionistHub/hooks.ts'

export const VideoCallCheckInAddendum = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const insets = useSafeAreaInsets()
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const track = useNutritionistHubTracker()
  const route = useRoute<RouteProp<AppStackParamList, 'VideoCallCheckInAddendum'>>()
  const { appointmentId } = route.params

  useFocusEffect(
    useCallback(() => {
      if (track) {
        track(CustomEventTypes.NutritionistHubCheckInAddendumStarted)
      }
    }, [track]),
  )

  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm({
    defaultValues: { addendum: '' },
  })

  const onSubmit = handleSubmit(({ addendum }) => {
    track(CustomEventTypes.NutritionistHubCheckInAddendumSubmitted)
    dispatch({
      type: 'nutritionistHub/createChartNote',
      payload: {
        data: { addendum },
        kind: EhrChartNoteKind.CheckInAddendum,
        getHealthieInstanceKind: EhrGetHealthieInstanceKind.BerryStreet,
        legacyAppointmentId: appointmentId,
      },
      success: () => {
        navigateToCheckInCompletedModal(navigation)
      },
      failure: (error: any) => {
        const errorMessage = error?.message || ErrorMessages.ServerError
        showSnack(errorMessage, null, 'error')
      },
    })
  })

  return (
    <NavigationContainer title="Call Check In" style={{ flex: 1 }}>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={[styles.container, { paddingBottom: insets.bottom + 16 }]}
      >
        <Text type="large" bold>
          Your call Check In has already been completed. Add additional information below:
        </Text>
        <View style={styles.form}>
          <Controller
            control={control}
            name="addendum"
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                {...field}
                hasError={!!errors.addendum}
                onSubmitEditing={onSubmit}
                placeholder="Type here..."
                returnKeyType="next"
              />
            )}
          />
        </View>

        <View style={styles.bottomContainer}>
          <Button
            type="primary"
            size="block"
            disabled={!isValid}
            onPress={onSubmit}
            accessibilityLabel="Send to Nutritionist"
            style={styles.button}
          >
            Send to Nutritionist
          </Button>
        </View>
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexGrow: 1,
    backgroundColor: 'theme.background',
    padding: 16,
  },
  form: {
    flex: 1,
    marginVertical: 16,
  },
  bottomContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  button: {
    marginTop: 'auto',
  },
})
