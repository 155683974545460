import React, { useCallback } from 'react'
import { FlatList } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ModuleWithContent } from '../models/courses.types'
import { LessonWithContent } from '../models/courses.types'
import { ModuleDescription } from './ModuleDescription'
import { LessonCard, LoadingLessonCard } from './LessonCard'

interface ModuleComponentProps {
  module: ModuleWithContent
  lessons?: LessonWithContent[]
  isLoading: boolean
  locked: boolean
  onLessonPress: (lesson: LessonWithContent) => void
}

const renderLoadingItem = () => <LoadingLessonCard />

export const ModuleComponent = ({
  module,
  lessons,
  isLoading,
  locked,
  onLessonPress,
}: ModuleComponentProps) => {
  const insets = useSafeAreaInsets()
  const styles = useStyleSheet(themedStyle)

  const renderHeader = useCallback(
    () => <ModuleDescription description={module.description} locked={locked} />,
    [module.description, locked],
  )

  const renderItem = useCallback(
    ({ item: lesson, index }: { item: LessonWithContent; index: number }) => {
      const isFirst = index === 0
      const isLast = index === (lessons || []).length - 1

      return (
        <LessonCard
          lesson={lesson}
          locked={locked}
          isFirst={isFirst}
          isLast={isLast}
          onPress={onLessonPress}
        />
      )
    },
    [lessons, locked, onLessonPress],
  )

  return (
    <FlatList
      style={styles.container}
      contentContainerStyle={{
        flexGrow: 1,
        paddingBottom: insets.bottom + 16,
      }}
      data={!isLoading ? lessons : Array(10).fill(0)}
      renderItem={!isLoading ? renderItem : renderLoadingItem}
      ListHeaderComponent={renderHeader}
    />
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
})
