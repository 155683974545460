import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'
import { chunk } from 'lodash'
import { useRoute } from '@react-navigation/core'
import { RouteProp } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { Product, SubscriptionCheckoutOptionKind } from '@src/types'
import { SubscriptionItem } from '@src/screens/Marketplace/components/SubscriptionItem'
import { isGroupedProducts } from '@src/screens/Marketplace/types/types'
import { NavigationContainer } from '@src/screens/Common/containers'
import { LoadingContainer } from '@src/screens/Common/containers/LoadingContainer'
import { getBasePlan } from '@src/screens/Marketplace/utils/utils'
import { AppStackParamList, OnboardingStackParamsList } from '@navigation/types'
import { useSignUpProducts } from '../hooks'
import { useNavigateToCheckout } from '../hooks/useNavigateToCheckout'
import { BottomBar } from './SteloSelectSubscription/BottomBar'

export const SteloSelectSubscription = () => {
  const styles = useStyleSheet(themedStyle)
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>()
  const route = useRoute<
    | RouteProp<AppStackParamList, 'SteloSelectSubscription'>
    | RouteProp<OnboardingStackParamsList, 'SteloSelectSubscription'>
  >()
  const { checkoutKind, subscriptionId } = route.params

  const navigateToCheckout = useNavigateToCheckout()

  const { groupedProducts } = useSignUpProducts({ reloadProducts: true })

  const item = groupedProducts.find((group) => isGroupedProducts(group))

  if (!item) {
    return <LoadingContainer />
  }

  const includedOptions = item.products[0]?.includedOptions || []

  const handleItemPress = (item: Product) => {
    setSelectedProduct(item)
  }

  const baseProduct = getBasePlan(item.products)

  const onNextPress = () => {
    if (!selectedProduct) {
      return
    }

    navigateToCheckout({
      product: selectedProduct,
      requireEligibilityCheck: false,
      checkoutOptionKinds: [SubscriptionCheckoutOptionKind.Stelo],
      checkoutKind,
      subscriptionId,
    })
  }

  return (
    <NavigationContainer style={styles.container} title="Program Duration">
      <ScrollView contentContainerStyle={styles.scrollViewContent} bounces={false}>
        <View style={styles.subscriptions}>
          {chunk(item.products, 2).map((plansPair) => {
            const firstPlan = plansPair[0]
            const secondPlan = plansPair[1] as Product | undefined

            return (
              <View style={styles.plansRow} key={firstPlan.id}>
                <SubscriptionItem
                  title={firstPlan.title}
                  description={firstPlan.description}
                  item={firstPlan}
                  pill={firstPlan.pill}
                  onItemPress={handleItemPress}
                  selectMode
                  style={styles.subscriptionItem}
                  showPrice={false}
                  selected={firstPlan === selectedProduct}
                />
                {secondPlan && (
                  <SubscriptionItem
                    title={secondPlan.title}
                    description={secondPlan.description}
                    item={secondPlan}
                    pill={secondPlan.pill}
                    onItemPress={handleItemPress}
                    selectMode
                    showPrice={false}
                    style={styles.subscriptionItem}
                    selected={secondPlan === selectedProduct}
                  />
                )}
              </View>
            )
          })}
        </View>
        {includedOptions.length > 0 && (
          <>
            <Text type="title-3">What you get:</Text>
            <View style={styles.includedOptionsContainer}>
              {includedOptions.map((option) => (
                <View key={option} style={styles.includedOptionRow}>
                  <Icon name="check" weight="bold" style={styles.icon} />
                  <Text type="regular" style={styles.optionText}>
                    {option}
                  </Text>
                </View>
              ))}
            </View>
          </>
        )}
      </ScrollView>
      {selectedProduct && (
        <BottomBar
          selectedProduct={selectedProduct}
          baseProduct={baseProduct}
          onNextPress={onNextPress}
        />
      )}
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexGrow: 1,
    backgroundColor: 'theme.background',
  },
  scrollViewContent: {
    flexGrow: 1,
    paddingHorizontal: 16,
  },
  subscriptions: {
    marginTop: 24,
    marginBottom: 12,
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  row: {
    flexDirection: 'row',
    paddingTop: 12,
  },
  plansRow: {
    flexDirection: 'row',
    paddingBottom: 12,
    gap: 12,
  },
  subscriptionItem: {
    flex: 1,
    minHeight: 106,
  },
  includedOptionsContainer: {
    marginTop: 20,
    gap: 12,
  },
  includedOptionRow: {
    flexDirection: 'row',
  },
  icon: {
    marginRight: 12,
    color: 'theme.success.base',
  },
  optionText: {
    marginTop: 2,
    flex: 1,
  },
})
