import React from 'react'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { AppStackParamList, OnboardingStackParamsList } from '@src/navigation/types'
import { ConfirmSteloEligibility } from './ConfirmSteloEligibility'

export const SteloEligibility = () => {
  const navigation = useNavigation<
    StackNavigationProp<AppStackParamList & OnboardingStackParamsList>
  >()
  const route = useRoute<RouteProp<AppStackParamList, 'SteloEligibility'>>()
  const { nextScreen } = route.params || {}

  const actionCallback = () => {
    navigation.replace(nextScreen?.screen, nextScreen?.params)
  }

  const ineligibleCallback = () => {
    navigation.replace('Drawer')
  }

  return (
    <ConfirmSteloEligibility
      actionCallback={actionCallback}
      ineligibleCallback={ineligibleCallback}
      allowExit
      mode="eligibility"
    />
  )
}
