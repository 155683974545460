import { Image, ImageStyle, View } from 'react-native'
import React from 'react'
import { useTheme } from '@ui-kitten/components'
import { Text } from '@components/base'
import { StyleService, useStyleSheet } from '@style/service.ts'
import { FlexView } from '@components'
import { useIsDarkMode } from '@config/theme.ts'

interface BenefitCardProps {
  title: string
  description: string
  image: {
    imageSource: any
  }
  imagePaddingTop?: number
  imagePaddingBottom?: number
}

export const BenefitCard = ({
  title,
  description,
  image,
  imagePaddingTop,
  imagePaddingBottom,
}: BenefitCardProps) => {
  const styles = useStyleSheet(themedStyle)
  const isDarkMode = useIsDarkMode()
  const theme = useTheme()

  return (
    <View
      style={[
        styles.card,
        { backgroundColor: isDarkMode ? theme['theme.solid.black'] : theme['theme.solid.white'] },
      ]}
    >
      <FlexView
        style={[
          styles.imageContainer,
          { paddingTop: imagePaddingTop, paddingBottom: imagePaddingBottom },
        ]}
      >
        <Image style={styles.image as ImageStyle} source={image.imageSource} />
      </FlexView>

      <View style={styles.textContainer}>
        <Text type="regular" bold style={{ marginBottom: 2 }}>
          {title}
        </Text>
        <Text type="small" style={styles.secondaryText}>
          {description}
        </Text>
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  card: {
    flexDirection: 'row',
    borderColor: 'theme.surface.base',
    backgroundColor: 'theme.solid.white',
    borderRadius: 24,
    borderWidth: 2,
    marginVertical: 4,
    paddingHorizontal: 8,
    gap: 8,
  },
  secondaryText: {
    color: 'theme.text.secondary',
  },
  image: {
    height: '100%',
    width: '100%',
    resizeMode: 'contain',
  },
  textContainer: {
    flex: 2.5,
    paddingVertical: 16,
  },
  imageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
