import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Divider } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Pill, Text } from '@components/base'
import { ProductMin, Subscription, UiPillType } from '@src/types'
import { isSubscriptionActive } from '@src/hooks/useActiveSubscriptions.ts'

interface ProductSummaryProps {
  product: ProductMin
  subscription?: Pick<Subscription, 'id' | 'status'>
  pillLabel: string
  pillStatus: UiPillType
}

export const PurchasedProductSummary = ({
  product,
  subscription,
  pillLabel,
  pillStatus,
}: ProductSummaryProps) => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()

  const showPill = subscription ? isSubscriptionActive(subscription) : true

  const onProductDetailsPress = () => {
    if (subscription) {
      navigation.navigate('ProgramDetails', { subscriptionId: subscription.id })
    } else {
      navigation.navigate('ManageUpcomingSubscriptionModal')
    }
  }

  return (
    <View style={styles.content}>
      <View style={styles.section}>
        <View style={styles.row}>
          <View>
            <Text type="small" style={styles.textSecondary}>
              {subscription ? 'Current' : 'Upcoming'} Plan
            </Text>

            <Text type="large" bold>
              {product.title}
            </Text>
          </View>

          {showPill && <Pill text={pillLabel} status={pillStatus} />}
        </View>
      </View>

      <Divider style={styles.divider} />

      <View style={styles.section}>
        <TouchableOpacity
          accessibilityLabel="Show product details"
          style={styles.row}
          onPress={onProductDetailsPress}
        >
          <Text type="regular" bold style={styles.manageText}>
            Show product details
          </Text>
          <Icon name="caret-right" style={styles.arrowIcon} />
        </TouchableOpacity>
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  content: {
    marginHorizontal: 16,
  },
  section: {
    height: 77,
    justifyContent: 'center',
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textSecondary: {
    color: 'theme.text.secondary',
  },
  manageText: {
    color: 'theme.text.link',
  },
  arrowIcon: {
    width: 16,
    height: 16,
    color: 'theme.surface.base0',
  },
  divider: {
    backgroundColor: 'theme.surface.base2',
    height: 2,
  },
})
