import React, { useEffect } from 'react'
import { ActivityIndicator, ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { useDispatch, useSelector } from 'react-redux'
import { RouteProp, useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import {
  CoachProfile,
  EhrAppointmentRecurrence,
  EhrAppointmentRecurrenceCadence,
  EhrAppointmentRecurrenceDay,
  HealthDataMeasurementType,
} from '@src/types'
import { useUnitSystemMeasurements } from '@src/screens/Measurements/hooks'
import { Button, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigationContainer } from '@screens/Common/containers'
import { ImageWithPlaceholder } from '@src/components/Image/FastImageWithPlaceholder'
import { ImageStyle } from '@src/components/LoadingFastImage'
import { AppStackParamList } from '@src/navigation/types'
import {
  appointmentRecurrenceSelector,
  insurancePolicySelector,
  topPriorityCoachAssignmentSelector,
} from '../models/nutritionistHub.selectors'
import { ExpectedWeightLossChart } from './ExpectedWeightLossChart'

const DEFAULT_CALLS_COUNT = 4

export const ConfirmWeightLossRecurrenceScreen = () => {
  const styles = useStyleSheet(themedStyles)
  const insets = useSafeAreaInsets()

  const route = useRoute<RouteProp<AppStackParamList, 'ConfirmWeightLossRecurrence'>>()
  const { weightLossRate, currentWeight } = route.params

  const measurementUnitConfig = useUnitSystemMeasurements()
  const { units } = measurementUnitConfig[HealthDataMeasurementType.Weight]
  const dispatch = useDispatch()
  const isFocused = useIsFocused()
  const coachAssignment = useSelector(topPriorityCoachAssignmentSelector)
  const insurancePolicy = useSelector(insurancePolicySelector)
  const appointmentRecurrence = useSelector(appointmentRecurrenceSelector)

  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const nutritionist = coachAssignment?.coach

  const { firstName, lastName } = nutritionist || {}
  const { photo, credentials } = nutritionist?.coachProfile || ({} as CoachProfile)

  let nutritionistNameAndTitle = ''
  if (firstName && lastName) {
    nutritionistNameAndTitle = `${firstName} ${lastName.charAt(0)}`
    if (credentials) {
      nutritionistNameAndTitle += `, ${credentials}`
    }
  }

  const proposedRecurrence = {
    ...appointmentRecurrence,
    days: [EhrAppointmentRecurrenceDay.All],
    cadence: EhrAppointmentRecurrenceCadence.Weekly,
    numberOfCalls: 4,
  }

  useEffect(() => {
    if (!isFocused) {
      return
    }

    dispatch({
      type: 'nutritionistHub/fetchCoachAssignments',
    })
  }, [dispatch, isFocused])

  const navigateToUpsertRecurrence = () => {
    if (!insurancePolicy) {
      return navigation.replace('Drawer', {
        screen: 'Dashboard',
        params: { screen: 'NutritionistHub' },
      })
    }
    return navigation.navigate('UpsertRecurrence', {
      appointmentRecurrence: proposedRecurrence as EhrAppointmentRecurrence,
      insurancePolicy,
    })
  }

  return (
    <NavigationContainer title="Schedule Calls" style={{ flex: 1 }}>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={[styles.container, { paddingBottom: insets.bottom + 16 }]}
      >
        <Text type="regular" style={styles.text}>
          We recommend that you meet with your nutritionist once every week to lose up to 8 pounds
          every month.
        </Text>

        <View style={styles.nutritionistInfoContainer}>
          {photo && (
            <ImageWithPlaceholder
              style={styles.nutritionistPhoto as ImageStyle}
              source={{ uri: photo }}
              placeholderComponent={<ActivityIndicator animating size="small" />}
            />
          )}
          <Text type="regular">{nutritionistNameAndTitle}</Text>
        </View>

        <ExpectedWeightLossChart
          initialWeight={currentWeight.toFixed(0)}
          expectedWeightLoss={(weightLossRate * DEFAULT_CALLS_COUNT).toFixed(0)}
          units={units}
        />

        <Text type="regular" style={styles.text}>
          Schedule your calls now and start seeing results!
        </Text>

        <Button
          type="primary"
          size="large"
          style={styles.button}
          accessibilityLabel="Set Goal"
          onPress={navigateToUpsertRecurrence}
        >
          Set Goal
        </Button>
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexGrow: 1,
    backgroundColor: 'theme.background',
    padding: 16,
  },
  bottomContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  button: {
    marginTop: 'auto',
    width: '100%',
  },
  text: {
    alignSelf: 'center',
    textAlign: 'center',
    marginVertical: 16,
  },
  nutritionistInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: 'theme.surface.base1',
    borderRadius: 16,
    borderWidth: 2,
    marginBottom: 24,
    padding: 16,
    alignSelf: 'center',
  },
  nutritionistPhoto: {
    width: 32,
    height: 32,
    borderRadius: 16,
    marginRight: 12,
  },
})
