import React from 'react'
import { TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StyleService } from '@src/style/service'
import { InlineAlert } from '@src/components/InlineAlert'
import {
  useDexcomConnection,
  useDexcomConnectionSensorName,
  useIsActiveDexcom,
} from '@src/utils/hooks'
import { DexcomConnectionStatus, UiInlineAlert } from '@src/types'
import { useIsEligibleForStelo } from '@src/screens/Dexcom/Stelo/hooks/useIsEligibleForStelo'
import { useShowSteloSetupPopupOrchestrator } from './hooks/useShowSteloSetupPopupOrchestrator'

export const MissingDexcomIntegrationPopup = () => {
  const navigation = useNavigation()
  const dexcomConnection = useDexcomConnection()
  const isActiveDexcom = useIsActiveDexcom()
  const isEligibleForStelo = useIsEligibleForStelo()

  const { shouldShow: alreadyShowingSteloPopup } = useShowSteloSetupPopupOrchestrator()

  const sensorName = useDexcomConnectionSensorName()

  const hidePopup =
    !isActiveDexcom ||
    dexcomConnection?.status === DexcomConnectionStatus.Active ||
    alreadyShowingSteloPopup

  if (hidePopup) {
    return null
  }

  const navigateToSensorSettings = () => {
    if (isEligibleForStelo) {
      return navigation.navigate('DexcomSensorSettings')
    }

    navigation.navigate('SteloEligibility', {
      nextScreen: { screen: 'DexcomSensorSettings', params: {} },
    })
  }

  return (
    <TouchableOpacity
      accessibilityLabel="Enable dexcom integration"
      activeOpacity={0.7}
      onPress={navigateToSensorSettings}
    >
      {dexcomConnection?.status === DexcomConnectionStatus.Invalidated ? (
        <InlineAlert
          style={styles.popup}
          category={UiInlineAlert.Danger}
          message={`Your ${sensorName} data integration authentication has been reset. Please authenticate again to integrate your data.`}
        />
      ) : (
        <InlineAlert
          style={styles.popup}
          category={UiInlineAlert.Warning}
          message={`Connect your ${sensorName} data to continue`}
        />
      )}
    </TouchableOpacity>
  )
}

const styles = StyleService.create({
  popup: {
    marginHorizontal: 16,
    marginTop: 8,
  },
})
