import React from 'react'
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button, Icon } from '@components/base'

import { SkipButton } from '@src/screens/SignUp/components/SkipButton'
import { NavigationContainer } from '@src/screens/Common/containers'
import { useAllowSkipPurchaseFlow } from '@src/utils/hooks'
import { userSelector } from '@src/selectors/app'
import { openUrl, Zendesk } from '@src/utils'
import { InlineAlert } from '@src/components'
import { UiInlineAlert } from '@src/types'

export const ProgramError = () => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const allowSkipPurchaseFlow = useAllowSkipPurchaseFlow()
  const user = useSelector(userSelector)

  const dispatch = useDispatch()

  const onSkipPress = () => {
    dispatch({
      type: 'app/skipOnboardingFlow',
    })
  }

  const onSignOutPress = () => {
    dispatch({ type: 'app/logout' })
  }

  const onContactSupportPress = () => {
    openUrl(Zendesk.baseUrl(user?.email))
  }

  return (
    <NavigationContainer title="Program Error" style={styles.container}>
      <ScrollView
        style={styles.container}
        bounces={false}
        contentContainerStyle={{
          flexGrow: 1,
          paddingHorizontal: 16,
          paddingBottom: insets.bottom + 16,
        }}
      >
        <View style={styles.content}>
          <Icon name="warning" weight="fill" style={styles.icon} />
          <Text type="title-2" style={styles.title}>
            Something went wrong
          </Text>
          <Text type="title-3" style={styles.description}>
            It seems like you can’t move forward with your program choice.
          </Text>
        </View>
        <InlineAlert
          category={UiInlineAlert.Neutral}
          message={
            <Text type="regular">
              Questions? Don't hesitate to reach out to our{' '}
              <TouchableOpacity
                accessibilityLabel="Contact Support"
                onPress={onContactSupportPress}
              >
                <Text type="regular" bold lineSpacing="none" style={styles.supportLink}>
                  Support Team
                </Text>
              </TouchableOpacity>
              .
            </Text>
          }
        />
        <View style={styles.buttonsContainer}>
          <Button
            type="transparent"
            size="block"
            onPress={onSignOutPress}
            accessibilityLabel="Sign Out"
          >
            Sign Out
          </Button>
          {allowSkipPurchaseFlow && <SkipButton title="Skip checkout" onPress={onSkipPress} />}
        </View>
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  content: {
    flexGrow: 1,
    alignItems: 'center',
  },
  title: {
    marginTop: 24,
    textAlign: 'center',
  },
  description: {
    marginTop: 32,
    textAlign: 'center',
    maxWidth: 276,
  },
  supportLink: {
    color: 'theme.text.link',
  },
  icon: {
    marginTop: 56,
    width: 56,
    height: 56,
    color: 'theme.error.base',
  },
  buttonsContainer: {
    marginTop: 16,
    gap: 8,
  },
})
