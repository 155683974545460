import { renderColumnChart, SingleDayScoreChartConfig } from './renderColumnChart'

const dayMap: { [key: number]: string } = {
  0: 'SUN',
  1: 'MON',
  2: 'TUE',
  3: 'WED',
  4: 'THU',
  5: 'FRI',
  6: 'SAT',
}

export const renderDayOfWeekChart = ({
  data,
  yLabel = '',
  style,
  range,
}: SingleDayScoreChartConfig) => {
  return renderColumnChart({
    data: data.map((item) => ({ ...item, x: dayMap[item.x as number] })),
    yLabel,
    range,
    style,
    xScaleValues: Object.values(dayMap),
  })
}
