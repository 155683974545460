import React from 'react'
import { useTheme } from '@ui-kitten/components'
import { Image, ImageStyle, View } from 'react-native'
import { Text } from '@components/base'
import { useStyleSheet, StyleService } from '@src/style/service'
import { WarningBanner } from '@src/screens/Home/components/Banners/WarningBanner'
import { useIsDarkMode } from '@src/config/theme'
import { cgmSteloImage } from '@src/assets/images'

interface SteloNoticePopupPopupProps {
  onPress: () => void
  headlineText: string
  bodyText: string
}

export const SteloNoticePopup = ({
  onPress,
  headlineText,
  bodyText,
}: SteloNoticePopupPopupProps) => {
  const styles = useStyleSheet(themedStyles)
  const theme = useTheme()
  const isDarkMode = useIsDarkMode()

  const textColor = isDarkMode ? theme['theme.tooltip.textPrimary'] : theme['theme.text.primary']
  const backgroundColor = isDarkMode
    ? theme['theme.marketing.yellow']
    : theme['theme.marketing.lightYellow']

  const image = <Image source={cgmSteloImage.imageSource} style={styles.image as ImageStyle} />

  const body = (
    <View style={styles.textContainer}>
      <Text type="regular" style={[{ color: textColor }]}>
        <Text type="regular" bold style={[{ color: textColor }]}>
          {headlineText}
        </Text>{' '}
        {'\n'}
        {bodyText}
      </Text>
    </View>
  )

  return (
    <WarningBanner
      accessibilityLabel="Stelo setup"
      onPress={onPress}
      image={image}
      body={body}
      containerStyle={{ backgroundColor }}
    />
  )
}

const themedStyles = StyleService.create({
  popup: {
    backgroundColor: 'theme.marketing.lightYellow',
  },
  textStyle: {
    color: 'theme.text.secondary',
  },
  image: {
    width: 48,
    height: 48,
    marginRight: 8,
  },
  textContainer: {
    flex: 1,
    marginRight: 8,
  },
})
