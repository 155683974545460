import React from 'react'
import { RouteProp, useNavigation } from '@react-navigation/native'
import { useRoute } from '@react-navigation/core'
import { StackNavigationProp } from '@react-navigation/stack'
import { AppStackParamList, OnboardingStackParamsList } from '@navigation/types'
import { ConfirmSteloEligibility } from '@src/screens/Dexcom/Stelo/components/ConfirmSteloEligibility'

export const SteloCheckout = () => {
  const navigation = useNavigation<
    StackNavigationProp<AppStackParamList & OnboardingStackParamsList>
  >()
  const route = useRoute<
    | RouteProp<AppStackParamList, 'SteloCheckout'>
    | RouteProp<OnboardingStackParamsList, 'SteloCheckout'>
  >()
  const { checkoutKind, subscriptionId } = route.params || {}

  const onAddToCartPress = () => {
    navigation.navigate('SteloSelectSubscription', { checkoutKind, subscriptionId })
  }

  const onIneligiblePress = () => {
    navigation.navigate('ProgramError')
  }

  return (
    <ConfirmSteloEligibility
      actionCallback={onAddToCartPress}
      ineligibleCallback={onIneligiblePress}
      mode="checkout"
    />
  )
}
