import { useSelector } from 'react-redux'
import { userSelector } from '@src/selectors/app'
import { SurveyLink, SurveysConfigKind } from '@src/types'
import { lastFinishedSurveyOfKind } from '@src/screens/Questionnaire/utils'
import { usePrescriptionFlowStep, PrescriptionFlowStep } from './usePrescriptionFlowStep'
import { useResearchFlowStep, ResearchFlowStep } from './useResearchFlowStep'
import { SteloFlowStep, useSteloFlowStep } from './useSteloFlowStep'

export enum ApprovalFlowStep {
  CgmPrerequisiteSurvey = 'cgm_prerequisite_survey',
  PrescriptionEligibilitySurvey = 'prescription_eligibility_survey',
  SteloEligibilitySurvey = 'stelo_eligibility_survey',
  ResearchEligibilitySurvey = 'research_eligibility_survey',
  ResearchConsentSurvey = 'research_consent_survey',
  PendingApproval = 'pending_approval',
  Rejected = 'rejected',
  Complete = 'complete',
}

const FINISHED_SURVEY_STATES = [
  ApprovalFlowStep.ResearchConsentSurvey,
  ApprovalFlowStep.Complete,
  ApprovalFlowStep.Rejected,
  ApprovalFlowStep.PendingApproval,
]

const getApprovalFlowStep = (
  prescriptionStep: PrescriptionFlowStep,
  researchStep: ResearchFlowStep,
  steloStep: SteloFlowStep,
) => {
  switch (researchStep) {
    case ResearchFlowStep.EligibilitySurvey:
      return ApprovalFlowStep.ResearchEligibilitySurvey
    case ResearchFlowStep.ConsentSurvey:
      return ApprovalFlowStep.ResearchConsentSurvey
    case ResearchFlowStep.PendingApproval:
      return ApprovalFlowStep.PendingApproval
    case ResearchFlowStep.Rejected:
    case ResearchFlowStep.Withdrawn:
    case ResearchFlowStep.Unknown:
    case ResearchFlowStep.NotApplicable:
    case ResearchFlowStep.Complete:
      // defer to Stelo and prescription
      break
  }

  switch (steloStep) {
    case SteloFlowStep.EligibilitySurvey:
      return ApprovalFlowStep.SteloEligibilitySurvey
    case SteloFlowStep.Rejected:
    case SteloFlowStep.PendingApproval:
    case SteloFlowStep.Complete:
    case SteloFlowStep.Skipped:
    case SteloFlowStep.NotApplicable:
      // Defer to the prescription flow state
      break
  }

  switch (prescriptionStep) {
    case PrescriptionFlowStep.Unknown:
    case PrescriptionFlowStep.EligibilitySurvey:
      return ApprovalFlowStep.PrescriptionEligibilitySurvey
    case PrescriptionFlowStep.Rejected:
      return ApprovalFlowStep.Rejected
    case PrescriptionFlowStep.PendingApproval:
      return ApprovalFlowStep.PendingApproval
    case PrescriptionFlowStep.Skipped:
    case PrescriptionFlowStep.Complete:
    case PrescriptionFlowStep.NotApplicable:
    default:
      return ApprovalFlowStep.Complete
  }
}

const getPrerequisiteStep = (
  approvalStep: ApprovalFlowStep,
  surveyLinks: (Pick<SurveyLink, 'finished' | 'startedAt'> & {
    survey: { kind: SurveysConfigKind }
  })[],
) => {
  const lastFinishedCgmPrerequisiteSurvey = lastFinishedSurveyOfKind(
    SurveysConfigKind.CgmPrerequisite,
    surveyLinks,
  )

  // Users who have not completed the CGM Prerequisite Survey should be forced to complete it
  // unless they finished a legacy survey which contained the same questions.
  if (!lastFinishedCgmPrerequisiteSurvey && !FINISHED_SURVEY_STATES.includes(approvalStep)) {
    return ApprovalFlowStep.CgmPrerequisiteSurvey
  }
}

const getApprovalStep = (
  prescriptionStep: PrescriptionFlowStep,
  researchStep: ResearchFlowStep,
  steloStep: SteloFlowStep,
  surveyLinks: (Pick<SurveyLink, 'finished' | 'startedAt'> & {
    survey: { kind: SurveysConfigKind }
  })[],
) => {
  const approvalFlowStep = getApprovalFlowStep(prescriptionStep, researchStep, steloStep)
  const prerequisiteStep = getPrerequisiteStep(approvalFlowStep, surveyLinks)

  return prerequisiteStep || approvalFlowStep
}

export const useApprovalFlow = () => {
  const user = useSelector(userSelector)
  const prescriptionStep = usePrescriptionFlowStep()
  const researchStep = useResearchFlowStep()
  const steloStep = useSteloFlowStep()
  const approvalStep = getApprovalStep(
    prescriptionStep,
    researchStep,
    steloStep,
    user?.surveyLinks || [],
  )

  const isPrescriptionRequired = prescriptionStep === PrescriptionFlowStep.EligibilitySurvey
  const isResearchRequired =
    researchStep === ResearchFlowStep.EligibilitySurvey ||
    researchStep === ResearchFlowStep.ConsentSurvey
  const isSteloRequired = steloStep === SteloFlowStep.EligibilitySurvey

  const isApprovalRequired =
    prescriptionStep !== PrescriptionFlowStep.Skipped &&
    (isPrescriptionRequired || isResearchRequired || isSteloRequired)

  return {
    approvalStep,
    isPrescriptionRequired,
    isResearchRequired,
    isSteloRequired,
    isApprovalRequired,
  }
}
