import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { userSelector } from '@src/selectors/app'
import { Intercom } from '@src/utils'
import {
  RootStackParamList,
  QuestionnaireScreenName,
  QuestionnaireStackParamList,
} from '@src/navigation/types'

const INTERCOM_INELIGIBLE_ROUTE_NAMES: (keyof RootStackParamList)[] = [
  'CallScheduling',
  'ClaimFreeVideoCallModal',
  'EligibilityCheck',
  'PaidCallsIntroScreen',
  'RescheduleOrCancelVideoCallModal',
  'SelectPlan',
  'SelectSubscription',
  'SubscriptionCheckout',
  'VideoCallConfirmedModal',
  'VideoCallInReviewModal',
  'VideoCallCheckout',
  'VideoCallsGettingStartedScreen',
  'VideoCallsIntroModal',
  ...(Object.keys(QuestionnaireScreenName) as (keyof QuestionnaireStackParamList)[]),
]

// enable or disable intercom based on app state
export const useSetIntercomUser = ({
  hasAppAccess,
  routeName,
}: {
  hasAppAccess: boolean
  routeName: keyof RootStackParamList | null
}) => {
  const user = useSelector(userSelector)

  const disableIntercom =
    !hasAppAccess || (routeName && INTERCOM_INELIGIBLE_ROUTE_NAMES.includes(routeName))

  useEffect(() => {
    const setIntercomUser = async () => {
      if (!user?.id) {
        return
      }

      const intercomUserProperties = {
        id: user.id,
        email: user.email,
        fullName: user.fullName,
        intercomDigest: user.intercomDigest,
      }

      try {
        await Intercom.setUser(intercomUserProperties)
      } catch {
        // Intercom user is already set
      }
    }

    const logoutIntercom = async () => {
      try {
        await Intercom.reset()
      } catch {
        // Intercom user is already removed
      }
    }

    if (disableIntercom) {
      logoutIntercom()
    } else {
      setIntercomUser()
    }
  }, [disableIntercom, user?.email, user?.fullName, user?.id, user?.intercomDigest])
}
