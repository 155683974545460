import { useDispatch } from 'react-redux'
import { useMemo } from 'react'
import { AppAction, AppDispatch } from '@src/redux'

export const useDispatchAsync = () => {
  const dispatch = useDispatch()
  return useMemo(() => dispatchAsync(dispatch), [dispatch])
}

export const injectDispatchAsync = (dispatch: AppDispatch) => ({
  dispatchAsync: dispatchAsync(dispatch),
  dispatch,
})

export const dispatchAsync = (dispatch: AppDispatch) => {
  return <R>(action: AppAction) => {
    return new Promise<R>((resolve, reject) => {
      dispatch({
        ...action,
        success: (data: R) => {
          resolve(data)
        },
        failure: (error: any) => {
          reject(error)
        },
      })
    })
  }
}
