import React from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { ScrollView } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ThresholdType } from '@src/screens/Settings/models/settings.types'
import { User } from '@src/utils'
import { MobileAppFeature } from '@src/types'
import ThresholdSetting from './ThresholdSetting'
import SelectSetting from './SelectSetting'
import DarkModeSetting from './DarkModeSetting'
import MacroGoalSetting from './MacroGoalsSetting'
import FastingWindowSetting from './FastingWindowSetting'
import SettingsSection from './SettingsSection'

export const UserSettingsComponent = () => {
  const styles = useStyleSheet(themedStyles)
  const insets = useSafeAreaInsets()

  const isCGMFeatureAvailable = User.hasFeature(MobileAppFeature.ScanCgm)

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={{ paddingBottom: insets.bottom + 16 }}
      showsVerticalScrollIndicator={false}
    >
      <SettingsSection title="Look and Feel">
        <SelectSetting title="Unit System" field="unitSystem" optionsKey="unitSystemOptions" />
        <DarkModeSetting />
      </SettingsSection>
      {isCGMFeatureAvailable && (
        <SettingsSection title="Chart Settings">
          <SelectSetting title="Chart" field="dashboardGraph" optionsKey="dashboardGraphOptions" />
          <ThresholdSetting
            type={ThresholdType.Glucose}
            title="Glucose Target Range"
            lowField="glucoseLowThreshold"
            highField="glucoseHighThreshold"
            lowOptionsKey="glucoseLowOptions"
            highOptionsKey="glucoseHighOptions"
          />
          <ThresholdSetting
            type={ThresholdType.Ketone}
            title="Ketone Target Range"
            lowField="ketoneLowThreshold"
            highField="ketoneHighThreshold"
            lowOptionsKey="ketoneLowOptions"
            highOptionsKey="ketoneHighOptions"
          />
        </SettingsSection>
      )}

      <SettingsSection title="Nutrition">
        <MacroGoalSetting />
      </SettingsSection>

      <SettingsSection title="Fasting">
        <FastingWindowSetting />
      </SettingsSection>
    </ScrollView>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
})
