import React, { useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { compact, uniq } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import { Text } from '@src/components/base/Text'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button } from '@src/components/base/Button'
import { Icon } from '@src/components/base/Icon'
import {
  Availability,
  EhrAppointmentRecurrenceCadence,
  EhrAppointmentRecurrenceDay,
} from '@src/types'
import {
  buildTimeSlotOptions,
  toStartCaseOption,
  toTimeSlotOption,
} from '@src/screens/NutritionistHub/components/UpcomingCalls/helpers'
import {
  dayOfWeekToRecurrenceDayLookup,
  orderedCadenceOptions,
  TIME_SLOT_FORMAT,
} from '@src/screens/NutritionistHub/components/UpcomingCalls/constants'
import { CommonSelect } from '@src/components/CommonSelect'
import { MultiSelect } from '@src/components/MultiSelect'
import { showSnack } from '@src/services/Bluetooth/utils'
import { useWeightLossExperience } from './context'

export const ScheduleCalls = () => {
  const { goToNextStep } = useWeightLossExperience()
  const styles = useStyleSheet(themedStyles)
  const dispatch = useDispatch()

  const [availabilities, setAvailabilities] = useState<Availability[]>([])
  const [submitting, setSubmitting] = useState(false)

  const defaultValues = {
    cadence: toStartCaseOption(EhrAppointmentRecurrenceCadence.Weekly),
    days: [] as ReturnType<typeof toStartCaseOption>[],
    timeSlots: [] as ReturnType<typeof toTimeSlotOption>[],
  }

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm({ defaultValues })

  const days = watch('days')

  const dayOptions = useMemo(() => {
    const availableDays = uniq(availabilities.map((availability) => availability.dayOfWeek))
      .sort()
      .map((dayOfWeek) => dayOfWeekToRecurrenceDayLookup[dayOfWeek])

    return [EhrAppointmentRecurrenceDay.All, ...availableDays].map(toStartCaseOption)
  }, [availabilities])

  const timeSlotOptions = useMemo(() => {
    return buildTimeSlotOptions(
      availabilities,
      days.map((day) => day.value as EhrAppointmentRecurrenceDay),
    )
  }, [availabilities, days])

  useEffect(() => {
    dispatch({
      type: 'nutritionistHub/fetchEhrAllAvailabilities',
      success: (availabilities: Availability[]) => setAvailabilities(availabilities),
    })
  }, [dispatch])

  const handleScheduleCalls = (formData: typeof defaultValues) => {
    if (!isValid) {
      return
    }

    setSubmitting(true)

    const recurrence = {
      cadence: formData.cadence.value as EhrAppointmentRecurrenceCadence,
      days: formData.days.map((day) => day.value as EhrAppointmentRecurrenceDay),
      numberOfCalls: 4,
      timeSlots: formData.timeSlots.map((timeSlot) => moment(timeSlot.value, TIME_SLOT_FORMAT)),
    }

    dispatch({
      type: 'nutritionistHub/upsertAppointmentRecurrence',
      payload: recurrence,
      success: () => {
        goToNextStep()
      },
      failure: () => {
        showSnack('An error occurred. Please try again.', null, 'error')
      },
      complete: () => {
        setSubmitting(false)
      },
    })
  }

  return (
    <>
      <View style={{ alignItems: 'center', width: '100%' }}>
        <Controller
          control={control}
          name="cadence"
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <View style={{ marginBottom: 24, width: '100%' }}>
              <CommonSelect
                options={orderedCadenceOptions.map(toStartCaseOption)}
                selectedOption={value}
                label="How frequently would you like to have your calls?"
                placeholder="Cadence"
                onSelect={onChange}
              />
              <Text type="regular" style={styles.caption}>
                Select all that apply.
              </Text>
            </View>
          )}
        />
        <Controller
          control={control}
          name="days"
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <View style={{ marginBottom: 24, width: '100%' }}>
              <MultiSelect
                options={dayOptions}
                selectedOptions={value}
                label="What are your preferred day(s) of the week?"
                placeholder="Days"
                // compacting since previously selected day might not be available anymore
                onSelect={(option) => onChange(compact(option))}
              />
              <Text type="regular" style={styles.caption}>
                Select all that apply.
              </Text>
            </View>
          )}
        />
        <Controller
          control={control}
          name="timeSlots"
          render={({ field: { value, onChange } }) => (
            <View style={{ marginBottom: 24, width: '100%' }}>
              <MultiSelect
                options={timeSlotOptions}
                selectedOptions={value}
                label="What are your preferred time slots?"
                placeholder="Time Slots"
                // compacting since previously selected slot might not be available anymore
                onSelect={(option) => onChange(compact(option))}
              />
              <Text type="regular" style={styles.caption}>
                Select all that apply.
              </Text>
            </View>
          )}
        />
      </View>

      <Button
        type="primary"
        size="block"
        accessoryRight={<Icon name="arrow-right" />}
        accessibilityLabel="Continue"
        onPress={handleSubmit(handleScheduleCalls)}
        disabled={submitting}
        style={styles.button}
      >
        Schedule Initial Calls
      </Button>
    </>
  )
}

const themedStyles = StyleService.create({
  title: {
    fontSize: 24,
    textAlign: 'center',
    maxWidth: 240,
    marginBottom: 16,
  },
  caption: {
    marginTop: 4,
    color: 'theme.text.secondary',
  },
  button: {
    width: '100%',
  },
})
