import { useEffect } from 'react'
import { SurveyLinkConfigItem, SurveysConfigKind } from '@src/types'
import { QuestionnaireConfigInteractor } from '../QuestionnaireConfigInteractor'
import { useLoadSurveyLink } from './useLoadSurveyLink'

export const useQuestionnaireState = (kind: SurveysConfigKind) => {
  const {
    loadSurveyLink,
    surveyLink,
    questionnaireConfigInteractor,
    questionnaireForm,
  } = useLoadSurveyLink(kind)

  useEffect(() => {
    loadSurveyLink()
  }, [loadSurveyLink])

  if (!(questionnaireConfigInteractor instanceof QuestionnaireConfigInteractor)) {
    return questionnaireConfigInteractor
  }

  const currentItemIndex = questionnaireConfigInteractor.getCurrentItemIndex()
  const currentIntroScreenIndex = questionnaireConfigInteractor.getCurrentIntroScreenIndex()

  return {
    currentItemIndex,
    setCurrentItemIndex: questionnaireConfigInteractor.setCurrentItemIndex,
    currentIntroScreenIndex,
    hasIntroScreen: currentIntroScreenIndex !== -1,
    questionnaireForm,
    surveyLink,
    loadSurveyLink,
    getSectionsCount: () => questionnaireConfigInteractor.getSectionsCount(),
    isLastQuestion: () => questionnaireConfigInteractor.isLastQuestion(currentItemIndex),
    getQuestionsCount: () => questionnaireConfigInteractor.getQuestionsCount(),
    findItemByIndex: (index: number) => questionnaireConfigInteractor.findItemByIndex(index),
    getAnsweredQuestionsCount: (index: number) =>
      questionnaireConfigInteractor.getAnsweredQuestionsCount(index),
    updateConfig: (params: { items: SurveyLinkConfigItem[]; questionsCount: number }) =>
      questionnaireConfigInteractor.updateConfig(params),
  }
}
