import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { NavigationContainer } from '@src/screens/Common/containers'
import { subscriptionsSelector, userSelector } from '@src/selectors/app'
import { AddressType } from '@src/screens/Marketplace/components/checkout/AddressForm'
import SubscriptionComponent from '../components/Subscription'

const SubscriptionContainer = () => {
  const user = useSelector(userSelector)
  const { address, paymentMethod } = user || {}

  const subscriptions = useSelector(subscriptionsSelector)

  const addressType = subscriptions.some(
    (subscription) => subscription.primaryProduct.requiresShipping,
  )
    ? AddressType.Shipping
    : AddressType.Billing

  const navigation = useNavigation()

  const onPaymentMethodFocus = () => {
    navigation.navigate('CreatePaymentMethod')
  }

  const onAddressFocus = () => {
    navigation.navigate('ChangeAddress', { addressType })
  }

  return (
    <NavigationContainer title="Account settings">
      <SubscriptionComponent
        address={address}
        paymentMethod={paymentMethod}
        subscriptions={subscriptions}
        onAddressFocus={onAddressFocus}
        onPaymentMethodFocus={onPaymentMethodFocus}
      />
    </NavigationContainer>
  )
}

export default SubscriptionContainer
