import { gql } from '@apollo/client'

export const DELETE_DEXCOM_CONNECTION = gql`
  mutation deleteDexcomConnection($dexcomConnectionKind: DexcomConnectionKind!) {
    deleteDexcomConnection(dexcomConnectionKind: $dexcomConnectionKind) {
      status
      lastSyncedAt
    }
  }
`
