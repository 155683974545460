import React, { RefObject, forwardRef, useImperativeHandle, useRef, useState } from 'react'
import {
  FlatList,
  NativeScrollEvent,
  NativeSyntheticEvent,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Source } from 'react-native-fast-image'
import { useStyleSheet } from '@src/style/service'
import { PageIndicator } from '@src/components/PageIndicator'
import { StyleService } from '@src/style/service'
import { useGoBack } from '@src/utils'
import { Icon } from '../base'
import { CarouselItem } from './CarouselItem'

export interface CarouselRef {
  goToSlide: (index: number) => void
}

type CarouselPropsItem = {
  title: string
  description: string
  image: Source
  fullScreenImage?: boolean
}

interface CarouselProps {
  data: CarouselPropsItem[]
  keyExtractor: (item: CarouselPropsItem) => string
  onSlideChange?: (index: number) => void
  children?: React.ReactNode
  showBackButton?: boolean
}

export const Carousel = forwardRef<CarouselRef, CarouselProps>((props, ref) => {
  const { data, keyExtractor, onSlideChange, children, showBackButton = false } = props
  const styles = useStyleSheet(themedStyle)

  const carouselRef = useRef<FlatList>()
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  const dimensions = useWindowDimensions()
  const itemWidth = dimensions.width

  const goBack = useGoBack()

  const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const index = Math.round(event.nativeEvent.contentOffset.x / itemWidth)
    setActiveSlideIndex(index)
    onSlideChange?.(index)
  }

  const goToSlide = (index: number) => {
    carouselRef.current?.scrollToIndex({ index })
  }

  useImperativeHandle(ref, () => ({
    goToSlide,
  }))

  const renderItem = ({ item }: { item: typeof data[0] }) => {
    return (
      <CarouselItem
        title={item.title}
        description={item.description}
        image={item.image}
        width={itemWidth}
        fullScreenImage={item.fullScreenImage}
      />
    )
  }

  return (
    <SafeAreaView style={styles.container} edges={['top', 'bottom']}>
      {showBackButton && (
        <TouchableOpacity
          onPress={goBack}
          activeOpacity={0.7}
          accessibilityLabel="Go Back"
          hitSlop={{ top: 8, right: 8, bottom: 8, left: 8 }}
        >
          <Icon name="caret-left" size="20" weight="bold" style={styles.defaultLeftIcon} />
        </TouchableOpacity>
      )}
      <View style={styles.contentContainer}>
        <FlatList
          ref={carouselRef as RefObject<FlatList>}
          data={data}
          renderItem={renderItem}
          horizontal
          keyExtractor={keyExtractor}
          onScroll={onScroll}
          pagingEnabled
          bounces
          showsHorizontalScrollIndicator={false}
        />
        <PageIndicator
          pages={data.length}
          selectedIndex={activeSlideIndex}
          goToSlide={goToSlide}
          style={styles.dots}
        />
      </View>
      {children}
    </SafeAreaView>
  )
})

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    flex: 1,
  },
  continueButton: {
    marginBottom: 32,
    marginHorizontal: 24,
  },
  dots: {
    flex: 0.2,
    position: 'relative',
    paddingTop: 16,
  },
  defaultLeftIcon: {
    marginLeft: 16,
  },
})
