import BleManager from 'react-native-ble-manager'
import {
  PERMISSIONS,
  PermissionStatus,
  RESULTS,
  requestMultiple,
  checkMultiple,
  Permission,
} from 'react-native-permissions'
import { Libre3BluetoothManagerConnectionState } from 'react-native-freestyle-libre'
import { Platform } from 'react-native'
import { Device } from '@src/config'

const requiredAndroidPermissions = (): Permission[] => {
  const apiLevel = Number(Platform.Version)

  // Android 12+
  if (apiLevel >= 31) {
    return [PERMISSIONS.ANDROID.BLUETOOTH_CONNECT, PERMISSIONS.ANDROID.BLUETOOTH_SCAN]
  }

  // we need to require PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION on Android 10+
  // and PERMISSIONS.ANDROID.ACCESS_COARSE_LOCATION or PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION on Android 9 and lower
  // more info:
  // https://developer.android.com/about/versions/10/privacy/changes#location-telephony-bluetooth-wifi

  // Android 10+
  if (apiLevel >= 29) {
    return [PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION]
  }

  return [PERMISSIONS.ANDROID.ACCESS_COARSE_LOCATION]
}

export const ALL_BLUETOOTH_PERMISSIONS =
  Platform.select({
    ios: [PERMISSIONS.IOS.BLUETOOTH_PERIPHERAL],
    android: requiredAndroidPermissions(),
  }) || []

export const requestBluetoothPermissions = () => {
  return requestMultiple(ALL_BLUETOOTH_PERMISSIONS)
}

export const areBluetoothPermissionsGranted = (results: Record<Permission, PermissionStatus>) => {
  return Object.values(results).every((result) => {
    if (Device.ios) {
      // iOS returns RESULTS.UNAVAILABLE when bluetooth is disabled with enabled bluetooth permission
      return result === RESULTS.GRANTED || result === RESULTS.UNAVAILABLE
    }

    return result === RESULTS.GRANTED
  })
}

export const checkBluetoothPermissions = async () => {
  const permissions = await checkMultiple(ALL_BLUETOOTH_PERMISSIONS)

  const isBluetoothDenied =
    // Android only returns DENIED during permission check
    checkPermissionStatus(RESULTS.DENIED, Object.values(permissions)) ||
    // iOS returns BLOCKED as well
    checkPermissionStatus(RESULTS.BLOCKED, Object.values(permissions))

  return { isBluetoothDenied }
}

export const checkPermissionStatus = (
  status: PermissionStatus,
  statusResults: PermissionStatus[],
) => {
  return statusResults.some((permissionStatus) => permissionStatus === status)
}

export const enableBluetoothAndroid = async () => {
  if (Device.android) {
    try {
      await BleManager.enableBluetooth()
    } catch {
      return false
    }

    return true
  } else {
    return true
  }
}

export const FORMATTED_BLUETOOTH_CONNECTION_STATE = {
  [Libre3BluetoothManagerConnectionState.NotConnected]: 'Not connected',
  [Libre3BluetoothManagerConnectionState.NotConnectedStoppedScanning]:
    'Not connected, stopped scanning',
  [Libre3BluetoothManagerConnectionState.NotConnectedWrongBlePin]:
    'Not connected, scan the sensor for connection',
  [Libre3BluetoothManagerConnectionState.Connecting]: 'Discovering sensor...',
  [Libre3BluetoothManagerConnectionState.SecurityChallenge]: 'Connecting...',
  [Libre3BluetoothManagerConnectionState.Connected]: 'Connected',
  [Libre3BluetoothManagerConnectionState.FailedToConnect]: 'Failed to connect',
}
