import React, { useContext } from 'react'
import { ScrollView, View } from 'react-native'
import { ImageStyle } from 'react-native-fast-image'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { RouteProp, useRoute } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button } from '@components/base'
import { InlineAlert, LoadingFastImage, NavigationBar } from '@components'
import { SurveyLinkConfigIntroItem } from '@src/types'
import { Analytics, CustomEventTypes } from '@src/config'
import { QuestionnaireScreenName, QuestionnaireStackParamList } from '@src/navigation/types'
import { MarkdownView } from '@src/components/Markdown'
import { QuestionnaireContext } from '../questionnaire.navigator'

export const InsuranceQuestionnaireStartScreen = () => {
  const {
    sectionStartScreenConfig: { findItemByIndex, currentIntroScreenIndex, onContinueButtonPress },
    startScreenConfig: { onCloseButtonPress },
  } = useContext(QuestionnaireContext)
  const styles = useStyleSheet(themedStyles)

  const insets = useSafeAreaInsets()
  const introScreen = findItemByIndex(currentIntroScreenIndex!) as SurveyLinkConfigIntroItem
  const { imageUrl, header, introMarkdown, footnote, inlineAlert } = introScreen

  const { params } = useRoute<
    RouteProp<QuestionnaireStackParamList, QuestionnaireScreenName.InsuranceQuestionnaireStart>
  >()

  const { allowEarlyExit = false, showEligibilitySuccessNote = false } = params || {}

  const onContinuePress = () => {
    Analytics.track(CustomEventTypes.InsuranceSurveySetupStart)
    return onContinueButtonPress(false)
  }

  return (
    <View style={styles.topLevelContainer}>
      {allowEarlyExit && (
        <NavigationBar
          title={header}
          leftAccessory={{
            iconDescriptor: { pack: 'eva', name: 'arrow-ios-back' },
            onPress: onCloseButtonPress,
          }}
        />
      )}
      <ScrollView
        bounces={false}
        contentContainerStyle={[
          styles.container,
          allowEarlyExit ? {} : styles.topSpacing,
          { paddingBottom: Math.max(insets.bottom, 16) },
        ]}
      >
        {showEligibilitySuccessNote && (
          <View style={styles.eligibilityNote}>
            <Text type="regular">🎉 Great! You’re eligible to use Nutrisense!</Text>
          </View>
        )}

        <View style={styles.headContainer}>
          {!allowEarlyExit && <Text type="title-3">{header}</Text>}
          {!!imageUrl && (
            <LoadingFastImage source={{ uri: imageUrl }} style={styles.image as ImageStyle} />
          )}
          {!!introMarkdown && (
            <MarkdownView content={introMarkdown} paragraphStyle={styles.description} />
          )}
        </View>

        <View>
          {!!inlineAlert && (
            <InlineAlert category={inlineAlert.type} message={inlineAlert.message} />
          )}
          {!!footnote && (
            <Text type="tiny" style={styles.footnote}>
              {footnote}
            </Text>
          )}
          <Button
            type="primary"
            size="block"
            onPress={onContinuePress}
            accessibilityLabel="Start questionnaire section"
          >
            Continue
          </Button>
        </View>
      </ScrollView>
    </View>
  )
}

const themedStyles = StyleService.create({
  topLevelContainer: {
    flex: 1,
  },
  topSpacing: {
    paddingTop: 60,
  },
  container: {
    flexGrow: 1,
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  headContainer: {
    alignItems: 'center',
  },
  image: {
    marginTop: 24,
    marginBottom: 12,
    aspectRatio: 2 / 1,
    width: '100%',
    resizeMode: 'cover',
  },
  description: {
    textAlign: 'left',
    marginVertical: 12,
    color: 'theme.text.primary',
  },
  footnote: {
    marginTop: 24,
    marginBottom: 8,
    textAlign: 'center',
  },
  eligibilityNote: {
    backgroundColor: 'theme.warning.lighter',
    paddingVertical: 8,
    alignItems: 'center',
    marginHorizontal: -16,
    marginBottom: 16,
  },
})
