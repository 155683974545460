import React, { useCallback } from 'react'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { Image, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Text } from '@components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Storage, useGoBack } from '@src/utils'
import { BottomSheet } from '@src/components'
import { cgmSteloImage } from '@src/assets/images'
import { markModalAsShown } from '@src/hooks/useOneTimeVisitedState'
import { UiStateNames } from '@src/types'
import { userSelector } from '@src/selectors/app'
import { useIsEligibleForStelo } from '../hooks/useIsEligibleForStelo'

export const RealtimeSteloUpgrade = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const goBack = useGoBack()
  const user = useSelector(userSelector)
  const dispatch = useDispatch()
  const isEligibleForStelo = useIsEligibleForStelo()

  const onActionPress = () => {
    if (isEligibleForStelo) {
      return navigation.navigate('SteloSetup', { mode: 'upgrade' })
    }

    navigation.navigate('SteloEligibility', {
      nextScreen: {
        screen: 'SteloSetup',
        params: { nextScreen: 'SteloSetup', params: { mode: 'upgrade' } },
      },
    })
  }

  const primaryButton = {
    text: 'Set up Connection',
    onPress: onActionPress,
  }

  const secondaryButton = {
    text: 'Dismiss',
    onPress: () => {
      goBack()
    },
  }

  const image = <Image source={cgmSteloImage.imageSource} />

  useFocusEffect(
    useCallback(() => {
      markModalAsShown(`${Storage.STELO_REALTIME_API_UPGRADE_MODAL_VISITED_KEY}_${user?.id}`)
      dispatch({
        type: 'app/upsertUiState',
        payload: {
          name: UiStateNames.SteloRealtimeApiUpgradeBanner,
          value: false,
        },
      })
    }, [dispatch, user?.id]),
  )

  return (
    <BottomSheet
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      buttonDirection="column-reverse"
    >
      <View style={styles.content}>
        <View style={styles.iconContainer}>{image}</View>
        <Text type="title-2" style={styles.text}>
          Direct integration is here!
        </Text>
        <View style={styles.body}>
          <Text type="regular" style={styles.text}>
            To connect your Stelo glucose data directly, click on Set up Connection below and enter
            your account information.
          </Text>
        </View>
      </View>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  content: {
    marginTop: 16,
  },
  iconContainer: {
    alignItems: 'center',
    marginBottom: 16,
  },
  body: {
    marginVertical: 24,
    paddingHorizontal: 16,
  },
  text: {
    textAlign: 'center',
  },
})
