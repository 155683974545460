import { SurveysConfigObjectQuestionsGroupQuestionResponseKind as QuestionResponseKind } from '@src/types'
import { AddressInput } from './AddressInput'
import { ConsentInput } from './ConsentInput'
import { DateInput } from './DateInput'
import { SliderInput } from './SliderInput'
import { TextAreaInput } from './TextAreaInput'
import { TextAreaWithSkipInput } from './TextAreaWithSkipInput'
import { PhotoUploadingInput } from './PhotoUploadingInput'
import { PhoneNumberInput } from './PhoneNumberInput'
import { TabbedTextInput } from './TabbedTextInput'
import { SelectInput } from './SelectInput'
import { SliderWithSkipInput } from './SliderWithSkipInput'
import { RadioInput } from './RadioInput'
import { CheckBoxMultiInput } from './CheckBoxMultiInput'
import { TextInput } from './TextInput'
import { HeightImperialInput } from './HeightImperialInput'

export const inputTypeToInputComponentMap: {
  [key in QuestionResponseKind]: ((props: any) => JSX.Element | JSX.Element[][]) | undefined
} = {
  [QuestionResponseKind.Address]: AddressInput,
  [QuestionResponseKind.CheckboxMulti]: CheckBoxMultiInput,
  [QuestionResponseKind.Consent]: ConsentInput,
  [QuestionResponseKind.DateInput]: DateInput,
  [QuestionResponseKind.FileUpload]: PhotoUploadingInput,
  [QuestionResponseKind.HeightImperial]: HeightImperialInput,
  [QuestionResponseKind.PhoneNumber]: PhoneNumberInput,
  [QuestionResponseKind.Radio]: RadioInput,
  [QuestionResponseKind.SelectSingle]: SelectInput,
  [QuestionResponseKind.Slider]: SliderInput,
  [QuestionResponseKind.SliderWithSkip]: SliderWithSkipInput,
  [QuestionResponseKind.TabsTextInput]: TabbedTextInput,
  [QuestionResponseKind.TextArea]: TextAreaInput,
  [QuestionResponseKind.TextAreaWithSkip]: TextAreaWithSkipInput,
  [QuestionResponseKind.TextInput]: TextInput,
}
