import { gql } from '@apollo/client'

export const LIST_USER_DAILY_ACTIONS = gql`
  query listUserDailyActions(
    $startDate: ISO8601Date
    $endDate: ISO8601Date!
    $status: UserDailyActionStatus
  ) {
    listUserDailyActions(startDate: $startDate, endDate: $endDate, status: $status) {
      dailyActions {
        id
        title
        description
        status
        kind
        category
        progress
        autocomplete
        createdAt
        updatedAt
        icon
        statKey
      }
      completionCounts {
        dailyActionId
        completionCount
      }
    }
  }
`
