import React from 'react'
import { InlineAlert } from '@src/components/InlineAlert'
import { UiInlineAlert } from '@src/types'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useIsDarkMode } from '@src/config/theme'
import { useShowSteloSyncDelayPopup } from '@src/utils/hooks'

export const SteloSyncDelayPopup = () => {
  const showPopup = useShowSteloSyncDelayPopup()
  const styles = useStyleSheet(themedStyles)
  const isDarkMode = useIsDarkMode()

  if (!showPopup) {
    return null
  }

  const textStyle = isDarkMode ? styles.darkText : null
  const borderStyle = isDarkMode ? styles.darkBorder : null

  return (
    <InlineAlert
      style={[styles.popup, borderStyle]}
      category={UiInlineAlert.Neutral}
      message="First Stelo data might take 3.5 hours to show in the app. Please check again later."
      textStyle={textStyle}
    />
  )
}

const themedStyles = StyleService.create({
  popup: {
    marginHorizontal: 16,
    marginTop: 8,
  },
  darkText: {
    color: 'theme.text.primary',
  },
  darkBorder: {
    borderColor: 'theme.surface.base',
  },
})
