import { gql } from '@apollo/client'
import { QUESTION_ITEM_FIELDS } from './fragments/question'

export const SAVE_SURVEY_RESPONSE = gql`
  ${QUESTION_ITEM_FIELDS}
  mutation saveSurveyResponse(
    $surveyLinkId: ID!
    $questionKey: String!
    $answer: JSON!
    $warnBeforeStopping: Boolean
  ) {
    saveSurveyResponse(
      surveyLinkId: $surveyLinkId
      questionKey: $questionKey
      answer: $answer
      warnBeforeStopping: $warnBeforeStopping
    ) {
      id
      surveyLink {
        config {
          items {
            ... on SurveyLinkConfigQuestionItem {
              type
              ...QuestionItemFields
            }
            ... on SurveyLinkConfigIntroItem {
              type
              header
              imageUrl
              introMarkdown
              icon
              footnote
              inlineAlert {
                type
                message
              }
            }
          }
          questionsCount
        }
      }
    }
  }
`
