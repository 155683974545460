import { gql } from '@apollo/client'
import { EHR_APPOINTMENT_ATTENDANCE_FIELDS } from './ehrAppointmentAttendance'

export const EHR_APPOINTMENT_FIELDS = gql`
  ${EHR_APPOINTMENT_ATTENDANCE_FIELDS}
  fragment EhrAppointmentFields on EhrAppointment {
    id
    getHealthieId
    category
    kind
    meetingAt
    title
    getHealthieInstanceKind
    startUrl
    joinUrl
    addToGcalLink
    unauthenticatedIcsLink
    lastState {
      reason
      kind
    }
    providerName
    recurrent
    attendances {
      ...EhrAppointmentAttendanceFields
    }
    recurrent
  }
`
