import React from 'react'
import { View } from 'react-native'
import FastImage, { ImageStyle } from 'react-native-fast-image'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '../base/Text'

interface CoachBubbleProps {
  photoSrc?: string | null
  label: string
  maxWidth?: number
}

export const NutritionistBubble = ({ photoSrc, label, maxWidth = 260 }: CoachBubbleProps) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={[styles.container, { maxWidth }]}>
      <View style={styles.labelContainer}>
        <Text type="regular" style={styles.label}>
          {label}
        </Text>
      </View>
      {photoSrc && (
        <>
          <FastImage source={{ uri: photoSrc }} style={styles.avatar as ImageStyle} />
          <View style={styles.firstBubble} />
          <View style={styles.secondBubble} />
        </>
      )}
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    position: 'relative',
    alignItems: 'center',
  },
  avatar: {
    position: 'absolute',
    width: 70,
    height: 74,
    borderRadius: 60,
    bottom: -60,
    zIndex: 0,
  },
  firstBubble: {
    zIndex: 2,
    position: 'absolute',
    width: 20,
    height: 20,
    left: '30%',
    bottom: -10,
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    transform: [{ rotate: '56deg' }],
    backgroundColor: 'theme.solid.white',
    borderBottomColor: 'theme.surface.base1',
    borderRightColor: 'theme.surface.base1',
    borderRadius: 20,
  },
  secondBubble: {
    zIndex: 2,
    position: 'absolute',
    width: 10,
    height: 10,
    bottom: -18,
    left: '37%',
    borderBottomWidth: 1,
    borderBottomColor: 'theme.surface.base1',
    backgroundColor: 'theme.solid.white',
    borderRadius: 5,
  },
  labelContainer: {
    backgroundColor: 'theme.solid.white',
    borderRadius: 16,
    padding: 16,
    elevation: 2, // For Android shadow
    shadowColor: 'theme.solid.black', // For iOS shadow
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    zIndex: 1,
  },
  label: {
    fontSize: 14,
    textAlign: 'left',
    color: 'theme.text.primary',
  },
})
