export const MINI_CHART_HEIGHT = 30
export const TOP_PADDING = 32
export const LEFT_PADDING = 40
export const RIGHT_PADDING = 30
export const SLIDER_PADDING = 20
export const ZOOM_MODE_SLIDER_PADDING = 40

export enum ChartTypes {
  Glucose = 'Glucose',
  Ketones = 'Ketones',
}

export const PLACEHOLDER_TEXT_BY_CHART_TYPE = {
  [ChartTypes.Glucose]: 'Scan CGM to view your glucose data',
  [ChartTypes.Ketones]: 'Add blood ketones measurements to see data',
}
