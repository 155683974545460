import { gql } from '@apollo/client'

export const FETCH_EHR_AVAILABLE_DATES_WITH_SLOTS = gql`
  query ehrAvailableDatesWithSlots(
    $appointmentKind: EhrAppointmentKind
    $providerKind: EhrAppointmentProviderKind
    $getHealthieInstanceKind: EhrGetHealthieInstanceKind
    $timeZone: String
  ) {
    ehrAvailableDatesWithSlots(
      appointmentKind: $appointmentKind
      providerKind: $providerKind
      getHealthieInstanceKind: $getHealthieInstanceKind
      timeZone: $timeZone
    ) {
      dates {
        date
        slots {
          time
          providerId
        }
      }
      providers {
        ... on Coach {
          id
          fullName
          bio
          photo
          credentials
          specialties {
            key
            name
          }
        }
      }
    }
  }
`
