import React, { useEffect } from 'react'
import { View, TouchableWithoutFeedback } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@src/components/base/Text'
import { ProgressCircle } from '@src/components'
import { Icon } from '@src/components/base/Icon'
import {
  appointmentRecurrenceSelector,
  pastEhrAppointmentsSelector,
} from '@src/screens/NutritionistHub/models/nutritionistHub.selectors'
import { EhrAppointmentStateKind } from '@src/types'
import { userPropertiesSelector } from '@src/selectors/app'

export const WeightLossExperiencePlanSection = () => {
  const theme = useTheme()
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const dispatch = useDispatch()

  const appointmentRecurrence = useSelector(appointmentRecurrenceSelector)
  const pastEhrAppointments = useSelector(pastEhrAppointmentsSelector)

  const { weightLossExperience } = useSelector(userPropertiesSelector) ?? {}
  const userIsParticipant = JSON.parse(weightLossExperience?.value || '{}').participating

  const countOfOccurredRecurrentAppointments = pastEhrAppointments.filter(
    (appointment) =>
      appointment.recurrent && appointment.lastState.kind === EhrAppointmentStateKind.Occurred,
  ).length
  const userCompletedAllCalls =
    appointmentRecurrence &&
    countOfOccurredRecurrentAppointments >= appointmentRecurrence?.numberOfCalls

  useEffect(() => {
    if (userIsParticipant) {
      dispatch({ type: 'nutritionistHub/fetchPastEhrAppointments' })
    }
  }, [dispatch, userIsParticipant])

  const handleOnPress = () => {
    navigation.navigate('AllVideoCalls')
  }

  if (!appointmentRecurrence || !userIsParticipant || userCompletedAllCalls) {
    return null
  }

  return (
    <TouchableWithoutFeedback accessibilityLabel="My Plan" onPress={handleOnPress}>
      <View style={styles.container}>
        <Text type="large" bold style={styles.titleText}>
          My Plan
        </Text>

        <View style={styles.contentContainer}>
          <View style={styles.progressCircleContainer}>
            <ProgressCircle
              style={styles.progressCircle}
              progress={countOfOccurredRecurrentAppointments / appointmentRecurrence.numberOfCalls}
              progressColor={theme['theme.info.light_']}
              backgroundColor={theme['theme.surface.base3']}
              strokeWidth={6}
            />
            <Text type="large" bold style={styles.progressText}>
              {appointmentRecurrence.numberOfCalls - countOfOccurredRecurrentAppointments}
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text type="large" bold>
              Complete initial calls!
            </Text>
            <Text type="small">
              Complete your remaining initial calls to achieve your weight loss goal!
            </Text>
          </View>
          <Icon name="caret-right" size={20} color={theme['theme.primary.base']} />
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    marginHorizontal: 16,
    marginTop: 16,
    marginBottom: 8,
  },
  titleText: {
    marginBottom: 8,
  },
  contentContainer: {
    flexDirection: 'row',
    gap: 16,
  },
  progressCircleContainer: {
    width: 48,
    height: 48,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'theme.surface.base3',
    borderRadius: 100,
    alignSelf: 'center',
  },
  progressCircle: {
    width: '100%',
    height: '100%',
  },
  progressText: {
    position: 'absolute',
    color: 'theme.text.primary',
  },
})
