import React from 'react'
import { View } from 'react-native'
import { StyleService } from '@src/style/service'
import {
  DigitValidator,
  PasswordLengthValidator,
  SpecialCharacterValidator,
  UpperCaseValidator,
} from '@src/utils/validators'
import { ValidationResult } from './ValidationResult'
import { PasswordStrength } from './PasswordStrength'

interface PasswordValidationInfoProps {
  password: string
  passwordRepeat?: string
  validatePasswordsMatch?: boolean
}

export const PasswordValidationInfo = (props: PasswordValidationInfoProps) => {
  const { password, passwordRepeat, validatePasswordsMatch } = props

  const passwordLengthIsValid = PasswordLengthValidator(password)
  const passwordHasUppercaseLetter = UpperCaseValidator(password)
  const passwordHasNumberOrSpecialSymbol =
    SpecialCharacterValidator(password) || DigitValidator(password)

  return (
    <View style={styles.container}>
      <PasswordStrength password={password} />
      <ValidationResult text="10 characters or more" valid={passwordLengthIsValid} />
      <ValidationResult text="At least one upper case letter" valid={passwordHasUppercaseLetter} />
      <ValidationResult
        text="At least one number or special symbol"
        valid={passwordHasNumberOrSpecialSymbol}
      />
      {!!passwordRepeat && (
        <ValidationResult
          text="The passwords do not match"
          valid={validatePasswordsMatch ?? false}
        />
      )}
    </View>
  )
}

const styles = StyleService.create({
  container: {
    marginBottom: 24,
  },
})
