import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { insightsCalendarSelector } from '@src/screens/Insights/models/insights.selectors'
import { Period } from '@src/types'
import { useGetCacheValue } from './useChartData'
import { WithinTimeChart } from './WithinTimeChart'

export interface WithinTimeChartDataItem {
  x: { min: number; max: number }
  y: number
}

interface WithinTimeChartProps {
  section: string
}

const FETCH_KEY = 'time_in_target'

export const WithinTimeChartManager = ({ section }: WithinTimeChartProps) => {
  const { startDate, endDate } = useSelector(insightsCalendarSelector)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({
      type: 'insights/fetchChartsData',
      payload: {
        filter: {
          startDate,
          endDate,
          period: Period.Day,
          types: [{ key: section, value: [FETCH_KEY] }],
        },
      },
    })
  }, [dispatch, endDate, section, startDate])

  const timeInRangeData = useGetCacheValue({
    startDate,
    endDate,
    section,
    fetchKey: FETCH_KEY,
    period: Period.Day,
  })

  const withinTimeChartData = ((timeInRangeData?.data || []) as WithinTimeChartDataItem[]).map(
    ({ x, y }) => ({
      x: `${x.min}-${x.max}`,
      y,
      type: 'current',
    }),
  )

  return <WithinTimeChart data={withinTimeChartData} datesRange={{ startDate, endDate }} />
}
