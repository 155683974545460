export const palette = {
  transparent: 'transparent',
  solid: {
    white: '#FFFFFF',
    black: '#0A0A0C',
  },
  opacity: {
    white70: '#FFFFFFB3',
    black70: '#0A0A0CB3',
  },
  accent: {
    citron: '#EFD482',
    brick: '#EFA982',
    moonstone: '#A9B7B9',
    tea: '#C2D0AE',
  },
  information: {
    lightest: '#C9F0FF',
    lighter: '#9BDCFD',
    light: '#6EC2FB',
    base: '#3599EF',
    darkest: '#0065D0',
  },
  success: {
    lightest: '#ECFCE5',
    lighter: '#7DDE86',
    light: '#4CD471',
    base: '#21BA67',
    darkest: '#198155',
  },
  warning: {
    lightest: '#FFEFD7',
    lighter: '#FFD188',
    light: '#FFC462',
    base: '#FFBB36',
    darkest: '#C6770C',
  },
  error: {
    lightest: '#FFE5E5',
    lighter: '#FF9898',
    light: '#FA5764',
    base: '#F83342',
    darkest: '#D3180C',
  },
  info: {
    lightest: '#C9F0FF',
    lighter: '#9BDCFD',
    light: '#6EC2FB',
    base: '#3599EF',
    darkest: '#0065D0',
  },
  like: '#FA5764',
  disabled: {
    base: '#D7D7D7',
    text: '#6B7B7B',
    ghost: '#929292',
  },
  marketing: {
    yellow: '#FFD862',
    lightYellow: '#FBEFC9',
    brown: '#A67C52',
    onText: '#102F32',
    subtleText: '#685211',
  },
  light: {
    primary: {
      base: '#11494F',
      dark: '#0D363A',
    },
    secondary: {
      base: '#F8F6F1',
      dark: '#F0ECE2',
      darker: '#EFE6CF',
    },
    text: {
      primary: '#102F32',
      secondary: '#416461',
      tertiary: '#9DAEAE',
      quaternary: '#B8CDCD',
      link: '#136EF5',
      inColor: '#D9ECEC',
    },
    background: {
      base: '#FFFFFF',
      light: '#FFFFFF',
      modal: '#FFFFFF',
      event: '#F8F6F1',
      drawer: '#FFFFFF',
      selected: '#F7F9FA',
    },
    surface: {
      base0: '#9DAEAE',
      base: '#D7DDDD',
      base1: '#E7EAEA',
      base2: '#F2F4F5',
      base3: '#F7F9FA',
      banner: '#E6F1F1',
    },
    disabled: {
      background: '#D7D7D7',
      text: '#A0A0A0',
    },
    range: {
      good: '#00873F',
      regular: '#FFB114',
      bad: '#D3180C',
    },
    rangeText: {
      good: '#00873F',
      regular: '#E86826',
      bad: '#B50000',
    },
    barChart: {
      primary: '#1A7079',
      secondary: '#B8CDCD',
    },
    tooltip: {
      background: '#0A0A0C',
      surface: '#57626D',
      textPrimary: '#FFFFFF',
      textSecondary: '#B5B5B6',
      link: '#67A1FF',
    },
    marketing: {
      lightYellow: '#FBEFC9',
    },
  },
  dark: {
    primary: {
      base: '#1A7079',
      dark: '#0D363A',
    },
    secondary: {
      base: '#272C2E',
      dark: '#36444A',
      darker: '#1B2427',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#B5B5B6',
      tertiary: '#858586',
      quaternary: '#3F3E3E',
      link: '#67A1FF',
      inColor: '#86CBCB',
    },
    background: {
      base: '#141418',
      light: '#303437',
      modal: '#202325',
      event: '#272C2E',
      drawer: '#232327',
      selected: '#06181A',
    },
    surface: {
      base0: '#667079',
      base: '#373D41',
      base1: '#2B2F32',
      base2: '#202325',
      base3: '#1A1C1E',
      banner: '#40484B',
    },
    disabled: {
      background: '#1A1C1E',
      text: '#424344',
    },
    range: {
      good: '#4CD471',
      regular: '#FFC462',
      bad: '#F83342',
    },
    rangeText: {
      good: '#4CD471',
      regular: '#FFC462',
      bad: '#F83342',
    },
    barChart: {
      primary: '#11494F',
      secondary: '#A9B7B9',
    },
    tooltip: {
      background: '#FFFFFF',
      surface: '#C7E1E1',
      textPrimary: '#102F32',
      textSecondary: '#416461',
      link: '#136EF5',
    },
    marketing: {
      lightYellow: '#8E7837',
    },
  },
}
