import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CardStyleInterpolators,
  createStackNavigator,
  StackNavigationOptions,
} from '@react-navigation/stack'
import RNBootSplash from 'react-native-bootsplash'
import { NavigationContainer, NavigationState, PartialState } from '@react-navigation/native'
import { useTheme } from '@ui-kitten/components'
import Heap from '@heap/react-native-heap'
import { IConfig } from 'unleash-proxy-client'
import { useFlagsStatus, useUnleashClient, useUnleashContext } from '@unleash/proxy-client-react'
import { useIsDarkMode } from '@config/theme'
import { createNavigator } from '@src/navigation/containers/Navigator'
import { AuthWithThirdPartyNavigator, getAppNavigator } from '@src/navigation'
import { Analytics, App, Bugsnag, CustomEventTypes } from '@config'
import { lastRequestStatusSelector, userSelector } from '@src/selectors/app'
import { OnboardingNavigator } from '@src/navigation/navigators/onboarding.navigator'
import { useImpersonationContext } from '@src/config/impersonation'
import { Storage } from '@src/utils'
import {
  AddressModal,
  CameraScreen,
  CreatePaymentMethodScreen,
  DiscountCodeModal,
  OutdatedScreen,
  SensorSelection,
  WalkthroughCarouselScreen,
} from '@src/screens'
import { Login } from '@src/models/app.types'
import { useShouldShowPurchaseFlow } from '@src/utils/hooks'
import { Feature } from '@src/components'
import { QuestionnaireNavigator } from '@src/screens/Questionnaire/questionnaire.navigator'
import { useFeatureFlag } from '@src/components/FeatureFlag/FeatureFlagUtils'
import { useSetIntercomUser } from '@src/hooks/useSetIntercomUser'
import { NavigationRouteConfig } from '@src/utils/analytics'
import { navigationRef, useNavigationContext } from '@src/config/navigation'
import { useHasLargeScreen } from '@src/config/device'
import NFCListenerContainer from '@src/containers/NFCListenerContainer'
import { AlmostThere } from '@src/screens/SignUp/components/Approvals/AlmostThere'
import { useReducedMotion } from '@src/config/accessibility'
import { Scene, SceneContext } from '@src/config/scene'
import { ActionBottomSheetProvider } from '@components'
import { useScreenCaptureTracking } from '@src/hooks/useScreenCaptureTracking'
import { useStorageValue } from '@src/utils/storage'
import { useIframeImpersonation } from '@src/hooks/useIframeImpersonation'
import { useApprovalFlow } from '@src/screens/SignUp/components/Approvals/useApprovalFlow'
import { ActionBottomSheet } from '@src/components/ActionBottomSheet/ActionBottomSheet'
import { useShouldShowByosOnboarding } from '@src/screens/OwnSensorOnboarding/hooks'
import { ScanModalContainer } from '@src/screens/Scans/containers/ScanModal'
import { CMSTutorial } from '@src/screens/Tutorials/containers/CMSTutorial'
import { useIsResearchEligiblePendingEnrollment } from '@src/screens/SignUp/components/SensorSelection/hooks.ts'
import { HomeScreenWalkthroughProvider } from '@src/context/homeScreenWalkthroughContext'
import { CancelModal } from '@src/components/CancelModal'
import { LoadingContainer } from '@src/screens/Common/containers/LoadingContainer'
import { SteloPaymentSuccessfulScreen } from '@src/screens/SignUp'
import { AppStackParamList } from '../types'
import { fadeFromBottomModalOptions } from '../utils'
import { DrawerRoute, useDrawerRoutes } from '../components/drawer/hooks'
import { linking } from '../linking'

const AppContainer = Heap.withReactNavigationAutotrack(NavigationContainer)
const BugsnagNavigationContainer = Bugsnag.createNavigationContainer(AppContainer)
const Stack = createStackNavigator()

const MIN_SPLASH_SCREEN_DURATION = 3000

const getCurrentSceneName = ({
  isAuth,
  isOutdated,
  shouldShowPurchaseFlow,
  shouldShowByosOnboarding,
  shouldShowApprovalFlow,
}: {
  isAuth: boolean
  isOutdated: boolean
  shouldShowPurchaseFlow: boolean
  shouldShowByosOnboarding: boolean
  shouldShowApprovalFlow: boolean
}) => {
  if (isOutdated) {
    return Scene.Outdated
  }

  if (!isAuth) {
    return Scene.Auth
  }

  if (shouldShowPurchaseFlow || shouldShowByosOnboarding) {
    return Scene.Onboarding
  }

  if (shouldShowApprovalFlow) {
    return Scene.Approval
  }

  return Scene.AppAccess
}

const getCurrentScene = ({
  sceneName,
  screenOptions,
  hasLargeScreen,
  drawerRoutes,
  shouldShowWalkthrough,
  onboardingFlowWithGoalQuestionEnabled,
}: {
  sceneName: Scene
  screenOptions: StackNavigationOptions
  hasLargeScreen: boolean
  drawerRoutes: DrawerRoute[]
  shouldShowWalkthrough: boolean
  onboardingFlowWithGoalQuestionEnabled: boolean
}) => {
  switch (sceneName) {
    case Scene.Outdated:
      return <Stack.Screen name="OutdatedScreen" component={OutdatedScreen} />
    case Scene.Auth:
      return (
        <>
          {!onboardingFlowWithGoalQuestionEnabled && shouldShowWalkthrough && (
            <Stack.Screen name="Walkthrough" component={WalkthroughCarouselScreen} />
          )}
          <Stack.Screen
            name="Auth"
            options={screenOptions}
            component={AuthWithThirdPartyNavigator}
          />
          {onboardingFlowWithGoalQuestionEnabled && (
            <Stack.Screen name="Walkthrough" component={WalkthroughCarouselScreen} />
          )}
        </>
      )
    case Scene.Onboarding:
      return (
        <>
          <Stack.Screen name="Onboarding" options={screenOptions} component={OnboardingNavigator} />
          <Stack.Screen name="AddScan" component={ScanModalContainer} />
          <Stack.Screen name="CMSTutorial" component={CMSTutorial} />
          <Stack.Screen name="CancelModal" component={CancelModal} />
        </>
      )
    case Scene.Approval:
      return <Stack.Screen name="AlmostThere" options={screenOptions} component={AlmostThere} />
    default:
      return getAppNavigator(Stack, screenOptions, hasLargeScreen, drawerRoutes)
  }
}

const getInitialRouteName = ({
  isAuth,
  shouldShowPurchaseFlow,
  shouldShowApprovalFlow,
  shouldShowByosOnboarding,
  shouldShowSensorSelection,
  shouldShowWalkthrough,
  onboardingFlowWithGoalQuestionEnabled,
}: {
  isAuth: boolean
  shouldShowPurchaseFlow: boolean
  shouldShowApprovalFlow: boolean
  shouldShowByosOnboarding: boolean
  shouldShowSensorSelection: boolean
  shouldShowWalkthrough: boolean
  onboardingFlowWithGoalQuestionEnabled: boolean
}) => {
  if (!onboardingFlowWithGoalQuestionEnabled && shouldShowWalkthrough) {
    return 'Walkthrough'
  }

  if (!isAuth) {
    return 'Auth'
  }

  if (shouldShowPurchaseFlow || shouldShowByosOnboarding) {
    return 'Onboarding'
  }

  if (shouldShowApprovalFlow) {
    return 'AlmostThere'
  }

  if (shouldShowSensorSelection) {
    return 'SensorSelection'
  }

  return 'Drawer'
}

const MainApp = () => {
  const dispatch = useDispatch()
  const lastRequestStatus = useSelector(lastRequestStatusSelector)
  const user = useSelector(userSelector)
  const isAuth = !!user
  const isDexcomResearchEnabled = useFeatureFlag(Feature.DexcomResearch)

  const userId = user?.id
  const userEmail = user?.email

  const updateContext = useUnleashContext()
  const unleashClient = useUnleashClient()

  const isImpersonating = useImpersonationContext()

  const [adminLogin] = useStorageValue<Login>('admin_login')
  const adminId = adminLogin?.user?.id

  useIframeImpersonation(adminLogin)

  useEffect(() => {
    const context: IConfig['context'] = {
      userId,
      properties: {
        appVersion: App.version,
        appBuildEnv: App.build.env,
        isImpersonating: isImpersonating.toString(),
        isAdmin: (!!adminId).toString(),
        adminId: adminId || '',
        userEmail: userEmail || '',
      },
    }
    updateContext(context)
  }, [userId, isImpersonating, adminId, updateContext, userEmail])

  useEffect(() => {
    unleashClient.on('impression', (event: any) => {
      const { eventType, enabled, featureName: featureFlag } = event

      if (eventType === 'isEnabled') {
        Analytics.track(
          CustomEventTypes.FeatureFlagFetched,
          { enabled, featureFlag },
          `${CustomEventTypes.FeatureFlagFetched}/${featureFlag}`,
        )
      }
    })
  }, [unleashClient])

  const shouldShowPurchaseFlow = useShouldShowPurchaseFlow()
  const shouldShowByosOnboarding = useShouldShowByosOnboarding()

  useEffect(() => {
    dispatch({
      type: 'app/updateOnboardingState',
      payload: { isInProgress: shouldShowPurchaseFlow || shouldShowByosOnboarding },
    })
  }, [dispatch, shouldShowPurchaseFlow, shouldShowByosOnboarding])

  // If the user selected a sensor at checkout, then this will be true during the
  // prescription and research approval flows; shouldShowSensorSelection will therefore be false.
  //
  const { isApprovalRequired: shouldShowApprovalFlow } = useApprovalFlow()

  const isResearchEligiblePendingEnrollment = useIsResearchEligiblePendingEnrollment()

  const shouldShowSensorSelection =
    !isImpersonating &&
    isDexcomResearchEnabled &&
    !shouldShowApprovalFlow &&
    isResearchEligiblePendingEnrollment

  const hasLargeScreen = useHasLargeScreen()
  const drawerRoutes = useDrawerRoutes()

  const isOutdated = lastRequestStatus === 426

  const [walkthroughShown] = useStorageValue<boolean>(Storage.WALKTHROUGH_SHOWN_KEY)

  const shouldShowWalkthrough = !isAuth && !walkthroughShown

  const reduceMotionEnabled = useReducedMotion()
  const onboardingFlowWithGoalQuestionEnabled = useFeatureFlag(
    Feature.OnboardingFlowWithGoalQuestion,
  )

  const screenOptions: StackNavigationOptions = {
    headerShown: false,
    animationEnabled: !reduceMotionEnabled,
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  }

  const currentSceneName = getCurrentSceneName({
    isAuth,
    isOutdated,
    shouldShowPurchaseFlow,
    shouldShowByosOnboarding,
    shouldShowApprovalFlow,
  })

  const currentScene = getCurrentScene({
    sceneName: currentSceneName,
    screenOptions,
    hasLargeScreen,
    drawerRoutes,
    shouldShowWalkthrough,
    onboardingFlowWithGoalQuestionEnabled,
  })

  const initialRouteName = getInitialRouteName({
    isAuth,
    shouldShowPurchaseFlow,
    shouldShowApprovalFlow,
    shouldShowByosOnboarding,
    shouldShowSensorSelection,
    shouldShowWalkthrough,
    onboardingFlowWithGoalQuestionEnabled,
  })

  const hasAppAccess =
    isAuth && !shouldShowPurchaseFlow && !shouldShowApprovalFlow && !shouldShowSensorSelection

  const [navigationReady, setNavigationReady] = useState(false)

  const [isMinSplashTimeElapsed, setIsMinSplashTimeElapsed] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsMinSplashTimeElapsed(true)
    }, MIN_SPLASH_SCREEN_DURATION)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  const shouldHideSplashScreen = isMinSplashTimeElapsed && navigationReady

  useEffect(() => {
    if (!shouldHideSplashScreen) {
      return
    }

    RNBootSplash.hide({ fade: true })
  }, [shouldHideSplashScreen])

  const routeNameRef = useRef<NavigationRouteConfig['name'] | null>(null)
  const [routeName, setRouteNameState] = useState<NavigationRouteConfig['name'] | null>(null)

  const setRouteName = (name: NavigationRouteConfig['name']) => {
    routeNameRef.current = name
    setRouteNameState(name)
  }

  useScreenCaptureTracking(routeNameRef)

  useSetIntercomUser({ hasAppAccess, routeName })

  useNavigationContext(navigationRef.current)

  const getActiveRoute = (
    state?: NavigationState | PartialState<NavigationState>,
  ): NavigationRouteConfig | null => {
    if (!state || typeof state.index !== 'number') {
      return null
    }

    const route = state.routes[state.index]

    if (route.state) {
      return getActiveRoute(route.state)
    }

    return route as NavigationRouteConfig
  }

  const theme = useTheme()
  const isDarkMode = useIsDarkMode()

  const navigationTheme = {
    dark: isDarkMode,
    colors: {
      primary: theme['theme.primary.base'],
      background: theme['theme.background'],
      card: theme['theme.surface.base'],
      text: theme['theme.text.primary'],
      border: theme['theme.surface.base'],
      notification: theme['theme.error.base'],
    },
  }

  const { flagsReady, flagsError } = useFlagsStatus()
  const flagsLoaded = flagsReady || flagsError

  if (!flagsLoaded) {
    return <LoadingContainer />
  }

  return (
    <BugsnagNavigationContainer
      ref={navigationRef}
      linking={linking(initialRouteName, hasLargeScreen)}
      onReady={() => {
        setNavigationReady(true)
        Analytics.screenView(initialRouteName)
        setRouteName(initialRouteName)
      }}
      onStateChange={(state) => {
        const previousRouteName = routeNameRef.current
        const currentRoute = getActiveRoute(state)

        if (currentRoute && currentRoute.name !== previousRouteName) {
          Analytics.screenView(currentRoute.name, { ...currentRoute.params })
          setRouteName(currentRoute.name)
        }
      }}
      theme={navigationTheme}
    >
      <SceneContext.Provider value={{ scene: currentSceneName }}>
        <ActionBottomSheetProvider>
          <NFCListenerContainer>
            <HomeScreenWalkthroughProvider routeName={routeName}>
              <Stack.Navigator initialRouteName={initialRouteName} screenOptions={screenOptions}>
                {currentScene}

                <Stack.Screen
                  options={{
                    cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS,
                    presentation: 'transparentModal',
                  }}
                  name="Camera"
                  component={CameraScreen}
                />
                <Stack.Screen
                  name="ActionBottomSheet"
                  component={ActionBottomSheet}
                  options={fadeFromBottomModalOptions}
                />

                {isAuth && (
                  <>
                    <Stack.Screen name="ChangeAddress" component={AddressModal} />
                    <Stack.Screen
                      name="CreatePaymentMethod"
                      component={CreatePaymentMethodScreen}
                    />
                    <Stack.Screen
                      name="SteloPaymentSuccessful"
                      component={SteloPaymentSuccessfulScreen}
                    />
                    <Stack.Screen
                      name="DiscountCode"
                      component={DiscountCodeModal}
                      options={fadeFromBottomModalOptions}
                    />
                    <Stack.Screen
                      name="Questionnaire"
                      component={QuestionnaireNavigator}
                      getId={({ params }) =>
                        (params as AppStackParamList['Questionnaire'] | undefined)?.questionnaire
                      }
                    />
                    {isDexcomResearchEnabled && (
                      <Stack.Screen name="SensorSelection" component={SensorSelection} />
                    )}
                  </>
                )}
              </Stack.Navigator>
            </HomeScreenWalkthroughProvider>
          </NFCListenerContainer>
        </ActionBottomSheetProvider>
      </SceneContext.Provider>
    </BugsnagNavigationContainer>
  )
}

export const Navigation = createNavigator(MainApp)
