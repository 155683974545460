import React from 'react'
import { ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Feature, InlineAlert, useFeatureFlag } from '@src/components'
import { UiInlineAlert } from '@src/types'
import { NavigationContainer } from '@src/screens/Common/containers'
import { marketplaceByosImage, marketplaceIphoneWAppImage } from '@src/assets/images'
import { Storage } from '@src/utils'
import { ProductSummary } from '@src/screens/Marketplace/components/NewMarketplace/ProductSummary'
import { OnboardingFlowType } from '../constants'

export const NewGettingStarted = () => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const navigation = useNavigation()
  const steloSignUpEnabled = useFeatureFlag(Feature.SteloSignUp)

  const onOwnSensorPress = () => {
    Storage.set(Storage.ONBOARDING_FLOW_TYPE_SELECTED_KEY, OnboardingFlowType.OwnSensor)
    navigation.navigate('OwnSensorSelection')
  }

  const onPurchaseSensorPress = () => {
    if (steloSignUpEnabled) {
      Storage.set(Storage.ONBOARDING_FLOW_TYPE_SELECTED_KEY, OnboardingFlowType.PurchaseStelo)
      navigation.navigate('CreateAccountOptionsStelo')
    } else {
      Storage.set(Storage.ONBOARDING_FLOW_TYPE_SELECTED_KEY, OnboardingFlowType.Purchase)
      navigation.navigate('CreateAccountOptions')
    }
  }

  return (
    <NavigationContainer title="Getting Started">
      <ScrollView
        contentContainerStyle={[styles.content, { paddingBottom: insets.bottom + 16 }]}
        bounces={false}
      >
        <View style={styles.productsContainer}>
          <ProductSummary
            title="Glucose Programs"
            description="Optimize your health with glucose monitoring and dietitian support."
            image={marketplaceIphoneWAppImage}
            imageStyle={{ marginRight: 32 }}
            onPress={onPurchaseSensorPress}
          />
          <ProductSummary
            title="Bringing My Own CGM"
            description="Activate your own sensor seamlessly with the Nutrisense app."
            image={marketplaceByosImage}
            imageStyle={{ alignSelf: 'center' }}
            onPress={onOwnSensorPress}
          />
        </View>
        <InlineAlert
          category={UiInlineAlert.Neutral}
          // eslint-disable-next-line max-len
          message="Your Program might include a CGM (Continuous Glucose Monitor) or Glucose Biosensor. These are medical devices that continuously track glucose levels in real-time."
        />
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  content: {
    flexGrow: 1,
    paddingTop: 16,
    marginHorizontal: 16,
  },
  productsContainer: {
    flex: 1,
    gap: 16,
    marginBottom: 16,
  },
})
