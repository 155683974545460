import { useNavigation } from '@react-navigation/native'
import { Product, SubscriptionCheckoutOptionKind } from '@src/types'
import { CheckoutKind } from '@screens/Marketplace/types/types.ts'
import { useAutomaticCheckoutOptionKinds } from '../components/SensorSelection/hooks'

export interface ContinueThroughCheckoutProps {
  product: Product
  checkoutOptionKinds?: SubscriptionCheckoutOptionKind[]
  requireEligibilityCheck: boolean
  savings?: number | undefined
  checkoutKind?: CheckoutKind
  subscriptionId?: string
}

export const useNavigateToCheckout = () => {
  const navigation = useNavigation()
  const automaticCheckoutOptionKinds = useAutomaticCheckoutOptionKinds()

  const navigateToCheckout = ({
    product,
    requireEligibilityCheck,
    savings,
    checkoutOptionKinds = automaticCheckoutOptionKinds,
    checkoutKind,
    subscriptionId,
  }: ContinueThroughCheckoutProps) => {
    if (product.includesCgm && !checkoutOptionKinds) {
      return navigation.navigate('SensorSelection', {
        product,
        requireEligibilityCheck,
        savings,
      })
    }

    if (requireEligibilityCheck) {
      return navigation.navigate('EligibilityCheck', {
        product,
        savings,
        checkoutOptionKinds,
      })
    }

    return navigation.navigate('SubscriptionCheckout', {
      product,
      savings,
      checkoutOptionKinds,
      checkoutKind,
      subscriptionId,
    })
  }

  return navigateToCheckout
}
