import { mean } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { BaseChart } from './BaseChart'
import { ByHourChartDataItem } from './ByHourChartManager'
import { groupHourDataByNHours } from './helper'
import { renderByHourChart } from './renderByHourChart'
import { DatesRange, PointTimePeriod } from './types'

export enum PeriodsDisplayType {
  AllPeriods = 'AllPeriods',
  OnlySleepHours = 'OnlySleepHours',
}

const periodsDisplayTypeToXScaleValuesMap: Record<PeriodsDisplayType, string[] | undefined> = {
  [PeriodsDisplayType.AllPeriods]: undefined,
  [PeriodsDisplayType.OnlySleepHours]: ['12-4 AM', '4-8 AM'],
}

export interface ByHourChartProps {
  precision: 0 | 1
  currentDataByHour: ByHourChartDataItem[]
  previousDataByHour: ByHourChartDataItem[]
  yLabel: string
  periodsDisplayType: PeriodsDisplayType
  onLoad?: () => void
  range?: {
    min: number
    max: number
  }
  datesRange: DatesRange
}

export const ByHourChart = ({
  precision,
  currentDataByHour,
  previousDataByHour,
  yLabel,
  periodsDisplayType,
  range,
  onLoad,
  datesRange,
}: ByHourChartProps) => {
  const byHourData = useMemo(
    () => [
      ...groupHourDataByNHours({
        data: currentDataByHour,
        hourInterval: 4,
        aggregationFunction: mean,
        roundPrecision: precision,
      }).map((item) => ({ ...item, type: PointTimePeriod.Current })),
      ...groupHourDataByNHours({
        data: previousDataByHour,
        hourInterval: 4,
        aggregationFunction: mean,
        roundPrecision: precision,
      }).map((item) => ({ ...item, type: PointTimePeriod.Previous })),
    ],
    [currentDataByHour, previousDataByHour, precision],
  )

  const hasHourData = byHourData.some(
    (item) => item.y !== null && item.type === PointTimePeriod.Current,
  )
  const renderFunction = useCallback(
    (style: Record<string, string | number>) => {
      if (!hasHourData) {
        return null
      }

      return renderByHourChart({
        data: byHourData,
        range,
        yLabel,
        style: style as any,
        xScaleValues: periodsDisplayTypeToXScaleValuesMap[periodsDisplayType],
      })
    },
    [byHourData, hasHourData, yLabel, periodsDisplayType, range],
  )

  return (
    <BaseChart
      datesRange={datesRange}
      renderFunction={renderFunction}
      title="By hour of day"
      height={235}
      onLoad={onLoad}
    />
  )
}
