import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Text } from '@src/components/base/Text'
import { NutritionistBubble } from '@src/components/NutritionistBubble'
import { topPriorityCoachAssignmentSelector } from '@src/screens/NutritionistHub/models/nutritionistHub.selectors'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button } from '@src/components/base/Button'
import { Icon } from '@src/components/base/Icon'
import { RootStackParamList } from '@src/navigation/types'

export const Completed = () => {
  const styles = useStyleSheet(themedStyles)
  const coachAssignment = useSelector(topPriorityCoachAssignmentSelector)
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()

  const handleGoToHome = () => {
    navigation.navigate('Home')
  }

  return (
    <>
      <View style={{ alignItems: 'center' }}>
        <Icon name="check-circle" size={67} weight="fill" style={styles.icon} />

        <NutritionistBubble
          label="Calls were scheduled with me successfully"
          photoSrc={coachAssignment?.coach?.coachProfile?.photo}
          maxWidth={190}
        />

        <Text type="regular" style={styles.description}>
          You’re on the right track to begin your weight loss journey!
        </Text>
      </View>

      <Button
        type="outline"
        size="block"
        accessibilityLabel="Go To Home"
        onPress={handleGoToHome}
        style={styles.button}
      >
        Go Home
      </Button>
    </>
  )
}

const themedStyles = StyleService.create({
  icon: {
    alignSelf: 'center',
    width: 64,
    height: 64,
    marginTop: 16,
    marginBottom: 48,
    color: 'theme.success.base',
  },
  description: {
    fontSize: 17,
    textAlign: 'center',
    maxWidth: 300,
    marginTop: 110,
    marginBottom: 32,
  },

  button: {
    width: '100%',
  },
})
