import Config from 'react-native-config'
import { User } from '@src/types'
import { Storage } from '@src/utils'
import { ByosVideoCallsUpsellStep, OWN_SENSOR_GOAL_SURVEY_RESPONSE_MAP } from '../constants'

export const recordByosVideoCallsUpsellInteraction = (
  step: ByosVideoCallsUpsellStep,
  user: User,
) => {
  Storage.set(`${Storage.BYOS_VIDEO_CALLS_UPSELL_LAST_STEP_KEY}_${user?.id}`, step)
  Storage.set(
    `${Storage.BYOS_VIDEO_CALLS_UPSELL_LAST_INTERACTION_TIMESTAMP_KEY}_${user?.id}`,
    Date.now(),
  )
}

export const getMinimumMinutesToWaitBetweenSteps = () => {
  const build = Config.ENV
  return build === 'production' ? 24 * 60 * 1 : 2
}

export const getMinimumMinutesToWaitToStartCampaign = () => {
  const build = Config.ENV
  return build === 'production' ? 24 * 60 * 2 : 2
}

export const humanizeOwnSensorGoalSurveyAnswer = (answer: string) => {
  const mappedAnswer = OWN_SENSOR_GOAL_SURVEY_RESPONSE_MAP[answer]

  return mappedAnswer ?? answer.split('_').join(' ')
}
